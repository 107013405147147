export enum Translations {
    "LABEL.PRODUCT_LIST" = "LABEL.PRODUCT_LIST",
    "LABEL.SCANNER_LIST" = "LABEL.SCANNER_LIST",
    "LABEL.ORDER_LIST" = "LABEL.ORDER_LIST",
    "LABEL.AUDIT_LIST" = "LABEL.AUDIT_LIST",
    "LABEL.SITES" = "LABEL.SITES",
    "LABEL.AUDIT_TEMPLATES" = "LABEL.AUDIT_TEMPLATES",
    "LABEL.PARTNERS" = "LABEL.PARTNERS",
    "LABEL.USERS" = "LABEL.USERS",
    "LABEL.PRINT_EXPORT" = "LABEL.PRINT_EXPORT",
    "LABEL.BLOCKCHAIN_STATUS" = "LABEL.BLOCKCHAIN_STATUS",
    "LABEL.ACCEPT" = "LABEL.ACCEPT",
    "LABEL.ACCEPT_PARTNERSHIP" = "LABEL.ACCEPT_PARTNERSHIP",
    "LABEL.ACTION" = "LABEL.ACTION",
    "LABEL.ACTIVE" = "LABEL.ACTIVE",
    "LABEL.ADJUST_POSITION" = "LABEL.ADJUST_POSITION",
    "LABEL.ADMIN" = "LABEL.ADMIN",
    "LABEL.ADDRESS" = "LABEL.ADDRESS",
    "LABEL.PROCESSES" = "LABEL.PROCESSES",
    "LABEL.ADMINISTRATOR_PHONE" = "LABEL.ADMINISTRATOR_PHONE",
    "LABEL.ALL_RIGHTS_RESERVED" = "LABEL.ALL_RIGHTS_RESERVED",
    "LABEL.ATTACH_FILES" = "LABEL.ATTACH_FILES",
    "LABEL.AUDIT" = "LABEL.AUDIT",
    "LABEL.AUDIT.ACCEPT" = "LABEL.AUDIT.ACCEPT",
    "LABEL.AUDIT.REJECT" = "LABEL.AUDIT.REJECT",
    "LABEL.AUDIT.TRANSFER" = "LABEL.AUDIT.TRANSFER",
    "LABEL.AUDITOR" = "LABEL.AUDITOR",
    "LABEL.AUDITS" = "LABEL.AUDITS",
    "LABEL.AUDIT_CLIENT" = "LABEL.AUDIT_CLIENT",
    "LABEL.AUDIT_ID" = "LABEL.AUDIT_ID",
    "LABEL.AUDIT_ID_PLACEHOLDER" = "LABEL.AUDIT_ID_PLACEHOLDER",
    "LABEL.AUDIT_INFORMATION" = "LABEL.AUDIT_INFORMATION",
    "LABEL.AUDIT_TABS.ACCEPTED" = "LABEL.AUDIT_TABS.ACCEPTED",
    "LABEL.AUDIT_TABS.PENDING" = "LABEL.AUDIT_TABS.PENDING",
    "LABEL.AUDIT_TABS.PREVIOUS" = "LABEL.AUDIT_TABS.PREVIOUS",
    "LABEL.AUDIT_TABS.MISSED" = "LABEL.AUDIT_TABS.MISSED",
    "LABEL.AUDIT_TABS.RECEIVED" = "LABEL.AUDIT_TABS.RECEIVED",
    "LABEL.AUDIT_TABS.REJECTED" = "LABEL.AUDIT_TABS.REJECTED",
    "LABEL.AUDIT_TABS.UPCOMING" = "LABEL.AUDIT_TABS.UPCOMING",
    "LABEL.AUDIT_TEMPLATE" = "LABEL.AUDIT_TEMPLATE",
    "LABEL.BACK_TO_LOGIN" = "LABEL.BACK_TO_LOGIN",
    "LABEL.BARCODE_TOOLTIP" = "LABEL.BARCODE_TOOLTIP",
    "LABEL.BC_FORM.BTN_INSERT" = "LABEL.BC_FORM.BTN_INSERT",
    "LABEL.BC_FORM.BTN_UPDATE" = "LABEL.BC_FORM.BTN_UPDATE",
    "LABEL.BC_FORM.COMPARISON" = "LABEL.BC_FORM.COMPARISON",
    "LABEL.BC_FORM.INSERT_SUCCESS" = "LABEL.BC_FORM.INSERT_SUCCESS",
    "LABEL.BC_FORM.UPDATE_SUCCESS" = "LABEL.BC_FORM.UPDATE_SUCCESS",
    "LABEL.BLOCKCHAIN" = "LABEL.BLOCKCHAIN",
    "LABEL.BRAND" = "LABEL.BRAND",
    "LABEL.CANNOT_TRANSFER_AUDIT" = "LABEL.CANNOT_TRANSFER_AUDIT",
    "LABEL.CARBON" = "LABEL.CARBON",
    "LABEL.CERTIFICATES" = "LABEL.CERTIFICATES",
    "LABEL.CHANGE_PASSWORD" = "LABEL.CHANGE_PASSWORD",
    "LABEL.CLIENT" = "LABEL.CLIENT",
    "LABEL.CLOSE" = "LABEL.CLOSE",
    "LABEL.CODE" = "LABEL.CODE",
    "LABEL.COMING_SOON" = "LABEL.COMING_SOON",
    "LABEL.COMMENT" = "LABEL.COMMENT",
    "LABEL.COMPANY" = "LABEL.COMPANY",
    "LABEL.COMPANY_CODE" = "LABEL.COMPANY_CODE",
    "LABEL.COMPANY_DESCRIPTION" = "LABEL.COMPANY_DESCRIPTION",
    "LABEL.COMPANY_INFO" = "LABEL.COMPANY_INFO",
    "LABEL.COMPANY_LOGO" = "LABEL.COMPANY_LOGO",
    "LABEL.COMPANY_NAME" = "LABEL.COMPANY_NAME",
    "LABEL.COMPANY_TYPES" = "LABEL.COMPANY_TYPES",
    "LABEL.COMPANY_WEBSITE" = "LABEL.COMPANY_WEBSITE",
    "LABEL.COMPLETED" = "LABEL.COMPLETED",
    "LABEL.CONFIRM_PASSWORD" = "LABEL.CONFIRM_PASSWORD",
    "LABEL.CONNECT_SCANNER" = "LABEL.CONNECT_SCANNER",
    "LABEL.CONTACT_US" = "LABEL.CONTACT_US",
    "LABEL.CREATE" = "LABEL.CREATE",
    "LABEL.CREATE_AUDIT" = "LABEL.CREATE_AUDIT",
    "LABEL.CREATE_PRODUCT" = "LABEL.CREATE_PRODUCT",
    "LABEL.CURRENT" = "LABEL.CURRENT",
    "LABEL.CURRENT_PASSWORD" = "LABEL.CURRENT_PASSWORD",
    "LABEL.DATE_TIME" = "LABEL.DATE_TIME",
    "LABEL.DELETE" = "LABEL.DELETE",
    "LABEL.DELETED" = "LABEL.DELETED",
    "LABEL.DESCRIPTION" = "LABEL.DESCRIPTION",
    "LABEL.DOCTYPE" = "LABEL.DOCTYPE",
    "LABEL.DONT_HAVE_ACCOUNT" = "LABEL.DONT_HAVE_ACCOUNT",
    "LABEL.DUE_DATE" = "LABEL.DUE_DATE",
    "LABEL.EDIT" = "LABEL.EDIT",
    "LABEL.EMAIL_ADDRESS" = "LABEL.EMAIL_ADDRESS",
    "LABEL.ENABLE_GEO" = "LABEL.ENABLE_GEO",
    "LABEL.ENERGY" = "LABEL.ENERGY",
    "LABEL.ENERGY_AMOUNT" = "LABEL.ENERGY_AMOUNT",
    "LABEL.ENERGY_TYPE" = "LABEL.ENERGY_TYPE",
    "LABEL.ENTER_PASSWORD" = "LABEL.ENTER_PASSWORD",
    "LABEL.ENTRIES" = "LABEL.ENTRIES",
    "LABEL.ERROR" = "LABEL.ERROR",
    "LABEL.FIBRETRACE" = "LABEL.FIBRETRACE",
    "LABEL.FIBRETRACE_ID" = "LABEL.FIBRETRACE_ID",
    "LABEL.FILES" = "LABEL.FILES",
    "LABEL.FIRST_NAME" = "LABEL.FIRST_NAME",
    "LABEL.FORGOT_PASSWORD" = "LABEL.FORGOT_PASSWORD",
    "LABEL.FORGOT_PASSWORD.DESCRIPTION" = "LABEL.FORGOT_PASSWORD.DESCRIPTION",
    "LABEL.FORGOT_PASSWORD.SEND_EMAIL" = "LABEL.FORGOT_PASSWORD.SEND_EMAIL",
    "LABEL.GENERIC_PROCESS" = "LABEL.GENERIC_PROCESS",
    "LABEL.GET_ID" = "LABEL.GET_ID",
    "LABEL.GREETING" = "LABEL.GREETING",
    "LABEL.HEADER.MY_AUDITS" = "LABEL.HEADER.MY_AUDITS",
    "LABEL.HEADER.MY_ORDERS" = "LABEL.HEADER.MY_ORDERS",
    "LABEL.HEADER.MY_SCANNERS" = "LABEL.HEADER.MY_SCANNERS",
    "LABEL.HOME" = "LABEL.HOME",
    "LABEL.HTTP" = "LABEL.HTTP",
    "LABEL.HTTPS" = "LABEL.HTTPS",
    "LABEL.ID" = "LABEL.ID",
    "LABEL.IMAGE" = "LABEL.IMAGE",
    "LABEL.IMAGES" = "LABEL.IMAGES",
    "LABEL.INACTIVE" = "LABEL.INACTIVE",
    "LABEL.INVITE" = "LABEL.INVITE",
    "LABEL.INVITE_EMAIL" = "LABEL.INVITE_EMAIL",
    "LABEL.INVITE_MESSAGE" = "LABEL.INVITE_MESSAGE",
    "LABEL.INVITE_PARTNERS" = "LABEL.INVITE_PARTNERS",
    "LABEL.INVITE_USERS" = "LABEL.INVITE_USERS",
    "LABEL.LAST_NAME" = "LABEL.LAST_NAME",
    "LABEL.LAT" = "LABEL.LAT",
    "LABEL.LOCATION" = "LABEL.LOCATION",
    "LABEL.LOG_OUT" = "LABEL.LOG_OUT",
    "LABEL.LONG" = "LABEL.LONG",
    "LABEL.MANDATORY" = "LABEL.MANDATORY",
    "LABEL.MANUFACTURER" = "LABEL.MANUFACTURER",
    "LABEL.MY_ACCOUNT" = "LABEL.MY_ACCOUNT",
    "LABEL.MY_ANALYSIS" = "LABEL.MY_ANALYSIS",
    "LABEL.NAME" = "LABEL.NAME",
    "LABEL.NAME_SKU" = "LABEL.NAME_SKU",
    "LABEL.NEW_PASSWORD" = "LABEL.NEW_PASSWORD",
    "LABEL.NEXT" = "LABEL.NEXT",
    "LABEL.NEXT_AUDIT" = "LABEL.NEXT_AUDIT",
    "LABEL.NOTIFICATION" = "LABEL.NOTIFICATION",
    "LABEL.NO_RECORD" = "LABEL.NO_RECORD",
    "LABEL.ORDER" = "LABEL.ORDER",
    "LABEL.ORDER.CO2_EMISSION" = "LABEL.ORDER.CO2_EMISSION",
    "LABEL.ORDER.CONSUMPTION_OVERVIEW" = "LABEL.ORDER.CONSUMPTION_OVERVIEW",
    "LABEL.ORDER.CURRENT_LOCATION" = "LABEL.ORDER.CURRENT_LOCATION",
    "LABEL.ORDER.CURRENT_MANUFACTURER" = "LABEL.ORDER.CURRENT_MANUFACTURER",
    "LABEL.ORDER.ENERGY_USED" = "LABEL.ORDER.ENERGY_USED",
    "LABEL.ORDER.LAST_SCANNED" = "LABEL.ORDER.LAST_SCANNED",
    "LABEL.ORDER.MY_ORDER_SUMMARY" = "LABEL.ORDER.MY_ORDER_SUMMARY",
    "LABEL.ORDER.ORDER_DATE" = "LABEL.ORDER.ORDER_DATE",
    "LABEL.ORDER.ORDER_ID" = "LABEL.ORDER.ORDER_ID",
    "LABEL.ORDER.ORDER_NAME" = "LABEL.ORDER.ORDER_NAME",
    "LABEL.ORDER.SCANNING_STEPS_HISTORY" = "LABEL.ORDER.SCANNING_STEPS_HISTORY",
    "LABEL.ORDER.TABLE.CERTIFICATES" = "LABEL.ORDER.TABLE.CERTIFICATES",
    "LABEL.ORDER.TABLE.DATE" = "LABEL.ORDER.TABLE.DATE",
    "LABEL.ORDER.TABLE.FILES" = "LABEL.ORDER.TABLE.FILES",
    "LABEL.ORDER.TABLE.LOCATION" = "LABEL.ORDER.TABLE.LOCATION",
    "LABEL.ORDER.TABLE.MANUFACTURER" = "LABEL.ORDER.TABLE.MANUFACTURER",
    "LABEL.ORDER.TABLE.PROCESS" = "LABEL.ORDER.TABLE.PROCESS",
    "LABEL.ORDER.TRACK_HISTORY" = "LABEL.ORDER.TRACK_HISTORY",
    "LABEL.ORDER.TRACK_ORDER" = "LABEL.ORDER.TRACK_ORDER",
    "LABEL.ORDER.WATER_USED" = "LABEL.ORDER.WATER_USED",
    "LABEL.ORDER_CODE" = "LABEL.ORDER_CODE",
    "LABEL.ORDER_TABS.ACTIVE" = "LABEL.ORDER_TABS.ACTIVE",
    "LABEL.ORDER_TABS.ALL" = "LABEL.ORDER_TABS.ALL",
    "LABEL.ORDER_TABS.COMPLETED" = "LABEL.ORDER_TABS.COMPLETED",
    "LABEL.OUT_OF" = "LABEL.OUT_OF",
    "LABEL.PAGING_DESCRIPTION" = "LABEL.PAGING_DESCRIPTION",
    "LABEL.PASSWORD" = "LABEL.PASSWORD",
    "LABEL.PENDING" = "LABEL.PENDING",
    "LABEL.PHONE" = "LABEL.PHONE",
    "LABEL.PICTURES" = "LABEL.PICTURES",
    "LABEL.PREVIEW_PRODUCT" = "LABEL.PREVIEW_PRODUCT",
    "LABEL.PREVIOUS" = "LABEL.PREVIOUS",
    "LABEL.PREVIOUS_AUDIT" = "LABEL.PREVIOUS_AUDIT",
    "LABEL.PROCESS" = "LABEL.PROCESS",
    "LABEL.PROCESS_NAME" = "LABEL.PROCESS_NAME",
    "LABEL.PRODUCED" = "LABEL.PRODUCED",
    "LABEL.PRODUCTS" = "LABEL.PRODUCTS",
    "LABEL.PRODUCT_BARCODE" = "LABEL.PRODUCT_BARCODE",
    "LABEL.PRODUCT_NAME" = "LABEL.PRODUCT_NAME",
    "LABEL.PRODUCT_TEMPLATE" = "LABEL.PRODUCT_TEMPLATE",
    "LABEL.PROFILE" = "LABEL.PROFILE",
    "LABEL.QR_CODE_NOT_AVAILABLE" = "LABEL.QR_CODE_NOT_AVAILABLE",
    "LABEL.QUANTITY" = "LABEL.QUANTITY",
    "LABEL.REDUCED" = "LABEL.REDUCED",
    "LABEL.REGISTER" = "LABEL.REGISTER",
    "LABEL.REGISTRATION_ID" = "LABEL.REGISTRATION_ID",
    "LABEL.REJECT" = "LABEL.REJECT",
    "LABEL.REMEMBER_ME" = "LABEL.REMEMBER_ME",
    "LABEL.REQUEST_ACCOUNT" = "LABEL.REQUEST_ACCOUNT",
    "LABEL.RESEND_ACTIVATION" = "LABEL.RESEND_ACTIVATION",
    "LABEL.RESTORE" = "LABEL.RESTORE",
    "LABEL.REVOKE" = "LABEL.REVOKE",
    "LABEL.REVOKE_PARTNERSHIP" = "LABEL.REVOKE_PARTNERSHIP",
    "LABEL.SAVED" = "LABEL.SAVED",
    "LABEL.SCANNER" = "LABEL.SCANNER",
    "LABEL.SCANNER.ASSIGNED_DATE" = "LABEL.SCANNER.ASSIGNED_DATE",
    "LABEL.SCANNER.ASSIGNED_TO" = "LABEL.SCANNER.ASSIGNED_TO",
    "LABEL.SCANNER.AUDITOR" = "LABEL.SCANNER.AUDITOR",
    "LABEL.SCANNER.CONDITION" = "LABEL.SCANNER.CONDITION",
    "LABEL.SCANNER.DESCRIPTION" = "LABEL.SCANNER.DESCRIPTION",
    "LABEL.SCANNER.EDIT" = "LABEL.SCANNER.EDIT",
    "LABEL.SCANNER.NO_SCANNERS_PLEASE_ORDER" = "LABEL.SCANNER.NO_SCANNERS_PLEASE_ORDER",
    "LABEL.SCANNER.ORDER" = "LABEL.SCANNER.ORDER",
    "LABEL.SCANNER.ORDER_NOTE" = "LABEL.SCANNER.ORDER_NOTE",
    "LABEL.SCANNER.SCANNER_ASSIGNED" = "LABEL.SCANNER.SCANNER_ASSIGNED",
    "LABEL.SCANNER.SCANNER_LIST" = "LABEL.SCANNER.SCANNER_LIST",
    "LABEL.SCANNER.STATUS" = "LABEL.SCANNER.STATUS",
    "LABEL.SCANNER.TEST_CONNECTION" = "LABEL.SCANNER.TEST_CONNECTION",
    "LABEL.SCANNER.TYPE" = "LABEL.SCANNER.TYPE",
    "LABEL.SCANNER_NAME" = "LABEL.SCANNER_NAME",
    "LABEL.SCAN_CONNECTED" = "LABEL.SCAN_CONNECTED",
    "LABEL.SCAN_CONNECT_FAILED" = "LABEL.SCAN_CONNECT_FAILED",
    "LABEL.SCAN_DATE" = "LABEL.SCAN_DATE",
    "LABEL.SCAN_RESULT" = "LABEL.SCAN_RESULT",
    "LABEL.SEARCH" = "LABEL.SEARCH",
    "LABEL.SEARCH_DESCRIPTION" = "LABEL.SEARCH_DESCRIPTION",
    "LABEL.SENDER_COMPANY" = "LABEL.SENDER_COMPANY",
    "LABEL.SENDER_NAME" = "LABEL.SENDER_NAME",
    "LABEL.SEND_AUDIT" = "LABEL.SEND_AUDIT",
    "LABEL.SET_AS_ADMIN" = "LABEL.SET_AS_ADMIN",
    "LABEL.SET_PASSWORD" = "LABEL.SET_PASSWORD",
    "LABEL.SHARP" = "LABEL.SHARP",
    "LABEL.SHOW" = "LABEL.SHOW",
    "LABEL.SHOW_LESS" = "LABEL.SHOW_LESS",
    "LABEL.SHOW_MORE" = "LABEL.SHOW_MORE",
    "LABEL.SIGN_IN" = "LABEL.SIGN_IN",
    "LABEL.SITE" = "LABEL.SITE",
    "LABEL.SLOGAN" = "LABEL.SLOGAN",
    "LABEL.STATUS" = "LABEL.STATUS",
    "LABEL.STATUS.ACTIVE" = "LABEL.STATUS.ACTIVE",
    "LABEL.STATUS.DISABLED" = "LABEL.STATUS.DISABLED",
    "LABEL.STATUS.INACTIVE" = "LABEL.STATUS.INACTIVE",
    "LABEL.STEP.SCANNED_BY" = "LABEL.STEP.SCANNED_BY",
    "LABEL.STEP.TABLE.DOCUMENT" = "LABEL.STEP.TABLE.DOCUMENT",
    "LABEL.STEP.TABLE.FIELD" = "LABEL.STEP.TABLE.FIELD",
    "LABEL.STEP.TABLE.NAME" = "LABEL.STEP.TABLE.NAME",
    "LABEL.STEP.TABLE.VALIDITY" = "LABEL.STEP.TABLE.VALIDITY",
    "LABEL.STEPS" = "LABEL.STEPS",
    "LABEL.STEP_TABS.ALL" = "LABEL.STEP_TABS.ALL",
    "LABEL.STEP_TABS.UPCOMING" = "LABEL.STEP_TABS.UPCOMING",
    "LABEL.STEP_TABS.UPDATED" = "LABEL.STEP_TABS.UPDATED",
    "LABEL.SUBMIT" = "LABEL.SUBMIT",
    "LABEL.SUGGEST_PASSWORD" = "LABEL.SUGGEST_PASSWORD",
    "LABEL.SUR_NAME" = "LABEL.SUR_NAME",
    "LABEL.TAB.INFORMATION" = "LABEL.TAB.INFORMATION",
    "LABEL.TAB.PROCESSES" = "LABEL.TAB.PROCESSES",
    "LABEL.TAB.SITES" = "LABEL.TAB.SITES",
    "LABEL.TAB.TEMPLATES" = "LABEL.TAB.TEMPLATES",
    "LABEL.TAX_ID" = "LABEL.TAX_ID",
    "LABEL.THANK_YOU.DESC" = "LABEL.THANK_YOU.DESC",
    "LABEL.THANK_YOU.TITLE" = "LABEL.THANK_YOU.TITLE",
    "LABEL.TOOLTIP.AUDIT_CAN_NOT_BE_SENT" = "LABEL.TOOLTIP.AUDIT_CAN_NOT_BE_SENT",
    "LABEL.TOOLTIP.CANNOT_DELETE_ORDER_PROCESS" = "LABEL.TOOLTIP.CANNOT_DELETE_ORDER_PROCESS",
    "LABEL.TOOLTIP.CANNOT_DELETE_PARTNERSHIP" = "LABEL.TOOLTIP.CANNOT_DELETE_PARTNERSHIP",
    "LABEL.TOOLTIP.CANNOT_DELETE_PROCESS" = "LABEL.TOOLTIP.CANNOT_DELETE_PROCESS",
    "LABEL.TOOLTIP.CANNOT_DELETE_PRODUCT" = "LABEL.TOOLTIP.CANNOT_DELETE_PRODUCT",
    "LABEL.TOOLTIP.CANNOT_DELETE_SITE" = "LABEL.TOOLTIP.CANNOT_DELETE_SITE",
    "LABEL.TOOLTIP.CANNOT_DELETE_USER" = "LABEL.TOOLTIP.CANNOT_DELETE_USER",
    "LABEL.TOOLTIP.CANNOT_TRANSFER_AUDIT" = "LABEL.TOOLTIP.CANNOT_TRANSFER_AUDIT",
    "LABEL.TOOLTIP.PLEASE_UPLOAD_FILES" = "LABEL.TOOLTIP.PLEASE_UPLOAD_FILES",
    "LABEL.TOOLTIP.PREVIOUS_AUDIT_HAS_NOT_BEEN_SENT" = "LABEL.TOOLTIP.PREVIOUS_AUDIT_HAS_NOT_BEEN_SENT",
    "LABEL.TOOLTIP.PROCESS_CAN_NOT_AUDIT" = "LABEL.TOOLTIP.PROCESS_CAN_NOT_AUDIT",
    "LABEL.TYPE" = "LABEL.TYPE",
    "LABEL.UPCOMING_AUDIT" = "LABEL.UPCOMING_AUDIT",
    "LABEL.UPDATE" = "LABEL.UPDATE",
    "LABEL.UPDATE_COMPANY_INFO" = "LABEL.UPDATE_COMPANY_INFO",
    "LABEL.UPDATE_PASSWORD" = "LABEL.UPDATE_PASSWORD",
    "LABEL.UPDATE_PROFILE" = "LABEL.UPDATE_PROFILE",
    "LABEL.USER.ACTIVATE" = "LABEL.USER.ACTIVATE",
    "LABEL.USER.DEACTIVATE" = "LABEL.USER.DEACTIVATE",
    "LABEL.VIEW" = "LABEL.VIEW",
    "LABEL.VIEW_MODE" = "LABEL.VIEW_MODE",
    "LABEL.WASTED" = "LABEL.WASTED",
    "LABEL.WATER" = "LABEL.WATER",
    "LABEL.WATER_AMOUNT" = "LABEL.WATER_AMOUNT",
    "LABEL.WATER_RECYCLED" = "LABEL.WATER_RECYCLED",
}

export enum ErrorTranslations {
    "ERROR.API_ERROR" = "ERROR.API_ERROR",
    "ERROR.FIELD_REQUIRED" = "ERROR.FIELD_REQUIRED",
    "ERROR.EMAIL_DESCRIPTION" = "ERROR.EMAIL_DESCRIPTION",
    "ERROR.PASSWORD_LENGTH" = "ERROR.PASSWORD_LENGTH",
    "ERROR.PASSWORD_CHARACTER" = "ERROR.PASSWORD_CHARACTER",
    "ERROR.PASSWORD_NUMBER" = "ERROR.PASSWORD_NUMBER",
    "ERROR.PASSWORD_CAPITAL" = "ERROR.PASSWORD_CAPITAL",
    "ERROR.PASSWORD_NOT_MATCH" = "ERROR.PASSWORD_NOT_MATCH",
    "ERROR.IMAGE_TYPE" = "ERROR.IMAGE_TYPE",
    "ERROR.IMAGE_QUANTITY" = "ERROR.IMAGE_QUANTITY",
    "ERROR.IMAGE_SIZE" = "ERROR.IMAGE_SIZE",
    "ERROR.LOCATION_MISMATCH" = "ERROR.LOCATION_MISMATCH",
    "ERROR.FID_MISMATCH" = "ERROR.FID_MISMATCH",
    "ERROR.PAGE_NOT_FOUND" = "ERROR.PAGE_NOT_FOUND",
    "ERROR.URL_WRONG_FORMAT" = "ERROR.URL_WRONG_FORMAT",
    "ERROR.EMAIL_WRONG_FORMAT" = "ERROR.EMAIL_WRONG_FORMAT",
    "ERROR.VALIDATE.EMAILS" = "ERROR.VALIDATE.EMAILS",
    "ERROR.VALIDATE.MESSAGE" = "ERROR.VALIDATE.MESSAGE",
    "ERROR.WRONG_DEVICE" = "ERROR.WRONG_DEVICE",
    "ERROR.CANNOT_GET_SERIAL" = "ERROR.CANNOT_GET_SERIAL",
    "ERROR.AUDITORS_LIST_EMPTY" = "ERROR.AUDITORS_LIST_EMPTY",
    "ERROR.VALIDATE.USER_HAS_RELATED_ORDER_PROCESS" = "ERROR.VALIDATE.USER_HAS_RELATED_ORDER_PROCESS",
    "ERROR.QR_CODE_NOT_FOUND" = "ERROR.QR_CODE_NOT_FOUND",
}

export enum WarningTranslation {
    "WARNING.REVOKE_PARTNERSHIP" = "WARNING.REVOKE_PARTNERSHIP"
}

export enum SuccessTranslations {
    "SUCCESS.INVITED" = "SUCCESS.INVITED",
    "SUCCESS.PASSWORD_CHANGED" = "SUCCESS.PASSWORD_CHANGED",
}
