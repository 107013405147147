import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {InputRules, PROCESS_AUDIT} from '@src/types';
import {AUDIT_API} from '@api/Audit';
import {translateV2, validate} from '@src/helpers';
import _ from "lodash-es";
import Select from "react-select";
import {AUDIT_TEMPLATE_API} from '@api/AuditTemplate';
import {Accordion, Button, Card, Col, Form, Row} from "react-bootstrap";

import GlobalMessage from "@components/Input/Message";
import AuditViewFormV2 from "@components/AuditViewForm/AuditViewFormV2";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DEFAULT_SELECT, MAX_FILES_AUDIT_SEND} from "@src/variables";
import {useAppSelector} from "@redux/hooks/hooks";
import {COMPANY_API} from "@api/Company";
import {configState} from "@redux/reducers/configSlice";

const AuditSendFormV2 = ({data, process = {} as PROCESS_AUDIT, defaultForm, onClose, onChange, onSubmit}: any) => {
    const appConfig = useAppSelector(configState);

    const [formStatus, setFormStatus] = useState({
        processing: false,
        isValid: false,
        message: ""
    });

    let audit = {
        audit_client: '',
        audit_id: '',
        auditor_name: '',
        carbon: '',
        comment: null,
        energy: '',
        energy_type: '',
        lat: '',
        long: '',
        order_code: '',
        process_name: '',
        product_barcode: '',
        scan_result: '',
        scanner_name: '',
        water: '',
        water_recycled: '',
    };

    const [adForm, setAdForm] = useState({...audit});
    const [form, setForm] = useState(defaultForm || {
        uid: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: false,
            error: ''
        },
        receiver_email: {
            value: '',
            rules: [InputRules.REQUIRED, InputRules.EMAIL],
            isValid: false,
            error: ''
        },
        company_id: {
            value: '',
            rules: [],
            isValid: true,
            error: ''
        },
        documents: {
            value: [],
            rules: [],
            isValid: true,
            error: ''
        },
        product_list_detail: {
            value: "" as any,
            rules: [],
            isValid: true,
            error: ''
        },
        audit_template_content: {
            value: '',
            rules: [],
            isValid: true,
            error: ''
        },
        company: {
            value: '',
            rules: [],
            isValid: true,
            error: ''
        },
        receiver: {
            value: '',
            rules: [],
            isValid: true,
            error: ''
        },
    })
    const [products, setProducts] = useState([] as any)
    const [companies, setCompanies] = useState({...DEFAULT_SELECT})
    const [auditors, setAuditors] = useState({...DEFAULT_SELECT} as any)
    const [templates, setTemplates] = useState({...DEFAULT_SELECT})
    const [questions, setQuestions] = useState({selected: [], list: [],} as any);

    const [company, setCompany] = useState({id: null, value: null} as any)
    const [receiver, setReceiver] = useState({} as any)
    const [recipient, setRecipient] = useState({} as any)

    const fileInput = useRef<HTMLInputElement | null>(null)

    const handleInput = async (event: any = {}) => {

        try {

            const f = form;
            const name = event.target.name;
            if (_.has(f, name)) {

                const value = event.target?.type === 'checkbox' ? event.target.checked : event.target.value;
                const {error, value: formattedValue} = validate(value, f[name].rules);

                f[name].error = error || "";
                f[name].isValid = !error;
                f[name].value = formattedValue || value || "";

                setForm({...form, ...f});
                await formHandle.validate();
            }
        } catch (e) {
            console.error(e)
        }

    }

    const handleMessageModal = {
        open: () => {
        },
        close: () => {
        }
    }

    const formHandle = {
        validate: async (trigger: boolean = true) => {
            const fs = formStatus, f = form;
            let tmp = {} as any;
            fs.isValid = _.every(f, (v: any) => v.isValid);
            fs.message = "";
            setFormStatus({...formStatus, ...fs})

            if (trigger) {
                tmp = {
                    formStatus: fs,
                    audit: formHandle.build(),
                    form: {...form, ...f}
                };

                if (_.isFunction(onChange)) onChange(tmp);
            }
        },
        build: () => {
            let t = {}, f = form;
            _.each(f, (v: any, k: any) => {
                t[k] = v.value;
            })
            return t;
        },
        onChange: handleInput,
        onBlur: handleInput,
        onSelectChange: async (selected, field) => {
            try {
                const f = {...form};

                setRecipient({...recipient, ...selected})
                if (selected.value === "OTHER") {
                    f[field].error = "";
                    f[field].isValid = false;
                    f[field].value = "";
                    setForm({...form, ...f})
                    await formHandle.validate();
                    return false;
                }

                if (_.has(form, field) && selected.value) {
                    const {error, isValid} = validate(selected.value, f[field].rules);
                    f[field].error = (error || "");
                    f[field].isValid = isValid;
                    f[field].value = selected.value;
                    setForm({...form, ...f})
                }
                await formHandle.validate();
            } catch (e) {
                console.error(e)
            }
        },
        onChangeInputProducts: async (e: any, index: any, product: any) => {
            try {
                const {quantity: max} = product;

                let p = products, f = form;
                let value = parseInt(e.target.value);

                if (value > max) value = max;
                if (value <= 0) value = 0;

                p[index].input_quantity = value;

                setProducts({...products, ...p});
            } catch (e) {
                console.error(e);
            }
        },
        onTemplateChange: async (value, {action, removedValue}) => {
            try {
                let ques = {...questions};
                setTemplates({...templates, selected: value});
                ques.list = value;
                if (action === "remove-value") {
                    ques.selected = _.filter(ques.selected, (o: any) => {
                        return o.templateId !== removedValue.id;
                    })
                }
                setQuestions({...ques});
            } catch (e) {
                console.error(e)
            }
        },
        submit: async (e) => {
            e.preventDefault();
            setFormStatus({...formStatus, processing: true})
            try {
                const stt = formStatus;
                const params = formHandle.build();
                const {message, data, error} = await AUDIT_API.sendAudit(params, {})
                if (error) {
                    stt.message = error || message || "";
                    stt.isValid = false;
                    stt.processing = false;
                }
                setFormStatus({...formStatus, ...stt})
            } catch (e) {
                console.error('ERROR', e);
                setFormStatus({
                    ...formStatus, ...{
                        isValid: false,
                        processing: false,
                        message: e
                    }
                })
            }
        }
    }

    const productTemplateHandle = {
        getOne: async () => {
            setTemplates({...templates, isLoading: true})
            let tp = {...templates}, ques = {...questions}, list: any = [];
            try {
                const attrTemplates = await AUDIT_API.getOrderProcessAuditTemplate({uid: data.ad_process_uid})
                if (!_.isEmpty(attrTemplates)) {
                    list = _.map(attrTemplates, (v: any) => {
                        let {uid, name} = v;
                        return {...v, value: uid, label: name}
                    })
                    // tp.isDisabled = true;
                    tp.isLoading = false;
                    tp.list = list;
                    tp.selected = list;
                    ques.list = list;
                }
            } catch (e) {
                console.error(e)
            }
            setTemplates({...templates, ...tp})
            setQuestions({...ques});
        },
        getBatch: async (extra = {}) => {
            setTemplates({...templates, isLoading: true})
            let tp = {...templates};
            try {
                const {data, error} = await AUDIT_TEMPLATE_API.getTemplates({}, extra);
                if (data && !error) {
                    tp.isDisabled = _.isEmpty(data);
                    tp.isLoading = false;
                    tp.list = _.map(data, (v: any) => {
                        let {uid, name} = v;
                        return {...v, value: uid, label: name}
                    })
                }
            } catch (e) {
                console.error(e)
            }
            setTemplates({...templates, ...tp})
        },
        getCurrentTemplates: async (data: any) => {
            try {
                let tp = {...templates}, q = {...questions};
                let {audit_template_content} = data;
                setQuestions({...questions, list: [...audit_template_content]})

                if (!_.isEmpty(audit_template_content)) {
                    if (!_.isEmpty(tp.list)) {
                        tp.selected = audit_template_content.map((v: any) => {
                            let {uid, name} = v;
                            return {...v, value: uid, label: name, isFixed: true}
                        });
                    }
                    setTemplates({...templates, ...tp});
                }

            } catch (e) {
                console.error(e)
            }
        },
        onChange: async (data: any) => {
            try {

                let ques = {...questions};
                let {selected} = data;

                let i = _.findIndex(ques.selected, (o: any) => {
                    return o.templateId === selected.templateId;
                });

                if (i === -1) ques.selected.push(selected);
                else ques.selected[i] = {...ques.selected[i], ...selected};

                setQuestions({...questions, ...ques})
            } catch (e) {
                console.error(e)
            }
        }

    }

    const getCompanies = async (params = {}) => {
        try {
            const {data: list, message, error} = await COMPANY_API.getCompaniesV2(_.omitBy(params, _.isNil));
            if (!error) {
                setCompanies({
                        ...companies,
                        isLoading: false,
                        isDisabled: !(list && _.size(list) > 0),
                        list: list.map((i: any) => {
                            const {id: value, name: label} = i;
                            return {...i, value, label}
                        })
                    }
                )

            }
        } catch (e) {
            console.error(e)
        }
    }

    const getAuditReceivers = async (params = {}) => {
        try {
            const {data: auditorsList, message, error} = await AUDIT_API.getAuditReceivers(_.omitBy(params, _.isNil));
            if (!error) {
                if (!auditorsList) form.receiver_email.error = "ERROR.AUDITORS_LIST_EMPTY"
                setAuditors({
                        ...auditors,
                        isLoading: false,
                        isDisabled: !(auditorsList && _.size(auditorsList) > 0),
                        list: auditorsList.map((i: any) => {
                            const {email: value} = i;
                            const label = `${i.name} - ${i.company}`
                            return {...i, value, label}
                        })
                    }
                )

            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        (async () => {
            let {input_products, audit_template_content} = process, f = form;
            let tmp = _.map(input_products, (v: any) => {
                v.input_quantity = v.quantity;
                return v;
            })
            f.audit_template_content.value = JSON.stringify(audit_template_content || "");

            setProducts([...products, ...tmp]);
            setForm({...form, ...f});
        })()
    }, [process]);

    useEffect(() => {
        (async () => {
            let f = form, p = products;
            let tmp = JSON.parse(JSON.stringify(p));
            tmp = _.map(tmp, (v) => {
                v.quantity = v.input_quantity;
                _.unset(v, 'input_quantity');
                return v;
            });
            f.product_list_detail.value = JSON.stringify(tmp);
            setForm({...form, ...f})
        })()
    }, [products]);

    useEffect(() => {
        (async () => {
            await productTemplateHandle.getBatch();
            const {ad_process_uid} = data;
            if (ad_process_uid) {
                let f = form;
                const {error, isValid} = validate(ad_process_uid, f.uid.rules);
                f.uid.error = error || "";
                f.uid.isValid = !error;
                f.uid.value = ad_process_uid
                setForm({...form, ...f})
            }
        })()
    }, [data]);

    useEffect(() => {
        (async () => {
            await getCompanies({});
        })()
        return () => {
            setCompanies({...DEFAULT_SELECT})
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (!_.isNil(company.id)) await getAuditReceivers({company_id: company.id});
        })()
        return () => {
            setAuditors({...DEFAULT_SELECT})
        }
    }, [company]);

    useEffect(() => {
        (async () => {
            if (!_.isEmpty(process) && !_.isEmpty(templates.list)) {
                await productTemplateHandle.getCurrentTemplates(process);
            }
        })()
    }, [process, templates.list])

    useEffect(() => {
        (async () => {
            if (!_.isEmpty(process) && !_.isEmpty(auditors)) {
                let receiver: PROCESS_AUDIT & any = {}
                receiver = {...process.receiver}
                if (!_.isEmpty(receiver)) {
                    setReceiver({...receiver});
                    let t = _.find(auditors.list, (v) => {
                        return v.uid === receiver.uid;
                    });

                    receiver.value = receiver.email;
                    receiver.label = `${receiver.name} - ${receiver.company}`

                    if (!t) {
                        setAuditors({
                            ...auditors,
                            isDisabled: true,
                            list: [
                                ...auditors.list,
                                receiver
                            ]
                        })
                    }

                    let f = {...form};
                    const {error, isValid} = validate(receiver.email, f.receiver_email.rules);
                    f.receiver_email.error = (error || "");
                    f.receiver_email.isValid = isValid;
                    f.receiver_email.value = receiver.email;

                    f.company.error = (error || "");
                    f.company.isValid = isValid;
                    f.company.value = receiver.company;

                    f.receiver.error = (error || "");
                    f.receiver.isValid = isValid;
                    f.receiver.value = receiver.name;

                    setForm({...form, ...f})

                }
            } else {

            }
        })();

        return () => {
        }

    }, [process, auditors])

    useEffect(() => {
        (async () => {
            await formHandle.validate();
        })()
    }, [form])

    return (
        <>
            <Accordion className={"mb-4"}>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <FontAwesomeIcon icon={faEye}/>
                        &nbsp;{_.capitalize(translateV2("LABEL.VIEW"))}
                        &nbsp;{_.lowerCase(translateV2("LABEL.AUDIT_INFORMATION"))}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body><AuditViewFormV2 data={data}/></Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

            <Form onSubmit={onSubmit || formHandle.submit}>

                {_.isEmpty(receiver) && <Form.Group as={Row} className="mb-3">
                    <Form.Label column xs="12"
                                sm={3}>{translateV2("LABEL.SEND_AUDIT.MANUFACTURER")}</Form.Label>
                    <Col>
                        <Select
                            menuPosition={"fixed"} menuShouldScrollIntoView={true}
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={companies.isDisabled}
                            isLoading={companies.isLoading}
                            isClearable={companies.isClearable}
                            isSearchable={companies.isSearchable}
                            options={
                                [...companies.list,
                                    {
                                        value: "OTHER",
                                        label: translateV2("LABEL.AUDIT.OTHER_RECIPIENT")
                                    }
                                ]
                            }
                            onChange={async (e) => {
                                if (e.value === "OTHER") await formHandle.onSelectChange({value: "OTHER"}, 'receiver_email')
                                setCompany({...e})
                                await formHandle.onChange({target: {name:"company", value: e.name}})

                            }}
                        />
                    </Col>
                </Form.Group>}

                {company.value !== "OTHER" && <Form.Group as={Row} className="mb-3" controlId="formReceiverId">
                    <Form.Label column xs="12" sm={3}>{translateV2("LABEL.SEND_ORDER_TO")}</Form.Label>
                    <Col>
                        <Select
                            menuPosition={"fixed"} menuShouldScrollIntoView={true}
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={auditors.isDisabled || receiver.uid}
                            isLoading={auditors.isLoading}
                            isClearable={auditors.isClearable}
                            isSearchable={auditors.isSearchable}
                            options={auditors.list}
                            value={auditors.list[_.findIndex(auditors.list, (v: any) => v.uid === receiver.uid)]}
                            onChange={(e) => {
                                formHandle.onSelectChange(e, 'receiver_email').then(
                                    r => formHandle.onSelectChange({value: e.name}, 'receiver'))
                            }}
                        />

                        <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                                       message={translateV2(`LABEL.SEND_ORDER_TO.NOTE`)}
                        />

                        {recipient.value !== "OTHER" && form.receiver_email.error &&
                            <GlobalMessage message={translateV2(form.receiver_email.error)}/>}
                    </Col>
                </Form.Group>}

                {recipient.value === "OTHER" && <Form.Group as={Row} className="mb-3" controlId="receiver_email">
                    <Form.Label column xs="12"
                                sm={3}>{translateV2("LABEL.RECIPIENT_EMAIL")}</Form.Label>
                    <Col>
                        <Form.Control type="text" name={"receiver_email"}
                                      value={form.receiver_email.value}
                                      onChange={formHandle.onChange}
                                      placeholder={translateV2("LABEL.RECIPIENT_EMAIL")}/>

                        {form.receiver_email.error &&
                            <GlobalMessage message={translateV2(form.receiver_email.error)}/>}
                    </Col>
                </Form.Group>}

                <div style={{display: "none"}}>
                    {!_.isEmpty(products) && <Form.Group as={Row} className="mb-3">
                        <Form.Label column xs="12"
                                    sm={3}>{translateV2("LABEL.AUDIT_PRODUCTS")}</Form.Label>
                        <Col>
                            <div className="aud-products-container">
                                {
                                    _.map(products, (product: any, itemIndex) =>
                                            <div key={itemIndex} className={"d-flex aud-products-item"}>
                                                <label htmlFor={`input-${itemIndex}`} className={""}
                                                       style={{width: "100%"}}>{product.name}</label>

                                                <Form.Control type="number" value={product.input_quantity || ""}
                                                              id={`input-${itemIndex}`}
                                                              min={0} max={product.quantity}
                                                              style={{
                                                                  lineHeight: "inherit",
                                                                  borderColor: "#ced4da",
                                                                  maxWidth: "100px",
                                                                  width: "100px"
                                                              }}
                                                              className={"form-control fibre-input_control fibre-input_file fibre-input--borde mr-2 mb-2 text-left"}
                                                              onChange={(e) => formHandle.onChangeInputProducts(e, itemIndex, product)}
                                                />
                                                {/*<span
                                        style={{whiteSpace: "nowrap"}}>{translateV2( "LABEL.NUMBER_OF", "", {1: product.quantity})}</span>*/}
                                                <span
                                                    style={{whiteSpace: "nowrap"}}>
                                        of {product.quantity}
                                    </span>
                                            </div>
                                    )
                                }
                            </div>
                            {form?.input_products?.error &&
                                <GlobalMessage message={translateV2(form?.input_products?.error)}/>}
                        </Col>
                    </Form.Group>}
                </div>


                <Form.Group as={Row} className="mb-3" controlId="formDocuments">
                    <Form.Label column xs="12" sm={3}>{translateV2("LABEL.DOCUMENTS")}</Form.Label>
                    <Col>
                        <Form.Control type="file" name={"documents"} multiple={true} ref={fileInput}
                                      style={{lineHeight: "inherit", borderColor: "#ced4da"}}
                                      className={"form-control fibre-input_control fibre-input_file fibre-input--border text-left btn-white mr-2 mb-2 btn"}
                                      onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                                          try {

                                              let fm = {...form}
                                              if (_.size(e.target.files) > MAX_FILES_AUDIT_SEND) {
                                                  fm.documents.error = "ERROR.MAX_FILES_REACH"

                                                  if (fileInput?.current?.value) {
                                                      fileInput.current.value = "";
                                                  }
                                              } else {
                                                  fm.documents.value = e.target.files;

                                                  /**
                                                   * @todo: below code is used for render documents, validate files size etc.
                                                   */
                                                  _.map(e.target.files, (f, k) => {
                                                      const reader = new FileReader();
                                                      reader.readAsDataURL(f);
                                                      if (f) {
                                                          let maxSize = appConfig.audit_media_max_upload_file_size;

                                                          if (f.type.indexOf("image/") === -1) {

                                                          } else {
                                                              if (f.size <= Number(maxSize) * 1024 * 1024) {
                                                                  fm.documents.error = "";
                                                                  reader.onloadend = function (e) {

                                                                  }
                                                              } else {
                                                                  fm.documents.error = "ERROR.FILE_TOO_LARGE";
                                                              }
                                                          }

                                                      }

                                                  });
                                              }


                                              await formHandle.validate({...form, ...fm});
                                              if (_.isFunction(onChange)) await onChange({form: fm});

                                          } catch (e) {
                                              console.error(e)
                                          }
                                      }}
                        />

                        <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                                       message={translateV2(`LABEL.SEND_AUDIT.DOCUMENTS.NOTE`)}
                        />

                        {form?.documents.error &&
                            <GlobalMessage message={translateV2(form.documents.error)}/>}
                    </Col>
                </Form.Group>

            </Form>

            {!_.isFunction(onSubmit) && !_.isEmpty(onSubmit) && <div className="d-flex justify-content-end mt-4">
                <Button type={"submit"} variant={"success"}
                        disabled={!formStatus.isValid}
                        onClick={formHandle.submit}>
                    {translateV2("LABEL.SEND_AUDIT")}
                </Button>
            </div>}
            {/**/}

        </>
    );
}

export default AuditSendFormV2;
