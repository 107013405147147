import React from 'react';
import {translateV2} from '@src/helpers';
import {Button, Image, Modal} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {ASSETS} from "@assets/Assets";
import {DEFAULT_APP_CONFIG} from "@src/variables";
import {companyClaimState} from "@redux/reducers/companySlice";
import {useNavigate} from "react-router-dom";

const GlobalCompanyClaimModal = () => {

    const claimCompany = useAppSelector(companyClaimState);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const closePendingClaimModal = () => {
        navigate(0)
    }

    return (
        <>

            <Modal show={claimCompany.isShow}
                   style={{zIndex: 999999999}}
                   centered={true}
                   onHide={() => {
                       closePendingClaimModal()
                   }} size={"lg"}>

                <Modal.Body>

                    <Image src={ASSETS.IconCloseV2} loading={"lazy"} height={20} decoding={"async"}
                           style={{marginBottom: "0.5em"}} className={"modal-fib-close"}
                           onClick={() => {
                               closePendingClaimModal()
                           }}
                    />

                    <div className="text-center pt-4 pb-4">
                        <Image src={ASSETS.logo} style={{height: "30px"}} fluid={true}
                               alt={DEFAULT_APP_CONFIG.app_name} className={"mb-4"}/>

                        <h3 className={`text-dark mb-3`}

                            style={{
                                fontFamily: "Mona Sans",
                                fontSize: "20px",
                                fontWeight: 600,
                                lineHeight: "27px",
                            }}>{translateV2("LABEL.COMPANY.CLAIM.APPROVAL_PENDING.TITLE")}</h3>

                        <p className={`fz-16 fw4`}
                           dangerouslySetInnerHTML={{__html: translateV2("LABEL.COMPANY.CLAIM.APPROVAL_PENDING.DESCRIPTION")}}/>

                        <Button variant={"success"}
                                className={"mt-3"}
                                style={{
                                    boxSizing: "border-box",
                                    width: "152px",
                                    height: "44px",
                                    padding: "10px 80px",
                                    borderRadius: "8px",
                                    color: "white"
                                }}
                                onClick={() => {
                                    closePendingClaimModal()
                                }}>
                            {translateV2("LABEL.BACK")}
                        </Button>

                    </div>

                </Modal.Body>
            </Modal>


        </>
    );
}

export default GlobalCompanyClaimModal;
