import React, {useState} from 'react';
import {Icon} from '@src/types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {translateV2} from "@src/helpers";


const Input = ({
                   type,
                   label,
                   icon,
                   placeholder,
                   disabled,
                   val,
                   className,
                   change,
                   blur,
                   name,
                   inputClassName = "",
                   labelClassName = "",
                   autoComplete = "",
                   required = false
               }: any) => {
    const [displayPass, setDisplayPass] = useState(false);
    const toggleDisplay = () => {
        setDisplayPass(!displayPass);
    }
    return (
        <div className={`input-group fibre-input ${className || ''}`}>
            <div className="input-group-prepend">
                {label && <span className={`input-group-text fibre-input_label ${labelClassName}`}>{label}
                    {required && <span className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span>}
                </span>}
                <span className="input-group-text fibre-input_icon">
                    {icon && icon.type === Icon.IMG && <img src={icon.icon} alt=""/>}
                    {icon && icon.type === Icon.FA && <FontAwesomeIcon icon={icon.icon}/>}
                </span>
            </div>

            <input type={(type == 'password' && displayPass) ? 'text' : type} autoComplete={autoComplete}
                   className={`form-control fibre-input_control ${inputClassName}`} placeholder={placeholder}
                   name={name} value={val} disabled={disabled} onChange={change} onBlur={blur}/>

            {type == 'password' && <div className="input-group-prepend align-items-center">
        <span className="input-group-text fibre-input_icon">
          <FontAwesomeIcon icon={displayPass ? faEye : faEyeSlash} onClick={toggleDisplay}/>
        </span>
            </div>}

        </div>
    );
}

export default Input;
