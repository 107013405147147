import React, {useEffect, useState} from "react";
import {ProgressBar} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {importPartnersState} from "@redux/reducers/importPartnersSlice";
import {importPartnersProgressState, ImportStatusEnum} from "@redux/reducers/importPartnersProgressSlice";
import {translateV2} from "@src/helpers";

const PartnerImporting = () => {

    const dispatch = useAppDispatch();

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);

    const [progress, setProgress] = useState(0)

    useEffect(() => {

        const t = setInterval(() => {
            if (progress <= 97) {
                setProgress(prev => {
                    return ++prev;
                })
            }
        }, 50)

        return () => {
            clearInterval(t)
        }

    }, [progress, importPartnersProgress.stepData.importing.progress])

    return (
        <>
            <p>{translateV2("LABEL.PARTNER.IMPORT.IMPORTING.WAITING")}</p>

            <div className="ml-4 mr-4">

                <ProgressBar
                    className={`fib-progress-bar mt-4 mb-3`}
                    variant={"success"}
                    now={progress}
                    animated={true}
                />

            </div>
        </>
    )
}

export default PartnerImporting;