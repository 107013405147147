import React from "react";
import {Accordion, Alert, Card, Image} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {importPartnersState} from "@redux/reducers/importPartnersSlice";
import {
    importPartnersProgressSlice,
    importPartnersProgressState,
    InvitationStatusEnum
} from "@redux/reducers/importPartnersProgressSlice";
import _ from "lodash-es";
import {isMobile} from "react-device-detect";
import {translateV2} from "@src/helpers";
import {ASSETS} from "@assets/Assets";

const PartnerInvitationDone = () => {

    const dispatch = useAppDispatch();

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);

    return (
        <>
            {
                importPartnersProgress.statusInvitation === InvitationStatusEnum.FAILED ?
                    <React.Fragment>
                        <Alert variant={'danger'} className={"fibre-alert-danger pt-4 pb-4"}>
                            <div className={`d-flex flex-row justify-content-start align-items-center `}>

                                <Image src={ASSETS.IconInfoCircleError} loading={"lazy"} height={20} decoding={"async"}
                                       className={""}
                                       onClick={() => {
                                       }}
                                />

                                <p className="mb-0 ml-3"
                                   dangerouslySetInnerHTML={{
                                       __html: translateV2(importPartnersProgress.message, "", importPartnersProgress.messageData)
                                   }}/>
                            </div>
                        </Alert>

                        <div className={`partners-preview-wrapper`} style={{maxHeight: "40vh", overflowY: "auto"}}>

                            <table className={`partners-preview-table table`}>
                                <thead className={isMobile ? `d-none` : ""}>
                                <tr>
                                    <th>

                                        <label htmlFor={`allInvitePartners`} className={`fib-checkboxv2`}>

                                            <input checked={importPartnersProgress.stepData.preview.checked}
                                                   id={`allInvitePartners`}
                                                   onChange={(e) => {
                                                       dispatch(importPartnersProgressSlice.actions.setAllFormattedInvitePartner({
                                                           checked: !importPartnersProgress.stepData.preview.checked
                                                       }))
                                                   }} type="checkbox"/>

                                            <span className="fib-checkboxv2-checkmark"/>
                                        </label>


                                    </th>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th className={`fpw-20`}>Process</th>
                                </tr>
                                </thead>

                                <tbody>
                                {
                                    importPartnersProgress.stepData.invitation.partner_list_invitation.map((p: any, itemIndex: number) =>
                                        <tr key={_.uniqueId(`partner-invite-`)}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <label htmlFor={`partner-invite-${itemIndex}`}
                                                           className={`fib-checkboxv2`}>

                                                        <input checked={p.checked} id={`partner-invite-${itemIndex}`}
                                                               onChange={(e) => {
                                                                   dispatch(importPartnersProgressSlice.actions.setSingleFormattedInvitePartner({
                                                                       itemIndex, partner: {...p, checked: !p.checked}
                                                                   }))
                                                               }} type="checkbox"/>

                                                        <span className="fib-checkboxv2-checkmark"/>
                                                    </label>
                                                </div>

                                            </td>

                                            <td className={`d-none d-sm-table-cell`}><b>{p.company_name}</b></td>
                                            <td className={`d-none d-sm-table-cell`}>{p.supplier_hq_postal_address}</td>
                                            <td className={`d-none d-sm-table-cell`}>{p.supplier_process_name}</td>

                                            <td className="d-table-cell d-sm-none">
                                                <Accordion>
                                                    <Card>
                                                        <Card.Header>
                                                            <Accordion.Toggle eventKey="0">
                                                                <p><b>{p.company_name}</b></p>
                                                            </Accordion.Toggle>
                                                            {/*<ContextAwareToggle eventKey="0">Click me!</ContextAwareToggle>*/}

                                                        </Card.Header>
                                                        <Accordion.Collapse eventKey="0">
                                                            <Card.Body>

                                                                Address
                                                                <p><b>{p.supplier_hq_postal_address}</b></p>

                                                                Processes
                                                                <p><b>{p.supplier_process_name}</b></p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>

                                            </td>
                                        </tr>)
                                }
                                </tbody>

                            </table>

                        </div>

                    </React.Fragment>
                    : null
            }

            {
                importPartnersProgress.statusInvitation === InvitationStatusEnum.SUCCESS &&  <Alert variant={'success'} className={"fibre-alert-success pt-4 pb-4"}>
                    <div className={`d-flex flex-row justify-content-start align-items-center `}>

                        <Image src={ASSETS.IconCheckSuccessGreenV2} loading={"lazy"} height={20} decoding={"async"}
                               className={""}
                               onClick={() => {
                               }}
                        />

                        <p className="mb-0 ml-3" dangerouslySetInnerHTML={{
                            __html: translateV2("LABEL.PARTNER.IMPORT.INVITATION.DONE", "", {
                                total: _.size(_.filter(importPartnersProgress.stepData.invitation.partner_list_invitation, (p: any) => p.send_invitation))
                            })
                        }}/>
                    </div>
                </Alert>
            }

        </>
    )
}

export default PartnerInvitationDone;