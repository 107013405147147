import {Accordion, Alert, Button, Card, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import LoadingV2 from "@v2components/Loading/LoadingV2";
import FibreModalHeader from "@v2components/Modal/FibreModalHeader";
import {translateV2} from "@src/helpers";
import {userState} from "@redux/reducers/userSlice";
import {
    auditClaimDisputeModalSlice,
    auditClaimDisputeModalState,
    auditSelectedSlice,
    auditSelectedState,
    E_AuditClaimDispute
} from "@redux/reducers/auditSelectedSlice";
import _ from "lodash-es";
import {isMobile} from "react-device-detect";
import {AUDIT_API} from "@api/Audit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faInfoCircle} from "@fortawesome/free-solid-svg-icons";

/**
 * @todo:
 * config FilePartnersImportUrl
 */


enum E_AuditClaimDisputeStatus {
    SUCCESS = "SUCCESS",
    FAILED = "FAILED"
}

interface I_AuditClaimDisputeDone {
    status?: E_AuditClaimDisputeStatus,
    message?: any,
    messageData?: {}
}

const AuditClaimDisputeDone = ({status, message, messageData}: I_AuditClaimDisputeDone) => {
    if (!status) return null;

    return <>

        {
            status === E_AuditClaimDisputeStatus.FAILED && <Alert variant={'danger'}>
                <div className={`d-flex flex-row justify-content-start align-items-center `}>
                    <FontAwesomeIcon icon={faInfoCircle}/>
                    <p className="mb-0 ml-3">
                        {translateV2(message, "", messageData)}
                    </p>
                </div>
            </Alert>
        }

        {
            status === E_AuditClaimDisputeStatus.SUCCESS && <Alert variant={'success'}>
                <div className={`d-flex flex-row justify-content-start align-items-center `}>
                    <FontAwesomeIcon icon={faCheckCircle}/>
                    <p className="mb-0 ml-3">
                        {translateV2(message, "", messageData)}
                    </p>
                </div>
            </Alert>
        }
    </>
}

const AuditClaimDisputeModal = () => {

    const dispatch = useAppDispatch();

    const auditClaimDisputeModalStore = useAppSelector(auditClaimDisputeModalState);
    const auditSelectedStore = useAppSelector(auditSelectedState);

    const [stage, setStage] = useState("PREVIEW" as "PREVIEW" | "DONE")
    const [loading, setLoading] = useState(false);


    const [status, setStatus] = useState<E_AuditClaimDisputeStatus>();
    const [message, setMessage] = useState("");
    const [messageData, setMessageData] = useState({});


    /**
     * Related to: first_login_and_first_user of User
     */
    const user = useAppSelector(userState);


    return (
        <>
            <Modal style={{}}
                   backdrop={'static'}
                   keyboard={false} scrollable={true}
                   show={auditClaimDisputeModalStore.isShow}
                   onHide={() => {
                       dispatch(auditSelectedSlice.actions.reset())

                   }} size={"lg"} centered={true}>

                {loading && <LoadingV2 className={`fibre-modal-radius`}/>}

                <FibreModalHeader
                    title={translateV2("LABEL.AUDIT.CLAIM_DISPUTE." + _.toUpper(auditSelectedStore.action))}
                    onClickClose={() => {
                        dispatch(auditClaimDisputeModalSlice.actions.hide({}))
                        dispatch(auditSelectedSlice.actions.reset())
                    }}
                />

                <Modal.Body className={"modal-fib-body"}>


                    {
                        !status
                            ? <React.Fragment>

                                {
                                    <p className={"text-center"}>{translateV2("LABEL.AUDIT.CLAIM_DISPUTE." + _.toUpper(auditSelectedStore.action) + ".DESCRIPTION")}</p>
                                }


                                {
                                    auditSelectedStore.data.length <= 0
                                        ? null
                                        :
                                        <div className={`audit-claimdispute-wrapper`}
                                             style={{maxHeight: "40vh", overflowY: "auto"}}>

                                            <table className={`audit-claimdispute-table table`}>
                                                <thead className={isMobile ? `d-none` : ""}>
                                                <tr>
                                                    <th>

                                                        <label htmlFor={`allShowingAudits`} className={`fib-checkboxv2`}>

                                                            <input
                                                                id={"allShowingAudits"}
                                                                checked={_.every(auditSelectedStore.data, (p) => p.checked)}
                                                                onChange={(e) => {
                                                                    dispatch(auditSelectedSlice.actions.toggleAll())
                                                                }} type="checkbox"/>
                                                            <span className="fib-checkboxv2-checkmark"/>
                                                        </label>


                                                    </th>
                                                    <th>Order</th>
                                                    <th>Product</th>
                                                    <th>Process</th>
                                                    <th className={`fpw-20`}>Location</th>
                                                    <th className={`fpw-20`}>Scan date</th>
                                                </tr>
                                                </thead>

                                                {/*"company_name": string | any,*/}
                                                {/*"company_email": string | any,*/}
                                                {/*"supplier_phone_number": string | any,*/}
                                                {/*"supplier_hq_postal_address": string | any,*/}
                                                {/*"supplier_process_name": string | any,*/}
                                                {/*"supplier_process_postal_address": string | any,*/}
                                                {/*"process_description": string | any,*/}
                                                {/*"allow_import": boolean,*/}
                                                {/*"note": string | any,*/}

                                                <tbody>
                                                {
                                                    auditSelectedStore.data.map((p: any, itemIndex: number) =>
                                                        <tr key={_.uniqueId(`audit-item-`)}
                                                            className={``}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <label htmlFor={`partner-import-${itemIndex}`}
                                                                           className={`fib-checkboxv2`}>
                                                                        <input checked={p.checked}
                                                                               id={`partner-import-${itemIndex}`}
                                                                               onChange={(e) => {
                                                                                   dispatch(auditSelectedSlice.actions.toggleSingle({
                                                                                       itemIndex,
                                                                                       process: p
                                                                                   }))

                                                                               }} type="checkbox"/>

                                                                        <span className="fib-checkboxv2-checkmark"/>
                                                                    </label>
                                                                </div>
                                                            </td>

                                                            <td className={`d-none d-sm-table-cell`}><b>{p.order_id}</b>
                                                            </td>
                                                            <td className={`d-none d-sm-table-cell`}>{p.product_name}</td>
                                                            <td className={`d-none d-sm-table-cell`}>{p.name}</td>
                                                            <td className={`d-none d-sm-table-cell`}>{p.manufacturer_location}</td>
                                                            <td className={`d-none d-sm-table-cell`}>{p.due_date}</td>

                                                            <td className="d-table-cell d-sm-none">
                                                                <Accordion>
                                                                    <Card>
                                                                        <Card.Header>
                                                                            <Accordion.Toggle eventKey="0">
                                                                                <p><b>{p.company_name}</b></p>
                                                                            </Accordion.Toggle>
                                                                            {/*<ContextAwareToggle eventKey="0">Click me!</ContextAwareToggle>*/}

                                                                        </Card.Header>
                                                                        <Accordion.Collapse eventKey="0">
                                                                            <Card.Body>

                                                                                Address
                                                                                <p><b>{p.supplier_hq_postal_address}</b></p>

                                                                                Processes
                                                                                <p><b>{p.supplier_process_name}</b></p>
                                                                            </Card.Body>
                                                                        </Accordion.Collapse>
                                                                    </Card>
                                                                </Accordion>

                                                            </td>
                                                        </tr>)
                                                }
                                                </tbody>

                                            </table>

                                        </div>
                                }
                                <hr/>
                            </React.Fragment>
                            : <AuditClaimDisputeDone status={status} message={message} messageData={messageData}/>
                    }


                </Modal.Body>

                {
                    status
                        ? null
                        : <Modal.Footer className={`modal-fib-footer`}>

                            <div
                                className={`d-flex flex-column flex-sm-row justify-content-sm-center align-items-center w-100`}>

                                <Button variant="success"
                                        onClick={async () => {
                                            setLoading(true)

                                            if (_.size(_.filter(auditSelectedStore.data, (p: any) => p.checked)) > 0) {
                                                const {message, error} = await AUDIT_API.updateAuditClaimedStatus({
                                                    processes: _.map(_.filter(auditSelectedStore.data, (p) => p.checked), (process: any) => process.id),
                                                    status: auditSelectedStore.action as E_AuditClaimDispute
                                                })
                                                setStatus(error ? E_AuditClaimDisputeStatus.FAILED : E_AuditClaimDisputeStatus.SUCCESS)
                                                setMessage(message || error)
                                                setMessageData({
                                                    total: _.size(_.filter(auditSelectedStore.data, (p) => p.checked))
                                                })
                                            } else {

                                            }
                                            // updateAuditClaimedStatus
                                            setLoading(false)

                                        }}>
                                    {translateV2("LABEL.AUDIT.CLAIM_DISPUTE." + _.toUpper(auditSelectedStore.action) + ".CTA")}

                                </Button>

                            </div>

                        </Modal.Footer>
                }


            </Modal>

        </>
    )

}

export default AuditClaimDisputeModal