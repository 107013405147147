import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {COMPANY_API} from "@api/Company";
import {CompanyTabs, USER_ACTION} from "@src/types";
import {DEFAULT_MODAL} from "@src/variables";
import _ from "lodash-es";

export interface CompanyPackagePayment {
    audit_template: boolean,
    b2c: boolean,
    eco_score: boolean,
    fim: boolean,
    import_sites: boolean,
    transaction_certificate: boolean,
    transaction_credits: any,
    scanning_intensity_module: boolean
}

export interface CompanyInterface {
    company_code: string | null | any
    company_description: string | null | any
    company_id: number | null | ""
    company_name: string
    company_package_payment: any | CompanyPackagePayment
    company_website: string | null | any
    css: string | null | any
    email: string | null | any
    is_public: number | boolean
    logo: string | null | any
    phone: string | null | any
    registration_id: string | null | any
    socials: Array<any>
    tax_id: string | null | any
    tax_id_type: string | null | any
    types: Array<Object>,
    headquarter_site_id: Array<Object>
    current_subscription: any
    has_unpaid_bills: boolean | null
    scanner_function_available: boolean | null
    billing_email: string | null | any
}

export interface CompanyUpdateInterface {
    company_code?: string | null | any
    company_description?: string | null | any
    company_id?: number | null | ""
    company_name?: string
    company_package_payment?: any | CompanyPackagePayment
    company_website?: string | null | any
    css?: string | null | any
    email?: string | null | any
    is_public?: number | boolean
    logo?: string | null | any
    phone?: string | null | any
    registration_id?: string | null | any
    socials?: Array<any>
    tax_id?: string | null | any
    types?: Array<Object>,
    headquarter_site_id?: Array<Object>
    current_subscription?: any
    has_unpaid_bills?: boolean | null
    scanner_function_available?: boolean | null
    tax_id_type?: string | null | any
    billing_email?: string | null | any
}

const initialState: CompanyInterface = {
    company_code: "",
    company_description: "",
    company_id: "",
    company_name: "",
    company_package_payment: {
        fim: false,
        eco_score: false,
        audit_template: false,
        b2c: false,
        transaction_certificate: false,
        transaction_credits: 0
    },
    company_website: "",
    css: "",
    email: "",
    is_public: false,
    logo: "",
    phone: "",
    registration_id: "",
    socials: [],
    tax_id: "",
    types: [],
    headquarter_site_id: [],
    current_subscription: {},
    has_unpaid_bills: false,
    scanner_function_available: false,
    tax_id_type: "",
    billing_email: ""
}

export const getCompanyAsync = createAsyncThunk(
    'company/getCompanyInfo',
    async () => {
        const response = await COMPANY_API.getOne();

        const company = response.data;

        if (company.company_package_payment) {
            let {
                company_package_payment: {
                    fim,
                    eco_score,
                    b2c,
                    transaction_certificate,
                    audit_template,
                    import_sites
                }
            } = company
            localStorage.setItem("fim_enable", fim ? "1" : "0");
            localStorage.setItem("eco_score_enable", eco_score ? "1" : "0");
            localStorage.setItem("b2c_enable", b2c ? "1" : "0");
            localStorage.setItem("transaction_certificate_enable", transaction_certificate ? "1" : "0");
            localStorage.setItem("audit_template_enable", audit_template ? "1" : "0");

        }

        localStorage.setItem("scanner_function_available", company.scanner_function_available ? "1" : "0");

        return response.data;
    }
);

export const updateCompanyAsync = createAsyncThunk(
    'company/updateCompany',
    async (company: object) => {
        const response = await COMPANY_API.updateCompanyFormData({...company})
        return response.data;
    }
);

export const updateCompanyObjAsync = createAsyncThunk(
    'company/updateCompanyObj',
    async (company: object) => {
        const response = await COMPANY_API.updateCompany({...company})
        return response.data;
    }
);

export const updateCompanyObjV2Async = createAsyncThunk(
    'company/updateCompanyObjV2',
    async (company: CompanyUpdateInterface) => {
        const response = await COMPANY_API.updateCompanyV2({...company})
        return response.data;
    }
);

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setCompany: (state, action: PayloadAction<CompanyInterface>) => {
            state = {...action.payload}
            return state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyAsync.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
            })
            .addCase(updateCompanyAsync.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
            })
            .addCase(updateCompanyObjAsync.fulfilled, (state, action) => {
                Object.assign(state, action.payload)
            })
            .addCase(updateCompanyObjV2Async.fulfilled, (state, action: PayloadAction<CompanyUpdateInterface>) => {
                for (const [key, value] of Object.entries(action.payload)) {
                    state[key] = value
                }
                return state
            })
    },
});

export const companyPageSlice = createSlice({
    name: 'companyPage',
    initialState: {
        selected: CompanyTabs.INFORMATION,
        canNavigate: true,
        tabs: [
            {
                value: CompanyTabs.INFORMATION,
                display: "LABEL.TAB.INFORMATION"
            },
            {
                value: CompanyTabs.USERS,
                display: "LABEL.TAB.USERS"
            },
            {
                value: CompanyTabs.SITES,
                display: "LABEL.TAB.SITES"
            },
            {
                value: CompanyTabs.PROCESSES,
                display: "LABEL.TAB.PROCESSES"
            },
            {
                value: CompanyTabs.PARTNERS,
                display: "LABEL.TAB.PARTNERS",
            },
        ],
        showPendingPartners: false,
        showPendingUsers: false,
        showRequestToJoinUsers: false,
        panelState: {
            loading: true, show: false,
            meta: {
                step: USER_ACTION.CREATE,
                header: "LABEL.VIEW",
            }
        }
    },
    reducers: {
        setTab: (state, action: PayloadAction<any>) => {
            state.selected = action.payload;
        },
        setCanNavigate: (state, action: PayloadAction<any>) => {
            state.canNavigate = action.payload;
        },
        setCompanyPage: (state, action: PayloadAction<any>) => {
            state.selected = action.payload.selected;
            state.canNavigate = action.payload.canNavigate;
        },
        setShowPendingPartners: (state, action: PayloadAction<any>) => {
            state.showPendingPartners = action.payload
        },
        setPanelState: (state, action: PayloadAction<any>) => {
            state.panelState = action.payload
        },
    },
})

/**
 * FIP-2472 Pending Claim company
 */
export const companyClaimSlice = createSlice({
    name: 'companyClaim',
    initialState: {
        ...DEFAULT_MODAL,
        pending_claim: false,
        // isShow: true,
    },
    reducers: {
        show: (state) => {
            state.pending_claim = true;
            state.isShow = true;
        },
        setPendingClaim: (state, action: PayloadAction<any>) => {
            _.merge(state, action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyAsync.fulfilled, (state, action) => {
                const {pending_claim} = action.payload;
                if (pending_claim) {
                    state.isShow = true
                }
            })
    },
})

export const companyPartnersPageSlice = createSlice({
    name: 'companyPartnersPage',
    initialState: {
        showPendingPartners: false,
        panelState: {
            loading: true, show: false,
            meta: {
                step: USER_ACTION.CREATE,
                header: "LABEL.VIEW",
            }
        },
        invite: {
            isLoading: false,
            isModalShow: false,
        },
        revokePartner: {
            isShow: false,
            isLoading: false,
            disabled: false,
            text: '',
            type: 'info'
        }
    },
    reducers: {
        setState: (state, action: PayloadAction<any>) => {
            Object.assign(state, action.payload)
        },
        setShowPendingPartners: (state, action: PayloadAction<any>) => {
            state.showPendingPartners = action.payload
        },
        setPanelState: (state, action: PayloadAction<any>) => {
            state.panelState = action.payload
        },
        setInviteState: (state, action: PayloadAction<any>) => {
            state.invite = action.payload
        },
        setRevokePartnerState: (state, action: PayloadAction<any>) => {
            state.revokePartner = action.payload
        },
    },
})

export const companyUsersPageSlice = createSlice({
    name: 'companyUsersPage',
    initialState: {
        showPendingInvitation: false,
        showRequestToJoin: false,
        panelState: {
            loading: true, show: false,
            meta: {
                step: USER_ACTION.CREATE,
                header: "LABEL.INVITE_USERS",
            }
        },
        invite: {
            isLoading: false,
            isModalShow: false,
        },
        revokePartner: {
            isShow: false,
            isLoading: false,
            disabled: false,
            text: '',
            type: 'info'
        }
    },
    reducers: {
        setState: (state, action: PayloadAction<any>) => {
            Object.assign(state, action.payload)
        },
        setShowPendingInvitation: (state, action: PayloadAction<any>) => {
            state.showRequestToJoin = false;
            state.showPendingInvitation = action.payload
        },
        setShowRequestToJoin: (state, action: PayloadAction<any>) => {
            state.showPendingInvitation = false;
            state.showRequestToJoin = action.payload
        },
        setPanelState: (state, action: PayloadAction<any>) => {
            state.panelState = action.payload
        },
        setInviteState: (state, action: PayloadAction<any>) => {
            state.invite = action.payload
        },
        setRevokePartnerState: (state, action: PayloadAction<any>) => {
            state.revokePartner = action.payload
        },
    },
})

export const {setCompany} = companySlice.actions;

export const companyState = (state: RootState) => state.company;
export const companyPageState = (state: RootState) => state.companyPage;
export const companyPartnersPageState = (state: RootState) => state.companyPartnersPage;
export const companyClaimState = (state: RootState) => state.companyClaim;
export const companyUsersPageState = (state: RootState) => state.companyUsersPage;

export default companySlice.reducer;
