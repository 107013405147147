import appRouteReducer from '@redux/reducers/appRoutesSlice';
import appProviderReducer from '@redux/reducers/appProviderSlice';
import accountInvoiceReducer, {invoiceDetailModalSlice, invoiceDetailSlice} from "@redux/reducers/accountInvoiceSlice";
import {accountServicesSlice, profilePageSlice} from "@redux/reducers/profileSlice";
import auditReducer from '@redux/reducers/auditSlice';
import auditSelectedReducer, {auditClaimDisputeModalSlice} from '@redux/reducers/auditSelectedSlice';
import auditTemplateReducer from "@redux/reducers/auditTemplateSlice";
import blockReducer from '@redux/reducers/blockSlice';
import companyMetaReducer from "@redux/reducers/companyMetaSlice";
import companyReducer, {
  companyClaimSlice,
  companyPageSlice,
  companyPartnersPageSlice,
  companyUsersPageSlice
} from '@redux/reducers/companySlice';
import companySetupReducer from '@redux/reducers/companySetupSlice';
import companyDataReducer from '@redux/reducers/companyDataSlice';
import companyTypeReducer from '@redux/reducers/companyTypeSlice';
import configReducer from '@redux/reducers/configSlice';
import confirmationMessageReducer from '@redux/reducers/confirmationMessageSlice';
import deviceReducer from '@redux/reducers/deviceSlice';
import fibretraceMiddleware from "@redux/hooks/middleware";
import guideReducer from '@redux/reducers/guideSlice';
import headerReducer from '@redux/reducers/headerSlice';
import importPartnersProgress from "@redux/reducers/importPartnersProgressSlice";
import importPartnersReducer from "@redux/reducers/importPartnersSlice";
import inventoryReducer from '@redux/reducers/inventorySlice';
import jobReducer from '@redux/reducers/jobSlice';
import languageReducer from '@redux/reducers/languageSlice';
import linkedOrdersReducer from '@redux/reducers/linkedOrderMessageSlice';
import messageReducer from '@redux/reducers/messageSlice';
import notificationReducer from '@redux/reducers/notificationSlice';
import orderDetailPageReducer, {orderProcessesSlice} from '@redux/reducers/orderDetailPage';
import pageReducer from '@redux/reducers/pageSlice';
import panelReducer from "@redux/reducers/panelSlice";
import partnerPendingReducer from '@redux/reducers/partnerPendingSlice';
import partnerReducer from '@redux/reducers/partnerSlice';
import processReducer from '@redux/reducers/processSlice';
import productReducer, {productImportModalSlice} from '@redux/reducers/productSlice';
import productTemplateReducer from '@redux/reducers/productTemplateSlice';
import registerReducer from '@redux/reducers/registerSlice';
import routerReducer from '@redux/reducers/routerSlice';
import searchReducer from '@redux/reducers/searchSlice';
import scannerReducer from '@redux/reducers/scannerSlice';
import sessionExpiredReducer from "@redux/reducers/sessionExpiredSlice";
import siteReducer from '@redux/reducers/siteSlice';
import userCompanyReducer from '@redux/reducers/userCompanySlice';
import userIntroManagerReducer from '@redux/reducers/userIntroManagerSlice';
import userIntroReducer from "@redux/reducers/userIntroSlice";
import userPendingReducer from '@redux/reducers/userPendingSlice';
import userReducer, {userMetaSlice} from '@redux/reducers/userSlice';
import userRequestReducer from '@redux/reducers/userRequestSlice';
import videoModalReducer from '@redux/reducers/videoModalSlice';
import wizardChildReducer from '@redux/reducers/wizardChildSlice';
import wizardReducer from '@redux/reducers/wizardSlice';
import companyPaymentInfoModalSlice from '@redux/reducers/companyPaymentInfoModalSlice';
import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {
  auditConfirmAcceptSlice,
  auditConfirmPendingSlice,
  auditConfirmRejectSlice,
  auditConfirmTriggerSlice
} from "@redux/reducers/auditConfirmation";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE,} from 'redux-persist';
import * as localforage from "localforage";
import sendInventorySlice from "@redux/reducers/sendInventorySlice";

const persistConfig = {
    key: 'language',
    storage: localforage, // use localforage as storage
    whitelist: ['language'] // only language will be persisted, add other reducer names if needed
};
const persistedLanguageReducer = persistReducer(persistConfig, languageReducer);

export const store = configureStore({
    reducer: {
        appRoutes: appRouteReducer,
        appProvider: appProviderReducer,
        accountInvoices: accountInvoiceReducer,
        accountServices: accountServicesSlice.reducer,
        audit: auditReducer,
        auditSelected: auditSelectedReducer,
        auditClaimDisputeModal: auditClaimDisputeModalSlice.reducer,
        auditConfirmTrigger: auditConfirmTriggerSlice.reducer,
        auditConfirmPending: auditConfirmPendingSlice.reducer,
        auditConfirmAccept: auditConfirmAcceptSlice.reducer,
        auditConfirmReject: auditConfirmRejectSlice.reducer,
        auditTemplate: auditTemplateReducer,
        block: blockReducer,
        company: companyReducer,
        companyClaim: companyClaimSlice.reducer,
        companyData: companyDataReducer,
        companyMeta: companyMetaReducer,
        companyPage: companyPageSlice.reducer,
        companyPartnersPage: companyPartnersPageSlice.reducer,
        companyPaymentInfoModal: companyPaymentInfoModalSlice,
        companyUsersPage: companyUsersPageSlice.reducer,
        companySetup: companySetupReducer,
        companyType: companyTypeReducer,
        config: configReducer,
        confirmation: confirmationMessageReducer,
        device: deviceReducer,
        guide: guideReducer,
        header: headerReducer,
        importPartners: importPartnersReducer,
        importPartnersProgress: importPartnersProgress,
        inventory: inventoryReducer,
        invoiceDetail: invoiceDetailSlice.reducer,
        invoiceDetailModal: invoiceDetailModalSlice.reducer,
        job: jobReducer,
        language: persistedLanguageReducer,
        linkedOrders: linkedOrdersReducer,
        message: messageReducer,
        notification: notificationReducer,
        orderDetailPage: orderDetailPageReducer,
        orderProcesses: orderProcessesSlice.reducer,
        page: pageReducer,
        panel: panelReducer,
        partner: partnerReducer,
        partnerPending: partnerPendingReducer,
        process: processReducer,
        product: productReducer,
        productImportModal: productImportModalSlice.reducer,
        productTemplate: productTemplateReducer,
        profilePage: profilePageSlice.reducer,
        register: registerReducer,
        router: routerReducer,
        search: searchReducer,
        scanner: scannerReducer,
        sessionExpired: sessionExpiredReducer,
        site: siteReducer,
        user: userReducer,
        userCompany: userCompanyReducer,
        userIntro: userIntroReducer,
        userIntroManager: userIntroManagerReducer,
        userMetadata: userMetaSlice.reducer,
        userPending: userPendingReducer,
        userRequest: userRequestReducer,
        videoModal: videoModalReducer,
        wizard: wizardReducer,
        wizardChild: wizardChildReducer,
        sendInventoryModalState: sendInventorySlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // serializableCheck: false,
            serializableCheck: {ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],},
        }).concat(fibretraceMiddleware),
});

let persistor = persistStore(store);

export {  persistor };

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
