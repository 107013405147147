import React, {Fragment, useEffect, useRef, useState} from 'react';
import {COMPONENT_SETTINGS, COMPONENT_STATUS, Icon, InputRules, Message, USER_ACTION} from "@src/types";
import {blockPage, translateV2, validate} from "@src/helpers";
import {Translations} from "@constants/translate";
import Input from "@components/Input/Input";
import _ from "lodash-es";
import {COMPANY_API} from "@api/Company";
import {faCogs, faCreditCard, faMoneyBill, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GlobalMessage from "@components/Input/Message";
import {Button} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {userState} from "@redux/reducers/userSlice";
import {companyState, setCompany as setCompanyState} from "@redux/reducers/companySlice";
import {companyTypeState} from "@redux/reducers/companyTypeSlice";
import {SITE_API} from "@api/Site";
import {APP_ROUTES_PATH} from "@src/routes";
import {colourStyles} from "@components/Input/MultiSelect";
import Select from "react-select";
import {configState} from "@redux/reducers/configSlice";

enum EForm {
  company_name = "company_name ",
  company_code = "company_code",
  company_description = "company_description",
  company_website = "company_website",
  email = "email",
  phone = "phone",
  company_type = "company_type",
  registration_id = "registration_id",
  tax_id = "tax_id",
}

const CompanyInfoPayment = (
  {onLeave, onChange, onSubmit}: {
    onLeave?: (params: { block: boolean }) => void,
    onChange?: ({}: COMPONENT_SETTINGS) => void,
    onSubmit?: ({}: COMPONENT_SETTINGS) => void,
  }) => {
  const langData = useAppSelector(languageState);
  const companyInfo = useAppSelector(companyState);
  const userInfo = useAppSelector(userState);
  const allTypes = useAppSelector(companyTypeState);
  const config = useAppSelector(configState);

  const dispatch = useAppDispatch();
  const siteAbortControllerRef = useRef<any>(null)

  const formDefault = {
    headquarter_site_id: {
      value: null as any,
      rules: [InputRules.REQUIRED],
      isValid: true,
      error: ''
    },
    registration_id: {
      value: '',
      rules: [InputRules.REQUIRED],
      isValid: true,
      error: ''
    },
    tax_id: {
      value: '',
      rules: [InputRules.REQUIRED],
      isValid: true,
      error: ''
    },
    tax_id_type: {
      value: '',
      rules: [InputRules.REQUIRED],
      isValid: true,
      error: ''
    },
  }

  const [company, setCompany] = useState({} as any);

  const [form, setForm] = useState({...formDefault});
  const [companyDefault, setCompanyDefault] = useState({types: undefined as any});
  const [formStatus, setFormStatus] = useState({
    processing: false,
    isValid: false,
    message: "",
    messageType: Message.ERROR
  });
  const [sites, setSites] = useState({
    list: [] as any,
    selected: [] as any
  });

  const [taxIdTypes, setTaxIdTypes] = useState({
    list: config.tax_id_types,
    selected: [] as any
  });

  const [errorMessage, setErrorMessage] = useState(null as any);

  const handleInput = async (e: any = {}) => {
    const f = form, c = company;
    const target = e.target;
    const value = target?.type === 'checkbox' ? target.checked : target.value;
    const name: EForm = target.name;

    if (_.has(f, name) && value !== f[name].value) {
      const {error, value: formattedValue} = validate(value, f[name].rules);
      f[name].value = formattedValue || value;
      f[name].isValid = !error
      f[name].error = error || "";
      if (_.has(c, name)) c[name] = formattedValue || value;
      setForm({...form, ...f});
      setCompany({...company, ...c});
      await formHandle.validate();
    }

  }

  const formHandle = {
    validate: async (formExtra = {}, companyExtra = {}) => {
      try {
        const fs = formStatus, c = await buildSubmitData(formExtra, companyExtra);
        fs.isValid = _.every(form, (v) => v.isValid)

        setFormStatus({...formStatus, ...fs});
        if (onLeave) onLeave({block: true})
        if (onChange) onChange({
          ...fs,
          status: COMPONENT_STATUS.FINISH,
          action: USER_ACTION.UPDATE,
          object: c,
          form: {...form, ...formExtra}
        })

      } catch (e) {
        console.error(e)
      }
    },
    onBlur: handleInput,
    onchange: handleInput,
    onSubmit: async (e: any = {}) => {
      try {
        e.preventDefault();
        setFormStatus({...formStatus, processing: true});
        let f = {...form}, c = {...company};

        if (formStatus.isValid) {
          const c = _.omitBy({
            registration_id: form.registration_id.value,
            tax_id: form.tax_id.value,
            tax_id_type: form.tax_id_type.value,
            headquarter_site_id: form.headquarter_site_id.value,
          }, _.isNil)

          const {
            error,
            message,
          } = await handleUpdateCompany(c);

          if (error) setErrorMessage(error)
          else setErrorMessage(null)

          if (onLeave) onLeave({block: false})

          if (onSubmit && !error) onSubmit({
            ...formStatus,
            status: COMPONENT_STATUS.FINISH,
            action: USER_ACTION.UPDATE,
            object: c,
            form
          })
        }
      } catch (error) {
        setFormStatus({
          ...formStatus,
          message: error,
          messageType: Message.ERROR
        })
        console.error(e)
      }
    }
  }

  const buildSubmitData = async (formExtra = {}, companyExtra = {}) => {
    try {
      return {...company, ...companyExtra}
    } catch (e) {
      console.error(e)
      return {}
    }
  }

  const handleUpdateCompany = async (params: any = {}) => {
    try {
      if (!_.isEmpty(params)) {
        const fs = {...formStatus};
        const res = await COMPANY_API.updateCompanyV2(params);
        const {
          error, message, data, sanction_organizations = [],
          sanction_regions = [],
          sanction_words = []
        } = res;

        fs.isValid = false;
        fs.message = error || message || "";
        fs.messageType = error ? Message.ERROR : Message.SUCCESS;

        if (!error) {
          dispatch(setCompanyState({...companyInfo, ...data}))
        } else {
          setErrorMessage(error)
        }

        setFormStatus({...formStatus, ...fs});

        return {
          error, message,
          sanction_organizations,
          sanction_regions,
          sanction_words,
        }
      }
      return {error: Message.ERROR, message: ""}
    } catch (e) {
      console.error(e)
      return {error: Message.ERROR, message: e.message}
    }
  }

  const onSelectHeadquarter = (value, {action, removedValue}) => {
    try {
      const c = company;
      c.headquarter_site_id = value.id;
      setSites({...sites, selected: value})
      setCompany({...company, ...c})
      formHandle.validate();
    } catch (e) {
      console.error(e)
    }
  }

  const fetchSites = async (params: any = {}) => {
    try {
      if (siteAbortControllerRef.current) {
        siteAbortControllerRef.current.abort("cancel fetching sites")
      }

      siteAbortControllerRef.current = new AbortController();

      let {data: dataSite} = await SITE_API.getSites({
        'sort': 'ASC',
        'sort_by': 'name',
        'is_paginate': false,
        signal: siteAbortControllerRef.current?.signal,
      });

      if (dataSite) {
        dataSite = dataSite.map((i: any) => {
          return {...i, value: i.id, label: i.name + ' - ' + i.address}
        })

        setSites({
          ...sites,
          list: [...dataSite],
          selected: dataSite.filter((i: any) => {
            if (i.id == companyInfo.headquarter_site_id) {
              const {id: value, name: label} = i;
              return {...i, value, label}
            }
          })
        })
      }

    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    (async () => {
      try {

        await fetchSites();

        let f = {...form}, c = {...company}, cf = {...companyDefault};
        _.forIn(companyInfo, function (v: any, k) {
          if (_.has(f, k) && !_.isNil(v)) {
            const {error, value: defaultValue} = validate(v, f[k].rules);
            f[k].value = defaultValue || v;
            f[k].error = error || "";
            f[k].isValid = !error;
          }
        });

        setForm({...form, ...f})
        setCompany({...company, ...c})
        setCompanyDefault({...companyDefault, ...cf})

        if (companyInfo.tax_id_type) {
          setTaxIdTypes({
            ...taxIdTypes,
            selected: taxIdTypes.list.find((i: any) => i.type === companyInfo.tax_id_type)
          })
        }
      } catch (e) {
        console.error(e)
      }
    })();

    blockPage(false);

  }, [companyInfo])

  return (
    <>

      <form action="" className="company-form" onSubmit={formHandle.onSubmit}>

        <div className="row">

          {
            companyInfo.headquarter_site_id ? null : <div className="col-12">
              <h3 className={"page-subtitle"}>{translateV2("Headquarter")}</h3>

              <div
                className="input-group fibre-input align-items-center flex-wrap flex-sm-nowrap pt-1 pb-1">
                <div className="input-group-prepend">
                                    <span className="input-group-text fibre-input_label text-left">
                                        {translateV2("LABEL.HEADQUARTER")}
                                    </span>
                  <span className="input-group-text fibre-input_icon">
                                        <FontAwesomeIcon icon={faCogs}/>
                                    </span>
                </div>

                {
                  sites && _.size(sites.list) <= 0
                    ? <div dangerouslySetInnerHTML={
                      {
                        __html: translateV2("LABEL.COMPANY.HEADQUARTER.CREATE_A_SITE") + translateV2("Click {0} to create a size", "", {
                          '0': `<a href="${APP_ROUTES_PATH.COMPANY}?t=SITES">here</a>`
                        })
                      }}>
                    </div>
                    : <Select
                      menuPosition={"fixed"} menuShouldScrollIntoView={true}
                      className="basic-multi-select fib-multi-select"
                      styles={colourStyles}
                      isClearable={false}
                      options={sites.list}
                      value={sites.selected}
                      onChange={onSelectHeadquarter}
                    />
                }

              </div>
            </div>
          }

          <div className="col-12 ">

            <h3 className={"page-subtitle"}>{translateV2("LABEL.TAX_AND_REGISTRATION_ID")}</h3>

            <Input icon={{type: Icon.FA, icon: faCreditCard}} type="text" name="registration_id" required={true}
                   label={translateV2("LABEL.REGISTRATION_ID")}
                   change={formHandle.onchange} blur={formHandle.onBlur}
                   val={form.registration_id.value} labelClassName="text-left"
                   inputClassName="editable"/>
            {form.registration_id.error &&
                <GlobalMessage className="--pad"
                               message={translateV2(form.registration_id.error)}/>}

            <div className="input-group fibre-input align-items-center flex-wrap flex-sm-nowrap pt-1 pb-1">

              <div className="input-group-prepend">
                <Fragment>
                    <span className="input-group-text fibre-input_label text-left">
                        {translateV2("LABEL.COMPANY.TAX_ID_TYPE")}
                      <span className={"text-red"}
                            dangerouslySetInnerHTML={{__html: translateV2('LABEL.ASTERISK')}}
                      />
                    </span>

                  <span className="input-group-text fibre-input_icon"><FontAwesomeIcon icon={faMoneyBill}/></span>
                </Fragment>
              </div>

              <Select
                menuPosition={"fixed"} menuShouldScrollIntoView={true}
                className="basic-multi-select fib-multi-select"
                styles={colourStyles}
                isClearable={false}
                options={taxIdTypes.list}
                value={taxIdTypes.selected}
                getOptionLabel={(option: {
                  [x: string]: any;
                }) => `${option.flag} ${option.name} - ${option.type.replace('_', ' ').toUpperCase()}`}
                getOptionValue={(option: { [x: string]: any; }) => `${option.type}`}
                onChange={async (value, {action, removedValue}) => {
                  // const c = company;
                  // c.tax_id_type = value.type;
                  setTaxIdTypes({...taxIdTypes, selected: value})
                  await formHandle.onchange({target: {name: "tax_id_type", value: value.type}})
                }}
              />

            </div>

            <Input icon={{type: Icon.FA, icon: faMoneyBill}} type="text" name="tax_id" required={true}
                   label={translateV2("LABEL.TAX_ID")}
                   change={formHandle.onchange} blur={formHandle.onBlur}
                   val={form.tax_id.value} labelClassName="text-left"
                   inputClassName="editable"/>
            {form.tax_id.error &&
                <GlobalMessage className="--pad"
                               message={translateV2(form.tax_id.error)}/>}


            {errorMessage && <GlobalMessage errorClassName={"error"} message={errorMessage}/>}

          </div>

        </div>

        {Boolean(JSON.parse(localStorage.getItem("administrator_enable") + "") && userInfo.is_company_admin) &&
            <div className="mt-lg-5 text-right">

                <Button variant="success" type="submit"
                        className={`btn-green btn-green--big w-250 btn-flex mx-auto ${!formStatus.isValid || formStatus.processing ? " disabled " : ""}`}
                        disabled={!formStatus.isValid || formStatus.processing}>
                  {formStatus.processing && <><FontAwesomeIcon icon={faSpinner} spin/> &nbsp;</>}
                  {translateV2(Translations["LABEL.UPDATE"])}
                </Button>

            </div>}

      </form>

    </>
  );
}

export default CompanyInfoPayment;
