import React from "react";
import {Button} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {EImportPartnersSteps, importPartnersSlice, importPartnersState} from "@redux/reducers/importPartnersSlice";
import {importPartnersProgressSlice, importPartnersProgressState} from "@redux/reducers/importPartnersProgressSlice";
import _ from "lodash-es";
import {translateV2} from "@src/helpers";
import {userIntroState} from "@redux/reducers/userIntroSlice";
import {partnerImportAfterClose} from "@v2components/PartnerImport/PartnerImportHelper";

const PartnerImportDoneCTA = () => {

    const dispatch = useAppDispatch();
    const user = useAppSelector(userIntroState);

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);

    return (
        <>
            <Button variant="success" className={"fibre-btn-success fibre-landscape-padding fibre-border-radius"}
                    onClick={() => {
                        if (!_.size(importPartnersProgress.stepData.invitation.partner_list_invitation)) {
                            dispatch(importPartnersSlice.actions.hide())
                            dispatch(importPartnersProgressSlice.actions.reset())
                            partnerImportAfterClose()

                        } else {
                            dispatch(importPartnersProgressSlice.actions.setStep({
                                step: EImportPartnersSteps.INVITATION
                            }))
                        }
                    }}>
                {translateV2(_.size(importPartnersProgress.stepData.invitation.partner_list_invitation) ? "LABEL.CONTINUE" : "LABEL.CLOSE")}
            </Button>
        </>
    )
}

export default PartnerImportDoneCTA;