import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {DEFAULT_MODAL} from "@src/variables";
import {ConfirmationMessageInterface} from "@src/types/modal";

const initialState: ConfirmationMessageInterface = {
  ...DEFAULT_MODAL, size: "lg",
  confirmAction: "",
  cancelAction: "",
  chainConfirmActions: [],
  chainCancelActions: [],
  confirmButton: {
    visible: true,
    variant: "success",
    label: "Yes"
  },
  cancelButton: {
    visible: true,
    variant: "outline-dark",
    label: "No"
  },
  children: null
}

export const companyPaymentInfoModalSlice = createSlice({
  name: 'companyPaymentInfo',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<ConfirmationMessageInterface>) => {
      state.isShow = true;
    },
    hide: (state) => {
      Object.assign(state, initialState);
    }
  },
});

export const companyPaymentInfoModalState = (state: RootState) => state.companyPaymentInfoModal;
export default companyPaymentInfoModalSlice.reducer