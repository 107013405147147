import {ASSETS} from "@assets/Assets";
import {Image} from "react-bootstrap";
import React from "react";

const FibreModalCloseButton = ({onClick}) => {

    return <Image src={ASSETS.IconCloseV2}
                  loading={"lazy"}
                  height={16}
                  decoding={"async"}
                  style={{marginBottom: "0.5em"}}
                  className={"modal-fib-closev2"}
                  onClick={onClick}
    />
}

export default FibreModalCloseButton