import React from "react";
import {Alert, Image} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {importPartnersState} from "@redux/reducers/importPartnersSlice";
import {importPartnersProgressState, ImportStatusEnum} from "@redux/reducers/importPartnersProgressSlice";
import _ from "lodash-es";
import {translateV2} from "@src/helpers";
import {ASSETS} from "@assets/Assets";

const PartnerImportDone = () => {

    const dispatch = useAppDispatch();

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);

    return (
        <>
            {
                importPartnersProgress.statusImport === ImportStatusEnum.FAILED &&
                <Alert variant={'danger'} className={"fibre-alert-danger pt-4 pb-4"}>
                    <div className={`d-flex flex-row justify-content-start align-items-center `}>

                        <Image src={ASSETS.IconInfoCircleError} loading={"lazy"} height={20} decoding={"async"}
                               className={""}
                               onClick={() => {
                               }}
                        />

                        <p className="mb-0 ml-3">
                            Import failed due to [PartnerImportFailReason]
                        </p>
                    </div>
                </Alert>
            }

            {
                importPartnersProgress.statusImport === ImportStatusEnum.SUCCESS &&
                <Alert variant={'success'} className={"fibre-alert-success pt-4 pb-4"}>
                    <div className={`d-flex flex-row justify-content-start align-items-center `}>

                        <Image src={ASSETS.IconCheckSuccessGreenV2} loading={"lazy"} height={20} decoding={"async"}
                               className={""}
                               onClick={() => {
                               }}
                        />

                        <p className="mb-0 ml-3" dangerouslySetInnerHTML={{
                            __html: translateV2("LABEL.PARTNER.IMPORT.IMPORT_DONE.TOTAL_SUCCESS", "", {
                                total: _.size(_.filter(importPartnersProgress.stepData.preview.partner_list_formatted, (p: any) => p.allow_import))
                            })
                        }}/>
                    </div>
                </Alert>
            }
        </>
    )
}

export default PartnerImportDone;
