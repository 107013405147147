export const colourStyles = {
    control: styles => ({...styles, backgroundColor: 'white', width: "100%", minWidth: "300px", maxWidth: "100%"}),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default',
            backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? "#719949" : null,
            color: isFocused ? "#000000" : null,
            ':hover': {
                ...styles[':hover'],
                color: "#fff",
                backgroundColor: "#719949",
            },
            ':active': {
                ...styles[':hover'],
                color: "#fff"
            },
        }
    },
    multiValue: (base, state) => {
        return {...base, backgroundColor: "#719949", color: "#ffffff"};
    },
    multiValueLabel: (base, state) => {
        base = {...base, color: '#ffffff'}
        return {...base, fontWeight: 'bold', color: 'white', paddingRight: 6}
    },
    multiValueRemove: (base, {data, isDisabled, isFocused, isSelected}) => {
        return {...base, display: (isDisabled || data.isFixed) ? "none" : "inherit"}
    },
}

export const colourSingleStyles = {
    control: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles, width: "100%", minWidth: "300px", maxWidth: "100%",
            backgroundColor: isDisabled ? "#e9ecef" : 'white',
            border: isDisabled ? "1px solid #ced4da" : styles.border,
        }
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default',
            backgroundColor: isDisabled ? "#e9ecef" : isSelected ? data.color : isFocused ? "#719949" : null,
            color: isFocused ? "#000000" : null,
            ':hover': {
                ...styles[':hover'],
                color: "#fff",
                backgroundColor: "#719949",
            },
            ':active': {
                ...styles[':hover'],
                color: "#fff"
            },
        }
    },
    multiValue: (base, state) => {
        return {...base, backgroundColor: "#719949", color: "#ffffff"};
    },
    multiValueLabel: (base, state) => {
        base = {...base, color: '#ffffff'}
        return {...base, fontWeight: 'bold', color: 'white', paddingRight: 6}
    },
    multiValueRemove: (base, {data, isDisabled, isFocused, isSelected}) => {
        return {...base, display: (isDisabled || data.isFixed) ? "none" : "inherit"}
    },
}

export const auditTemplateStyles = {
    control: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles, width: "100%", minWidth: "300px", maxWidth: "100%",
            backgroundColor: isDisabled ? "#e9ecef" : 'white',
            border: isDisabled ? "1px solid #ced4da" : styles.border,
        }
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default',
            backgroundColor: isDisabled ? "#e9ecef" : isSelected ? data.color : isFocused ? "#719949" : null,
            display: "none",
            color: isFocused ? "#000000" : null,
            ':hover': {
                ...styles[':hover'],
                color: "#fff",
                backgroundColor: "#719949",
            },
            ':active': {
                ...styles[':hover'],
                color: "#fff"
            },
        }
    },
    multiValue: (base, state) => {
        return {...base, backgroundColor: "#719949", color: "#ffffff"};
    },
    multiValueLabel: (base, state) => {
        base = {...base, color: '#ffffff'}
        return {...base, fontWeight: 'bold', color: 'white', paddingRight: 6}
    },
    multiValueRemove: (base, {data, isDisabled, isFocused, isSelected}) => {
        return {...base, display: (isDisabled || data.isFixed) ? "none" : "inherit"}
    },
}

export const colourSingleInlineStyles = {
    control: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles, width: "100%", minWidth: "200px", maxWidth: "100%", minHeight: "52px",
            backgroundColor: isDisabled ? "#e9ecef" : 'white',
            border: isDisabled ? "1px solid #ced4da" : styles.border,
            borderColor: "#ced4da",
            ':hover': {
                ...styles[':hover'],
                borderColor: "#719949"
            },
            ':focus': {
                ...styles[':hover'],
                borderColor: "#719949"
            },
            ':active': {
                ...styles[':hover'],
                borderColor: "#719949"
            },
        }
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default',
            backgroundColor: isDisabled ? "#e9ecef" : isSelected ? data.color : isFocused ? "#719949" : null,
            color: isFocused ? "#000000" : null,
            ':hover': {
                ...styles[':hover'],
                color: "#fff",
                backgroundColor: "#719949",
            },
            ':active': {
                ...styles[':hover'],
                color: "#fff"
            },
        }
    },
    multiValue: (base, state) => {
        return {...base, backgroundColor: "#719949", color: "#ffffff"};
    },
    multiValueLabel: (base, state) => {
        base = {...base, color: '#ffffff'}
        return {...base, fontWeight: 'bold', color: 'white', paddingRight: 6}
    },
    multiValueRemove: (base, {data, isDisabled, isFocused, isSelected}) => {
        return {...base, display: (isDisabled || data.isFixed) ? "none" : "inherit"}
    },
}
export const colourCreatableInlineStyles = {
    control: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles, width: "100%", minWidth: "200px", maxWidth: "100%", minHeight: "52px",
            backgroundColor: isDisabled ? "#e9ecef" : 'white',
            border: isDisabled ? "1px solid #ced4da" : styles.border,
            borderColor: "#ced4da",
            ':hover': {
                ...styles[':hover'],
                borderColor: "#719949"
            },
            ':focus': {
                ...styles[':hover'],
                borderColor: "#719949"
            },
            ':active': {
                ...styles[':hover'],
                borderColor: "#719949"
            },
        }
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default',
            backgroundColor: isDisabled ? "#e9ecef" : isSelected ? data.color : isFocused ? "#719949" : null,
            color: isFocused ? "#000000" : null,
            ':hover': {
                ...styles[':hover'],
                color: "#fff",
                backgroundColor: "#719949",
            },
            ':active': {
                ...styles[':hover'],
                color: "#fff"
            },
        }
    },
    multiValue: (base, state) => {
        return {...base, backgroundColor: "#719949", color: "#ffffff"};
    },
    multiValueLabel: (base, state) => {
        base = {...base, color: '#ffffff'}
        return {...base, fontWeight: 'bold', color: 'white', paddingRight: 6}
    },
    multiValueRemove: (base, {data, isDisabled, isFocused, isSelected}) => {
        return {...base, display: (isDisabled || data.isFixed) ? "none" : "inherit"}
    },
}

export const colourRequiredSingleInlineStyles = {
    control: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles, width: "100%", minWidth: "200px", maxWidth: "100%", minHeight: "52px",
            backgroundColor: isDisabled ? "#e9ecef" : 'white',
            border: isDisabled ? "1px solid #ced4da" : styles.border,
            borderColor: "red",
            ':hover': {
                ...styles[':hover'],
                borderColor: "#719949"
            },
            ':focus': {
                ...styles[':hover'],
                borderColor: "#719949"
            },
            ':active': {
                ...styles[':hover'],
                borderColor: "#719949"
            },
        }
    },
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default',
            backgroundColor: isDisabled ? "#e9ecef" : isSelected ? data.color : isFocused ? "#719949" : null,
            color: isFocused ? "#000000" : null,
            ':hover': {
                ...styles[':hover'],
                color: "#fff",
                backgroundColor: "#719949",
            },
            ':active': {
                ...styles[':hover'],
                color: "#fff"
            },
        }
    },
    multiValue: (base, state) => {
        return {...base, backgroundColor: "#719949", color: "#ffffff"};
    },
    multiValueLabel: (base, state) => {
        base = {...base, color: '#ffffff'}
        return {...base, fontWeight: 'bold', color: 'white', paddingRight: 6}
    },
    multiValueRemove: (base, {data, isDisabled, isFocused, isSelected}) => {
        return {...base, display: (isDisabled || data.isFixed) ? "none" : "inherit"}
    },
}