import {Image, Modal} from "react-bootstrap";
import {ASSETS} from "@assets/Assets";
import {DEFAULT_APP_CONFIG, DEFAULT_MODAL} from "@src/variables";
import {translateV2} from "@src/helpers";
import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {companyClaimState, companyState} from "@redux/reducers/companySlice";
import {wizardState} from "@redux/reducers/wizardSlice";
import {Message} from "@src/types";
import CompanySetupV2 from "@v2components/Company/CompanySetupV2";
import {companySetupSlice, companySetupState} from "@redux/reducers/companySetupSlice";
import {userMetadataState, userState} from "@redux/reducers/userSlice";
import {importPartnersState} from "@redux/reducers/importPartnersSlice";
import {triggerNextOrCloseModalAsync, userIntroManagerState} from "@redux/reducers/userIntroManagerSlice";

export const GlobalCompanySetupModal = () => {

    const dispatch = useAppDispatch();
    const user = useAppSelector(userState)
    const userMetadata = useAppSelector(userMetadataState);

    const userIntroManager = useAppSelector(userIntroManagerState)
    const company = useAppSelector(companyState)
    const companySetup = useAppSelector(companySetupState)
    const importPartner = useAppSelector(importPartnersState)
    const setupWizard = useAppSelector(wizardState)
    const claimCompany = useAppSelector(companyClaimState);

    const [messageModal, setMessageModal] = useState({...DEFAULT_MODAL});

    const handleMessageModal = {
        open: () => {
            try {
                dispatch(companySetupSlice.actions.showMessage({
                    isShow: true,
                    title: "",
                    message: "",
                    type: Message.NEUTRAL
                }))
            } catch (e) {
                console.error(e)
            }
        },
        close: () => {
            /**
             * dispatch(companySetupSlice.actions.hideMessage())
             *
             * if (user.first_login_and_first_user) {
             *     dispatch(importPartnersSlice.actions.show())
             * } else {
             *     if (!user.is_company_admin) {
             *   }
             * }
             */
            dispatch(triggerNextOrCloseModalAsync({userMetadata, userIntroManager}))

        }
    }

    return <>
        <Modal show={companySetup.isShow}
               onHide={handleMessageModal.close} size={"lg"}>

            <Modal.Body>

                <Image src={ASSETS.IconCloseV2} loading={"lazy"} height={20} decoding={"async"}
                       style={{marginBottom: "0.5em"}} className={"modal-fib-close"}
                       onClick={handleMessageModal.close}
                />

                <div className="text-center pt-5">
                    <Image src={ASSETS.logo} fluid={true} alt={DEFAULT_APP_CONFIG.app_name} className={"mb-2"}/>
                    <p>{translateV2("LABEL.REGISTER.GUIDE.ACCOUNT")}</p>
                </div>

                <CompanySetupV2 onFinish={handleMessageModal.close}/>

            </Modal.Body>
        </Modal>
    </>
}

