import React, {useEffect, useState} from 'react';
import {COMPONENT_STATUS, Message, USER_ACTION} from '@src/types';
import {translateV2, validateEmails} from '@src/helpers';
import {ACCOUNT_API} from "@api/Account";
import erroricon from "@assets/images/icons/error-msg.png";
import {useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {DEFAULT_COMPONENT_SETTINGS} from "@src/variables";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isMobile} from "react-device-detect";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import GlobalMessage from "@components/Input/Message";
import _ from "lodash-es";

const InviteFormWizardV2 = ({data, onChange, onSubmit}: any) => {
    const langData = useAppSelector(languageState);
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccessState] = useState(false);

    const [globalMsg, setGlobalMsg] = useState({
        type: '',
        text: '',
        data: []
    });

    const [inviteData, setInviteData] = useState({
        emails: "",
        message: "",
        same_company: true
    })

    const [errorMsg, setErrorMsg] = useState({
        emails: {isValid: true, error: "ERROR.VALIDATE.EMAILS", messageData: {}},
        message: {isValid: true, error: "ERROR.VALIDATE.MESSAGE", messageData: {}},
    })

    const [canSubmit, setSubmitState] = useState(false);
    const [compSettings, setCompSettings] = useState({...DEFAULT_COMPONENT_SETTINGS});
    const [emailError, showEmailError] = useState(false);

    const handleChange = (event: any) => {
        let data = {...inviteData};

        setGlobalMsg({
            type: '',
            text: '',
            data: []
        });

        if (event.target) {
            const value = event.target?.type === 'checkbox' ? event.target.checked : event.target.value;
            const name = event.target.name;
            switch (name) {
                case "plain_emails" : {
                    showEmailError(false);
                    setInviteData({...inviteData, emails: value});
                    data.emails = value;
                    validateInput.emails(value);
                    break;
                }
                case "plain_message" : {
                    setInviteData({...inviteData, message: value});
                    data.message = value;
                    validateInput.message(value);
                    break;
                }
                case "same_company" : {
                    setInviteData({...inviteData, same_company: value});
                    break;
                }
            }
            validateInput.submit(data);
        }
    };

    const validateInput = {
        emails: (emails) => {
            const err = errorMsg;
            if (!emails) {
                err.emails.isValid = false;
                err.emails.error = "ERROR.VALIDATE.EMAILS";
                return !emails;
            }

            const stt = validateEmails(emails)
            err.emails.isValid = true;
            if (!stt) {
                err.emails.isValid = false;
                err.emails.error = "ERROR.EMAIL_WRONG_FORMAT";
                setSubmitState(false);
            }
            setErrorMsg(err);
            return stt;
        },
        message: (message) => {
            const err = errorMsg;
            err.message.isValid = true;
            if (!message) {
                err.message.isValid = false;
                setErrorMsg(err)
                setSubmitState(false);
            }
        },
        submit: ({emails, message}) => {
            const stt = validateEmails(emails);
            if (stt && message) {
                setSubmitState(true);
                const tmp = {
                    ...compSettings,
                    isValid: true,
                    action: USER_ACTION.INVITE_USERS,
                    status: COMPONENT_STATUS.READY,
                    object: {...inviteData, emails, message}
                };
                setCompSettings({...tmp})
                if (_.isFunction(onChange)) {
                    onChange({...tmp})
                }
            }
        }
    };

    const handleSubmit = async (event: any) => {
        try {
            event.preventDefault();
            setLoading(true);
            if (canSubmit) {
                const req = await ACCOUNT_API.inviteUser({
                    emails: inviteData.emails,
                    message: inviteData.message,
                    same_company: inviteData.same_company
                });
                const {error, data} = req;
                if (!error) {
                    setGlobalMsg({
                        type: Message.SUCCESS,
                        text: translateV2("SUCCESS.INVITED"),
                        data: []
                    })
                    setSuccessState(true);
                    if (_.isFunction(onSubmit)) await onSubmit(req);
                } else {
                    setGlobalMsg({
                        type: Message.ERROR,
                        text: (!_.isArray(error) ? error : error[0]) || "ERROR.API_ERROR",
                        data
                    })
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        } catch (err) {
            setGlobalMsg({
                type: Message.ERROR,
                text: err || "ERROR.API_ERROR",
                data: []
            })
            setLoading(false)
        }
    };

    useEffect(() => {
        setInviteData({...inviteData, message: translateV2("LABEL.INVITE_MESSAGE.JOIN_FIBRETRACE_COLLEAGUES")})
    }, [langData.localize]);

    return (
        <>
            <form id="inviteForm" className={"fib-inviteusers-form position-relative"} onSubmit={handleSubmit}>

                <OverlayTrigger overlay={

                    <Tooltip id={`tooltip-process-cat`}>
                        {translateV2("LABEL.TOOLTIP.COLLEAGUES.SELECT_WITH_EG")}
                    </Tooltip>} defaultShow={false}>

                    <FontAwesomeIcon
                        style={{right: isMobile ? "16px" : "-24px"}}
                        className={"tooltip-helper cursor-pointer"} icon={faInfoCircle}/>

                </OverlayTrigger>

                {globalMsg.type &&
                    <div className="global-msg-wrap text-left">
                        <div className="global-msg">
                            {globalMsg.type === Message.ERROR && <img src={erroricon} alt=""/>}
                            <p className={`${globalMsg.type === Message.ERROR ? 'error' : ''}`}>
                                {globalMsg.text === 'ERROR.INVITE_EXISTED_USER' && `${globalMsg.data} `}
                                {translateV2(globalMsg.text)}</p>
                        </div>
                    </div>
                }

                {!isSuccess &&
                    <>
                        <textarea id="inviteEmail" className="form-control"
                                  style={{width: isMobile ? "calc(100% - 40px)" : "100%"}}
                                  onChange={handleChange}
                                  onBlur={() => {showEmailError(true)}}
                                  name="plain_emails"
                                  placeholder={translateV2("LABEL.INVITE_EMAIL")}/>

                        <p className={"mt-3"}>
                            <b className={``}>&#40;{translateV2("LABEL.ASTERISK")}&#41;</b>&nbsp;
                            <i>{translateV2("LABEL.COMMON.CHARACTERS_NOT_ALLOWED", "",
                                {characters: translateV2("LABEL.COMMON.ALLOWED_CHARACTERS")}
                            )}</i>
                        </p>

                        {!errorMsg.emails.isValid && errorMsg.emails.error && emailError &&
                            <GlobalMessage errorClassName={Message.ERROR}
                                           message={translateV2(errorMsg.emails.error)}
                                           messageData={errorMsg.emails.messageData}
                            />}

                        <br/>

                        <label htmlFor="inviteMessage" className={"d-none"}>
                            {translateV2("LABEL.INVITE_MESSAGE")}
                        </label>

                        <textarea id="inviteMessage" className="form-control d-none" rows={5}
                                  onChange={handleChange} name="plain_message"
                                  value={translateV2(inviteData.message)}
                                  placeholder={translateV2("LABEL.INVITE_MESSAGE")}/>

                        {!errorMsg.message.isValid && errorMsg.message.error && <div className="global-msg">
                                    <span className="error">
                                        {translateV2(errorMsg.message.error)}</span>
                        </div>}

                        <p className={""}>
                            <i>{translateV2("LABEL.INVITE_USERS_NOTE")}</i>
                        </p>

                    </>
                }

            </form>
        </>
    );
}

export default InviteFormWizardV2;
