import {colourSingleStyles} from "@components/Input/MultiSelect";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import _ from "lodash-es";

interface I_Option {
    value: number | string,
    label: string,
    isDisabled?: boolean
    selected?: boolean
}

const SelectMultipleIdPercentage = ({
                                        min = 0, max = 100, step = 10, name = _.uniqueId(`percentage_`),
                                        value,
                                        minSelectable = 0,
                                        maxSelectable = 100,
                                        className = "basic-single mb-1 mb-sm-0",
                                        classNamePrefix = "select",
                                        onChange,
                                    }: any) => {

    const [options, setOptions] = useState<I_Option[]>([])

    const [isDisabled, setDisabled] = useState(true)
    const [isLoading, setLoading] = useState(true)
    const [isClearable, setClearable] = useState(false)
    const [isSearchable, setSearchable] = useState(true)

    const [selected, setSelected] = useState<I_Option>()

    useEffect(() => {
        let opt: I_Option[] = []
        for (let i = min; i <= max; i += step) {
            let o: I_Option = {
                value: i,
                label: `${i}%`,
                isDisabled: false,
            }
            if (i === value) {
                setSelected(o)
            }
            opt.push(o)
        }

        setOptions(opt)
        setDisabled(false)
        setLoading(false)
        // setClearable(false)
        // setSearchable(false)

        return () => {
        }
    }, []);

    useEffect(() => {
        setOptions(prevState => {
            prevState.map((o) => {
                o.isDisabled = o.value > maxSelectable
                return o;
            })
            return prevState;
        })
    }, [maxSelectable]);

    useEffect(() => {
        _.map(options, (o) => {
            if (o.value === value) {
                setSelected(o)
            }
        })
    }, [value]);

    return (
        <Select
            styles={colourSingleStyles}
            className={className}
            classNamePrefix={classNamePrefix}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            name={name}
            options={options}
            value={selected}
            onChange={(e) => {
                // setSelected(e)
                if (_.isFunction(onChange)) onChange({
                    selected: e,
                    min
                })
            }}
        />
    );
}

export default SelectMultipleIdPercentage;