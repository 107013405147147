import React from "react";
import {translateV2} from "@src/helpers";
import {useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";

const GlobalMessage = ({
                           message = "" as any, messageDefault = "", messageData = {},
                           className = "global-msg",
                           errorClassName = "error" as string,
                           style = {} as any
                       }) => {
    const langData = useAppSelector(languageState);

    return (
        <>
            {message && <div className={`global-msg ${className}`} style={style}>
                <span className={errorClassName}
                      dangerouslySetInnerHTML={{__html: translateV2( message, messageDefault, messageData)}}/>
            </div>}
        </>
    )

}

export default GlobalMessage;
