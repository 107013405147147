import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Message, PROCESS_AUDIT} from "@src/types";
import {DEFAULT_MODAL} from "@src/variables";
import {ButtonVariant} from "react-bootstrap/types";
import {RootState} from "@redux/hooks/store";
import {AUDIT_API} from "@api/Audit";
import {buildAuditProcessInfo} from "@pages/MyAudits/helper";

interface I_AuditConfirmation {
  isShow: boolean
  title: string | any
  message: string | any
  messageData?: any
  type: Message
  size?: "sm" | "lg" | "xl",
  style?: {},
  centered?: boolean
  cancelButton?: {
    visible: true,
    variant: ButtonVariant,
    label: "Close"
  },
  curProcess: any,
  currentAudit: any,
}

const initialState: I_AuditConfirmation = {
  ...DEFAULT_MODAL, size: "lg", cancelButton: {
    visible: true,
    variant: "outline-dark",
    label: "Close"
  },
  curProcess: {},
  currentAudit: {},
}

export const sendInventorySlice = createSlice({
  name: 'sendInventory',
  initialState,
  reducers: {
    hideModal: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessAuditData.pending, (state, action) => {
        state.curProcess = {}
      })
      .addCase(getProcessAuditData.rejected, (state, action) => {
        state.curProcess = {}
      })
      .addCase(getProcessAuditData.fulfilled, (state, action) => {
        const audit = action.payload
        state.curProcess = audit;
        state.currentAudit = buildAuditProcessInfo({audit});
        console.log(buildAuditProcessInfo({audit}))
        state.isShow = true;
      })
    ;
  },
});

export const getProcessAuditData = createAsyncThunk(
  'getProcessAuditData',
  async (data: any) => {
    try {
      const response = await AUDIT_API.getOrderProcessAuditDetailByProcessUid({...data})
      return response.data;
    } catch (e) {
      console.error(e)
      return []
    }
  },
);

export const {hideModal} = sendInventorySlice.actions

export const sendInventoryModalState = (state: RootState) => state.sendInventoryModalState;
export default sendInventorySlice.reducer
