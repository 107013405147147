import React from "react";
import {Button} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {importPartnersSlice, importPartnersState} from "@redux/reducers/importPartnersSlice";
import {
    importPartnersProgressSlice,
    importPartnersProgressState, InvitationStatusEnum,
    invitePartnersAsync
} from "@redux/reducers/importPartnersProgressSlice";
import {translateV2} from "@src/helpers";
import _ from "lodash-es";
import {unwrapResult} from "@reduxjs/toolkit";
import {showMessage} from "@redux/reducers/messageSlice";
import {Message} from "@src/types";
import {userIntroState} from "@redux/reducers/userIntroSlice";
import {partnerImportAfterClose} from "@v2components/PartnerImport/PartnerImportHelper";

const PartnerInvitationDoneCTA = () => {

    const dispatch = useAppDispatch();
    const user = useAppSelector(userIntroState);

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);

    return (
        <>

            <div className={`d-flex flex-column flex-sm-row justify-content-sm-end w-100`}>

                <div
                    className={`d-inline-flex flex-row justify-content-between justify-content-sm-end align-items-baseline`}>

                    <Button variant="outline-dark"
                            className={"fibre-landscape-padding fibre-border-radius fibre-btn-outline-dark"}
                            onClick={() => {
                                dispatch(importPartnersSlice.actions.hide())
                                dispatch(importPartnersProgressSlice.actions.reset())
                                partnerImportAfterClose()

                            }}>
                        {translateV2("LABEL.CLOSE")}
                    </Button>

                    {
                        (_.size(importPartnersProgress.stepData.invitation.partner_list_invitation) > 0) && <>
                            <Button variant="success" className={"fibre-btn-success fibre-landscape-padding fibre-border-radius ml-3"}
                                    onClick={() => {
                                        /**
                                         *
                                         */
                                        dispatch(invitePartnersAsync({
                                            emails: _.reduce(
                                                importPartnersProgress.stepData.invitation.partner_list_invitation, (res: any, arr) => {
                                                    return res.concat(arr.email_list)
                                                }, []).join(','),
                                            message: translateV2("LABEL.INVITE_MESSAGE.JOIN_FIBRETRACE_PARTNERS")
                                        })).then(unwrapResult).then((response: any) => {
                                            const {error, data, message} = response

                                            if (!error) {
                                                dispatch(importPartnersProgressSlice.actions.reset())
                                                dispatch(showMessage({
                                                    style: {zIndex: 999999999},
                                                    centered: true,
                                                    isShow: true,
                                                    type: Message.SUCCESS,
                                                    title: Message.SUCCESS,
                                                    message: translateV2("LABEL.PARTNER.IMPORT.INVITATION.INVITATION_SEND_SUCCESSFULLY"),
                                                }))
                                            } else {
                                                dispatch(showMessage({
                                                    style: {zIndex: 999999999},
                                                    isShow: true,
                                                    centered: true,
                                                    type: Message.ERROR,
                                                    title: translateV2("LABEL.PARTNER.IMPORT.INVITATION.INVITATION_SEND_UNSUCCESSFULLY"),
                                                    message: message ? message
                                                        : ((_.isArray(error) && _.every(error, (a: any) => !_.isObject(a)))
                                                            ? error
                                                            : _.flatten(_.map(error, (obj,) => _.keys(obj)))),
                                                    messageData: !_.isArray(error) ? ""
                                                        : _.transform(_.flatten(_.map(error, (obj,) => {
                                                            return _.values(obj)
                                                        })), (result, value, key) => {
                                                            result[key] = value;
                                                        }, {})
                                                }))
                                            }

                                            dispatch(importPartnersSlice.actions.hide())
                                            partnerImportAfterClose()

                                        }).catch((e) => {
                                            console.error(e)
                                        })
                                    }}>
                                {translateV2("LABEL.PARTNER.IMPORT.INVITATION.RESEND_INVITE")}
                            </Button>
                        </>
                    }


                </div>

            </div>


        </>
    )
}

export default PartnerInvitationDoneCTA;