import {getFirstLoginFirstUser, getUserStore} from "@src/helpers";
import {store} from "@redux/hooks/store";
import {triggerNextOrCloseModalAsync} from "@redux/reducers/userIntroManagerSlice";

export const partnerImportAfterClose = () => {
    const user = getUserStore();

    if (getFirstLoginFirstUser()) {

        const gStore = store.getState();
        let userIntroManager;
        if (gStore.userIntroManager) userIntroManager = gStore.userIntroManager;

        store.dispatch(triggerNextOrCloseModalAsync({
            userMetadata: gStore.userMetadata,
            userIntroManager: gStore.userIntroManager
        }))


    } else {

    }
}