import React, {useState} from 'react';
import {blockPage, delay, translateV2} from '@src/helpers';
import {Button, Modal} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {showMessage} from "@redux/reducers/messageSlice";
import {Message} from "@src/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {DEFAULT_COMPONENT_SETTINGS} from "@src/variables";
import {AUDIT_API} from "@api/Audit";
import AuditSendFormV2 from "@v2components/AuditForm/AuditSendFormV2";
import {fetchInventoryAsync, inventoryState} from "@redux/reducers/inventorySlice";
import {sendInventoryModalState, hideModal} from "@redux/reducers/sendInventorySlice";

const GlobalAuditConfirmation = () => {
  const dispatch = useAppDispatch();
  const [auditSendComp, setAuditSendComp] = useState({...DEFAULT_COMPONENT_SETTINGS});
  const inventoryGlobalData = useAppSelector(inventoryState)
  const modalState = useAppSelector(sendInventoryModalState)

  const fetchData = async (queries = {}) => {
    try {
      blockPage();
      dispatch(fetchInventoryAsync({...inventoryGlobalData.search.queries, ...queries}))
      delay(1000).then(() => blockPage(false));
    } catch (e) {
      console.error(e)
      delay(1000).then(() => blockPage(false));
    }
  }

  const handleAuditSend = {
    close: () => {
      dispatch(hideModal({...modalState, isShow: false}))
    },
    change: (data: any) => {
      try {
        let as = auditSendComp;
        const {formStatus, audit} = data;
        if (formStatus) {
          const {isValid} = formStatus
          as.isValid = isValid
        }
        if (audit) as.object = {...audit}
        setAuditSendComp({...auditSendComp, ...as})
        console.log(auditSendComp)
      } catch (e) {
        console.error(e)
      }
    },
    submit: async () => {
      try {
        setAuditSendComp({...auditSendComp, processing: true})

        const t = auditSendComp;
        const {message, data: assignData, error} = await AUDIT_API.sendAudit(auditSendComp.object, {})
        t.isValid = !error;
        if (!error) {
          await fetchData();
        } else {
          t.message = error || message;
        }
        handleAuditSend.close();
        setAuditSendComp({...auditSendComp, ...t, processing: false})

        dispatch(showMessage({
          style: {zIndex: 999999999},
          title: translateV2("LABEL.SEND_AUDIT.MODAL.TITLE", "", auditSendComp.object),
          isShow: true,
          message: translateV2("LABEL.SEND_AUDIT.MODAL.SEND_SUCCESS", "",
            {
              ...auditSendComp.object,
              order_code: modalState.curProcess.order_code
            }) || "",
          type: Message.NEUTRAL,
          cancelButton: {
            variant: "success",
            visible: true,
            label: "Close"
          }
        }))

      } catch (e) {
        setAuditSendComp({...auditSendComp, processing: false})

        dispatch(showMessage({
          title: Message.ERROR,
          isShow: true,
          message: e.message,
          type: Message.ERROR
        }))

      }
    }
  }

  return (
    <>
      <Modal style={{zIndex: 999999999}}
             show={modalState.isShow} onHide={handleAuditSend.close} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>{translateV2("LABEL.ORDER.SEND_TO_CUSTOMER")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AuditSendFormV2 data={modalState.currentAudit}
                           process={modalState.curProcess}
                           onChange={handleAuditSend.change}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleAuditSend.close}>
            {translateV2("LABEL.CLOSE")}
          </Button>
          <Button variant="success" onClick={handleAuditSend.submit}
                  disabled={!auditSendComp.isValid || auditSendComp.processing}>
            {auditSendComp.processing && <><FontAwesomeIcon icon={faSpinner} spin/> &nbsp;</>}
            {translateV2("LABEL.SEND_AUDIT")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GlobalAuditConfirmation;
