import React, {useEffect, useState} from 'react';
import Input from '@components/Input/Input';
import {COMPONENT_STATUS, InputRules, Message, SCANNER_CONDITION, SCANNER_TYPES} from '@src/types';
import {translateMessage, translateV2, validate} from '@src/helpers';
import _ from "lodash-es";
import GlobalMessage from "@components/Input/Message";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "react-select";
import {SCANNER_API} from "@api/Scanner";
import LoadingV2 from "@v2components/Loading/LoadingV2";
import {isChrome, isEdge, isFirefox, isMobile} from "react-device-detect";
import {useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {DEFAULT_COMPONENT_SETTINGS} from "@src/variables";

const ScannerCreateWizardV2 = ({onChange, onSubmit}: any) => {
    const langData = useAppSelector(languageState);

    const [conditions, setConditions] = useState({
        isDisabled: false,
        isLoading: false,
        isClearable: false,
        isSearchable: true,
        list: SCANNER_CONDITION,
        selected: "" as any,
    })

    const [types, setTypes] = useState({
        isDisabled: false,
        isLoading: false,
        isClearable: false,
        isSearchable: true,
        list: SCANNER_TYPES,
        selected: SCANNER_TYPES[1] as any,
    })

    const keys = ["name", "characteristic", "tx_characteristic", "service", "type_id", "condition_id",]
    let scanner: any = {
        name: {
            value: '',
            label: 'LABEL.SCANNER.SERIAL_NUMBER',
            rules: [InputRules.REQUIRED],
            isValid: false,
            isDirty: false,
            editable: true,
            error: ''
        },
        characteristic: {
            value: '6e400003-c352-11e5-953d-0002a5d5c51b',
            label: 'LABEL.SCANNER.CHARACTERISTIC',
            rules: [InputRules.REQUIRED],
            isValid: true,
            isDirty: false,
            editable: false,
            error: ''
        },
        tx_characteristic: {
            value: '6e400002-c352-11e5-953d-0002a5d5c51b',
            label: 'LABEL.SCANNER.TX_CHARACTERISTIC',
            rules: [InputRules.REQUIRED],
            isValid: true,
            isDirty: false,
            editable: false,
            error: ''
        },
        service: {
            value: '6e400001-c352-11e5-953d-0002a5d5c51b',
            label: 'LABEL.SERVICE',
            rules: [InputRules.REQUIRED],
            isValid: true,
            isDirty: false,
            editable: false,
            error: ''
        },
        type_id: {
            value: '2',
            label: 'LABEL.SCANNER.TYPE',
            rules: [InputRules.REQUIRED],
            isValid: true,
            isDirty: false,
            error: ''
        },
        // condition_id: {
        //     value: '',
        //     label: 'LABEL.SCANNER.CONDITION',
        //     rules: [InputRules.REQUIRED],
        //     isValid: false,
        //     isDirty: false,
        //     error: ''
        // },
    };
    const [form, setForm] = useState({...scanner});
    const [compSettings, setCompSettings] = useState({...DEFAULT_COMPONENT_SETTINGS});

    const inputHandle = async ({target, type}: any) => {
        try {
            const f = {...form};
            const name = target.name;
            const value = target?.type === 'checkbox' ? target.checked : target.value;
            const maxLength = target.maxLength;
            const {error, value: formattedValue} = validate(value, f[name].rules);

            if (type === 'change') {
                if (_.has(f, name) && value !== f[name].value) {
                    f[name].value = formattedValue || value || "";
                    f[name].isDirty = true;
                    f[name].isValid = !error && f[name].isDirty;
                    f[name].error = "";
                }
            }

            if (type === 'blur') {
                f[name].error = f[name].isDirty ? (error || "") : "";
            }

            setForm({...form, ...f});
            formHandle.validate();
        } catch (e) {
            console.error(e)
        }
    }

    const inputNumberHandle = async ({target, type}: any) => {
        try {
            const f = {...form}, name = target.name, maxLength = target.maxLength;
            let value = target?.type === 'checkbox' ? target.checked : target.value, error: any,
                formattedValue: any, validator: any;


            if (_.size(value) >= maxLength) value = value.substring(0, maxLength);
            value = _.replace(value, "e", "");
            if (isNaN(_.toNumber(value))) value = "";

            validator = validate(value, f[name].rules);
            error = validator.error;
            formattedValue = validator.value;

            if (type === 'change') {
                if (_.has(f, name) && value !== f[name].value) {
                    f[name].value = formattedValue || value || "";
                    f[name].isDirty = true;
                    f[name].isValid = !error && f[name].isDirty;
                    f[name].error = "";
                }
            }

            if (type === 'blur') {
                f[name].error = f[name].isDirty ? (error || "") : "";
            }

            setForm({...form, ...f});
            formHandle.validate();
        } catch (e) {
            console.error(e)
        }
    }

    const formHandle = {
        validate: () => {

            const tmp = {...compSettings, status: COMPONENT_STATUS.READY};
            tmp.isValid = _.every(form, (v) => v.isValid)
            setCompSettings(tmp);

            if (_.isFunction(onChange)) {
                _.each(form, (v: any, k: string) => {
                    if (_.indexOf(keys, k) !== -1) {
                        tmp.object[k] = v.value
                    }
                })

                onChange({...compSettings, ...tmp})
            }
        },
        onChange: inputHandle,
        onBlur: inputHandle,
        onSelectChange: async (selected, field) => {
            try {
                const f = form;
                const {error, isValid} = validate(selected.value, f[field].rules);
                f[field].error = error || "";
                f[field].isValid = !error;

                if (_.has(form, field) && selected.value) {
                    f[field].value = selected.value;
                }
                setForm({...form, ...f})
                if (_.every(f, (v) => v.isValid)) setCompSettings({...compSettings, isValid: true, message: ""})
            } catch (e) {
                console.error(e)
            }
        },
        onSubmit: async (event: any) => {
            try {
                setCompSettings({...compSettings, processing: true});
                const stt = {...compSettings};
                event.preventDefault();
                formHandle.validate();
                if (compSettings.isValid) {
                    let data: any = {};
                    _.each(form, (v: any, k: string) => {
                        if (_.indexOf(keys, k) !== -1) {
                            data[k] = v.value
                        }
                    })
                    const {message, error} = await SCANNER_API.createScanner({data})

                    stt.processing = false;
                    stt.message = error || message || "";
                    stt.messageType = error ? Message.ERROR : Message.SUCCESS;

                    if (error) {
                        stt.isValid = false;
                    } else {
                        if (_.isFunction(onSubmit)) onSubmit(form);
                    }
                    setCompSettings({...compSettings, ...stt})
                }
            } catch (e) {
                console.error('ERROR', e);
                setCompSettings({
                    ...compSettings, ...{
                        isValid: false,
                        processing: false,
                        message: e.toString()
                    }
                })
            }
        }
    };

    useEffect(() => {

    }, [])

    return (
        <>
            {compSettings.message && <div className="global-msg-wrap">
                <GlobalMessage errorClassName={compSettings.messageType}
                               message={translateMessage(langData.localize, compSettings.message)}/>
            </div>}

            {compSettings.processing && <LoadingV2/>}

            <form className={`fib-scannerdetail-form position-relative`} onSubmit={formHandle.onSubmit}>

                <OverlayTrigger
                    overlay={<Tooltip
                        id={`tooltip-scanner-info`}>
                        {translateV2("LABEL.WIZARD.GUIDE.HELP.SCANNER.TOOLTIP")}</Tooltip>}>
                    <FontAwesomeIcon className={"tooltip-helper cursor-pointer"}
                                     style={{right: isMobile ? "0" : "-24px"}}
                                     icon={faInfoCircle}/>
                </OverlayTrigger>

                <div className={`input-group fibre-input `}>

                    <input maxLength={5}
                           style={{
                               maxWidth: isMobile ? "calc(100% - 30px)" : "inherit"
                           }}
                           className={`form-control fibre-input_control fibre-input--border mb-2 mt-2`}
                           placeholder={`${translateV2(form.name.label)} e.g. 00123, 88888 etc.`}
                           name={"name"} value={form.name.value}
                           onChange={inputNumberHandle}
                           onBlur={inputNumberHandle}
                    />

                    <p className="action-texts font-italic fw-500">
                        <span
                            className={"action-texts-cta text-green"}>
                            {translateV2("LABEL.WIZARD.GUIDE.HELP.SCANNER.TOOLTIP")}
                        </span>
                    </p>

                </div>

                {
                    _.map(['service', 'characteristic', 'tx_characteristic'], (key: any) =>

                        <Input type="text" name={key} key={key}
                               label={translateV2(form[key].label)}
                               change={formHandle.onChange} blur={formHandle.onBlur}
                               disabled={!form[key].editable}
                               val={form[key].value} className={"d-none"}
                               labelClassName={"text-left"}
                               inputClassName={"fibre-input--border mb-2 mt-2"}
                        />
                    )
                }

                <div className="input-group fibre-input align-items-center flex-nowrap pt-1 pb-1 d-none">
                    <div className="input-group-prepend">
                                    <span className="input-group-text fibre-input_label text-left">
                                        {translateV2("LABEL.SCANNER.TYPE")}
                                    </span>
                        <span className="input-group-text fibre-input_icon">
                                        {/*<FontAwesomeIcon icon={faCogs}/>*/}
                                    </span>
                    </div>

                    <Select
                        className="basic-single w-100"
                        classNamePrefix="select"
                        isDisabled={types.isDisabled}
                        isLoading={types.isLoading}
                        isClearable={types.isClearable}
                        isSearchable={types.isSearchable}
                        name="type_id"
                        options={types.list}
                        value={types.selected}
                        onChange={(e) => formHandle.onSelectChange(e, 'type_id')}
                    />
                </div>

                {
                    (!isChrome && !isFirefox && !isEdge)
                        ?
                        <GlobalMessage className={"mt-3 fz-18"} errorClassName={Message.ERROR}
                                       style={{lineHeight: "24px"}}
                                       message={translateV2("LABEL.BROWSER_NOT_SUPPORT")}/>
                        : ""
                }

            </form>
        </>
    );
}

export default ScannerCreateWizardV2;
