import React, {useEffect, useRef, useState} from 'react';
import ModalVideo from 'react-modal-video';
import {Message, USER_ACTION} from '@src/types';
import {delay, fibLogger, translateV2} from '@src/helpers';
import _ from "lodash-es";
import {COMPANY_API} from '@api/Company';
import {faLongArrowAltLeft, faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Image, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {
    DEFAULT_COMPONENT_SETTINGS,
    DEFAULT_MODAL,
    DEFAULT_ORDER_SUMMARY,
    DEFAULT_PRODUCT_DATA,
    DEFAULT_SELECT
} from "@src/variables";
import {ASSETS} from "@assets/Assets";
import {APP_ROUTES_PATH} from "@src/routes";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {userState} from "@redux/reducers/userSlice";
import {companyState} from "@redux/reducers/companySlice";
import {companyTypeState,} from "@redux/reducers/companyTypeSlice";
import SiteCreateWizardV2 from "@v2components/Company/CompanySiteV2/SiteCreateWizardV2";
import ProcessDetailWizardV2 from "@v2components/Company/CompanyProcessV2/Detail/ProcessDetailWizardV2";
import InviteFormWizardV2 from "@v2components/Company/CompanyUser/InviteFormV2/InviteFormWizardV2";
import InvitePartnerWizardV2 from "@v2components/Company/CompanyPartnerV2/InvitePartnerWizardV2";
import ScannerCreateWizardV2 from "@v2components/Scanner/ScannerCreateV2/ScannerCreateWizardV2";
import OrderFormV2P from "@v2components/Order/OrderFormV2P";
import {ORDER_API} from "@api/Order";
import OrderProcessFormV2 from "@v2components/Order/OrderProcessFormV2";
import PanelV2 from "@v2components/Panel/PanelV2";
import {SITE_API} from "@api/Site";
import GlobalMessage from "@components/Input/Message";
import {PROCESS_API} from "@api/Process";
import {ACCOUNT_API} from "@api/Account";
import {PARTNER_API} from "@api/Partner";
import {SCANNER_API} from "@api/Scanner";
import {wizardSlice} from "@redux/reducers/wizardSlice";
import LoadingV2 from "@v2components/Loading/LoadingV2";
import {isMobile} from "react-device-detect";
import ProductDetailWizardV2 from "@v2components/Product/Detail/ProductDetailWizardV2";
import {appRoutesState} from "@redux/reducers/appRoutesSlice";
import {useNavigate} from "react-router-dom";

enum EForm {
    first_name = 'first_name',
    last_name = 'last_name',
    phone = 'phone',
    rbrand = 'rbrand',
    email = 'email',
    company_code = 'company_code',
    company_name = 'company_name',
    company_website = 'company_website',
    registration_id = 'registration_id',
    tax_id = 'tax_id',
    invited_by = 'invited_by',
    password = 'password',
    password_confirmation = 'password_confirmation'
}

enum SendStatus {
    'SUCCESS' = 'SUCCESS',
    'FAILED' = 'FAILED',
    'LOADING' = 'LOADING',
    'NONE' = ''
}

/**
 * @toto
 * Refactor code, now just make-it-work
 * @param params
 * @param onFinish
 * @param onContinue
 * @constructor
 */

const QuickStartWizardV2 = ({
                                params = {} as any,
                                onFinish = {} as any,
                                onContinue = {} as any,
                            }) => {
    const langData = useAppSelector(languageState);
    const routeSettings = useAppSelector(appRoutesState)
    const companyInfo = useAppSelector(companyState);
    const allTypes = useAppSelector(companyTypeState);
    const userInfo = useAppSelector(userState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [formStatus, setFormStatus] = useState({
        processing: false,
        isValid: false,
        message: "",
        messageType: "error" as Message
    });

    let [progress, setProgress] = useState({
        current: 1 as number,
        list: [
            {step: 1, status: "PENDING", isProcessing: false, isValid: true, isSkip: false, error: []},
            {step: 2, status: "PENDING", isProcessing: false, isValid: true, isSkip: false, error: []},
            {
                step: 3,
                status: "PENDING",
                isProcessing: false,
                isValid: true,
                isSkip: true,
                error: []
            },
            {
                step: 4,
                status: "PENDING",
                isProcessing: false,
                isValid: true,
                isSkip: !Boolean(JSON.parse(localStorage.getItem("administrator_enable") + "")
                    && userInfo.is_company_admin
                    && (companyInfo.current_subscription?.name || "").toUpperCase() === "VERIFIED"
                ),
                error: []
            },
            {step: 5, status: "PENDING", isProcessing: false, isValid: true, isSkip: false, error: []},
            {step: 6, status: "PENDING", isProcessing: false, isValid: true, isSkip: false, error: []},
            {step: 7, status: "PENDING", isProcessing: false, isValid: true, isSkip: false, error: []},
            {step: 8, status: "PENDING", isProcessing: false, isValid: true, isSkip: false, error: []},
        ]
    } as any);

    /**
     * TwoFactor Login Handle
     */
    const [transaction, setTransaction] = useState("" as string);
    const [sendStatus, setSendStatus] = useState({
        status: SendStatus.NONE,
        message: ''
    });
    const [factorCode, setFactorCode] = useState({
        value: '',
        error: ''
    });
    const [partnerModal, setPartnerModal] = useState({...DEFAULT_MODAL})
    const [types, setTypes] = useState({...DEFAULT_SELECT});

    const [inviteModal, setInviteModal] = useState({...DEFAULT_MODAL});
    const [helpModal, setHelpModal] = useState({
        ...DEFAULT_MODAL,
        videoID: "",
        channel: "youtube",
        start: "0"
    });

    const [processing, setProcessing] = useState(false);
    const [businesses, setBusinesses] = useState({} as any);
    const [bizType, setBizType] = useState({} as any);

    const [siteMapVisible, setSitemapVisible] = useState(false);
    const [siteComponent, setSiteComponent] = useState({...DEFAULT_COMPONENT_SETTINGS, result: {}})
    const [processComponent, setProcessComponent] = useState({...DEFAULT_COMPONENT_SETTINGS, result: {}})
    const [userComponent, setUserComponent] = useState({...DEFAULT_COMPONENT_SETTINGS, result: {}})
    const [partnerComponent, setPartnerComponent] = useState({...DEFAULT_COMPONENT_SETTINGS, result: {}})
    const [scannerComponent, setScannerComponent] = useState({...DEFAULT_COMPONENT_SETTINGS, result: {}})
    const [productComponent, setProductComponent] = useState({...DEFAULT_COMPONENT_SETTINGS, result: {}})
    const [orderComponent, setOrderComponent] = useState({...DEFAULT_COMPONENT_SETTINGS, result: {}})

    const [currentProduct, setCurrentProduct] = useState({...DEFAULT_PRODUCT_DATA})
    const [currentOrder, setCurrentOrder] = useState({} as any)

    const [orderSummary, setOrderSummary] = useState({...DEFAULT_ORDER_SUMMARY});
    const [orderFormDetail, setOrderFormDetail] = useState({});

    const [panelState, setPanelState] = useState({
        loading: true, show: false,
        meta: {
            step: USER_ACTION.CREATE,
            header: "",
        } as any
    })
    const [duplicatedEmails, setDuplicatedEmails] = useState([] as any);

    const businessTypeAbortRef = useRef<any>(null)

    const stepHandle = {
        setStep: (step: number = 1) => {
            try {

                if (progress.current < _.size(progress.list) && (progress.list[progress.current - 1].isValid || (step < progress.current))) setProgress({
                    ...progress,
                    current: step
                })

                // /**
                //  * @todo: remove when dev-done
                //  */
                // setProgress({
                //     ...progress,
                //     current: step
                // })

            } catch (e) {
                console.error(e)
            }
        },
        prevStep: async () => {
            try {
                let pg = {...progress};
                if (progress.current <= _.size(progress.list)) {

                    pg.current = --progress.current

                    if (pg.list[pg.current - 1].isSkip) --pg.current;


                    setProgress({...pg})
                }
            } catch (e) {
                console.error(e)
            }
        },
        nextStep: async (data: any = {}) => {
            try {
                let pg = {...progress, ...data};

                if (progress.current < _.size(progress.list) && progress.list[progress.current - 1].isValid) {

                    pg.list[pg.current - 1].isProcessing = false;

                    if (pg.list[pg.current].isSkip) ++pg.current;

                    pg.current = ++progress.current;

                    if (pg.current === 2) {
                        checkSiteProcess()
                    }

                    setProgress({...pg})
                }
            } catch (e) {
                console.error(e)
            }
        },
        skipStep: async (data: any = {}) => {
            try {
                let pg = {...progress, ...data};
                if (progress.current < _.size(progress.list)) {
                    pg.list[pg.current - 1].isProcessing = false;

                    if (pg.list[pg.current].isSkip) pg.current = ++progress.current;

                    pg.current = ++progress.current;

                    if (pg.current === 2) {
                        checkSiteProcess()
                    }

                    if (pg.list[pg.current - 1].isSkip) {
                        await stepHandle.skipStep();
                    } else {
                        setProgress({...pg})
                    }
                }
            } catch (e) {
                console.error(e)
            }
        },
        processing: async () => {
            try {
                let pg = {...progress};
                pg.list[pg.current - 1].isProcessing = true;
                setProgress({...pg})
            } catch (e) {
                console.error(e)
            }
        },
        stop: async () => {
            try {
                let pg = {...progress};
                pg.list[pg.current - 1].isProcessing = false;
                pg.list[pg.current - 1].isValid = false;
                setProgress({...pg})
            } catch (e) {
                console.error(e)
            }
        }
    }

    const handlePanel = {
        reset: async () => {
            try {
            } catch (e) {
                console.error(e)
            }
        },
        open: async (data: any = {}) => {
            const {step, product} = data;
            setPanelState({...panelState, show: true, meta: {...panelState.meta, step}})
        },
        close: async () => {
            setPanelState({...panelState, show: false, meta: {}})
        },
        change: async (data: any) => {
            try {
                /**
                 * @todo: remove
                 */
            } catch (e) {
                console.error(e)
            }
        },
        submit: async (data: any = {}) => {
            try {
                const {rObject} = data;
                if (rObject) {
                    await handlePanel.close();
                    setCurrentProduct({...currentProduct, ...rObject})
                    setProductComponent({...productComponent, isValid: true, result: {...currentProduct, ...rObject}})
                    await stepHandle.nextStep();
                }
            } catch (e) {
                await handlePanel.reset();
                console.error(e)
            }

        },
    }

    const businessType = {
        getBatch: async () => {
            try {

                if (businessTypeAbortRef.current) businessTypeAbortRef.current.abort()

                businessTypeAbortRef.current = new AbortController();

                setProcessing(true);

                let tmpBiz = {...businesses};
                const {data, error} = await COMPANY_API.getBusinessTypes({signal: businessTypeAbortRef.current.sinal});
                if (!error) {

                    _.map(data, (biz: any, index: number) => {
                        const key = _.lowerCase(biz.name);
                        if (!_.has(tmpBiz, key)) {
                            tmpBiz[key] = {...biz}
                            tmpBiz[key].label = biz.name;
                            tmpBiz[key].value = biz.id;

                            _.map(tmpBiz[key].processCategories, (pc: any) => {
                                pc.value = pc.id;
                                pc.label = pc.name;
                                return pc;
                            })

                            if (index === 0) {
                                tmpBiz[key].active = true;
                                setBizType(tmpBiz[key]);
                                setProcessing(false);
                            }
                        }
                        return biz
                    })

                }
                setBusinesses({...businesses, ...tmpBiz})
            } catch (e) {
                fibLogger(e.toString(), "error")
            }
        }
    }

    const verifyUserPartnerEmails = (userData: any = {}, partnerData: any = {}) => {
        try {

            console.log(userComponent, partnerComponent)

            let users: any = [], partners: any = [];

            if (!_.isEmpty(userData)) {
                let {emails: userEmailString} = userData;
                users = [...userEmailString.split(",").map((u: any) => u.trim())]
            }

            if (!_.isEmpty(partnerData)) {
                let {emails: partnerEmailString} = partnerData;
                partners = [...partnerEmailString.split(",").map((u: any) => u.trim())]
            }

            let duplicated = _.intersectionWith(users, partners, _.isEqual);

            let proc = {...progress};
            if (!_.isEmpty(duplicated)) {
                proc.list[2].isValid = false;
                proc.list[2].error = ["ERROR.DUPLICATED_EMAILS"];
            } else {
                proc.list[2].isValid = true;
                proc.list[2].error = [];
            }

            setProgress({...progress, ...proc})


            setDuplicatedEmails([...duplicated]);
            return duplicated;
        } catch (e) {

        }
    }

    const checkSiteProcess = (site: any = {},
                              process: any = {}) => {
        try {
            let pg = {...progress},
                tmpSite = {...siteComponent, ...site},
                tmpProcess = {...processComponent, ...process};

            pg.list[1].isValid = !_.isEmpty(tmpSite.object) && tmpSite.isValid && tmpProcess.object.name;

            setProgress({...pg})
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {

        (async () => {
            await businessType.getBatch()
        })()
        return () => {
            setBizType({})
            setBusinesses({})
        }
    }, [])

    return (
        <>


            <div className="register-form">

                <div className={"ft-modal-container"}>

                    {processing && <LoadingV2 style={{borderRadius: "0.3rem"}}/>}

                    <div className={"ft-modal-inner"}>

                        {/*STEP1*/}
                        <div className={`progress-step-form ${progress.current === 1 ? "active" : ""}`}>

                            <div className="text-left">

                                <h3>{translateV2("LABEL.WIZARD.GUIDE.HELP")}</h3>
                                <hr/>

                                <p>{translateV2("LABEL.WIZARD.GUIDE.HELP.FIBRETRACE")}</p>
                                <p>{translateV2("LABEL.WIZARD.GUIDE.HELP.BUSINESS_TYPE")}</p>

                                <div className={"ux-businesses"}>

                                    {_.map(businesses, (b) =>
                                        <label key={`btype-${b.value}`} className="fibre-checkbox">

                                            <div className="fibre-checkbox_label">
                                                <span className="fibre-checkbox_thumbnail">
                                                         <Image loading={"lazy"}
                                                                className={bizType.value === b.value ? " activate " : ""}
                                                                src={
                                                                    !b.image ?
                                                                        _.includes(_.lowerCase(b.name), 'farming') ? ASSETS.IconUxWindmill :
                                                                            (_.includes(_.lowerCase(b.name), 'factory') || _.includes(_.lowerCase(b.name), 'manufacturer') ? ASSETS.IconUxFactory :
                                                                                (_.includes(_.lowerCase(b.name), 'retail') ? ASSETS.IconUxShop :
                                                                                    (_.includes(_.lowerCase(b.name), 'transport') ? ASSETS.IconUxTruck :
                                                                                        (_.includes(_.lowerCase(b.name), 'warehouse') ? ASSETS.IconUxCargo : ASSETS.IconUxQuestionMark))))
                                                                        : b.image
                                                                }
                                                                width={64}/>
                                                    </span>

                                                <div className={`fw-600 mt-1`}>
                                                    <span>{translateV2(b.label)}</span>
                                                </div>
                                            </div>

                                            <input type="radio"
                                                   id={`btype-${b.value}`}
                                                   name={"business_type"}
                                                   defaultChecked={b.active}
                                                   value={b.value}
                                                   onChange={(data: any) => {
                                                       setBizType({...bizType, ...b})
                                                   }}
                                            />
                                        </label>
                                    )}

                                </div>

                                {(_.size(progress.list[0].error) > 0) &&
                                    <div className="global-msg-wrap text-center text-red mt-3">
                                        <div className="global-msg error"
                                             dangerouslySetInnerHTML={{__html: translateV2(progress.list[0].error)}}/>
                                    </div>}


                            </div>

                        </div>

                        {/*STEP2*/}
                        <div className={`progress-step-form ${progress.current === 2 ? "active" : ""}`}>

                            {progress.current === 2 && <>

                                <p dangerouslySetInnerHTML={{
                                    __html: translateV2("LABEL.WIZARD.GUIDE.HELP.SITE", "", {
                                        '0': `<a class="text-green" href="${APP_ROUTES_PATH.COMPANY}?t=SITES" target="_blank">${translateV2("LABEL.COMPANY")} &#62; ${translateV2("LABEL.SITES")}</a>`
                                    })
                                }}/>

                                <SiteCreateWizardV2
                                    data={{
                                        ...siteComponent.object,
                                        ...siteComponent.result
                                    }}
                                    siteMapVisible={siteMapVisible}
                                    onToggleMap={() => {
                                        setSitemapVisible(!siteMapVisible)
                                    }}
                                    onShowMap={() => {
                                        setSitemapVisible(true)
                                    }}
                                    onHidemap={() => {
                                        setSitemapVisible(false)
                                    }}
                                    onChange={async (data: any) => {
                                        setSitemapVisible(true)
                                        setSiteComponent({...siteComponent, ...data})
                                        checkSiteProcess({...siteComponent, ...data});
                                    }}
                                    onSubmit={async (req: any) => {
                                        const {data} = req;
                                        setSiteComponent({...siteComponent, result: {...data}})
                                        await stepHandle.nextStep()
                                    }}/>

                                {siteComponent.message &&
                                    <GlobalMessage
                                        errorClassName={siteComponent.isValid ? Message.SUCCESS : Message.ERROR}
                                        message={translateV2(siteComponent.message)}/>}

                                <p className={"mt-3"}>{translateV2("LABEL.WIZARD.GUIDE.HELP.PROCESS")}</p>

                                <ProcessDetailWizardV2 action={processComponent.action}
                                                       processes={{
                                                           ...DEFAULT_SELECT,
                                                           list: [...bizType.processCategories]
                                                       }}
                                                       data={{
                                                           ...processComponent.object,
                                                           ...processComponent.result,
                                                           fibretrace_manufacturer_site_id: siteComponent.result['id'] ? siteComponent.result['id'] : "",
                                                           fibretrace_manufacturer_type_id: bizType.id
                                                       }}
                                                       onFocus={() => {
                                                           setSitemapVisible(false)
                                                           checkSiteProcess();

                                                       }}
                                                       onChange={async (req: any) => {
                                                           const {object} = req;
                                                           if (siteComponent.result['id']) {
                                                               object.fibretrace_manufacturer_site_id = siteComponent.result['id']
                                                           }
                                                           checkSiteProcess({}, {object});
                                                           setSitemapVisible(false)
                                                           setProcessComponent({
                                                               ...processComponent, object: {
                                                                   ...processComponent.object,
                                                                   ...object
                                                               }
                                                           })
                                                       }}
                                                       onSubmit={async (req: any) => {
                                                           const {data} = req
                                                           console.log(data)
                                                           // await stepHandle.nextStep()
                                                       }}/>


                                {processComponent.message &&
                                    <GlobalMessage
                                        errorClassName={processComponent.isValid ? Message.SUCCESS : Message.ERROR}
                                        message={translateV2(processComponent.message)}/>}

                            </>}

                            <p className={"mt-1"}
                               dangerouslySetInnerHTML={{
                                   __html: translateV2("LABEL.WIZARD.GUIDE.HELP.COMPANY", "", {
                                       '0': `<a class="text-green" href="${APP_ROUTES_PATH.COMPANY}" target="_blank">${translateV2("LABEL.COMPANY")}</a>`
                                   })
                               }}/>

                            {(_.size(progress.list[1].error) > 0) &&
                                <div className="global-msg-wrap text-center text-red mt-3">
                                    <div className="global-msg"
                                         dangerouslySetInnerHTML={{__html: translateV2(progress.list[1].error)}}/>
                                </div>}

                        </div>

                        {/*STEP3 FIP-2307 Invite partners and company user*/}
                        {
                            !progress.list[2].isSkip
                                ? <div className={`progress-step-form ${progress.current === 3 ? "active" : ""}`}>

                                    {
                                        progress.current === 3 && <>

                                            <p>{translateV2("LABEL.WIZARD.GUIDE.HELP.COLLEAGUES")}</p>

                                            <InviteFormWizardV2 data={userInfo}
                                                                onChange={(data: any) => {
                                                                    let tmp = {...userComponent, ...data};
                                                                    verifyUserPartnerEmails(tmp.object, partnerComponent.object)
                                                                    setUserComponent(tmp)
                                                                }}
                                            />

                                        </>
                                    }

                                    {
                                        progress.current === 3 && <>
                                            <hr/>
                                            <p className={""}>{translateV2("LABEL.WIZARD.GUIDE.HELP.PARTNERS")}</p>

                                            <InvitePartnerWizardV2 data={userInfo}
                                                                   onChange={(data: any) => {
                                                                       let tmp = {...partnerComponent, ...data}
                                                                       verifyUserPartnerEmails(userComponent.object, tmp.object)
                                                                       setPartnerComponent(tmp)
                                                                   }}
                                            />

                                            <p className={"mt-1"}
                                               dangerouslySetInnerHTML={{
                                                   __html: translateV2("LABEL.WIZARD.GUIDE.HELP.COMPANY", "", {
                                                       '0': `<a class="text-green" href="${APP_ROUTES_PATH.COMPANY}" target="_blank">${translateV2("LABEL.COMPANY")}</a>`
                                                   })
                                               }}/>

                                        </>
                                    }

                                    {(_.size(progress.list[2].error) > 0) &&
                                        <div className="global-msg-wrap text-center text-red mt-3">
                                            <div className="global-msg"
                                                 dangerouslySetInnerHTML={{__html: translateV2(progress.list[2].error, "", {'0': '<i>' + duplicatedEmails.join(', ') + '</i>'})}}/>
                                        </div>}


                                </div>
                                : null

                        }

                        {/*STEP4*/}
                        {
                            Boolean(JSON.parse(localStorage.getItem("administrator_enable") + "")
                                && userInfo.is_company_admin
                                && (companyInfo.current_subscription?.name || "").toUpperCase() === "VERIFIED"
                            )
                                ? <div className={`progress-step-form ${progress.current === 4 ? "active" : ""}`}>

                                    {
                                        progress.current === 4 && <>

                                            <p>{translateV2("LABEL.WIZARD.GUIDE.HELP.FIBRETRACE_SCANNER")}</p>

                                            {Boolean(JSON.parse(localStorage.getItem("administrator_enable") + "") && userInfo.is_company_admin) &&
                                                <ScannerCreateWizardV2
                                                    onChange={(data: any) => {
                                                        setScannerComponent({...scannerComponent, ...data})
                                                    }}
                                                />
                                            }

                                            <p className={"mt-3"}
                                               dangerouslySetInnerHTML={{
                                                   __html: translateV2("LABEL.WIZARD.GUIDE.HELP.SCANNER", "", {
                                                       '0': `<a class="text-green" href="${APP_ROUTES_PATH.MY_SCANNERS}" target="_blank">${translateV2("LABEL.SCANNERS")}</a>`
                                                   })
                                               }}
                                            />

                                            <p className="text-center">
                                                <Image src={ASSETS.IconUxScanner} loading={"lazy"}/>
                                            </p>
                                        </>
                                    }
                                </div>
                                : null
                        }

                        {/*STEP5*/}
                        <div className={`progress-step-form ${progress.current === 5 ? "active" : ""}`}>

                            <div className="text-center">

                                <p>{translateV2("LABEL.WIZARD.GUIDE.HELP.PRODUCT")}</p>

                                <div className={`d-flex justify-content-around mb-5`}>
                                    <Image src={ASSETS.IconUxFibProduct} width={96} loading={"lazy"}/>

                                    <OverlayTrigger overlay={

                                        <Tooltip id={`tooltip-ux-product-create`}>
                                            {translateV2("LABEL.WIZARD.GUIDE.HELP.PRODUCT_CTA")}
                                        </Tooltip>} defaultShow={false}>

                                        <Image className={`cursor-pointer`}
                                               loading={"lazy"} src={ASSETS.IconUxRightArrow}
                                               onClick={async (e) => {
                                                   setPanelState({
                                                       ...panelState,
                                                       show: true,
                                                       meta: {
                                                           ...panelState.meta,
                                                           step: USER_ACTION.CREATE,
                                                           header: "LABEL.CREATE_PRODUCT"
                                                       }
                                                   })
                                               }}/>

                                    </OverlayTrigger>

                                </div>

                                <p>{translateV2("LABEL.WIZARD.GUIDE.HELP.PRODUCT_CTA")}&nbsp;&#40;{translateV2("LABEL.ACTION.CLICK")}&nbsp;
                                    <u className={"fpw-600 cursor-pointer"} onClick={async (e) => {
                                        setPanelState({
                                            ...panelState,
                                            show: true,
                                            meta: {
                                                ...panelState.meta,
                                                step: USER_ACTION.CREATE,
                                                header: "LABEL.CREATE_PRODUCT"
                                            }
                                        })
                                    }}>{translateV2("LABEL.NEXT")}</u>&#41;</p>

                                {(_.size(progress.list[4].error) > 0) &&
                                    <div className="global-msg-wrap text-center text-red mt-3">
                                        <div className="global-msg error"
                                             dangerouslySetInnerHTML={{__html: translateV2(progress.list[4].error)}}/>
                                    </div>}

                            </div>

                        </div>

                        {/*STEP6*/}
                        <div className={`progress-step-form ${progress.current === 6 ? "active" : ""}`}>

                            <div className="text-center">

                                <p>{translateV2("LABEL.WIZARD.GUIDE.HELP.ORDER")}</p>

                                <div className={`d-flex justify-content-around mb-5`}>
                                    <Image src={ASSETS.IconUxFibOrder} width={96} loading={"lazy"}/>

                                    <OverlayTrigger overlay={

                                        <Tooltip id={`tooltip-ux-order-create`}>
                                            {translateV2("LABEL.WIZARD.GUIDE.HELP.ORDER_CTA")}
                                        </Tooltip>}>

                                        <Image className={`cursor-pointer`}
                                               loading={"lazy"} src={ASSETS.IconUxRightArrow}
                                               onClick={async (e) => {
                                                   setPanelState({
                                                       ...panelState,
                                                       show: true,
                                                       meta: {
                                                           ...panelState.meta,
                                                           step: USER_ACTION.CREATE,
                                                           header: "LABEL.ORDER"
                                                       }
                                                   })
                                               }}/>

                                    </OverlayTrigger>

                                </div>

                                <p>{translateV2("LABEL.WIZARD.GUIDE.HELP.ORDER_CTA")}&nbsp;&#40;{translateV2("LABEL.ACTION.CLICK")}&nbsp;
                                    <u className={"fpw-600 cursor-pointer"} onClick={async (e) => {
                                        setPanelState({
                                            ...panelState,
                                            show: true,
                                            meta: {
                                                ...panelState.meta,
                                                step: USER_ACTION.CREATE,
                                                header: "LABEL.ORDER"
                                            }
                                        })
                                    }}>{translateV2("LABEL.NEXT")}</u>&#41;</p>

                            </div>

                        </div>

                        {/*STEP7*/}
                        <div className={`progress-step-form ${progress.current === 7 ? "active" : ""}`}>

                            <div className="text-center">

                                <p dangerouslySetInnerHTML={{__html: translateV2("LABEL.WIZARD.GUIDE.HELP.ORDER_PROCESS")}}/>

                                <div
                                    className={`d-flex justify-content-around mb-5 ${_.isEmpty(productComponent.result) || _.isEmpty(productComponent.result) ? 'flex-row-reverse' : ''}`}>

                                    <Image src={ASSETS.IconUxFibAudit} width={96} loading={"lazy"}/>

                                    <OverlayTrigger overlay={
                                        <Tooltip id={`tooltip-ux-order-create`}>
                                            {translateV2(_.isEmpty(productComponent.result) || _.isEmpty(productComponent.result)
                                                ? "LABEL.WIZARD.GUIDE.HELP.ORDER_PROCESS_WARNING"
                                                : "LABEL.WIZARD.GUIDE.HELP.ORDER_PROCESS_CTA")}
                                        </Tooltip>}>

                                        <Image className={`cursor-pointer`}
                                               loading={"lazy"}
                                               src={!_.isEmpty(productComponent.result) && !_.isEmpty(orderComponent.result) ? ASSETS.IconUxRightArrow : ASSETS.IconUxLeftArrow}
                                               onClick={async (e) => {
                                                   let step: number = 5;
                                                   if (!_.isEmpty(productComponent.result)) {
                                                       step = 6;
                                                       if (!_.isEmpty(orderComponent.result)) {
                                                           step = 7;
                                                           setPanelState({
                                                               ...panelState,
                                                               show: true,
                                                               meta: {
                                                                   ...panelState.meta,
                                                                   step: USER_ACTION.CREATE,
                                                                   header: "LABEL.ORDER_PROCESS"
                                                               }
                                                           })
                                                       }
                                                   }
                                                   await stepHandle.setStep(step)
                                               }}/>

                                    </OverlayTrigger>

                                </div>

                                <p className={
                                    !_.isEmpty(productComponent.result) && !_.isEmpty(orderComponent.result) ? " " : " text-red "
                                }>{translateV2(!_.isEmpty(productComponent.result) && !_.isEmpty(orderComponent.result)
                                    ? "LABEL.WIZARD.GUIDE.HELP.ORDER_PROCESS_CTA"
                                    : "LABEL.WIZARD.GUIDE.HELP.ORDER_PROCESS_ERROR"
                                )} &#40;{translateV2("LABEL.ACTION.CLICK")}&nbsp;
                                    <u className={"fpw-600 cursor-pointer"} onClick={async (e) => {
                                        !_.isEmpty(productComponent.result) && !_.isEmpty(orderComponent.result)
                                            ? setPanelState({
                                                ...panelState,
                                                show: true,
                                                meta: {
                                                    ...panelState.meta,
                                                    step: USER_ACTION.CREATE,
                                                    header: "LABEL.ORDER_PROCESS"
                                                }
                                            })
                                            : await stepHandle.prevStep()
                                    }}>{translateV2(!_.isEmpty(productComponent.result) && !_.isEmpty(orderComponent.result) ? "LABEL.NEXT" : "LABEL.BACK")}</u>&#41;
                                </p>

                                <p dangerouslySetInnerHTML={{
                                    __html: translateV2("LABEL.WIZARD.GUIDE.HELP.ORDER_PROCESS_NOTE", "", {
                                        '0': `<a class="text-green" href="${APP_ROUTES_PATH.COMPANY}">${translateV2("LABEL.COMPANY")}</a>`
                                    })
                                }}/>

                            </div>

                        </div>

                        {/*STEP8*/}
                        <div className={`progress-step-form ${progress.current === 8 ? "active" : ""}`}>

                            <div className="text-center">

                                <Image className={`cursor-pointer`}
                                       loading={"lazy"} src={ASSETS.IconUxDoneBadge}
                                       onClick={async (e) => {

                                       }}/>

                                <p>{translateV2("LABEL.WIZARD.GUIDE.HELP.FINISH")}</p>
                                <p dangerouslySetInnerHTML={{
                                    __html: translateV2("LABEL.WIZARD.GUIDE.HELP.FINISH_CTA", "", {
                                        '0': `<a class="text-green" href="${APP_ROUTES_PATH.ORDERS}">${translateV2("LABEL.ORDERS")}</a>`,
                                        '1': 'section'
                                    })
                                }}/>
                                <p>{translateV2("LABEL.WIZARD.GUIDE.HELP.FINISH_NOTE")}</p>


                            </div>

                        </div>

                    </div>

                    <div className="ft-modal-navigator progress-navigator-container mt-3">


                        <div className="d-flex flex-column align-self-start">
                            <p className={"mb-0"}>
                                        <span className={"pl-2 pr-2 cursor-pointer"} onClick={async () => {
                                            await stepHandle.skipStep();
                                        }}>{translateV2(!isMobile ? "LABEL.SKIP_THIS_TASK" : "LABEL.SKIP")}&nbsp;
                                            <FontAwesomeIcon
                                                icon={faLongArrowAltRight}/></span>
                            </p>


                            <ModalVideo channel={'youtube'}
                                        isOpen={helpModal.isShow}
                                        youtube={{
                                            start: helpModal.start,
                                            autoplay: 1,
                                            modestbranding: 0
                                        }}
                                        videoId={helpModal.videoID}
                                        onClose={() => {
                                            setHelpModal({...helpModal, isShow: false})
                                        }}/>

                            {/*HELP*/}
                            {
                                _.includes([2, 3, 5, 6, 7, 8], progress.current) && <p className={"mb-0"}>
                                        <span className={"pl-2 pr-2 cursor-pointer"} onClick={async () => {
                                            let t = {...helpModal};
                                            switch (progress.current) {
                                                case 2:
                                                    t.isShow = true;
                                                    t.videoID = 'Co4mKtFDHxg';
                                                    t.start = "116";
                                                    break;
                                                case 3:
                                                    t.isShow = true;
                                                    t.videoID = 'Co4mKtFDHxg';
                                                    t.start = "81";
                                                    break;
                                                case 5:
                                                    t.isShow = true;
                                                    t.videoID = 'R3D2ufaCcqQ';
                                                    break;
                                                case 6:
                                                    t.isShow = true;
                                                    t.videoID = 'NlSYewjYNDo';
                                                    break;
                                                case 7:
                                                    t.isShow = true;
                                                    t.videoID = 'NlSYewjYNDo';
                                                    t.start = "100";
                                                    break;
                                            }
                                            if (progress.current !== 8) {
                                                setHelpModal({...helpModal, ...t})
                                            } else {
                                                window.open(window.location.origin + APP_ROUTES_PATH.HELP)
                                            }
                                        }}>{translateV2("LABEL.NEED_MORE_HELP")}</span>
                                </p>
                            }

                        </div>

                        <div className={"d-flex justify-between ux-step-navigator"}>

                            {progress.current !== 1 && <Button
                                variant={"success"}
                                className={"mr-1"}
                                onClick={async () => {
                                    await stepHandle.prevStep();
                                }}
                            >
                                <FontAwesomeIcon icon={faLongArrowAltLeft}/>&nbsp;{translateV2("LABEL.BACK")}
                            </Button>}


                            {
                                progress.current === 1 && <Button
                                    disabled={!progress.list[0].isValid}
                                    variant={"success"}
                                    onClick={async (e) => {
                                        await stepHandle.nextStep()
                                    }}
                                    style={{width: "100%"}}
                                >{translateV2("LABEL.NEXT")}&nbsp;<FontAwesomeIcon
                                    icon={faLongArrowAltRight}/></Button>
                            }

                            {
                                progress.current === 2 && <Button
                                    disabled={progress.list[1].isProcessing || !progress.list[1].isValid}
                                    variant={"success"}
                                    onClick={async (e) => {
                                        try {
                                            /**
                                             * @todo
                                             * create site + create process
                                             */

                                            setProcessing(true)

                                            await stepHandle.processing();
                                            let pg = {...progress}

                                            let siteData: any = {...siteComponent.result}

                                            if (_.isEmpty(siteComponent.result) && !_.isEmpty(siteComponent.object)) {
                                                const {
                                                    data,
                                                    error
                                                } = await SITE_API.createSite({...siteComponent.object})
                                                if (!error && siteData) {
                                                    siteData = {...data}
                                                    setSiteComponent({
                                                        ...siteComponent,
                                                        result: {...siteComponent.result, ...data},
                                                        messageType: Message.SUCCESS,
                                                        message: ""
                                                    })
                                                    pg.list[1].isValid = true;
                                                } else {
                                                    setSiteComponent({
                                                        ...siteComponent,
                                                        messageType: Message.ERROR,
                                                        message: error
                                                    })
                                                    pg.list[1].error = [...pg.list[1].error, ...error]
                                                    pg.list[1].isValid = false;
                                                    console.log("Site pre-created", pg);

                                                }
                                            } else {
                                                console.log("Site created", siteComponent);
                                                pg.list[1].isValid = true;
                                            }

                                            if (_.isEmpty(processComponent.result) && !_.isEmpty(processComponent.object)) {
                                                let p = {
                                                    ...processComponent.object,
                                                    fibretrace_manufacturer_site_id: siteData.id,
                                                    description: siteData.name
                                                }
                                                _.unset(p, 'images')
                                                _.unset(p, 'video')
                                                _.unset(p, 'certificates')

                                                const {
                                                    data: processData,
                                                    error
                                                } = await PROCESS_API.createOne({...p});

                                                setProcessComponent({
                                                    ...processComponent,
                                                    message: error || "",
                                                    messageType: error ? Message.ERROR : Message.NEUTRAL,
                                                    isValid: !error,
                                                    processing: false,
                                                    result: {...processData}
                                                })

                                                if (error) {
                                                    console.log("you created some error");

                                                    pg.list[1].error = [...pg.list[1].error, ...error]
                                                    pg.list[1].isValid = false;
                                                }
                                            } else {
                                                console.log("Process created", processComponent);
                                                pg.list[1].isValid = true;
                                            }

                                            console.log({pg})
                                            if (pg.list[1].isValid) {
                                                await stepHandle.nextStep(pg);
                                                setProcessing(false)
                                            }

                                        } catch (e) {
                                            let pg = {...progress}
                                            delay(300).then(async () => {
                                                pg.list[1].isProcessing = false;
                                                setProcessing(false)
                                                setProgress({...pg})
                                                await stepHandle.nextStep();
                                            })
                                        }
                                    }}
                                    style={{width: "100%"}}
                                >{translateV2("LABEL.NEXT")}&nbsp;<FontAwesomeIcon
                                    icon={faLongArrowAltRight}/></Button>
                            }

                            {
                                progress.current === 3 && <Button
                                    disabled={!progress.list[2].isValid || progress.list[2].isProcessing}
                                    variant={"success"}
                                    onClick={async (e) => {
                                        setProcessing(true)
                                        let tmp: any = [];

                                        if (userComponent.isValid && !_.isEmpty(userComponent.object)) {
                                            tmp.push(ACCOUNT_API.inviteUser({...userComponent.object}))
                                        }

                                        if (partnerComponent.isValid && !_.isEmpty(partnerComponent.object)) {
                                            tmp.push(PARTNER_API.invitePartner({...partnerComponent.object}))
                                        }

                                        Promise.all(tmp).then(r => {
                                            setInviteModal({
                                                ...inviteModal,
                                                isShow: true,
                                                title: "",
                                                message: "SUCCESS.INVITED",
                                                type: Message.SUCCESS
                                            })
                                        })

                                    }}
                                    style={{width: "100%"}}
                                >{translateV2("LABEL.NEXT")}&nbsp;<FontAwesomeIcon
                                    icon={faLongArrowAltRight}/></Button>
                            }

                            {
                                progress.current === 4 && <Button
                                    disabled={!progress.list[3].isValid}
                                    variant={"success"}
                                    onClick={async (e) => {
                                        setProcessing(true)

                                        if (scannerComponent.isValid) {
                                            SCANNER_API.createScanner({data: scannerComponent.object}).then((scanner: any) => {

                                            })
                                        }
                                        delay(300).then(async () => {
                                            await stepHandle.nextStep();
                                            setProcessing(false)
                                        })
                                    }}
                                >{translateV2("LABEL.NEXT")}&nbsp;<FontAwesomeIcon
                                    icon={faLongArrowAltRight}/></Button>
                            }

                            {
                                progress.current === 5 && <>

                                    <OverlayTrigger overlay={

                                        <Tooltip id={`tooltip-ux-product-create`}>
                                            {translateV2("LABEL.WIZARD.GUIDE.HELP.PRODUCT_CTA")}
                                        </Tooltip>} defaultShow={false}>

                                        <Button
                                            disabled={!progress.list[4].isValid}
                                            variant={"success"}
                                            onClick={async (e) => {
                                                setPanelState({
                                                    ...panelState,
                                                    show: true,
                                                    meta: {
                                                        ...panelState.meta,
                                                        step: USER_ACTION.CREATE,
                                                        header: "LABEL.CREATE_PRODUCT"
                                                    }
                                                })
                                            }}
                                        >{translateV2("LABEL.NEXT")}&nbsp;<FontAwesomeIcon
                                            icon={faLongArrowAltRight}/></Button>

                                    </OverlayTrigger>

                                </>
                            }

                            {
                                progress.current === 6 && <>

                                    <OverlayTrigger overlay={

                                        <Tooltip id={`tooltip-ux-order-create`}>
                                            {translateV2("LABEL.WIZARD.GUIDE.HELP.ORDER_CTA")}
                                        </Tooltip>}>

                                        <Button
                                            disabled={!progress.list[5].isValid}
                                            variant={"success"}
                                            onClick={async (e) => {
                                                setPanelState({
                                                    ...panelState,
                                                    show: true,
                                                    meta: {
                                                        ...panelState.meta,
                                                        step: USER_ACTION.CREATE,
                                                        header: "LABEL.ORDER"
                                                    }
                                                })
                                            }}
                                        >{translateV2("LABEL.NEXT")}&nbsp;<FontAwesomeIcon
                                            icon={faLongArrowAltRight}/></Button>

                                    </OverlayTrigger>

                                </>
                            }

                            {
                                progress.current === 7 && <Button
                                    disabled={!progress.list[6].isValid || !currentOrder.code}
                                    variant={"success"}
                                    onClick={async (e) => {
                                        setPanelState({
                                            ...panelState,
                                            show: true,
                                            meta: {
                                                ...panelState.meta,
                                                step: USER_ACTION.CREATE,
                                                header: "LABEL.ORDER_PROCESS"
                                            }
                                        })
                                    }}
                                >{translateV2("LABEL.NEXT")}<FontAwesomeIcon
                                    icon={faLongArrowAltRight} className={"ml-2"}/></Button>
                            }

                            {
                                progress.current === 8 && <Button
                                    disabled={!progress.list[7].isValid}
                                    variant={"success"}
                                    onClick={async (e) => {
                                        navigate(currentOrder.code ? APP_ROUTES_PATH.ORDERS + `/${currentOrder.code}` : APP_ROUTES_PATH.ORDERS)

                                        dispatch(wizardSlice.actions.hideMessage())
                                    }}
                                >{translateV2("LABEL.FINISH")}<FontAwesomeIcon
                                    icon={faLongArrowAltRight} className={"ml-2"}/></Button>
                            }


                        </div>


                    </div>

                    <div className="ft-modal-progress progress-step-container">

                        {
                            _.map(progress.list, (p: any, index: number) =>
                                !p.isSkip ?
                                    <span key={`step-${index}`}
                                          className={`progress-step ${progress.current >= p.step ? "active" : ""}`}
                                          style={{
                                              //width: `calc(${(100 / _.size(progress.list))}% - 4px)`
                                              marginLeft: "4px",
                                              marginRight: "4px",
                                          }}
                                          onClick={() => stepHandle.setStep(p.step)}
                                    />
                                    : null
                            )
                        }

                    </div>

                </div>

            </div>


            <PanelV2
                show={panelState.show}
                loading={panelState.loading}
                meta={panelState.meta}
                header={translateV2(panelState.meta.header)}
                onClose={() => {
                    handlePanel.reset().then(() => handlePanel.close())
                }}
            >

                {panelState.show && progress.current === 5 && <ProductDetailWizardV2 step={panelState.meta.step}
                                                                                     data={currentProduct}
                                                                                     onChange={handlePanel.change}
                                                                                     onSubmit={handlePanel.submit}/>}

                {panelState.show && progress.current === 6 &&
                    <OrderFormV2P pid={currentProduct.id}
                                  onCreateProduct={async () => {
                                      stepHandle.setStep(5);
                                      setPanelState({
                                          ...panelState,
                                          show: true,
                                          meta: {
                                              ...panelState.meta,
                                              step: USER_ACTION.CREATE,
                                              header: "LABEL.CREATE_PRODUCT"
                                          }
                                      })
                                  }}
                                  onSubmit={async (data: any) => {
                                      const {rObject} = data;

                                      if (rObject) {
                                          setCurrentOrder({...currentOrder, ...rObject});

                                          const {code} = rObject
                                          const {data} = await ORDER_API.getOrderSummary(code);
                                          const {data: orderDetail} = await ORDER_API.getOrderDetail(code);

                                          let fibretrace_id = orderSummary.fibretrace_id;

                                          if (orderDetail) {
                                              setOrderFormDetail(orderDetail);
                                              const {editable, fibretrace_id: {value: fib_id}} = orderDetail;
                                              fibretrace_id = fib_id;
                                          }

                                          if (data) {
                                              setOrderSummary({
                                                  ...orderSummary,
                                                  ...data.order,
                                                  ...data.order_process_latest,
                                                  ...data.order_process_summary,
                                                  fibretrace_id,
                                                  reference_id: orderDetail.reference_id.value
                                              });
                                              setOrderComponent({
                                                  ...orderComponent, isValid: true, result: {
                                                      ...orderSummary,
                                                      ...data.order,
                                                      ...data.order_process_latest,
                                                      ...data.order_process_summary,
                                                      fibretrace_id,
                                                      reference_id: orderDetail.reference_id.value
                                                  }
                                              })
                                          }

                                          await handlePanel.close();
                                          await stepHandle.nextStep();

                                      }

                                  }}/>}

                {panelState.show && progress.current === 7 && <OrderProcessFormV2
                    action={USER_ACTION.CREATE}
                    options={{
                        orderDetail: {
                            ...orderSummary,
                            fibretrace_manufacturer_process_id: !_.isEmpty(processComponent.result) ? processComponent.result['id'] : ""
                        },
                        orderFormDetail,
                        orderCode: currentOrder.code,
                    }}
                    handleClose={handlePanel.submit}
                    handleSubmit={async () => {
                        await handlePanel.close();
                        await stepHandle.nextStep();
                    }}
                />}

            </PanelV2>

            <Modal show={inviteModal.isShow} size={"sm"} onHide={() => {
                delay(300).then(async () => {
                    await stepHandle.nextStep();
                    setProcessing(false)
                    setInviteModal({...DEFAULT_MODAL})
                })
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{translateV2(inviteModal.title)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {inviteModal.message &&
                        <GlobalMessage errorClassName={inviteModal.type}
                                       message={inviteModal.message}
                                       messageData={inviteModal.messageData}/>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={() => {
                        delay(300).then(async () => {
                            await stepHandle.nextStep();
                            setProcessing(false)
                            setInviteModal({...DEFAULT_MODAL})
                        })
                    }}>
                        {translateV2("LABEL.CLOSE")}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default QuickStartWizardV2;
