import React, {Fragment, useEffect} from 'react';
import {Image} from "react-bootstrap";
import {ASSETS} from "@assets/Assets";
import _ from "lodash-es";
import {fibLogger, translateV2} from "@src/helpers";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {EComponents, panelSlice, panelState} from "@redux/reducers/panelSlice";
import OrderFormV2 from "@v2components/Order/OrderFormV2";
import LoadingV2 from "@v2components/Loading/LoadingV2";
import ProductDetailV2 from "@v2components/Product/Detail/ProductDetailV2";
import InvitePartnerV2 from "@v2components/Company/CompanyPartnerV2/InvitePartnerV2";
import InviteFormV2 from "@v2components/Company/CompanyUser/InviteFormV2/InviteFormV2";
import OrderProcessFormV2 from "@v2components/Order/OrderProcessFormV2";

// {panelState.show && <OrderFormV2 onSubmit={handlePanel.submit}/>}
interface DynamicComponent extends Record<EComponents, React.ComponentType<any>> {
}

const componentsMap: DynamicComponent = {
    [EComponents.ORDER_FORM]: OrderFormV2,
    [EComponents.PRODUCT_DETAIL]: ProductDetailV2,
    [EComponents.PARTNER_INVITATION]: InvitePartnerV2,
    [EComponents.USER_INVITATION]: InviteFormV2,
    [EComponents.ORDER_PROCESS]: OrderProcessFormV2,
};


const PanelHeader = ({header}: { header: string }) => {
    return header
        ? <Fragment>
            <div className="fib-panel-header"><h3>{translateV2(header)}</h3></div>
            <hr/>
        </Fragment>
        : null
}

const PanelComponent = ({component, componentProps}: {
    component: EComponents | undefined,
    componentProps: object
}) => {
    if (component) {
        return React.createElement(componentsMap[component], componentProps);
    }
    return null;
}

const GlobalPanel = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const {
        show, loading, header, component, componentProps = {}
        , afterClose
    } = useAppSelector(panelState);

    const close = (): void => {
        dispatch(panelSlice.actions.hide())
    }

    const renderLoading = () => {
        if (loading) {
            return <LoadingV2 className="bg-loading-opacity-30"/>
        }
        return null;
    }

    const afterClosePanel = () => {
        try {
            if (afterClose && _.isString(afterClose)) {
                dispatch({type: afterClose})
            }
            if (afterClose && _.isObject(afterClose) && !_.isArray(afterClose)) {
                dispatch(afterClose)

            }
            if (afterClose && _.isArray(afterClose) && !_.isEmpty(afterClose)) {
                _.map(afterClose, (dpObject) => {
                    dispatch(dpObject)
                })
            }

            dispatch(panelSlice.actions.reset())

            fibLogger({
                message: "global panel onClose watcher", panel: {
                    show, loading, header, component, componentProps, afterClose
                }
            })

        } catch (err) {

        } finally {

        }
    }

    useEffect(() => {

        const handleKeyDown = (event: any) => {
            if (event.key === 'Escape') {
                close();
            }
        };

        if (show) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            afterClosePanel();
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [show]);

    return (
        <div className={`fib-panel-container ${show ? " show " : ""}`}>

            <Image src={ASSETS.IconCloseV2} loading="lazy" decoding="async"
                   style={{marginBottom: "0.5em"}} className="fib-panel-close"
                   onClick={close}
            />

            <div className="fib-panel-inner">
                {renderLoading()}
                <PanelHeader header={header}/>
                <div className="fib-panel-body">
                    <PanelComponent component={component} componentProps={componentProps}/>
                </div>
            </div>
        </div>
    );
}

export default GlobalPanel;
