import {Modal} from "react-bootstrap";
import React from "react";
import FibreModalCloseButton from "@v2components/Modal/FibreModalCloseButton";
import {translateV2} from "@src/helpers";

interface FibreModalHeaderInterface {
    title: string

    onClickClose?(): void
}

const FibreModalHeader = ({title, onClickClose}: FibreModalHeaderInterface) => {

    return <Modal.Header className={"modal-fib-header"}>

        <Modal.Title className={``}>{translateV2(title)}</Modal.Title>

        <FibreModalCloseButton onClick={onClickClose}/>

    </Modal.Header>
}

export default FibreModalHeader