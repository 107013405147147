import React, {useEffect, useState} from 'react';
import {blockPage, createSearchQuery, fibLogger, getParams, navigateUrlStr, translateV2} from '@src/helpers';
import {Button, Modal} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {messageState, showMessage} from "@redux/reducers/messageSlice";
import {AUDIT_STATUS, Message, PROCESS_AUDIT} from "@src/types";
import {isMobile} from "react-device-detect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {DEFAULT_MODAL} from "@src/variables";
import _ from "lodash-es";
import {AUDIT_API} from "@api/Audit";
import {APP_ROUTES_PATH} from "@src/routes";
import {userMetadataState, userState} from "@redux/reducers/userSlice";
import {companySetupState} from "@redux/reducers/companySetupSlice";
import {wizardState} from "@redux/reducers/wizardSlice";
import {
  auditConfirmAcceptSlice,
  auditConfirmAcceptState,
  auditConfirmPendingSlice,
  auditConfirmPendingState,
  auditConfirmRejectSlice,
  auditConfirmRejectState,
  auditConfirmTriggerSlice,
  auditConfirmTriggerState
} from "@redux/reducers/auditConfirmation";
import {importPartnersState} from "@redux/reducers/importPartnersSlice";
import {companyClaimState} from "@redux/reducers/companySlice";
import {useNavigate} from "react-router-dom";
import {userIntroManagerState} from "@redux/reducers/userIntroManagerSlice";
import {appProviderState} from "@redux/reducers/appProviderSlice";

const AuditViewFormV3 = React.lazy(() => import ("@components/AuditViewForm/AuditViewFormV3"));
const AuditReceivedAccept = React.lazy(() => import ("@components/AuditReceived/AuditReceivedAccept"));
const AuditReceivedReject = React.lazy(() => import ("@components/AuditReceived/AuditReceivedReject"));

const GlobalAuditConfirmation = () => {
    const message = useAppSelector(messageState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const user = useAppSelector(userState);
    const userMetadata = useAppSelector(userMetadataState);
    const userIntroManager = useAppSelector(userIntroManagerState);

    const companySetup = useAppSelector(companySetupState)
    const setupWizard = useAppSelector(wizardState)
    const claimCompany = useAppSelector(companyClaimState);

    const importPartnerStore = useAppSelector(importPartnersState)

    const auditConfirmTriggerStore = useAppSelector(auditConfirmTriggerState)
    const auditConfirmPendingStore = useAppSelector(auditConfirmPendingState)
    const auditConfirmAcceptStore = useAppSelector(auditConfirmAcceptState)
    const auditConfirmRejectStore = useAppSelector(auditConfirmRejectState)

    const [trigger, setTrigger] = useState(false);

    const [auditViewModal, setAuditViewModal] = useState({...DEFAULT_MODAL});

    const [curProcess, setCurProcess] = useState({} as PROCESS_AUDIT)

    const [auditAccept, setAuditAccept] = useState({
        processing: false,
        disabled: true,
        message: "",
        form: undefined as any,
        audit: {} as any,
    });
    const [auditReject, setAuditReject] = useState({
        processing: false,
        disabled: true,
        message: "",
        form: undefined as any,
        audit: {} as any,
    });
    const {isLoggedIn} = useAppSelector(appProviderState)

    const handleAuditViewModal = {
        reset: async () => {
            try {
                setAuditViewModal({...DEFAULT_MODAL});
            } catch (e) {
                console.error(e.toString())
            }
        },
        open: async (audit: PROCESS_AUDIT) => {
            try {
                let adm = {...auditViewModal};
                adm.isShow = true;
                adm.title = "LABEL.VIEW";
                adm.type = Message.NEUTRAL;
                setCurProcess({...curProcess, ...audit});
                setAuditViewModal({...auditViewModal, ...adm})
            } catch (e) {
                alert(e.toString())
            }
        },
        close: async () => {
            try {
                await handleAuditViewModal.reset();
            } catch (e) {
                console.error(e);
                dispatch(showMessage({
                    isShow: true,
                    type: Message.ERROR,
                    title: "LABEL.ERROR",
                    message: e.toString(),
                }))
            }
        }
    }

    const handleAuditPendingModal = {
        reset: async () => {
            try {
                dispatch(auditConfirmPendingSlice.actions.hide())
            } catch (e) {
                console.error(e.toString())
            }
        },
        open: async (audit: PROCESS_AUDIT) => {
            try {
                setCurProcess({...curProcess, ...audit});

                dispatch(auditConfirmPendingSlice.actions.show({
                    ...auditConfirmPendingStore,
                    isShow: true,
                    title: `${audit.order_code} ${audit.audit_client} - ${audit.name}`,
                    type: Message.NEUTRAL
                }))

            } catch (e) {
                alert(e.toString())
            }
        },
        close: async () => {
            try {
                await handleAuditPendingModal.reset();
            } catch (e) {
                console.error(e);
                dispatch(showMessage({
                    isShow: true,
                    type: Message.ERROR,
                    title: "LABEL.ERROR",
                    message: e.toString(),
                }))
            }
        }
    }

    const handleAuditAccept = {
        reset: () => {
            //setAuditAcceptModal({...DEFAULT_MODAL})
            dispatch(auditConfirmAcceptSlice.actions.hide())
            setAuditAccept({
                processing: false,
                disabled: true,
                message: "",
                form: {},
                audit: {},
            });
        },
        open: async (e: any, audit: any) => {
            if (!_.isEmpty(audit)) {
                setCurProcess({...curProcess, ...audit});

                // setAuditAcceptModal({...auditAcceptModal, isShow: true})
                dispatch(auditConfirmAcceptSlice.actions.show({...auditConfirmAcceptStore, isShow: true}))

                await handleAuditPendingModal.close();
            }
        },
        close: async () => {
            try {
                handleAuditAccept.reset();
                await handleAuditPendingModal.open(curProcess);
            } catch (e) {
                console.error(e)
            }
        },
        change: (data: any) => {
            try {
                let as = auditAccept;
                const {formStatus, item} = data;

                if (formStatus) {
                    const {isValid} = formStatus
                    as.disabled = !isValid
                }
                if (item) as.audit = item
                setAuditAccept({...auditAccept, ...as})
            } catch (e) {
                console.error(e)
            }
        },
        submit: async () => {
            setAuditAccept({...auditAccept, processing: true})
            try {
                fibLogger({...curProcess, ...auditAccept.audit})
                const t = auditAccept;
                const {
                    message,
                    order,
                    data,
                    error
                } = await AUDIT_API.acceptLastStageAudit({...curProcess, ...auditAccept.audit}, {});

                t.disabled = !!error

                if (!error) {
                    // setAuditAcceptModal({...auditAcceptModal, isShow: false})
                    dispatch(auditConfirmAcceptSlice.actions.hide())


                    handleAuditAccept.reset();
                    if (order && order?.order_status === 2) {
                        navigate(navigateUrlStr({
                            pathname: APP_ROUTES_PATH.PRODUCTS,
                            search:  createSearchQuery({
                                t: 'INVENTORY'
                            }),
                        }))
                        window.location.reload()
                    } else {
                        navigate(navigateUrlStr({
                            pathname: APP_ROUTES_PATH.MY_AUDITS,
                            search: createSearchQuery({
                                t: 'UPCOMING_AUDITS'
                            }),
                        }))
                        window.location.reload()
                    }
                } else {
                    t.message = error || message;
                    setAuditAccept({...auditAccept, ...t})
                }
            } catch (e) {
                handleAuditAccept.reset();
            }
            setAuditAccept({...auditAccept, processing: false})
        },
    }

    const handleAuditReject = {
        reset: () => {
            // setAuditRejectModal({...DEFAULT_MODAL})
            dispatch(auditConfirmRejectSlice.actions.hide())
            setAuditReject({
                processing: false,
                disabled: true,
                message: "",
                form: {},
                audit: {},
            });
        },
        open: async (e: any, audit: any) => {
            if (!_.isEmpty(audit)) {
                setCurProcess({...curProcess, ...audit});
                // setAuditRejectModal({...auditRejectModal, isShow: true})
                dispatch(auditConfirmRejectSlice.actions.show({...auditConfirmRejectStore, isShow: true}))
                await handleAuditPendingModal.close();
            }
        },
        close: async () => {
            try {
                handleAuditReject.reset();
                await handleAuditPendingModal.open(curProcess);
            } catch (e) {
                console.error(e)
            }
        },
        change: (data: any) => {
            try {
                let as = auditReject;
                const {formStatus, item} = data;

                if (formStatus) {
                    const {isValid} = formStatus
                    as.disabled = !isValid
                }
                if (item) as.audit = item
                setAuditReject({...auditReject, ...as})
            } catch (e) {
                console.error(e)
            }
        },
        submit: async () => {
            setAuditReject({...auditReject, processing: true})
            try {
                const t = auditReject;
                const {
                    message,
                    data,
                    error
                } = await AUDIT_API.rejectLastStageAudit({...curProcess, ...auditReject.audit}, {});
                t.disabled = !!error
                if (!error) {
                    // setAuditRejectModal({...auditRejectModal, isShow: false})
                    dispatch(auditConfirmRejectSlice.actions.hide())

                    handleAuditReject.reset();
                    // await auditsSummary();
                } else {
                    t.message = error || message;
                    setAuditReject({...auditReject, ...t})
                }
            } catch (e) {
                handleAuditReject.reset();
            }
            setAuditReject({...auditReject, processing: false})
        },
    }

    const handleAuditConfirmation = async () => {
        try {
            const {s, review_audit: uid} = getParams();

            if (isLoggedIn && uid) {
                const {data, error} = await AUDIT_API.getLastStageAudit({uid});
                if (data && !error) {
                    const tmp = {...data, uid};
                    setCurProcess(tmp);
                    if (tmp.status === AUDIT_STATUS.PENDING) await handleAuditPendingModal.open(tmp)
                } else {
                    dispatch(showMessage({
                        isShow: true,
                        title: "LABEL.ERROR",
                        message: error,
                        type: Message.ERROR,
                    }))
                    navigate({search: ""})
                }
            }

            blockPage(false);
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        // console.log("auditConfirmTriggerStore changed", auditConfirmTriggerStore)
        setTrigger(auditConfirmTriggerStore)
    }, [auditConfirmTriggerStore, dispatch])

    useEffect(() => {
        (async () => {
            if (userMetadata.initialized && trigger) {
                console.log("hello world")
                await handleAuditConfirmation();
            }
        })();

        return () => {
            setTrigger(false)
            dispatch(auditConfirmTriggerSlice.actions.stop())
        }
    }, [dispatch, trigger, userMetadata]);

    return (
        <>
            {/*** Pending audit - Accept - Reject audit */}
            <Modal show={auditConfirmPendingStore.isShow} onHide={handleAuditPendingModal.close} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{auditConfirmPendingStore.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {curProcess.status === AUDIT_STATUS.PENDING &&
                        <p><strong>{translateV2("LABEL.AUDIT_PENDING_ACTION")}</strong></p>}
                    <AuditViewFormV3 data={curProcess}/>
                </Modal.Body>
                <Modal.Footer className={isMobile ? "flex-row-reverse" : ""}>

                    {curProcess.status === AUDIT_STATUS.PENDING && <>
                        <Button variant="success" className={isMobile ? "ml-auto" : ""}
                                onClick={(e) => handleAuditAccept.open(e, curProcess)}>
                            {translateV2("LABEL.AUDIT.ACCEPT")}
                        </Button>

                        <Button variant="outline-success" onClick={(e) => handleAuditReject.open(e, curProcess)}>
                            {translateV2("LABEL.AUDIT.REJECT")}
                        </Button>
                    </>}

                    <Button variant="outline-dark" onClick={handleAuditPendingModal.close}>
                        {translateV2("LABEL.CLOSE")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={auditConfirmAcceptStore.isShow} onHide={handleAuditAccept.close} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{translateV2("LABEL.AUDIT.ACCEPT")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AuditReceivedAccept data={curProcess}
                                         onChange={handleAuditAccept.change}
                                         onSubmit={handleAuditAccept.submit}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={handleAuditAccept.close}>
                        {translateV2("LABEL.CLOSE")}
                    </Button>
                    {curProcess.status === AUDIT_STATUS.PENDING &&
                        <Button variant="success" onClick={handleAuditAccept.submit}
                                disabled={auditAccept.disabled || auditAccept.processing}>
                            {auditAccept.processing && <><FontAwesomeIcon icon={faSpinner} spin/> &nbsp;</>}
                            {translateV2("LABEL.ACCEPT")}
                        </Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={auditConfirmRejectStore.isShow} onHide={handleAuditReject.close} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{translateV2("LABEL.AUDIT.REJECT")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AuditReceivedReject data={curProcess} onChange={handleAuditReject.change}
                                         onSubmit={handleAuditReject.submit}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={handleAuditReject.close}>
                        {translateV2("LABEL.CLOSE")}
                    </Button>
                    {curProcess.status === AUDIT_STATUS.PENDING &&
                        <Button variant="success" onClick={handleAuditReject.submit}
                                disabled={auditReject.disabled || auditReject.processing}>
                            {auditReject.processing && <><FontAwesomeIcon icon={faSpinner} spin/> &nbsp;</>}
                            {translateV2("LABEL.REJECT")}
                        </Button>}
                </Modal.Footer>
            </Modal>


        </>
    );
}

export default GlobalAuditConfirmation;
