import React, {useEffect, useState} from 'react';
import erroricon from '@assets/images/icons/error-msg.png';
import {Message, USER_ACTION} from '@src/types';
import {AUDIT_API} from '@api/Audit';
import {translateV2} from '@src/helpers';
import _ from "lodash-es";
import Select from "react-select";
import {colourStyles} from "@components/Input/MultiSelect";
import AuditTemplateAnswer from "@components/auditTemplates/Answer/AuditTemplateAnswer";
import {AUDIT_TEMPLATE_API} from '@api/AuditTemplate';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {useAppSelector} from "@redux/hooks/hooks";
import {companyState} from "@redux/reducers/companySlice";
import {PRODUCTS_API} from "@api/Product";
import ProductTemplateAnswerViewV2 from "@v2components/ProductTemplate/Answer/ProductTemplateAnswerViewV2";

const AuditViewFormV2 = ({data, onClose}: any) => {
    const company = useAppSelector(companyState);

    const [templates, setTemplates] = useState({
        isDisabled: true,
        isLoading: true,
        isClearable: false,
        isSearchable: true,
        list: [] as any,
        selected: "" as any,
    })

    const [questions, setQuestions] = useState({
        selected: [],
        list: [],
    } as any);

    let audit = {
        audit_client: '',
        audit_id: '',
        auditor_name: '',
        carbon: '',
        comment: null,
        energy: '',
        energy_type: '',
        lat: '',
        long: '',
        order_code: '',
        process_name: '',
        product_barcode: '',
        scan_result: '',
        scanner_name: '',
        water: '',
        water_recycled: '',
    };

    const [adForm, setAdForm] = useState({...audit});

    const [globalMsg, setGlobalMsg] = useState({
        type: '',
        text: ''
    });

    const [productDetail, setProductDetail] = useState({attributeTemplate:[]} as any);

    const auditsSummary = async () => {
        try {
            setGlobalMsg({
                type: '',
                text: ''
            });
            const {error, data: dataProcess} = await AUDIT_API.getPreviousProcess({
                order_code: data.ad_order_id,
                process_position: data.ad_process_position
            });
            if (dataProcess) {
                setAdForm({
                    ...adForm,
                    ...dataProcess
                });
            } else {
                setGlobalMsg({
                    type: Message.ERROR,
                    text: error || "ERROR.API_ERROR"
                });
            }
        } catch (e) {
            // setGlobalMsg({
            //     type: Message.ERROR,
            //     text: e.toString()
            // });
        }
    }


    const productTemplateHandle = {
        getOne: async () => {
            setTemplates({...templates, isLoading: true})
            let tp = {...templates}, ques = {...questions}, list: any = [];
            try {
                const attrTemplates = await AUDIT_API.getOrderProcessAuditTemplate({uid: data.ad_process_uid})
                if (!_.isEmpty(attrTemplates)) {
                    list = _.map(attrTemplates, (v: any) => {
                        let {uid, name} = v;
                        return {...v, value: uid, label: name}
                    })
                    tp.isDisabled = true;
                    tp.isLoading = false;
                    tp.list = list;
                    tp.selected = list;
                    ques.list = list;
                }
            } catch (e) {
                console.error(e)
            }
            setTemplates({...templates, ...tp})
            setQuestions({...ques});
        },
        getBatch: async (extra = {}) => {

            setTemplates({...templates, isLoading: true})
            let tp = {...templates};
            try {
                const {data, error} = await AUDIT_TEMPLATE_API.getTemplates({}, extra);
                if (data && !error) {
                    tp.isDisabled = _.isEmpty(data);
                    tp.isLoading = false;
                    tp.list = _.map(data, (v: any) => {
                        let {uid, name} = v;
                        return {...v, value: uid, label: name}
                    })
                }
            } catch (e) {
                console.error(e)
            }
            setTemplates({...templates, ...tp})
        },
        getCurrentTemplates: async (data: any) => {
            try {

                let tp = {...templates}, q = {...questions};
                let {attributeTemplateValue} = data;
                setQuestions({...questions, list: [...attributeTemplateValue]})

                if (!_.isEmpty(attributeTemplateValue)) {
                    if (!_.isEmpty(tp.list)) {
                        tp.selected = attributeTemplateValue.map((v: any) => {
                            let {uid, name} = v;
                            return {...v, value: uid, label: name}
                        });
                    }
                    setTemplates({...templates, ...tp});
                }

            } catch (e) {
                console.error(e)
            }
        },
        onChange: async (data: any) => {
            try {

                let ques = {...questions};
                let {selected} = data;

                let i = _.findIndex(ques.selected, (o: any) => {
                    return o.templateId === selected.templateId;
                });

                if (i === -1) ques.selected.push(selected);
                else ques.selected[i] = {...ques.selected[i], ...selected};

                setQuestions({...questions, ...ques})
            } catch (e) {
                console.error(e)
            }
        }

    }

    const getProductDetail = async () => {
        try {
            const {data:res, error, message} = await PRODUCTS_API.getProductItemDetail({uid: data.product_uid});
            console.log(res.attributeTemplateValue)
            setProductDetail(res);
        } catch (e) {
            console.error("getProductDetail", e.message)
        }
    }

    useEffect(() => {
        (async () => {
            await getProductDetail();
        })()
    },[])

    useEffect(() => {
        (async () => {
            await auditsSummary();
        })()
    }, []);

    useEffect(() => {
        (async () => {
            setAdForm({
                ...adForm,
                ...data
            });
            await productTemplateHandle.getOne();
        })()
    }, [data]);


    return (
        <>
            {/*Body*/}
            {globalMsg.type &&
                <div className="global-msg-wrap text-center">
                    <div className="global-msg">
                        {globalMsg.type === Message.ERROR && <img src={erroricon} alt=""/>}
                        <p className={`${globalMsg.type === Message.ERROR ? 'error' : ''}`}>{translateV2( globalMsg.text)}</p>
                    </div>
                </div>
            }
            <dl>
                <dt>
                    <label>{translateV2( "LABEL.AUDIT_CLIENT")}</label>
                </dt>
                <dd>
                    <div className="input-group">
                        <input type="text" readOnly className="form-control"
                               value={adForm.audit_client}/>
                    </div>
                </dd>

                <dt>
                    <label>{translateV2( "LABEL.ORDER")}</label>
                </dt>
                <dd>
                    <div className="input-group">
                        <input type="text" className="form-control" value={adForm.order_code} readOnly/>
                    </div>
                </dd>

                <dt>
                    <label>{translateV2( "LABEL.PROCESS_NAME")}</label>
                </dt>
                <dd>
                    <div className="input-group">
                        <input type="text" className="form-control" value={adForm.process_name}
                               readOnly/>
                    </div>
                </dd>

                <dt>
                    <label>{translateV2( "LABEL.SCANNER_NAME")}</label>
                </dt>
                <dd>
                    <div>
                        <div className="input-group">
                            <input type="text" className="form-control" value={adForm.scanner_name}
                                   readOnly/>
                        </div>
                    </div>
                </dd>

                <dt>
                    <label>{translateV2( "LABEL.FIBRETRACE_ID")}</label>
                </dt>
                <dd>
                    <div>
                        <div className="input-group">
                            <input type="text" className="form-control" value={adForm.scan_result || ''}
                                   readOnly/>
                        </div>
                    </div>
                </dd>

                <dt>
                    <label>{translateV2( "LABEL.PRODUCT_BARCODE")}</label>
                </dt>
                <dd>
                    <div className="input-group">
                        <input type="text" className="form-control" value={adForm.product_barcode || ''}
                               readOnly/>
                    </div>
                </dd>

                <dt>
                    <label>{translateV2( "LABEL.AUDITOR")}</label>
                </dt>
                <dd>
                    <div className="input-group">
                        <input type="text" className="form-control" readOnly
                               value={adForm.auditor_name || ''}/>
                    </div>
                </dd>

                <dt>
                    <label>{translateV2( "LABEL.AUDIT_ID")}</label>
                </dt>
                <dd>
                    <div className="input-group">
                        <input type="text" className="form-control" readOnly
                               placeholder="Audit ID will be auto generated on submit!"
                               value={adForm.audit_id || ''}/>
                    </div>
                </dd>

                <dt>
                    <label>{translateV2( "LABEL.PRODUCT_INFORMATION")}</label>
                </dt>
                <dd>
                    <table className={"table table-borderless table-striped table-audit-list "}>
                        <tbody>
                        <tr>
                            <td>
                                <b>{translateV2( "LABEL.PRODUCT_NAME")}</b>:
                                <p dangerouslySetInnerHTML={{__html: data.product_name}}/>
                                <b>{translateV2( "LABEL.QUANTITY")}</b>:
                                <p dangerouslySetInnerHTML={{__html: data.quantity}}/>
                                <b>{translateV2( "LABEL.DESCRIPTION")}</b>:
                                <p dangerouslySetInnerHTML={{__html: data.product_description}}/>
                            </td>
                            <td className={"text-center"}>
                                {
                                    data.ad_product_image
                                        ? <img src={data.ad_product_image} alt=""
                                               style={{maxWidth: "100%", width: "200px"}}
                                               className="ad_product_image"/>
                                        : <FontAwesomeIcon icon={faImage} style={{fontSize: "8em"}}/>
                                }</td>
                        </tr>
                        </tbody>
                    </table>
                    {_.map(productDetail?.attributeTemplateValue, (item, itemIndex) =>
                        <ProductTemplateAnswerViewV2 key={itemIndex}
                                                     template={item}
                                                     onChange={() => {
                                                     }}/>
                    )}
                </dd>

                <dt>
                    <label>{translateV2("LABEL.SITE.NAME")}</label>
                </dt>
                <dd>
                    <div className="input-group">
                        <span className="form-control" style={{
                            backgroundColor: "#e9ecef",
                            color: "#495057"
                        }}>{data?.site || ''}</span>
                    </div>
                </dd>

                <dt>
                    <label>{translateV2( "LABEL.LAT")}</label>
                </dt>
                <dd>
                    <div className="input-group">
                        <input type="number" className="form-control" readOnly
                               value={adForm.lat || ''}/>
                    </div>
                </dd>

                <dt>
                    <label>{translateV2( "LABEL.LONG")}</label>
                </dt>
                <dd>
                    <div className="input-group">
                        <input type="number" readOnly className="form-control"
                               value={adForm.long || ''}/>
                    </div>
                </dd>

                <dt>
                    <label
                        htmlFor="ad_comment">{translateV2( "LABEL.COMMENT")}</label>
                </dt>
                <dd>
                    <div className="input-group">
                                        <textarea readOnly className="form-control"
                                                  value={adForm.comment || ''}/>
                    </div>
                </dd>

                {!_.isEmpty(templates.list) && <>
                    <dt>{translateV2( "LABEL.AUDIT_TEMPLATE")}</dt>
                    <dd>
                        <Select
                            isDisabled={templates.isDisabled}
                            // isLoading={templates.isLoading}
                            // isClearable={templates.isClearable}
                            // isSearchable={templates.isSearchable}
                            styles={colourStyles}
                            className="basic-multi-select mt-2 mb-2 w-100"
                            classNamePrefix="select"
                            name="attributeTemplateValue"
                            isMulti
                            options={templates.list}
                            value={templates.selected}
                        />

                    </dd>
                </>}

                {_.map(questions.list, (item, itemIndex) =>
                    <AuditTemplateAnswer key={itemIndex} template={item} action={USER_ACTION.VIEW}
                                         onChange={productTemplateHandle.onChange}/>
                )}

            </dl>
            {/*Body*/}

        </>
    );
}

export default AuditViewFormV2;
