import React, {Fragment} from "react";
import {Accordion, Card, Image, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {importPartnersState} from "@redux/reducers/importPartnersSlice";
import {importPartnersProgressSlice, importPartnersProgressState} from "@redux/reducers/importPartnersProgressSlice";
import _ from "lodash-es";
import {isMobile} from "react-device-detect";
import {translateV2, translateV2Plain} from "@src/helpers";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ASSETS} from "@assets/Assets";

const PartnerPreview = () => {

    const dispatch = useAppDispatch();

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);

  /**
   * @todo
   * work with backend - p.note line #106 sometimes return string, sometime return array of strings
   */

  return (
        <>

            <p dangerouslySetInnerHTML={{
                __html: translateV2("LABEL.PARTNER.IMPORT.INVITATION.TOTAL_PREVIEW", "", {
                    total: _.size(
                      _.filter(
                        importPartnersProgress.stepData.preview.partner_list_formatted,
                        (p:any)=> p.allow_import || p.send_invitation
                      )

                    )
                })
            }}/>

            {
                importPartnersProgress.stepData.preview.partner_list_formatted.length <= 0
                    ? null
                    : <div className={`partners-preview-wrapper`} style={{maxHeight: "40vh", overflowY: "auto"}}>

                        <table className={`partners-preview-table table`}>
                            <thead className={isMobile ? `d-none` : ""}>
                            <tr>
                                <th>

                                    <label htmlFor={`allPartners`} className={`fib-checkboxv2`}>

                                        <input checked={importPartnersProgress.stepData.preview.checked}
                                               id={"allPartners"}
                                               onChange={(e) => {
                                                   dispatch(importPartnersProgressSlice.actions.setAllFormattedPartner({
                                                       checked: !importPartnersProgress.stepData.preview.checked
                                                   }))
                                               }} type="checkbox"/>
                                        <span className="fib-checkboxv2-checkmark"/>
                                    </label>


                                </th>
                                <th>Name</th>
                                <th>Address</th>
                                <th className={`fpw-20`}>Process</th>
                            </tr>
                            </thead>

                            {/*"company_name": string | any,*/}
                            {/*"company_email": string | any,*/}
                            {/*"supplier_phone_number": string | any,*/}
                            {/*"supplier_hq_postal_address": string | any,*/}
                            {/*"supplier_process_name": string | any,*/}
                            {/*"supplier_process_postal_address": string | any,*/}
                            {/*"process_description": string | any,*/}
                            {/*"allow_import": boolean,*/}
                            {/*"note": string | any,*/}

                            <tbody>
                            {
                                importPartnersProgress.stepData.preview.partner_list_formatted.map((p: any, itemIndex: number) =>
                                    <tr key={_.uniqueId(`partner-import-`)}
                                        className={p.allow_import || p.send_invitation ? `` : `has-error`}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                {
                                                    p.allow_import || p.send_invitation
                                                        ? <label htmlFor={`partner-import-${itemIndex}`}
                                                                 className={`fib-checkboxv2`}>
                                                            <input checked={p.checked} id={`partner-import-${itemIndex}`}
                                                                   onChange={(e) => {
                                                                       dispatch(importPartnersProgressSlice.actions.setSingleFormattedPartner({
                                                                           itemIndex, partner: {...p, checked: !p.checked}
                                                                       }))
                                                                   }} type="checkbox"/>

                                                            <span className="fib-checkboxv2-checkmark"/>
                                                        </label>
                                                        : <OverlayTrigger overlay={

                                                            <Tooltip id={_.uniqueId(`partner_error_`)}>
                                                              {
                                                                _.isArray(p.note) ?

                                                                  _.map(p.note, (nn: any) => {
                                                                    return <span key={`partner_error_detail_`}>
                                                                      <br/>{translateV2(nn, "", {
                                                                        "0": p.supplier_process_name,
                                                                        "1": p.company_name
                                                                      })}</span>
                                                                  })
                                                                  : <span key={`partner_error_detail_`}>
                                                                      <br/>{translateV2(p.note, "", {
                                                                      "0": p.supplier_process_name,
                                                                      "1": p.company_name
                                                                    })}</span>
                                                              }

                                                              {
                                                                _.size(p.note) > 0 ? ". " + translateV2("LABEL.PARTNER.PREVIEW.RECORD.CANNOT_BE_IMPORTED") : null
                                                              }
                                                            </Tooltip>} defaultShow={false}>

                                                            <Image height={20}
                                                                   alt={translateV2("LABEL.PARTNER.IMPORT.PREVIEW.REFER_ERROR_ICON")}
                                                                   src={ASSETS.IconInfoCircleError}
                                                                   className={"mr-2"}
                                                            />

                                                        </OverlayTrigger>

                                                }

                                            </div>
                                        </td>

                                        <td className={`d-none d-sm-table-cell`}><b>{p.company_name}</b></td>
                                        <td className={`d-none d-sm-table-cell`}>{p.supplier_hq_postal_address}</td>
                                        <td className={`d-none d-sm-table-cell`}>{p.supplier_process_name}</td>

                                        <td className="d-table-cell d-sm-none">
                                            <Accordion className={" partners-preview-accordion"}>
                                                <Card className={""}>
                                                    <Card.Header>
                                                        <Accordion.Toggle eventKey="0">
                                                            <p><b>{p.company_name}</b></p>
                                                        </Accordion.Toggle>
                                                        {/*<ContextAwareToggle eventKey="0">Click me!</ContextAwareToggle>*/}

                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>

                                                            Address
                                                            <p><b>{p.supplier_hq_postal_address}</b></p>

                                                            Processes
                                                            <p><b>{p.supplier_process_name}</b></p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>

                                        </td>
                                    </tr>)
                            }
                            </tbody>

                        </table>

                    </div>
            }



            {
                _.some(importPartnersProgress.stepData.preview.partner_list_formatted, (p) => !p.allow_import && !p.send_invitation)
              && <Fragment>
                    <hr/>
                    <p className="fz-16 d-flex align-items-center fib-text-red">
                        <Image height={20} alt={translateV2("LABEL.PARTNER.IMPORT.PREVIEW.REFER_ERROR_ICON")}
                               src={ASSETS.IconInfoCircleError}
                               className={"mr-2"}
                        />
                      {translateV2("LABEL.PARTNER.IMPORT.PREVIEW.REFER_ERROR_ICON")}
                    </p>
                </Fragment>
            }


        </>
    )
}

export default PartnerPreview;