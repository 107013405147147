import {colourSingleStyles} from "@components/Input/MultiSelect";
import React, {useEffect, useRef, useState} from "react";
import Select from "react-select";
import _ from "lodash-es";
import {COMPANY_API} from "@api/Company";
import {translateV2} from "@src/helpers";

interface I_Option {
  value: number | string,
  label: string
}

const SelectMultipleIdFID = ({
                               name = _.uniqueId(`fibretrace_id_`),
                               value,
                               className = "basic-single mb-1 mb-sm-0",
                               classNamePrefix = "select",
                               onChange,
                               optionsDirect = []
                             }: any) => {

  const abortController = useRef<any>(null);

  const [options, setOptions] = useState<I_Option[]>([])

  /**
   * Disable FID changed based on new US
   */
  const [isDisabled, setDisabled] = useState(true)
  const [isLoading, setLoading] = useState(true)
  const [isClearable, setClearable] = useState(false)
  const [isSearchable, setSearchable] = useState(true)

  const [selected, setSelected] = useState([])

  const getFIDlist = async () => {
    try {
      if (abortController.current) {
        abortController.current.abort()
      }
      abortController.current = new AbortController();

      const {data, error} = await COMPANY_API.getCompanyFIDsV2({signal: abortController.current.signal})
      if (!error) {
        setOptions([
          {
            code: translateV2("LABEL.CHOOSE_ONE"),
            id: ""
          },
          {
            code: translateV2("LABEL.FIBRETRACE_ID.NONE"),
            id: -1
          }, ...data])
        // setDisabled(false)
        setLoading(false)
      }
    } catch (err) {
    } finally {

    }
  }

  useEffect(() => {
    (async () => {
      if (_.isEmpty(optionsDirect)) {
        // await getFIDlist();
      }
      // setDisabled(false)
      setLoading(false)
    })()
  }, []);

  useEffect(() => {
    if (value) {
      setSelected(value)
    }
  }, [value]);

  return (
    <Select
      styles={colourSingleStyles}
      className={className}
      classNamePrefix={classNamePrefix}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      name={name}
      options={
        optionsDirect
      }
      getOptionLabel={(option: { code: any; id: any }) => option.code}
      getOptionValue={(option: { code: any; id: any }) => option.id}
      value={selected}
      onChange={(e) => {
        setSelected(e)
        if (_.isFunction(onChange)) onChange({
          selected: e,
        })
      }}
    />
  );
}

export default SelectMultipleIdFID;