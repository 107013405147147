import React, {useEffect, useState} from 'react';
import {InputRules, MEDIA_TYPE, Message, WebProtocols} from '@src/types';
import {
    createSearchQuery,
    getParams,
    getProtocol,
    isValidURL, navigateUrlStr,
    translateV2,
    validate,
    validateEmail
} from '@src/helpers';
import _ from "lodash-es";
import {COMPANY_API} from '@api/Company';
import GlobalMessage from "@components/Input/Message";
import {Button, Image} from "react-bootstrap";
import {ASSETS} from "@assets/Assets";
import DropZoneSingleV2 from "@v2components/FormElement/DropZoneSingle";
import {ACCOUNT_API} from "@api/Account";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {companyState, updateCompanyAsync} from "@redux/reducers/companySlice";
import {userState} from "@redux/reducers/userSlice";
import {APP_ROUTES_PATH} from "@src/routes";
import {useNavigate} from "react-router-dom";
import {DEFAULT_COMPANY_SUBSCRIPTION_PACKAGE} from "@src/variables";

enum EForm {
    first_name = 'first_name',
    last_name = 'last_name',
    phone = 'phone',
    rbrand = 'rbrand',
    email = 'email',
    company_code = 'company_code',
    company_name = 'company_name',
    company_website = 'company_website',
    registration_id = 'registration_id',
    tax_id = 'tax_id',
    invited_by = 'invited_by',
    password = 'password',
    password_confirmation = 'password_confirmation'
}

enum SendStatus {
    'SUCCESS' = 'SUCCESS',
    'FAILED' = 'FAILED',
    'LOADING' = 'LOADING',
    'NONE' = ''
}

const CompanySetupV2 = ({
                            params = {} as any,
                            onFinish = {} as any,
                        }) => {
    const langData = useAppSelector(languageState);
    const companyInfo = useAppSelector(companyState)
    const userInfo = useAppSelector(userState)

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const formDefault = {
        company_name: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: ''
        },
        company_code: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: ''
        },
        company_description: {
            value: '',
            rules: [InputRules.HTML],
            isValid: true,
            error: ''
        },
        email: {
            value: '',
            rules: [InputRules.REQUIRED, InputRules.EMAIL],
            isValid: true,
            error: ''
        },
        phone: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: ''
        },
        types: {
            value: [] as any,
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: ''
        },
        registration_id: {
            value: '',
            rules: [],
            isValid: true,
            error: ''
        },
        tax_id: {
            value: '',
            rules: [],
            isValid: true,
            error: ''
        },
        protocol: {
            value: WebProtocols.HTTPS as WebProtocols,
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: ''
        },
        company_domain: {
            value: '',
            rules: [InputRules.DOMAIN],
            isValid: true,
            step: 1,
            error: ''
        },
        is_public: {
            value: undefined,
            rules: [],
            isValid: true,
            error: ''
        },
    }

    const [form, setForm] = useState({...formDefault});

    const [company, setCompany] = useState('');
    const [formStatus, setFormStatus] = useState({
        processing: false,
        isValid: false,
        message: "",
        messageType: "error" as Message
    });

    const first_login_and_first_user = userInfo.first_login_and_first_user;

    let [progress, setProgress] = useState({
        current: 1 as number,
        list: _.compact([
            {step: 1, status: "PENDING", allowSkip: true, isProcessing: false, isValid: false, error: []},
            !first_login_and_first_user ? false : {
                step: 2,
                status: "PENDING",
                allowSkip: true,
                isProcessing: false,
                isValid: false,
                error: []
            },
        ]),
        status: "DONE"
    } as any);

    /** Company */
    const [media, setMedia] = useState({
        hasFile: false,
        isUploading: false,
        type: "COMPANY_LOGO",
        media: {} as any,
        currentMedia: "" as any
    })

    /**
     * Invite users
     * @param event
     */
    const [canSubmit, setSubmitState] = useState(false);
    const [errorMsg, setErrorMsg] = useState({
        emails: {isValid: true, error: "ERROR.VALIDATE.EMAILS"},
        message: {isValid: true, error: "ERROR.VALIDATE.MESSAGE"},
    })
    const [inviteData, setInviteData] = useState({
        emails: "",
        message: "",
        same_company: true
    })


    const validateEmails = (emails: string) => {
        let emailsList: Array<string>;
        emailsList = emails.split(",").map((v: string) => {
            return _.escape(_.trim(v))
        });
        return _.every(emailsList, validateEmail);
    }

    const validateInput = {
        emails: (emails) => {
            const err = errorMsg;
            if (!emails) {
                err.emails.isValid = false;
                err.emails.error = "ERROR.VALIDATE.EMAILS";
                return !emails;
            }

            const stt = validateEmails(emails)
            err.emails.isValid = true;
            if (!stt) {
                err.emails.isValid = false;
                err.emails.error = "ERROR.EMAIL_WRONG_FORMAT";
                setSubmitState(false);
            }
            setErrorMsg(err);
            return stt;
        },
        message: (message) => {
            const err = errorMsg;
            err.message.isValid = true;
            if (!message) {
                err.message.isValid = false;
                setErrorMsg(err)
                setSubmitState(false);
            }
        },
        submit: ({emails, message}) => {
            const stt = validateEmails(emails);

            if (stt && message) {
                setSubmitState(true);
            }
        }
    };

    const inputHandle = (event: any) => {
        try {
            const f = form;
            const name: EForm = event.target.name;
            const type = event.type;
            const value = event.target?.type === 'checkbox' ? event.target.checked : event.target.value;
            const {error, value: formattedValue} = validate(value, f[name].rules,
                _.omitBy({
                    pass: _.indexOf(f[name].rules, InputRules.CONFIRM_PASSWORD) !== -1 && _.has(f[name], 'password_field') ? f[f[name].password_field].value : null
                }, _.isNil));

            if (type === 'change') {
                if (_.has(f, name) && value !== f[name].value) {
                    f[name].value = formattedValue || value || "";
                    f[name].isDirty = true;
                    f[name].isValid = !error && f[name].isDirty;
                    f[name].error = "";
                }
            }

            if (type === 'blur') {
                f[name].error = f[name].isDirty ? (error || "") : "";
            }

            formHandle.validate();
            formHandle.validateStep(f[name].step);

            setForm({...form, ...f});
        } catch (e) {
            console.error(e)
        }
    }

    const formHandle = {
        validate: () => {
            const fs = formStatus;
            fs.isValid = _.every(form, (v) => v.isValid)
            setFormStatus({...formStatus, ...fs});
        },
        validateStep: (step: number) => {
            let prog = {...progress}
            if (_.has(prog.list, (step - 1))) {
                prog.list[step - 1].isValid = _.every(_.filter(form, (v: any) => v.step === step), (st: any) => st.isValid)
                setProgress({...prog});
            }
        },
        onChange: inputHandle,
        onBlur: inputHandle,
        onSelectChange: async (selected, field) => {
            try {
                const f = form;
                const {error, isValid} = validate(selected.value, f[field].rules);
                f[field].error = error || "";
                f[field].isValid = !error;

                if (_.has(form, field) && selected.value) {
                    f[field].value = selected.value;
                }
                setForm({...form, ...f})
                if (_.every(f, (v) => v.isValid)) setFormStatus({...formStatus, isValid: true, message: ""})
            } catch (e) {
                console.error(e)
            }
        },
        onFileChange: async (file: any) => {
            try {
                if (!_.isEmpty(file.list)) {
                    let c = _.omitBy(companyInfo, _.isNil);
                    c.logo = file.list[0];
                    setMedia({
                        ...media, ...{
                            hasFile: true,
                            isUploading: true,
                            type: MEDIA_TYPE.COMPANY_LOGO,
                            media: file.list[0],
                            currentMedia: file.current[0]
                        }
                    })

                    await formHandle.validateStep(1)

                } else {
                    setMedia({
                        ...media, ...{
                            hasFile: false,
                            isUploading: false,
                            type: MEDIA_TYPE.COMPANY_LOGO,
                            media: {},
                            currentMedia: ""
                        }
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        onSubmit: async (event: any) => {
            setFormStatus({...formStatus, processing: true});
            try {
                event.preventDefault();
                formHandle.validate();
                const f = {...form}, fs = {...formStatus};

                if (formStatus.isValid) {
                    let c = {company_website: undefined, logo: {}};
                    let company_website: any = getProtocol(f.protocol.value) + f.company_domain.value;
                    company_website = isValidURL(company_website) ? company_website : "";
                    c.company_website = company_website || "";
                    if (_.isObject(media.media)) c.logo = media.media;
                    dispatch(updateCompanyAsync(_.omitBy({...companyInfo, ...c}, _.isNil)))
                    // USER_API.checkUpdateVirginLogin().then(message => {
                    //     fibLogger(message)
                    // });
                    await stepHandle.setStep(2);
                }
            } catch (e) {
                console.error(e)
            }
            setFormStatus({...formStatus, processing: false});
        }
    };

    const inviteHandle = {
        onChange: (event: any) => {
            try {
                const data = {...inviteData};

                if (event.target) {
                    const value = event.target?.type === 'checkbox' ? event.target.checked : event.target.value;
                    const name = event.target.name;

                    switch (name) {
                        case "plain_emails" : {
                            setInviteData({...inviteData, emails: value});
                            data.emails = value;
                            validateInput.emails(value);
                            break;
                        }
                        case "plain_message" : {
                            setInviteData({...inviteData, message: value});
                            data.message = value;
                            validateInput.message(value);
                            break;
                        }
                        case "same_company" : {
                            setInviteData({...inviteData, same_company: value});
                            break;
                        }
                    }
                    validateInput.submit(data);
                }
            } catch (e) {
                console.error(e)
            }
        },
        onSubmit: async (event: any) => {
            try {
                event.preventDefault();
                if (canSubmit) {
                    const req = await ACCOUNT_API.inviteUser({
                        emails: inviteData.emails,
                        message: inviteData.message,
                        same_company: inviteData.same_company
                    });
                    const {error, data} = req;
                    if (!error) {
                        if (_.isFunction(onFinish)) onFinish(progress);
                    } else {

                    }
                } else {
                }
            } catch (err) {

            }
        }
    }

    const stepHandle = {
        lastStep: () => {
            try {
                if (_.isFunction(onFinish)) onFinish(progress);
            } catch (e) {
                console.error(e)
            }
        },
        setStep: (step: number = 1) => {
            try {
                if (progress.current < _.size(progress.list) &&
                    (progress.list[progress.current - 1].isValid
                        || (step < progress.current)
                        || progress.list[progress.current - 1].allowSkip
                    )
                ) {
                    setProgress({...progress, current: step})

                    /**
                     * update first_login_and_first_user can cause packages not appear in CompanySetupV2 component
                     * Find proper place to set `first_login_and_first_user` of user
                     */
                    // USER_API.checkUpdateVirginLogin().then(message => {
                    //     fibLogger(message)
                    // });
                } else {
                    if (_.isFunction(onFinish)) onFinish(progress);
                }
                // /**
                //  * @todo: remove when dev-done
                //  */
                // setProgress({
                //     ...progress,
                //     current: step
                // })
            } catch (e) {
                console.error(e)
            }
        },
        nextStep: async () => {
            try {
                let o = {}, pg = {...progress};
                pg.current = ++progress.current
                setProgress({...pg})
            } catch (e) {
                console.error(e)
            }
        }
    }

    useEffect(() => {

        const qs = getParams();
        const {company: invited_by, email} = qs
        const f = form, fs = formStatus;

        if (invited_by && !_.isEmpty(invited_by)) {
            (async () => {
                const {data, error} = await COMPANY_API.getCompanyByID(invited_by);
                if (!error) {
                    setCompany(data?.name || "");
                    inputHandle({type: 'change', target: {name: 'company_name', value: data.name || ""}})
                } else {
                    fs.message = error;
                    fs.messageType = Message.ERROR

                    f.company_name.value = invited_by;
                    f.company_name.isValid = false;
                    f.company_name.error = error;

                    setForm({...form, ...f})
                }
            })();
        }

        if (email) {
            inputHandle({type: 'change', target: {name: 'email', value: email || ""}})
        }
    }, [])

    useEffect(() => {
        setInviteData({...inviteData, message: translateV2("LABEL.INVITE_MESSAGE.JOIN_FIBRETRACE_COLLEAGUES")})
    }, [langData.localize]);


    return (
        <>
            <form className="register-form" onSubmit={formHandle.onSubmit}>


                <div className={"ft-modal-container"}>

                    <div className={"ft-modal-inner"}>

                        {/*STEP1*/}
                        <div className={`progress-step-form ${progress.current === 1 ? "active" : ""}`}>

                            <div className="text-center">

                                <p>{translateV2("LABEL.GUIDE.ADD_LOGO")}</p>

                                <DropZoneSingleV2 label={translateV2("LABEL.DASHBOARD.GUIDE.UPLOAD")}
                                                  name={"company_logo"} type={MEDIA_TYPE.COMPANY_LOGO}
                                                  file_type={["image/jpg", "image/jpeg", "image/png"]}
                                                  change={formHandle.onFileChange}
                                />

                                <div className={`input-group fibre-input mt-3`}
                                     style={{border: "1px solid #DDD", borderRadius: "5px"}}>

                                    <select value={form.protocol.value} name="protocol"
                                            className="fibre-input_control"
                                            onChange={formHandle.onChange}>
                                        <option
                                            value={WebProtocols.HTTP}>{translateV2("LABEL.HTTP")}&#58;&#47;&#47;</option>
                                        <option
                                            value={WebProtocols.HTTPS}>{translateV2("LABEL.HTTPS")}&#58;&#47;&#47;</option>
                                    </select>

                                    <input type="text" className={`form-control fibre-input_control`}
                                           placeholder={translateV2("LABEL.COMPANY_WEBSITE")}
                                           name="company_domain"
                                           value={form.company_domain.value}
                                           onChange={formHandle.onChange} onBlur={formHandle.onBlur}/>
                                </div>

                                {form.company_domain.error &&
                                    <GlobalMessage className="--pad"
                                                   message={translateV2(form.company_domain.error)}/>}

                                {(_.size(progress.list[0].error) > 0) &&
                                    <div className="global-msg-wrap text-center text-red mt-3">
                                        <div className="global-msg error"
                                             dangerouslySetInnerHTML={{__html: translateV2(progress.list[0].error)}}/>
                                    </div>}

                                <div className={"d-flex justify-content-center"}>
                                    <Button
                                        disabled={!progress.list[0].isValid}
                                        variant={"success"} onClick={formHandle.onSubmit}
                                        style={{marginTop: "36px", width: "100%"}}
                                    >{translateV2("LABEL.CONTINUE")}</Button>

                                </div>

                                <p className={"text-green mt-3"}>
                                    <strong className={"text-underline"}
                                            onClick={() => stepHandle.setStep(2)}>{translateV2("LABEL.DO_THIS_LATER")}</strong>
                                </p>

                            </div>

                        </div>

                        {
                            !first_login_and_first_user
                                ? null
                                : <div className={`progress-step-form ${progress.current === 2 ? "active" : ""}`}>

                                    <p className="text-center">
                                        <Image src={ASSETS.IconEcommerceDollarSign} width={80} loading={"lazy"}/>
                                    </p>

                                    <p className={"text-center"}>{translateV2("LABEL.YOUR_CURRENT_PACKAGE", "", {'0': `${companyInfo.current_subscription.name || DEFAULT_COMPANY_SUBSCRIPTION_PACKAGE}`})}</p>

                                    <div className=""
                                         dangerouslySetInnerHTML={{__html: companyInfo.current_subscription.description}}/>

                                    <div className={"d-flex justify-content-center"}>

                                        <Button variant={"success"} id="submitInviteBtn"
                                                onClick={() => {
                                                    navigate(navigateUrlStr({
                                                        pathname: APP_ROUTES_PATH.PROFILE,
                                                        search: createSearchQuery({
                                                            t: "PACKAGE"
                                                        })
                                                    }))
                                                }}
                                                style={{marginTop: "16px", width: "100%"}}
                                        >
                                            {translateV2("LABEL.VIEW_PACKAGES")}
                                        </Button>

                                    </div>

                                    <p className={"text-green text-center mt-3"}>
                                        <strong className={"text-underline"}
                                                onClick={stepHandle.lastStep}>{translateV2("LABEL.DO_THIS_LATER")}</strong>
                                    </p>

                                </div>
                        }


                    </div>

                    <div className="ft-modal-progress progress-step-container justify-content-center">

                        {
                            _.map(progress.list, (p: any, index: number) =>
                                <span key={`step-${index}`}
                                      className={`progress-step ${progress.current >= p.step ? "active" : ""}`}
                                      style={{margin: "0 1px"}}
                                      onClick={() => stepHandle.setStep(p.step)}
                                />
                            )
                        }

                    </div>

                </div>


            </form>
        </>
    );
}

export default CompanySetupV2;
