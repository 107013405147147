import React, {useEffect, useState} from 'react';
import erroricon from '@assets/images/icons/error-msg.png';
import {DATE_FORMAT, FIBRETRACE_IDS, InputRules, Message} from '@src/types';
import Datetime from 'react-datetime';
import {translateMessage, translateV2, validate} from '@src/helpers';
import {PRODUCTS_API} from '@api/Product';
import {COMMON_API} from '@api/Common';
import {COMPANY_API} from '@api/Company';
import Select from "react-select";
import Switch from '@components/Input/Switch';
import {ORDER_API} from '@api/Order';
import {Accordion, Button, Card} from 'react-bootstrap';
import axios from "axios";
import _ from "lodash-es";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {useNavigate} from 'react-router-dom';
import {colourSingleStyles} from "@components/Input/MultiSelect";
import {DEFAULT_PANEL_SETTINGS, DEFAULT_SELECT} from "@src/variables";
import GlobalMessage from "@components/Input/Message";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {companyState} from "@redux/reducers/companySlice";

const ORDER_DATE_FORMAT = 'YYYY-MM-DD';

interface ISelect {
    id: number,
    value?: number,
    label?: string
}

const formatDataSelect = (data: ISelect, list: ISelect[]) => {
    if (data.value && data.label) {
        return data;
    } else {
        return list.find(item => item.id === data.id)
    }
}

const OrderFormV2P = ({pid, isShow, onSubmit, orderCode, onCreateProduct}: any) => {
    const langData = useAppSelector(languageState);
    const company = useAppSelector(companyState);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const orderFormDefault = {
        fibretrace_product_id: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: false,
            error: '',
            editable: true,
        },
        status: {
            value: 1,
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: '',
            editable: true,
        },
        quantity: {
            value: 1 as any,
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: '',
            editable: true,
        },
        uom_id: {
            value: 0,
            rules: [InputRules.REQUIRED],
            isValid: false,
            error: '',
            editable: true,
        },
        order_date: {
            value: moment().format(DATE_FORMAT),
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: '',
            editable: true,
        },
        retail_date: {
            value: "",
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: '',
            editable: true,
        },
        eco_visible: {
            value: false,
            rules: [] as InputRules[],
            isValid: true,
            error: '',
            editable: true,
        },
        condition_brand: {
            value: false,
            rules: [] as InputRules[],
            isValid: true,
            error: '',
            editable: true,
        },
        condition_manufacturer: {
            value: false,
            rules: [] as InputRules[],
            isValid: true,
            error: '',
            editable: true,
        },
        condition_whitelist: {
            value: false,
            rules: [] as InputRules[],
            isValid: true,
            error: '',
            editable: true,
        },
        condition_ip: {
            value: false,
            rules: [] as InputRules[],
            isValid: true,
            error: '',
            editable: true,
        },
        condition_fos: {
            value: false,
            rules: [] as InputRules[],
            isValid: true,
            error: '',
            editable: true,
        },
        fibretrace_id: {
            value: -1 as any,
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: '',
            editable: true,
        },
        frequency_of_scan: {
            value: 1,
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: '',
            editable: true,
        },
        frequency_of_scan_period: {
            value: 3600,
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: '',
            editable: true,
        },
        reference_id: {
            value: "",
            rules: [],
            isValid: true,
            error: '',
            editable: true,
        },
        countries: {
            value: [],
            rules: [] as InputRules[],
            isValid: true,
            error: '',
            editable: true,
        },
        intensity_check: {
            value: false,
            rules: [],
            isValid: true,
            error: '',
            step: 1,
            editable: false
        }
    }
    const [metaLoaded, setMetaLoaded] = useState(false)
    const [orderForm, setOrderForm] = useState({...orderFormDefault});
    const [listCountry, setListCountry] = useState([]);
    const [listFID, setListFID] = useState([] as Array<FIBRETRACE_IDS>);
    const [products, setProducts] = useState({...DEFAULT_SELECT})
    const [uoms, setUoms] = useState([])
    const [globalMsg, setGlobalMsg] = useState({
        type: '',
        text: ''
    });
    const [componentData, setComponentData] = useState({...DEFAULT_PANEL_SETTINGS})
    const [tmpOrderCode, setTmpOrderCode] = useState("");

    const inputHandler = async (e: any = {}) => {
        try {
            const target = e.target;
            const value = target?.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            const f = {...orderForm};
            let error: string;
            ({error} = validate(value, f[name].rules));
            f[name].error = error || "";
            f[name].isValid = !error;

            if (name && !_.isNil(value)) {
                f[name].value = value;
            }
            setOrderForm({...orderForm, ...f})

            await formHandle.validate();
        } catch (e) {
            alert(e.toString())
        }
    }

    const handleChangeCountry = async (event: any) => {
        await inputHandler({
            target: {
                name: 'countries',
                value: event
            }
        })
    };

    const handleChangeDate = async (event: any, name: string) => {
        await inputHandler({
            target: {
                name,
                value: event.format(ORDER_DATE_FORMAT)
            }
        })
    };

    const assignOrderFormData = async (data: any = {}) => {
        try {
            if (data) {

                if (_.findIndex(products.list, (v: any) => v.id === data.fibretrace_product_id.value) !== -1) {
                    let idx = products.list[_.findIndex(products.list, (v: any) => v.id === data.fibretrace_product_id.value)];
                    if (idx) products.selected = idx;
                    await formHandle.onSelectChange({value: idx}, 'fibretrace_product_id');
                    setProducts({...products, selected: idx})
                }

                if (data.fibretrace_product_id) data.fibretrace_product_id.isValid = true;

                setOrderForm({
                    fibretrace_product_id: {
                        ...orderForm.fibretrace_product_id,
                        ...data.fibretrace_product_id
                    },
                    status: {
                        ...orderForm.status,
                        ...data.status
                    },
                    quantity: {
                        ...orderForm.quantity,
                        ...data.quantity
                    },
                    uom_id: {
                        ...orderForm.uom_id,
                        ...data.uom_id
                    },
                    order_date: {
                        ...orderForm.order_date,
                        ...data.order_date
                    },
                    retail_date: {
                        ...orderForm.retail_date,
                        ...data.retail_date
                    },
                    eco_visible: {
                        ...orderForm.eco_visible,
                        ...data.eco_visible
                    },
                    condition_brand: {
                        ...orderForm.condition_brand,
                        ...data.condition_brand
                    },
                    condition_manufacturer: {
                        ...orderForm.condition_manufacturer,
                        ...data.condition_manufacturer
                    },
                    condition_whitelist: {
                        ...orderForm.condition_whitelist,
                        ...data.condition_whitelist
                    },
                    condition_ip: {
                        ...orderForm.condition_ip,
                        ...data.condition_ip
                    },
                    condition_fos: {
                        ...orderForm.condition_fos,
                        ...data.condition_fos
                    },
                    fibretrace_id: {
                        ...orderForm.fibretrace_id,
                        ...{
                            editable: data.fibretrace_id.editable,
                            value: data.fibretrace_id.value || -1
                        }
                    },
                    frequency_of_scan: {
                        ...orderForm.frequency_of_scan,
                        ...data.frequency_of_scan
                    },
                    frequency_of_scan_period: {
                        ...orderForm.frequency_of_scan_period,
                        ...data.frequency_of_scan_period
                    },
                    reference_id: {
                        ...orderForm.reference_id,
                        ...data.reference_id
                    },
                    countries: {
                        ...orderForm.countries,
                        ...data.countries,
                        value: data.countries.value.map(countryID => ({id: countryID}))
                    },
                    intensity_check: {
                        ...orderForm.intensity_check,
                        ...data.intensity_check,
                    },
                });
                return data
            }
        } catch (e) {
            console.error(e)
        }
    }

    const formHandle = {
        validate: async (trigger: boolean = true) => {
            setComponentData({
                ...componentData,
                isValid: _.every(orderForm, (v: any) => v.isValid)
            })
            if (trigger) {
            }
        },
        onBlur: inputHandler,
        onChange: inputHandler,
        onSelectChange: async (selected: any, field: any) => {
            try {
                const f = {...orderForm}, {error} = validate(selected.value, f[field].rules);

                if (_.has(f, field)) {

                    f[field].error = error || "";
                    f[field].isValid = !error;

                    if (selected.value) {
                        f[field].value = selected.value;
                        setProducts({...products, selected})
                    }
                }

                // setOrderForm({...orderForm, ...f})

                await formHandle.validate();
            } catch (e) {
                console.error(e)
            }
        },
        onSubmit: async (event: any, red = {redirect: false, path: ""}) => {
            try {
                setGlobalMsg({
                    type: '',
                    text: ''
                });
                event.preventDefault();
                let canSubmit = true;
                const curForm = {...orderForm};
                const keys = Object.keys(curForm) as any[];

                for (const key of keys) {
                    const {error, isValid} = validate(curForm[key].value, curForm[key].rules,);
                    curForm[key].error = error;
                    if (!isValid) {
                        canSubmit = false;
                    }
                }

                setOrderForm({...orderForm, ...curForm});

                if (canSubmit) {
                    const formData = {
                        fibretrace_product_id: orderForm.fibretrace_product_id.value,
                        quantity: orderForm.quantity.value,
                        uom_id: orderForm.uom_id.value,
                        order_date: orderForm.order_date.value,
                        retail_date: orderForm.retail_date.value,
                        countries: orderForm.countries.value.map((country: any) => country.id),
                        eco_visible: orderForm.eco_visible.value,
                        condition_brand: orderForm.condition_brand.value,
                        condition_manufacturer: orderForm.condition_manufacturer.value,
                        condition_whitelist: orderForm.condition_whitelist.value,
                        condition_ip: orderForm.condition_ip.value,
                        condition_fos: orderForm.condition_fos.value,
                        fibretrace_id: parseInt(orderForm.fibretrace_id.value) === -1 ? null : Number(orderForm.fibretrace_id.value),
                        frequency_of_scan: orderForm.frequency_of_scan.value,
                        frequency_of_scan_period: orderForm.frequency_of_scan_period.value,
                        reference_id: orderForm.reference_id.value,
                    }
                    let resultReq;
                    if (orderCode) {
                        resultReq = await ORDER_API.updateOrder(orderCode, formData);
                    } else {
                        resultReq = await ORDER_API.createOrder(formData);
                    }
                    const {error, data} = resultReq;
                    if (error) {
                        setGlobalMsg({
                            type: Message.ERROR,
                            text: error
                        });
                    } else {
                        if (data) {
                            let {code} = data;
                            if (code) setTmpOrderCode(code);
                            if (red.redirect) navigate(
                               red.path + `/${code}`
                            )
                        }
                        if (!red.redirect && _.isFunction(onSubmit)) onSubmit({rObject: data});
                    }
                }
            } catch (e) {
                setGlobalMsg({
                    type: Message.ERROR,
                    text: e.toString()
                });
            }

        },

    };

    const resetComponent = async () => {
        try {
            setProducts({...DEFAULT_SELECT})
            setListCountry([])
            setListFID([])
            setOrderForm({...orderFormDefault})
            setMetaLoaded(false)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        (async () => {
            setGlobalMsg({
                type: '',
                text: ''
            });
            const allInfoPromise = Promise.all([
                COMMON_API.getCountry(),
                COMPANY_API.getCompanyFIDs({cancelToken: source.token}),
                PRODUCTS_API.getProducts({is_paginate: false}),
                COMMON_API.getUnitOfMeasurements(),
                COMMON_API.getDefaultUOM()
            ])

            try {

                const result = await allInfoPromise;
                const fidList = result[1].data || []
                const uomList = result[3].data || []
                const {data: p} = result[2]
                const defaultUOM = result[4].data || 'PC'

                setListCountry(result[0].data ? result[0].data.map((val: any) => ({
                    ...val,
                    value: val.id,
                    label: val.name
                })) : []);
                setListFID(fidList);
                setUoms(uomList);

                let tmpList = [
                    ...products.list,
                    ...p.map((i: any) => {
                        const {id: value, name: label} = i;
                        return {...i, value, label}
                    })
                ]
                let tmpPlist = {
                    ...products,
                    isLoading: false,
                    isDisabled: !(p && _.size(p) > 0),
                    list: [...tmpList],
                }
                let pidx = _.findIndex(tmpList, (p: any) => p.id === Number(pid))
                if (pidx !== -1) {
                    tmpPlist.selected = tmpList[pidx]
                }

                let uom = uomList.length > 0 ? uomList.filter(uom => uom.code == defaultUOM) : [];

                let tmpForm = {
                    ...orderForm,
                    fibretrace_id: {
                        ...orderForm.fibretrace_id,
                        isValid: true,
                        value: !_.isEmpty(fidList) ? fidList[0].id : -1
                    },
                    fibretrace_product_id: {
                        ...orderForm.fibretrace_product_id,
                        isValid: pidx !== -1,
                        value: pidx !== -1 ? tmpList[pidx].id : 0,
                    },
                    uom_id: {
                        ...orderForm.uom_id,
                        value: uom.length > 0 ? uom[0].id : 0,
                        isValid: uom.length > 0,
                    }
                }
                setProducts({...products, ...tmpPlist})
                setOrderForm({...tmpForm});
                setComponentData({
                    ...componentData,
                    isValid: _.every(tmpForm, (v: any) => v.isValid)
                })

                setMetaLoaded(true)
            } catch (error) {
                setGlobalMsg({
                    type: Message.ERROR,
                    text: "ERROR.API_ERROR"
                });
            }
        })();

        return () => {
            resetComponent()
        }

    }, []);

    useEffect(() => {

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        (async () => {
            try {
                if (orderCode && _.isBoolean(metaLoaded) && metaLoaded) {
                    const {data} = await ORDER_API.getOrderByCode(orderCode, source.token);
                    await assignOrderFormData(data);
                }
            } catch (error) {
                setGlobalMsg({
                    type: Message.ERROR,
                    text: "ERROR.API_ERROR"
                });
            }

        })();

    }, [metaLoaded, orderCode]);

    return (
        <>

            {globalMsg.type &&
                <div className="global-msg-wrap text-center">
                    <div className="global-msg">
                        {globalMsg.type === Message.ERROR && <img src={erroricon} alt=""/>}
                        <p className={`${globalMsg.type === Message.ERROR ? 'error' : ''}`}>{translateMessage(langData.localize, globalMsg.text)}</p>
                    </div>
                </div>
            }

            <dl>
                <dt>
                    <label>{translateV2("LABEL.PRODUCT")} <span
                        className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
                </dt>
                <dd>
                    <div className="input-group">

                        <Select
                            styles={colourSingleStyles}
                            className="basic-single mb-2 w-100"
                            classNamePrefix="select"
                            isDisabled={products.isDisabled || !orderForm.fibretrace_product_id.editable}
                            isLoading={products.isLoading}
                            isClearable={products.isClearable}
                            isSearchable={products.isSearchable}
                            name="fibretrace_product_id"
                            options={products.list}
                            value={products.selected}
                            onChange={(e) => formHandle.onSelectChange(e, 'fibretrace_product_id')}
                        />

                    </div>

                    {orderForm.fibretrace_product_id.error &&
                        <GlobalMessage errorClassName={Message.ERROR} message={orderForm.fibretrace_product_id.error}/>}

                    {metaLoaded && products.list.length <= 0 && <p className="mt-2 text-red">
                        {translateV2("LABEL.NO_PRODUCTS")}. <span className="link"
                                                                  onClick={async () => {
                                                                      if (_.isFunction(onCreateProduct)) onCreateProduct();
                                                                  }}>{translateV2("LABEL.CREATE_PRODUCT")}</span>
                    </p>}

                </dd>

                <dt>
                    <label>{translateV2("LABEL.QUANTITY")} <span
                        className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
                </dt>
                <dd>
                    <div className="input-group">
                        <input type="number" className="form-control" value={orderForm.quantity.value}
                               onChange={formHandle.onChange} id="quantity" name="quantity"
                               disabled={!orderForm.quantity.editable}/>
                    </div>
                    {orderForm.quantity.error && <div className="global-msg">
                        <span className="error">{translateV2(orderForm.quantity.error)}</span>
                    </div>}
                </dd>

                <dt>
                    <label>{translateV2("LABEL.UOM")} <span
                        className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
                </dt>
                <dd>
                    <div className="input-group">
                        <select className="form-control" id="uom_id" name="uom_id"
                                disabled={!orderForm.uom_id.editable}
                                value={orderForm.uom_id.value || 0}
                                onChange={formHandle.onChange}>
                            <option value="">{translateV2('LABEL.CHOOSE_ONE')}</option>
                            {
                                uoms.length > 0 && uoms.map((uom: any) => <option key={`uom-${uom.id}`}
                                                                                  value={uom.id}>{uom.name}</option>)
                            }
                        </select>
                    </div>

                    {orderForm.uom_id.error &&
                        <GlobalMessage errorClassName={Message.ERROR} message={orderForm.uom_id.error}/>}
                </dd>

                <dt>
                    <label>{translateV2("LABEL.RETAIL_OR_DELIVERY_DATE")} <span
                        className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
                </dt>
                <dd>
                    <div className="input-group">
                        <Datetime timeFormat={false} dateFormat={ORDER_DATE_FORMAT} className="bg-transparent"
                                  closeOnSelect={true}
                                  inputProps={{
                                      autoComplete: "off",
                                      readOnly: true,
                                      disabled: !orderForm.retail_date.editable
                                  }}
                                  value={
                                      orderForm.retail_date.value
                                          ? moment(orderForm.retail_date.value).format(DATE_FORMAT)
                                          : ""
                                  }
                                  onChange={(val) => handleChangeDate(val, 'retail_date')}/>
                    </div>
                    {orderForm.retail_date.error && <div className="global-msg">
                        <span className="error">{translateV2(orderForm.retail_date.error)}</span>
                    </div>}
                </dd>

                <dt>
                    <label>{translateV2("LABEL.FIBRETRACE_ID")} <span
                        className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
                </dt>
                <dd>
                    <div className="input-group">
                        <select className="form-control" id="fibretrace_id" name="fibretrace_id"
                                disabled={!orderForm.fibretrace_id.editable}
                                value={orderForm.fibretrace_id.value}
                                onChange={formHandle.onChange}>
                            <option value="">{translateV2('LABEL.CHOOSE_ONE')}</option>
                            <option value="-1">{translateV2('LABEL.FIBRETRACE_ID.NONE')}</option>
                            {
                                listFID.length > 0 && listFID.map((fid: any) => <option key={`fid-${fid.id}`}
                                                                                        value={fid.id}>{fid.code}</option>)
                            }
                        </select>
                    </div>
                    {orderForm.fibretrace_id.error && <div className="global-msg">
                        <span className="error">{translateV2(orderForm.fibretrace_id.error)}</span>
                    </div>}
                </dd>

                {
                    !_.includes(_.toUpper(company.current_subscription.name), "VERIFIED") || Number(orderForm.fibretrace_id.value) <= 0 || _.isNil(orderForm.fibretrace_id.value)
                        ? null
                        : <>
                            <dt>
                                <label
                                    htmlFor="intensity_check"
                                    className={"mb-0"}>{translateV2("LABEL.SCAN_STRENGTH_NOTIFICATION")}</label>
                                <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                                               message={translateV2('LABEL.ASTERISK') + translateV2(`LABEL.SCAN_STRENGTH_NOTIFICATION.NOTE`)}
                                />
                            </dt>

                            <dd className={``}>
                                <div className="input-group">
                                    <Switch label={``}
                                            className="flex-row-reverse flex-sm-row justify-content-start "
                                            val={orderForm.intensity_check.value} name="intensity_check"
                                            disabled={
                                                !(_.includes(_.toUpper(company.current_subscription.name), "VERIFIED")
                                                    && !_.isNil(orderForm.fibretrace_id.value))
                                                && Number(orderForm.fibretrace_id.value) > 0
                                            }
                                            change={formHandle.onChange}
                                            options={[translateV2("LABEL.YES"), translateV2("LABEL.NO")]}/>
                                </div>
                                {orderForm.fibretrace_id.error && <div className="global-msg">
                                    <span className="error">{translateV2(orderForm.fibretrace_id.error)}</span>
                                </div>}
                            </dd>
                        </>
                }



                <dt>
                    <label
                        htmlFor="reference_id"
                        className={"mb-0"}>{translateV2("LABEL.REFERENCE_ID")}</label>
                    <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                                   message={translateV2('LABEL.ASTERISK') + translateV2(`LABEL.TOOLTIP.ORDER.REFERENCE`)}
                    />
                </dt>
                <dd>
                    <div className="input-group">
                        <input type="text" className="form-control"
                               value={orderForm.reference_id.value || ""} onChange={formHandle.onChange}
                               id="reference_id" name="reference_id"
                               disabled={!orderForm.reference_id.editable}/>
                    </div>

                    {orderForm.reference_id.error && <div className="global-msg">
                            <span
                                className="error">{translateV2(orderForm.reference_id.error)}</span>
                    </div>}
                </dd>

                {
                    company.company_package_payment.eco_score && <Accordion className={"mb-2"}>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                <FontAwesomeIcon icon={faPlusCircle} className={"mr-1"}/>
                                <b>
                                    {_.capitalize(translateV2("B2C.TITLE.ECO_SCORE"))}
                                </b>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <dt>
                                        <label>{translateV2("LABEL.ECO_VISIBLE")}</label>
                                    </dt>
                                    <dd>
                                        <div className="order-condition">
                                            <div className="input-group">
                                                <Switch val={orderForm.eco_visible.value} name="eco_visible"
                                                        className="flex-row-reverse justify-content-end"
                                                        change={formHandle.onChange}
                                                        options={[translateV2("LABEL.YES"), translateV2("LABEL.NO")]}
                                                        disabled={!orderForm.eco_visible.editable}/>
                                            </div>
                                        </div>
                                    </dd>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                }

                <dt>
                    <hr/>
                </dt>
                <dd style={{color: "red", fontStyle: "italic"}}><span
                    className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span> : {translateV2('LABEL.MANDATORY')}
                </dd>

            </dl>

            <div className={"mt-3 d-flex justify-content-between flex-column flex-sm-row"}>
                <Button
                    variant={"success"}
                    disabled={!componentData.isValid}
                    onClick={formHandle.onSubmit} className={"w-100"}
                >
                    {componentData.processing && <FontAwesomeIcon icon={faSpinner} className="mr-1" spin/>}
                    {translateV2("LABEL.SUBMIT")}
                </Button>

            </div>


        </>
    );
}

export default OrderFormV2P;
