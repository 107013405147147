import React, {useEffect, useState} from 'react';
import _ from "lodash-es";
import {DEFAULT_FILE} from "@src/variables";
import {MEDIA_TYPE} from "@src/types";
import {Image} from "react-bootstrap";
import {ASSETS} from "@assets/Assets";
import {fibLogger, translateV2} from "@src/helpers";

const DropZoneSingleV2 = ({
                              label,
                              options,
                              icon,
                              disabled,
                              isloading = false,
                              val,
                              className,
                              classNameZone = "",
                              change,
                              name, type = "" as MEDIA_TYPE,
                              file_type = ["image/"],
                              customClass,
                              controlClass = ""

                          }: any) => {

    let [file, setFile] = useState({...DEFAULT_FILE({ref: React.useRef({} as any), type: type})})
    let [fileType, setFileType] = useState("");

    const fileHandle = {
        onFileReset: () => {
            try {
                setFile({...DEFAULT_FILE({ref: React.createRef(), type: type})});
                if (_.isFunction(change)) change({...DEFAULT_FILE({ref: React.createRef(), type: type})})
            } catch (e) {
                console.error(e)
            }
        },
        onFileChange: (e: any) => {
            try {
                let m = {...file}, f = e.target.files[0]
                _.map(e.target.files, (f, k) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(f);
                    if (f) {
                        setFileType(f.type)
                        if (file_type.indexOf(f.type) === -1) {
                            m.ref.current.value = "";
                            setFile({...DEFAULT_FILE({ref: React.createRef(), type: type})})
                        } else {
                            m.hasFile = true;
                            m.list[k] = f;
                            setFile({...file, ...m});

                            reader.onloadend = function (e) {
                                m.current[k] = [reader.result]
                                setFile({...file, ...m});
                                if (_.isFunction(change)) change({...file})
                            }

                        }
                    }
                });
            } catch (e) {
                console.error(e)
            }
        }
    }

    useEffect(() => {
        fibLogger({file})
    }, [file])

    return (
        <div className="v2drop-zone-container">
            <div className="v2drop-zone-inner">

                <div className={`v2drop-zone ${classNameZone}`}>

                    {_.isEmpty(file.current)
                        ? <>
                            <label htmlFor={name}>
                                <input ref={file.ref} type="file" name={name} id={name}
                                       onChange={fileHandle.onFileChange}
                                       className={"v2-input-file"}/>
                                <p dangerouslySetInnerHTML={{
                                    __html: `${label} 
                                    ${fileType && file_type.indexOf(fileType) === -1
                                        ? `<br/><span class="text-red">${translateV2("LABEL.FILE_MIMES_ONLY", '', {
                                            '0': file_type,
                                            '1': ""
                                        })}${translateV2("LABEL.CHOOSE_AGAIN")}</span>`
                                        : ""
                                    }`
                                }}/>
                            </label>

                        </>
                        : <>
                            <Image src={ASSETS.IconCloseV2} loading={"lazy"} height={20} decoding={"async"}
                                   className={"v2drop-zone-reset"}
                                   onClick={fileHandle.onFileReset}
                            />

                            <div className="v2drop-zone-preview">
                                {
                                    file.current.map((imgData: any, itemIndex: any) =>
                                            <span key={itemIndex}>
                                                {file.list[itemIndex].type.indexOf('image/') !== -1
                                                    ? <img key={itemIndex} src={imgData} alt=""
                                                           className={"img-responsive"}
                                                           style={{maxHeight: "100px", width: "auto"}}/>
                                                    : <>
                                                        <Image src={ASSETS.IconDocumentV2} loading={"lazy"} height={100}
                                                               decoding={"async"}
                                                               style={{marginBottom: "0.5em"}} className={""}
                                                        />
                                                        <span>{file.list[itemIndex].name}</span>
                                                    </>
                                                }
                            </span>
                                    )
                                }
                            </div>
                        </>
                    }

                </div>

            </div>
        </div>
    );
}

export default DropZoneSingleV2;
