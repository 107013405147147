import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";

interface PRODUCT_TAGS_CRUD {
  product_id: string | number
  tags: Array<string | number>
}

interface SYNC_TAGS_PAYLOAD {
  model_id: string | number
  tags: Array<string | number>
  type: "order" | "product"
}

interface TAGS_DEFINITION {
  id?: string | number
  name: string
}

interface TAG {
  id?: string | number
  name: string,
  type: "order" | "product"
}

export const PRODUCT_TAGS_API = {
  getTags: (data: { signal?: AbortSignal } = {}) => {

    const {signal} = data;
    return axiosRequest('/tags', _.omitBy({
      method: 'GET', signal,
      headers: {...AUTH.getHeader()},
    }, _.isNil), false);
  },

  createTag: (data: TAGS_DEFINITION) => {
    return axiosRequest('/tags', {
      method: 'POST',
      data,
      headers: {...AUTH.getHeader()},
    }, true, false);
  },

  attachTags: (data: PRODUCT_TAGS_CRUD) => {
    return axiosRequest('/attach-tags', {
      method: 'POST',
      data,
      headers: {...AUTH.getHeader()},
    }, true, false);
  },

  detachTags: (data: PRODUCT_TAGS_CRUD) => {
    return axiosRequest('/detach-tags', {
      method: 'POST',
      data,
      headers: {...AUTH.getHeader()},
    }, true, false);
  },

}

export const TAGS_API = {
  getTags: (data: any = {}) => {
    const params = new URLSearchParams(data).toString();
    return axiosRequest(params === '' ? '/tags' : `/tags?${params}`, {
      method: 'GET',
      headers: {...AUTH.getHeader()},
    }, false);
  },

  createTag: (data: TAG) => {
    return axiosRequest('/tags', {
      method: 'POST',
      data,
      headers: {...AUTH.getHeader()},
    }, true, false);
  },

  attachTags: (data: SYNC_TAGS_PAYLOAD) => {
    return axiosRequest('/attach-tags', {
      method: 'POST',
      data,
      headers: {...AUTH.getHeader()},
    }, true, false);
  },

  detachTags: (data: SYNC_TAGS_PAYLOAD) => {
    return axiosRequest('/detach-tags', {
      method: 'POST',
      data,
      headers: {...AUTH.getHeader()},
    }, true, false);
  },

}

