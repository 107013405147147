import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@redux/hooks/store';
import {SEARCH_QUERIES} from "@src/types";
import {DEFAULT_VARIABLES} from "@src/variables";

const initialState: Omit<SEARCH_QUERIES, 'pagingData'> & any = {
  selected: "",
  queries: {...DEFAULT_VARIABLES.SEARCH_SETTINGS.queries},
  tabs: [],
  title: "",
  numPerPage: [5, 10, 20, 50, 100]
}
export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<typeof initialState.tabs>) => {
      state.tabs = action.payload;
      state.tabs.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    },
    addTab: (state, action: PayloadAction<any>) => {
      if (!state.tabs.some(tab => tab.value === action.payload.value)) {
        state.tabs.push(action.payload);
      }

      state.tabs.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    },
    setSearchState: (state, action: PayloadAction<SEARCH_QUERIES & any>) => {
      const {selected, tabs, title, queries} = action.payload
      state.selected = selected
      state.title = title
      state.queries = queries

      state.tabs.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
      state.tabs = [
        ...state.tabs,
        ...tabs.filter((tab) =>
          !state.tabs.some((existingTab) => existingTab.value === tab.value)
        ),
      ].sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    },
  },
});

export const {setSearchState} = searchSlice.actions;

export const searchState = (state: RootState) => state.search;

export default searchSlice.reducer;
