import 'react-image-lightbox/style.css';
import React, {useEffect, useRef, useState} from 'react';
import {COMPONENT_STATUS, Icon, InputRules, MEDIA_TYPE, Message, QUESTION_TYPE, USER_ACTION,} from "@src/types";

import {
  faAlignLeft,
  faFileAlt,
  faHome,
  faImage,
  faInfo,
  faPencilAlt,
  faTags,
  faTrashAlt,
  faUndoAlt
} from "@fortawesome/free-solid-svg-icons";
import {
  createSearchQuery,
  fibLogger,
  formatShortDescription,
  getLengthDescription,
  isEmptyValues,
  navigateUrlStr,
  translateV2,
  validate
} from "@src/helpers";
import Input from "@components/Input/Input";
import _ from "lodash-es";
import Select from 'react-select';
import {PRODUCTS_API} from "@api/Product";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MEDIA_API} from "@api/Media";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import GlobalMessage from "@components/Input/Message";
import {PRODUCT_TEMPLATE_API} from "@api/ProductTemplate";
import ProductTemplateAnswerV2 from "@v2components/ProductTemplate/Answer/ProductTemplateAnswerV2";
import {colourStyles} from "@components/Input/MultiSelect";
import LoadingV2 from "@v2components/Loading/LoadingV2";
import {
  DEFAULT_FILE,
  DEFAULT_MODAL,
  DEFAULT_PANEL_SETTINGS,
  DEFAULT_PRODUCT_DATA,
  DEFAULT_SELECT
} from "@src/variables";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {configState} from "@redux/reducers/configSlice";
import {APP_ROUTES_PATH} from "@src/routes";
import {companyState} from "@redux/reducers/companySlice";
import CustomTextArea from "@components/Input/TextArea";
import {showVideoModal, videoModalState} from "@redux/reducers/videoModalSlice";
import {useNavigate} from "react-router-dom";
import ModalConfirmation from "@v2components/Modal/Confirmation";
import CreatableSelect from 'react-select/creatable';

import {TAGS_API} from "@api/Tag";
import RModal from 'react-modal';
import {RouterInterface} from "@src/types/router";
import {userMetadataState, userState} from "@redux/reducers/userSlice";
import {userIntroManagerState} from "@redux/reducers/userIntroManagerSlice";
import {panelSlice} from "@redux/reducers/panelSlice";
import {fetchProductsAsync, productState} from "@redux/reducers/productSlice";

const ImageLazyLoader = React.lazy(() => import ("@v2components/Loading/ImageLazyLoader"))

const cssProductImage = {
    flexBasis: "33.33%"
}

const ProductDetailV2 = ({step = USER_ACTION.READ, data = {}, onChange, onSubmit}: any) => {
    const appConfig = useAppSelector(configState);
    const companyInfo = useAppSelector(companyState);
    const videoModalGlobalState = useAppSelector(videoModalState)
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const user = useAppSelector(userState);
    const userMetadata = useAppSelector(userMetadataState);
    const userIntroManager = useAppSelector(userIntroManagerState);
    const productGlobalState = useAppSelector(productState)

    const [isEditorReady, setEditor] = useState(false);
    const editorRef = useRef({} as any)

    const formDefault = {
        name: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: false,
            error: ''
        },
        description: {
            value: '',
            rules: [],
            isValid: true,
            error: ''
        },
        manufacturer_company_id: {
            value: '' as any,
            rules: [InputRules.REQUIRED],
            isValid: false,
            error: ''
        },
        manufacturer_name: {
            value: '',
            rules: [],
            isValid: true,
            error: ''
        },
        images: {
            value: '',
            rules: [],
            isValid: true,
            error: ''
        },
        uploaded_images: {
            value: '',
            rules: [],
            isValid: true,
            error: ''
        },
        attributeTemplateValue: {
            value: '' as any,
            rules: [],
            isValid: true,
            error: ''
        },
    }
    const [form, setForm] = useState({...formDefault});
    const [lightbox, setLightbox] = useState({
        url: "",
        isOpen: false,
    })
    const [desc, setDesc] = useState({
        expand: false,
        lines: 3,
        text: "" as any,
        limit: 40
    });
    const [product, setProduct] = useState({...DEFAULT_PRODUCT_DATA});
    const [manufactures, setManufactures] = useState({...DEFAULT_SELECT})
    const [templates, setTemplates] = useState({...DEFAULT_SELECT})
    const [files, setFiles] = useState({
        images: {...DEFAULT_FILE({ref: React.useRef({} as any), type: MEDIA_TYPE.PROCESS_IMAGE})},
    })

    const [questions, setQuestions] = useState({
        selected: [],
        list: [],
    } as any);

    const [confirmationModal, setConfirmationModal] = useState({...DEFAULT_MODAL});
    const [productProcess, setProductProcess] = useState({...DEFAULT_PANEL_SETTINGS});
    const [attributeTemplateValue, setAttributeTemplateValue] = useState("" as any);
    const fileInput = React.useRef({} as any);
    const [submitted, setSubmitted] = useState(false);
    const [templateChangeValue, setTemplateChangeValue] = useState({} as any);
    const [productImages, setProductImages] = useState([] as any);

    const [tags, setTags] = useState({...DEFAULT_SELECT})

    const [totalImages, setTotalImages] = useState(0);

    const handleLightbox = {
        open: (e: any, index, imgData) => {
            e.preventDefault();
            setLightbox({...lightbox, isOpen: true, url: imgData.sizes.large})
        },
        close: () => setLightbox({...lightbox, isOpen: false})
    }

    const inputHandler = async (e: any = {}) => {
        const target = e.target;
        const value = target?.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const f = {...form}, p = {...product};
        const {error} = validate(value, f[name].rules);
        f[name].error = error || "";
        f[name].isValid = !error;

        if (name && !_.isNil(value)) {
            f[name].value = value;
            p[name] = value;
        }

        setProduct({...product, ...p})

        await formHandle.validate({...form, ...f});
        if (_.isFunction(onChange)) await onChange({form: f, product: p});
    }

    const formHandle = {
        validate: async (data: any = {}, trigger: boolean = true) => {

            if (!_.isEmpty(data)) setForm({...form, ...data});

            setProductProcess({
                ...productProcess,
                object: {...product},
                isValid: _.every({...form, ...data}, (v: any) => v.isValid)
            })

            if (trigger) {
                //      do stuff
            }
        },
        onEditorReady: (editor) => {
            if (_.isNil(editor)) return false;
            setEditor(true);
            editor.setData(form.description.value || "")
            editor.editing.view.change((writer) => {
                writer.setStyle(
                    "height",
                    "200px",
                    editor.editing.view.document.getRoot()
                );
            });
        },
        onBlur: inputHandler,
        onChange: inputHandler,
        onFileChange: async (e: any, field: any, formField: any) => {
            try {
                const fm = {...form};
                const m = {...files};
                fm[formField].isValid = true;

                console.log("files", files)

                if (_.size(e.target.files) > appConfig.product_media_max_number_of_file
                    || ((_.size(files.images.current) + _.size(e.target.files)) > appConfig.product_media_max_number_of_file)
                    || ((_.size(files.images.current) + _.size(e.target.files) + _.size(_.filter(productImages, (p: any) => !p.deleted))) > appConfig.product_media_max_number_of_file)
                ) {
                    fm.uploaded_images.error = "ERROR.MAX_FILES_REACH"
                    fileInput.current.value = ""
                } else {

                    if (_.some(e.target.files, (f: any) => !_.includes(_.map(_.split(appConfig.audit_media_accepted_file_extension, ","), (config) => _.trim(config)), f.type))) {
                        alert(`${translateV2("LABEL.FILE_MIMES_ONLY", '', {
                            '0': 'jpeg, jpg, png',
                            '1': ""
                        })} ${translateV2("LABEL.CHOOSE_AGAIN")}`);

                        fileInput.current.value = ""
                    }

                    _.map(e.target.files, (f, k) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(f);
                        if (f) {
                            let maxSize = appConfig.product_media_max_upload_file_size;

                            if (f.type.indexOf("image/") === -1) {

                            } else {
                                if (f.size <= Number(maxSize) * 1024 * 1024) {
                                    fm.uploaded_images.error = "";
                                    m[field].hasFile = true;
                                    // m[field].list[k] = f;
                                    m[field].list.push(f)

                                    setFiles({...files, ...m})

                                    reader.onloadend = function (e) {
                                        // m[field].current[k] = ([reader.result])
                                        m[field].current.push(([reader.result]))
                                        setFiles({...files, ...m})
                                        fileInput.current.value = ""
                                    }
                                } else {
                                    //fileInput.current.value = ""
                                    fm.uploaded_images.error = "ERROR.FILE_TOO_LARGE";
                                }
                            }

                        }

                    });
                }


                await formHandle.validate({...form, ...fm});
                if (_.isFunction(onChange)) await onChange({form: fm});

            } catch (e) {
                console.error(e)
            }
        },
        onSelectChange: async (selected, field) => {
            try {
                const f = {...form}, p = {...product}, ma = {...manufactures};
                const {error} = validate(selected.value, f[field].rules);
                f[field].error = error || "";
                f[field].isValid = !error;

                if (_.has(form, field) && selected.value && selected.value !== f[field].value) {
                    p[field] = selected.value;
                    f[field].value = selected.value;
                    ma.selected = _.find(ma.list, (v) => v.id === selected.value)
                    if (_.isFunction(onChange)) await onChange({form: f, product: p});
                }

                setProduct({...product, ...p})
                await formHandle.validate({...form, ...f});
            } catch (e) {
                console.error(e)
            }
        },
        onTemplateChange: async (value, {action, removedValue}) => {
            try {

                let ques = {...questions};
                setTemplates({...templates, selected: value});
                ques.list = value;
                if (action === "remove-value") {

                    ques.selected = _.filter(ques.selected, (o: any) => {
                        return o.templateId !== removedValue.id;
                    })

                    if (_.isEmpty(ques.selected)) {
                        await productTemplateHandle.onChange({list: [], selected: []})

                        setForm({
                            ...form,
                            attributeTemplateValue: {
                                ...form.attributeTemplateValue,
                                value: [],
                                isValid: true,
                            }
                        })
                        setProduct({...product, attributeTemplateValue: '[]'})
                        setProductProcess({...productProcess, isValid: true})
                    }
                }
                setQuestions({...ques});
            } catch (e) {
                console.error(e)
            }
        },
        onEditorChange: async (event: any = {}, editor: any) => {
            if (!_.isNil(editor) && editor && event) {
                const f = {...form}, p = {...product}, field = 'description';
                const value = editor.getData();
                const {error} = validate(value, f[field].rules)

                if (value !== f[field].value) {
                    f[field].value = value;
                    f[field].error = error || "";
                    f[field].isValid = !error;

                    p.description = value

                    setProduct({...product, ...p})
                    await formHandle.validate({...form, ...f});
                    if (_.isFunction(onChange)) await onChange({form: f, product: p});
                }
            }
        },
        onEditorChangeV2: async (event: any) => {
            try {
                const f = {...form}, p = {...product}, field = 'description';
                const value = editorRef.current.getContent();
                const {error} = validate(value, f[field].rules)

                if (value !== f[field].value) {
                    f[field].value = value;
                    f[field].error = error || "";
                    f[field].isValid = !error;

                    p.description = value

                    setProduct({...product, ...p})
                    await formHandle.validate({...form, ...f});
                    if (_.isFunction(onChange)) await onChange({form: f, product: p});
                }
            } catch (e) {
                console.error(e)
            }
        },
        uploadMedia: async (field: any, type: string, formField: string) => {
            try {
                let f = {...form}, fi = {...files}, p = {...product};
                setFiles({...files, [field]: {...files[field], isUploading: true}});
                if (!_.isEmpty(files[field].list)) {
                    const uploaded_items = await MEDIA_API.uploadToS3({type, media: files[field].list});
                    if (uploaded_items) {
                        const {thumbnail, medium, large} = uploaded_items;
                        fi[field] = {
                            ...fi[field], ...{
                                isUploading: false,
                                hasFile: false,
                                list: [],
                            }
                        }
                        fi[field].ref.current.value = "";
                        f[formField].isValid = true;
                        f[formField].value = {};
                        p[formField] = {thumbnail, medium, large};
                    }
                    setFiles({...files, ...fi})
                    setProduct({...product, ...p})
                }

                await formHandle.validate({...form, ...f});
                if (_.isFunction(onChange)) await onChange({form: f, product: p});

                return p;
            } catch (e) {
                console.error(e)
            }
        },
        submit: async (e: any, red?: RouterInterface) => {

            try {
                e.preventDefault();

                _.forEach(productImages, async (img) => {
                    if (img.deleted) {
                        await PRODUCTS_API.deleteProductImage(img)
                        fibLogger(`Image deleted ${img.id}`)
                    }
                })

                console.log("total images", _.size(_.filter(productImages, (p: any) => !p.deleted)))

                formHandle.uploadMedia('images', 'PRODUCT', 'uploaded_images').then(async (prod) => {

                    setProductProcess({...productProcess, processing: true, message: "", messageType: Message.NEUTRAL});

                    const postData = {...product, ...prod};
                    const t = {...productProcess};

                    if (!(postData.file instanceof File)) delete postData.file

                    if (step === USER_ACTION.CREATE || step === USER_ACTION.UPDATE) {
                        let pid: number = -1;
                        let rq = {} as any;

                        const attachTags = _.differenceWith(tags.selected, postData.tags, (curr: any, prev: any) => curr.id === prev.id)

                        if (step === USER_ACTION.UPDATE) {
                            rq = await PRODUCTS_API.updateProductDetail(postData);
                            pid = product.id;

                            const detachTags = _.differenceBy(postData.tags, tags.selected, 'id')

                            /**
                             * detach tags from product
                             */
                            if (!_.isEmpty(detachTags)) {
                                await TAGS_API.detachTags({
                                    model_id: pid,
                                    tags: _.compact(_.map(detachTags, (t: any) => t.id)),
                                    type: 'product'
                                })
                            }

                        } else if (step === USER_ACTION.CREATE) {
                            rq = await PRODUCTS_API.createProduct(postData);
                        }
                        const {
                            data, error, message,
                            sanction_organizations = [], sanction_regions = [], sanction_words = [],
                        } = rq;

                        const t = {
                            ...productProcess,
                            messageType: error ? Message.ERROR : Message.SUCCESS,
                            sanction: {
                                sanction_organizations, sanction_regions, sanction_words
                            }
                        }

                        if (data && !error) {
                            setProduct({...product, ...data})
                            pid = data.id;
                            t.form = {...formDefault};
                        } else {
                            t.message = error || message || "";
                            t.isValid = false;
                            t.processing = false;
                        }

                        /**
                         * attach tags to product
                         */
                        if (!_.isEmpty(attachTags)) await TAGS_API.attachTags({
                            model_id: pid,
                            tags: _.compact(_.map(attachTags, (t: any) => t.id)),
                            type: 'product'
                        })

                        setProductProcess({...productProcess, ...t});

                        if (!error && !red && _.isFunction(onSubmit)) await onSubmit({
                            ...t,
                            rObject: {...product, ...data}
                        });

                        if (!error && red) navigate(navigateUrlStr({pathname: red.path,
                            search: createSearchQuery({
                                a: USER_ACTION.CREATE,
                                pid,
                            })
                        }))

                        dispatch(panelSlice.actions.hide())
                        dispatch(fetchProductsAsync(productGlobalState.search.queries))
                    }
                })

            } catch (e) {
                console.error(e)
                setProductProcess({
                    ...productProcess,
                    isValid: false,
                    processing: false,
                    message: e.message,
                    messageType: Message.ERROR
                });
            }

        },
        onUpdateQuestion: {
            validate: async () => {
                try {
                    const keys = ["id", "name", "tooltip", "answer_type", "is_required", "is_public", "value"]
                    let f = form;

                    const isValid = _.every(_.map(questions.list, (question) => {
                        return _.every(_.map(question, (cef) => {
                            let v = _.values(_.pick(cef, keys));
                            return !_.some(v, isEmptyValues)
                        }))
                    }));

                    if (isValid) {
                        let tmp = _.clone(questions.list);
                        let c = JSON.stringify(_.map(tmp, (v) => _.pick(v, keys)));
                        // f.content.value = c;
                        // p.content = c;
                    }

                    if (isValid) await formHandle.validate();
                } catch (e) {
                    console.error(e)
                }
            },
            onChange: async (e: any, index: number, field: string, templateIndex: number) => {
                try {
                    let cf = questions, {target} = e;
                    cf.list[templateIndex][index][field] = target?.type === 'checkbox' ? target.checked : target.value;
                    setQuestions({...questions, ...cf});

                    await formHandle.onUpdateQuestion.validate();
                } catch (e) {
                    console.error(e)
                }
            },
            onDelete: async (index: number, templateIndex: number) => {
                try {
                    const cf = questions;
                    cf.list[templateIndex].splice(index, 1);
                    setQuestions({...cf});
                    await formHandle.onUpdateQuestion.validate();
                } catch (e) {
                    console.error(e)
                }
            },
            onExpand: async (index: number, templateIndex: number) => {
                try {
                    let cf = questions
                    cf.list[templateIndex][index].is_expand = !cf.list[index][templateIndex].is_expand;
                    setQuestions({...questions, ...cf});

                } catch (e) {
                    console.error(e)
                }
            },
            onSelectChange: async (selected: any, index: number, field: string, templateIndex: number) => {
                try {
                    let cf = questions;
                    cf.list[templateIndex][index][field] = selected.value
                    setQuestions({...questions, ...cf});
                    await formHandle.onUpdateQuestion.validate();
                } catch (e) {
                    console.error(e)
                }
            },
        },
    };

    const productTemplateHandle = {
        getBatch: async (extra = {}) => {
            setTemplates({...templates, isLoading: true})
            let tp = {...templates};
            try {
                const {data, error} = await PRODUCT_TEMPLATE_API.getFullTemplates({is_paginate: false}, extra);
                if (data && !error) {
                    tp.isDisabled = _.isEmpty(data);
                    tp.isLoading = false;
                    tp.list = _.map(data, (v: any) => {
                        let {uid, name} = v;
                        return {...v, value: uid, label: name}
                    })
                    setTemplates({...templates, ...tp})
                }
                return tp;
            } catch (e) {
                console.error(e)
            }
            setTemplates({...templates, ...tp})
        },
        getCurrentTemplates: async (data: any) => {
            try {
                let tp = {...templates}, q = {...questions};
                let {attributeTemplateValue} = data;

                if (_.isArray(attributeTemplateValue) && !_.isEmpty(attributeTemplateValue)) {
                    setQuestions({...questions, list: [...attributeTemplateValue]})
                    return attributeTemplateValue;
                }
            } catch (e) {
                console.error(e)
            }
        },
        onChange: async (data: any) => {
            try {
                let keys = ["id", "name", "tooltip", "answer_type", "is_required", "is_public", "value"],
                    ques = {...questions}, {selected} = data, isValid = false,
                    i = _.findIndex(ques.selected, (o: any) => {
                        return o.templateId === selected.templateId;
                    });

                if (i === -1) ques.selected.push(selected);
                else ques.selected[i] = {...ques.selected[i], ...selected};

                let tmpSelected = [...ques.selected];

                if (_.isArray(tmpSelected)) {
                    tmpSelected = _.map(tmpSelected, (tem: any) => {
                        if (_.isArray(tem.fields)) {
                            tem.fields = _.map(tem.fields, (v: any) => _.pick(v, v.answer_type.toUpperCase() !== QUESTION_TYPE.DROPDOWNLIST ? keys : _.concat(keys, "answer_type_content")))
                            tem.isValid = true;
                            let required_fields = _.filter(tem.fields, (ft: any) => ft.is_required);
                            if (!_.isEmpty(required_fields)) tem.isValid = _.every(required_fields, (f: any) => f.is_required && f.value)
                        }
                        return tem;
                    })
                    isValid = _.every(tmpSelected, (t: any) => t.isValid) || _.isEmpty(tmpSelected);
                }

                let f = {
                    ...form,
                    attributeTemplateValue: {
                        ...form.attributeTemplateValue,
                        value: ques.selected,
                        isValid,
                    }
                }, p = {...product}
                p.attributeTemplateValue = JSON.stringify(tmpSelected);

                setForm({...form, ...f})
                setProduct({...product, ...p})
                setQuestions({...questions, ...ques})
                setProductProcess({...productProcess, isValid})
            } catch (e) {
                console.error(e)
            }
        }

    }

    const handleManufacturers = {
        getManufacturers: async () => {
            try {
                const ma = {...manufactures};
                const {manufacturer_company_id} = data;
                const tmpOptions = {include_id: undefined};
                if (manufacturer_company_id) {
                    tmpOptions.include_id = manufacturer_company_id;
                }
                const {data: list} = await PRODUCTS_API.getManufacturersV2(_.omitBy(tmpOptions, _.isNil));
                if (list) {
                    ma.isLoading = false;
                    ma.isDisabled = !(list && _.size(list) > 0);
                    ma.list = [
                        ...manufactures.list,
                        ...list.map((i: any) => {
                            const {id: value, name: label} = i;
                            return {...i, value, label}
                        })
                    ]
                    ma.selected = _.find(ma.list, (v: any) => v.id === product.manufacturer_company_id);
                    setManufactures({...manufactures, ...ma});
                }
                return {...manufactures, ...ma}
            } catch (e) {
                console.error(e)
            }
        },
        setDefaultManufacturer: async () => {
            try {

            } catch (e) {
                console.error(e)
            }
        }
    }

    const assignProductData = async () => {
        try {
            const fm = {...form};
            const p = _.omitBy(data, _.isNil)
            let tmp = {...product, ...p};

            if (step === USER_ACTION.CREATE) {
                p.manufacturer_company_id = companyInfo.company_id;
            }

            if (!_.isNil(p) && !_.isEmpty(p)) {
                _.forIn(p, (value, key) => {
                    if (_.has(fm, key)) {
                        const {error} = validate(value, fm[key].rules);
                        fm[key].value = value;
                        fm[key].error = "";
                        fm[key].isValid = !error;
                    }
                });
                let images = Object.assign([], JSON.parse(JSON.stringify(data.images)))
                setProductImages([..._.map(images, (img) => {
                    img.deleted = false;
                    return img;
                })])

                /**
                 * Total images
                 */

                setDesc({
                    ...desc,
                    text: p.description || "",
                });
                let tmp = {...product, ...p};
                setProduct({...product, ...p})
                setForm({...form, ...fm})
                setAttributeTemplateValue(tmp.attributeTemplateValue)
                setEditor(true)
            }
            return tmp

        } catch (e) {
            console.error(e)
            return null
        }
    }

    const resetComponent = async () => {
        try {
            setManufactures({...DEFAULT_SELECT})
            setTemplates({...DEFAULT_SELECT})
            setQuestions({selected: [], list: []});
            setForm({...formDefault});
            setProduct({...DEFAULT_PRODUCT_DATA})
            setFiles({
                images: {...DEFAULT_FILE({ref: React.createRef(), type: MEDIA_TYPE.PROCESS_IMAGE})},
            })
            setTags({...DEFAULT_SELECT})
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {

        Promise.all([
            handleManufacturers.getManufacturers(),
            productTemplateHandle.getBatch(),
            TAGS_API.getTags({
                type: "product",
            }),
        ]).then(async (response: any) => {
            let tpl = response[1];

            const tagsResponse: any = _.last(response);
            const {data: tagsData = []} = tagsResponse
            const tagsFormat = tagsData.map((i: any) => {
                const {id: value, name: label} = i;
                return {...i, value, label}
            })
            setTags({
                ...tags,
                list: [...tagsFormat]
            })

            setProductProcess({...productProcess, status: COMPONENT_STATUS.READY})

            assignProductData().then(async (tmpProductRes: any) => {
                const {attributeTemplateValue} = tmpProductRes
                await productTemplateHandle.getCurrentTemplates({attributeTemplateValue});

                if (_.isArray(attributeTemplateValue) && !_.isEmpty(attributeTemplateValue)) {
                    if (!_.isEmpty(tpl.list)) {
                        tpl.selected = attributeTemplateValue.map((v: any) => {
                            let {uid, name} = v;
                            return {...v, value: uid, label: name}
                        })
                        setTemplates({
                            ...templates, ...tpl
                        });
                    }
                }

                if (!_.isEmpty(tmpProductRes.tags) && step !== USER_ACTION.CREATE) {

                    setTags({
                        ...tags,
                        selected: _.intersectionWith(tagsFormat, tmpProductRes.tags, (o: any, i: any) => o.id === i.id),
                        list: [...tagsFormat]
                    })
                }

            })
        }).catch(err => {
            setProductProcess({...productProcess, status: COMPONENT_STATUS.NOT_INITIALIZED})
        })

        return () => {
            resetComponent()
        }
    }, [data])

    useEffect(() => {
        (async () => {
            if (userMetadata.initialized && step === USER_ACTION.CREATE) {
                const {company_id} = companyInfo;
                if (company_id) {
                    const ma = {...manufactures};
                    const curr = _.find(ma.list, (v: any) => v.id === company_id)
                    if (curr) {
                        const {value: manufacturer_company_id} = curr;
                        if (manufacturer_company_id) {
                            setProduct({...product, manufacturer_company_id});
                            await formHandle.onSelectChange(curr, 'manufacturer_company_id');
                        }
                    }
                }
            }
        })()
    }, [dispatch, step, companyInfo, userMetadata])

    useEffect(() => {
        fibLogger({files})
    }, [files]);

    return (
        <>

            {productProcess.processing && <LoadingV2/>}

            <form action="" className={`fibre-product user-action-${step.toLowerCase()}`}
                  onSubmit={formHandle.submit}>

                {productProcess.status === COMPONENT_STATUS.INITIALING && <LoadingV2/>}

                <Input icon={{type: Icon.FA, icon: faPencilAlt}} type="text" name="name" required={true}
                       label={translateV2("LABEL.NAME_SKU")}
                       change={formHandle.onChange} blur={formHandle.onBlur}
                       val={form.name.value} className="borderless"
                       inputClassName={step !== USER_ACTION.READ ? "fibre-input--border mb-2" : "mb-2"}
                       disabled={
                           step === USER_ACTION.READ
                           || (step === USER_ACTION.UPDATE && data?.auditing_in_progress)
                       }

                />

                <GlobalMessage className={"font-italic mb-1 fibre-input-descv1"} errorClassName={"success"}
                               message={translateV2(`LABEL.TOOLTIP.PRODUCT.NAME`)}
                />

                {form?.name?.error &&
                    <GlobalMessage className="pl-12"
                                   message={translateV2(form?.name?.error)}/>}

                {step === USER_ACTION.READ && form.manufacturer_name.value &&
                    <Input icon={{type: Icon.FA, icon: faHome}} name="manufacturer_name" required={true}
                           val={form.manufacturer_name.value}
                           inputClassName="" disabled={true}
                           label={translateV2("LABEL.MANUFACTURER")}
                           change={formHandle.onChange}/>}

                {step !== USER_ACTION.READ && <>
                    <div className={`input-group fibre-input borderless flex-nowrap`}>
                        <div className="input-group-prepend">
                            <span
                                className="input-group-text fibre-input_label">{translateV2("LABEL.MANUFACTURER")}<span
                                className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span><br/>{translateV2("LABEL.PRODUCT.MANUFACTURER_DESC")}</span>
                            <span className="input-group-text fibre-input_icon"><FontAwesomeIcon icon={faHome}/></span>
                        </div>
                        <Select
                            className="basic-single mt-2 mb-2 w-100"
                            classNamePrefix="select"
                            value={
                                _.find(manufactures.list, (v: any) => v.id === product.manufacturer_company_id)
                                    ? _.find(manufactures.list, (v: any) => v.id === product.manufacturer_company_id)
                                    : _.find(manufactures.list, (v: any) => v.id === companyInfo.company_id)
                            }
                            isDisabled={manufactures.isDisabled || (step === USER_ACTION.UPDATE && data?.auditing_in_progress)}
                            isLoading={manufactures.isLoading}
                            isClearable={manufactures.isClearable}
                            isSearchable={manufactures.isSearchable}
                            name="manufacturer_company_id"
                            options={manufactures.list}
                            onChange={(e) => formHandle.onSelectChange(e, 'manufacturer_company_id')}
                        />

                    </div>

                    <GlobalMessage className={"font-italic mb-1 fibre-input-descv1"} errorClassName={"success"}
                                   message={translateV2(`LABEL.TOOLTIP.PRODUCT.MANUFACTURER`)}
                    />

                    {form?.manufacturer_company_id?.error && <GlobalMessage className="pl-12"
                                                                            message={translateV2(form?.manufacturer_company_id?.error)}/>}
                </>
                }

                {step === USER_ACTION.READ && <>
                    <div className={`input-group fibre-textarea horizontal`}>
                        <div className="input-group-prepend">
                                <span className="input-group-text fibre-textarea_label">
                                    {translateV2("LABEL.PRODUCT.DESCRIPTION")}
                                </span>
                            <span className="input-group-text fibre-textarea_icon">
                                    <FontAwesomeIcon icon={faAlignLeft}/></span>
                        </div>

                        <div className="fibre-textarea_control disabled">
                            {(desc.expand || getLengthDescription(desc.text) <= desc.limit)
                                && <div className=""
                                        dangerouslySetInnerHTML={{__html: desc.text}}/>}

                            {!desc.expand && getLengthDescription(desc.text) > desc.limit
                                && <div
                                    className="">{formatShortDescription(desc.text, desc.limit)}...</div>}

                            {
                                getLengthDescription(desc.text) > desc.limit &&
                                <div className="text-ellipsis mt-2">
                                    <span className="short-des"
                                          onClick={() => setDesc({...desc, expand: !desc.expand})}>
                                        {desc.expand ? translateV2("LABEL.SHOW_LESS") : translateV2("LABEL.SHOW_MORE")}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                </>}

                {step !== USER_ACTION.READ && isEditorReady &&
                    <>
                        <CustomTextArea icon={{type: Icon.FA, icon: faInfo}} type="textarea" name="description"
                                        label={translateV2("LABEL.PRODUCT.DESCRIPTION")}
                                        change={formHandle.onChange} blur={formHandle.onBlur}
                                        val={form.description.value} labelClassName="text-left"
                                        inputClassName="editable company-description"
                        />
                    </>
                }

                {/*</div>*/}

                <div className={`input-group fibre-input flex-nowrap`}>
                    <div className="input-group-prepend">
                    <span
                        className="input-group-text fibre-input_label">{translateV2("LABEL.PRODUCT_TAGS")}
                        <br/>
                        &#40;{translateV2("LABEL.MAX_FILES", "", {
                            "0": 5,
                            "1": translateV2("LABEL.TAGS")
                        })}&#41;
                    </span>
                        <span className="input-group-text fibre-input_icon"><FontAwesomeIcon icon={faTags}/></span>
                    </div>

                    <CreatableSelect
                        styles={colourStyles}
                        className="basic-multi-select mt-2 mb-2 w-100"
                        classNamePrefix="select"
                        cacheOptions
                        defaultOptions
                        isMulti
                        isDisabled={step === USER_ACTION.READ}

                        name="tags"
                        options={tags.list}
                        value={tags.selected}
                        onChange={async (selected, attrs) => {
                            // console.log({selected, attrs}, tags.selected)
                            let tmpTags = {...tags}
                            const {action, name, option, removedValue} = attrs;

                            if (selected.length > 5) return alert(translateV2("LABEL.MAX_FILES", "", {
                                "0": 5,
                                "1": translateV2("LABEL.TAGS")
                            }));

                            if (action === 'create-option') {
                                const {data, error} = await TAGS_API.createTag({
                                    name: option.value,
                                    type: "product"
                                })

                                if (!error) {
                                    _.forIn(selected, (item: any) => {
                                        console.log("item", item.__isNew__ && item.label === data.name, item, data)

                                        if (item.__isNew__ && item.label === data.name) {
                                            item.value = data.id
                                            item.id = data.id
                                        }
                                        return item
                                    })
                                    // console.log("selected", tmpTags.selected)
                                } else {
                                    alert(translateV2(error))
                                }
                            }

                            if (action === 'remove-value' && step === USER_ACTION.UPDATE) {

                            }
                            // console.log({selected, attrs}, tags.selected)
                            // console.log(
                            //     "dataTags", data.tags,
                            //     _.differenceBy(data.tags, selected, 'id'),
                            //     _.differenceWith(selected, data.tags, (prev: any, cur: any) => prev.id === cur.id)
                            // );
                            tmpTags.selected = [...selected]

                            setTags({...tmpTags})
                            // console.log({...tmpTags})
                            await formHandle.validate();
                        }}
                    />
                </div>


                {step !== USER_ACTION.READ && <>
                    <div className={`input-group fibre-input mt-2 `}>
                        <div className="input-group-prepend">
                    <span
                        className="input-group-text fibre-input_label">
                {translateV2("LABEL.IMAGE")} <br/>
                        &#40;{translateV2("LABEL.MAX_FILES", "", {
                        "0": appConfig.product_media_max_number_of_file,
                        "1": "images. " + appConfig.product_media_max_upload_file_size + "MB"
                    })}&#41;
                    </span>
                            <span className="input-group-text fibre-input_icon"><FontAwesomeIcon
                                icon={faImage}/></span>
                        </div>

                        <input type="file" ref={fileInput} multiple={true}
                               className={`form-control fibre-input_control fibre-input_file fibre-input--border mr-2 mb-2`}
                               disabled={files.images.isUploading}
                               onChange={(e) => formHandle.onFileChange(e, 'images', 'uploaded_images')}/>

                        {/*<Button*/}
                        {/*    variant={!files.images.hasFile || files.images.isUploading ? "outline-dark" : "success"}*/}
                        {/*    disabled={!files.images.hasFile || files.images.isUploading}*/}
                        {/*    onClick={(e) => formHandle.uploadMedia('images', 'PRODUCT', 'uploaded_images')}*/}
                        {/*>*/}
                        {/*    {!files.images.isUploading*/}
                        {/*        ? <FontAwesomeIcon icon={faUpload} className="mr-1"/>*/}
                        {/*        : <FontAwesomeIcon icon={faSpinner} className="mr-1" spin/>}*/}
                        {/*    {translateV2( "LABEL.UPLOAD")}*/}
                        {/*</Button>*/}

                    </div>

                </>}

                <div className="row">
                    <div className="col-12">
                        <div className={"mt-3"}
                             style={step !== USER_ACTION.READ ? {} : {}}>

                            {form.uploaded_images.error &&
                                <GlobalMessage errorClassName={Message.ERROR}
                                               message={form.uploaded_images.error}
                                               messageData={{'0': appConfig.product_media_max_number_of_file}}
                                />
                            }

                            {
                                _.isEmpty(files.images.current) && _.isEmpty(productImages) && step !== USER_ACTION.READ
                                    ? <FontAwesomeIcon icon={faImage}
                                                       style={{
                                                           fontSize: "10em",
                                                           marginTop: "-0.125em",
                                                           maxWidth: "100%"
                                                       }}/>
                                    : <div className={"d-flex flex-wrap"}>
                                        {
                                            productImages.map((imgData: any, itemIndex: any) =>
                                                <div key={`thumb-${itemIndex}`} style={cssProductImage}>
                                                    <a href={imgData.sizes.large}
                                                       className={`fib-img-editor-container ${!imgData.deleted ? "" : " deleted "}`}
                                                       onClick={(e) => e.preventDefault()}>
                                                        <div className={`fib-img-editor`}>

                                                            <ImageLazyLoader src={imgData.sizes.thumbnail}
                                                                             alt={form?.name.value}
                                                                             className="img-responsive"
                                                                             style={{maxHeight: "100px", width: "auto"}}
                                                                             onClick={(e) => handleLightbox.open(e, itemIndex, imgData)}
                                                            />

                                                            {
                                                                step !== USER_ACTION.READ &&
                                                                <OverlayTrigger overlay={

                                                                    <Tooltip
                                                                        bsPrefix={"fib-bs-tooltip tooltip"}
                                                                        id={_.uniqueId('delete_product_image_')}>
                                                                        {translateV2(!imgData.deleted ? "LABEL.DELETE" : "LABEL.RESTORE")}
                                                                    </Tooltip>}
                                                                >
                                                                    <label
                                                                        className={`delete-product-image`}
                                                                        onClick={async () => {
                                                                            setProductImages(prevState => {
                                                                                const newItems = [...prevState];
                                                                                newItems[itemIndex].deleted = !prevState[itemIndex].deleted
                                                                                return newItems
                                                                            })
                                                                            await formHandle.validate()
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={!imgData.deleted ? faTrashAlt : faUndoAlt}
                                                                            style={{
                                                                                fontSize: "1.2em",
                                                                            }}/>
                                                                    </label>
                                                                </OverlayTrigger>
                                                            }


                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        }
                                        {
                                            files.images.current.map((imgData: any, itemIndex: any) =>
                                                <div key={`current-${itemIndex}`} style={cssProductImage}>
                                                    <div className="fib-img-editor-container">
                                                        <div className={`fib-img-editor`}>
                                                            <img key={itemIndex} src={imgData} alt=""
                                                                 className={"img-responsive"}
                                                                 style={{maxHeight: "100px", width: "auto"}}/>

                                                            <OverlayTrigger overlay={

                                                                <Tooltip
                                                                    bsPrefix={"fib-bs-tooltip tooltip"}
                                                                    id={_.uniqueId('delete_product_image_')}>
                                                                    {translateV2(!imgData.deleted ? "LABEL.DELETE" : "LABEL.RESTORE")}
                                                                </Tooltip>}
                                                            >
                                                                <label
                                                                    className={`delete-product-image`}
                                                                    onClick={async () => {
                                                                        try {
                                                                            let fm = {...form};
                                                                            let m = {...files};
                                                                            m.images.current.splice(itemIndex, 1);
                                                                            m.images.list.splice(itemIndex, 1);
                                                                            if (_.isEmpty(m.images.current) || _.isEmpty(m.images.list)) {
                                                                                m.images.hasFile = false
                                                                            }
                                                                            fileInput.current.value = ""
                                                                            setFiles({...m})
                                                                        } catch (e) {
                                                                            console.error("deleteImage", e)
                                                                        }
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={!imgData.deleted ? faTrashAlt : faUndoAlt}
                                                                        style={{
                                                                            fontSize: "1.2em",
                                                                        }}/>
                                                                </label>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                            }

                        </div>
                    </div>
                </div>

                <div className={`input-group fibre-input flex-nowrap`}>
                    <div className="input-group-prepend">
                    <span
                        className="input-group-text fibre-input_label">{translateV2("LABEL.PRODUCT_TEMPLATE")}&nbsp;{translateV2("LABEL.OPTIONAL")}
                        <br/><a className={`text-underline text-italic`}
                                href={"https://www.youtube.com/watch?v=R3D2ufaCcqQ"} rel={"noopener noreferrer"}
                                target={"_blank"} onClick={(e) => {
                            e.preventDefault();
                            dispatch(showVideoModal({
                                ...videoModalGlobalState,
                                isShow: true,
                                videoID: "R3D2ufaCcqQ",
                            }))
                        }}>{translateV2("LABEL.SHOW_ME_THE_WAY")}</a>
                    </span>
                        <span className="input-group-text fibre-input_icon"><FontAwesomeIcon icon={faFileAlt}/></span>
                    </div>

                    <Select
                        isDisabled={step === USER_ACTION.READ}
                        styles={colourStyles}
                        className="basic-multi-select mt-2 mb-2 w-100"
                        classNamePrefix="select"
                        name="attributeTemplateValue"
                        isMulti
                        options={templates.list}
                        value={templates.selected}
                        onChange={async (value, {action, removedValue}) => {
                            setTemplateChangeValue({value, action, removedValue});

                            if (action === "remove-value") {
                                setConfirmationModal({
                                    ...confirmationModal,
                                    isShow: true,
                                    title: Message.WARNING,
                                    type: Message.INFO,
                                    message: 'LABEL.CONFIRMATION_DELETE_GENERAL.PRODUCT_TEMPLATE'
                                })
                            } else {
                                await formHandle.onTemplateChange(value, {action, removedValue})
                            }

                        }}
                    />
                </div>

                <GlobalMessage
                    className={"font-italic mb-1 fibre-input-descv1"} errorClassName={"success"}
                    message={translateV2(`LABEL.TOOLTIP.PRODUCT.TEMPLATE`)}
                />

                {form?.attributeTemplateValue?.error && <GlobalMessage className="pl-12"
                                                                       message={translateV2(form?.attributeTemplateValue?.error)}/>}


            </form>

            {_.map(questions.list, (item, itemIndex) =>
                <ProductTemplateAnswerV2 key={itemIndex}
                                         template={item}
                                         onChange={productTemplateHandle.onChange}/>
            )}

            <hr/>

            <div style={{color: "red", fontStyle: "italic"}}><span
                className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span> : {translateV2('LABEL.MANDATORY')}
            </div>

            {
                !(step === USER_ACTION.UPDATE && data?.auditing_in_progress)
                    ? null
                    : <div style={{
                        color: "red",
                        fontStyle: "italic"
                    }}>{translateV2('LABEL.PRODUCT.AUDITING_IN_PROGRESS.CAN_NOT_EDIT_SKU_MANU')}
                    </div>
            }


            <GlobalMessage message={productProcess.message} errorClassName={productProcess.messageType}
                           messageData={({code, text}) => {
                               if (code === 'ERROR.VALIDATE.SANCTION_ORGANIZATION') {
                                   return translateV2(code, "",
                                       {
                                           '0': !_.isEmpty(productProcess?.sanction?.sanction_organizations) ? ` (${productProcess?.sanction?.sanction_organizations.join(', ')})` : "",
                                           '1': "",
                                           '2': ""
                                       }
                                   ) + "<br/>"
                               }
                               if (code === 'ERROR.VALIDATE.SANCTION_REGION') {
                                   return translateV2(code, "",
                                       {
                                           '0': !_.isEmpty(productProcess?.sanction?.sanction_regions) ? ` (${productProcess?.sanction?.sanction_regions.join(', ')})` : "",
                                           '1': "",
                                           '2': ""
                                       }
                                   ) + "<br/>"
                               }
                               if (code === 'ERROR.VALIDATE.SANCTION_WORD') {
                                   return translateV2(code, "",
                                       {
                                           '0': !_.isEmpty(productProcess?.sanction?.sanction_words) ? ` (${productProcess?.sanction?.sanction_words.join(', ')})` : "",
                                           '1': "",
                                           '2': ""
                                       }
                                   ) + "<br/>"
                               }
                               return text
                           }
                           }/>

            {step !== USER_ACTION.READ &&
                <>
                    <div className={"mt-3 d-flex justify-content-between"}>
                        <Button variant="success" className={"fpw-40"}
                                onClick={formHandle.submit}
                                disabled={!productProcess.isValid}
                        >

                            {step === USER_ACTION.CREATE && translateV2("LABEL.CREATE")}
                            {step === USER_ACTION.UPDATE && translateV2("LABEL.UPDATE")}
                        </Button>

                        <Button variant="success" className={"fpw-58"}
                                onClick={(e) => formHandle.submit(e, {
                                    redirect: true,
                                    path: APP_ROUTES_PATH.ORDERS,
                                    search: ""
                                })}
                                disabled={!productProcess.isValid || productProcess.processing}
                        >
                            {translateV2("LABEL.SAVE_AND_CREATE_ORDER")}
                        </Button>
                    </div>
                </>
            }

            <ModalConfirmation
                style={{zIndex: 999999999}}
                size={'md'}
                isShow={confirmationModal.isShow}
                title={confirmationModal.title}
                type={confirmationModal.type}
                message={confirmationModal.message}
                messageData={confirmationModal.messageData}
                onSubmit={async () => {
                    await formHandle.onTemplateChange(
                        templateChangeValue.value, {
                            action: templateChangeValue.action,
                            removedValue: templateChangeValue.removedValue
                        })
                    setConfirmationModal({...DEFAULT_MODAL})
                }}
                onClose={() => setConfirmationModal({...DEFAULT_MODAL})}
            >

            </ModalConfirmation>

            <RModal
                isOpen={lightbox.isOpen}
                onAfterOpen={() => {

                }}
                onRequestClose={handleLightbox.close}
                style={{
                    overlay: {
                        zIndex: 999990002
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        padding: 0,
                        border: 0,
                        zIndex: 999990002
                    },
                }}
                contentLabel="Example Modal"
            >
                <ImageLazyLoader
                    src={lightbox.url}
                    alt={form?.name.value}
                />
            </RModal>

        </>
    );
}

export default ProductDetailV2;
