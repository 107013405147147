import React from 'react';
import {axiosRequest, blockPage, logoutCleaner, translateV2} from '@src/helpers';
import {Button, Modal} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import GlobalMessage from "@components/Input/Message";
import {sessionExpiredSlice, sessionExpiredState} from "@redux/reducers/sessionExpiredSlice";
import {AUTH} from "@api/Auth";
import {AndroidInterfaceHelper} from "@api/AndroidInterfaceHelper";
import {useNavigate} from "react-router-dom";
import {APP_ROUTES_PATH} from "@src/routes";
import {appProviderSlice} from "@redux/reducers/appProviderSlice";

const GlobalSessionExpiredModal = () => {
    const message = useAppSelector(sessionExpiredState);
    const {style = {}} = message;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleMessageModal = {
        close: async () => {

            try {
                blockPage()
                const {message, status} = await axiosRequest('/logout', {
                    method: 'GET',
                    headers: {...AUTH.getHeader()},
                }, true, false);
                if (message && status) {

                }
            } catch (e) {
                console.error(e.message)
            } finally {
                dispatch(appProviderSlice.actions.setState({isLoggedIn: false}))
                dispatch(sessionExpiredSlice.actions.hideMessage());
                navigate(APP_ROUTES_PATH.LOGIN)

                logoutCleaner();
                blockPage(false)
                AndroidInterfaceHelper.appLogout()
            }
        }
    }


    return (
        <>
            {message.isShow &&
                <Modal style={style} show={message.isShow}
                       onShow={async () => {
                           logoutCleaner();
                       }}
                       onHide={handleMessageModal.close}
                       keyboard={false}
                       backdrop={false}
                       size={message.size}>
                    <Modal.Header closeButton>
                        <Modal.Title>{translateV2(message.title)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {message.message &&
                            <GlobalMessage errorClassName={message.type}
                                           message={message.message}
                                           messageData={message.messageData}/>}
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            !message?.cancelButton
                                ? null
                                : <Button variant={message?.cancelButton.variant} onClick={handleMessageModal.close}>
                                    {translateV2(message?.cancelButton.label)}
                                </Button>

                        }

                    </Modal.Footer>
                </Modal>}
        </>
    );
}

export default GlobalSessionExpiredModal;
