import React from "react";
import {Button} from "react-bootstrap";
import {translateV2} from "@src/helpers";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {EImportPartnersSteps, importPartnersSlice, importPartnersState} from "@redux/reducers/importPartnersSlice";
import {importPartnersProgressSlice, importPartnersProgressState} from "@redux/reducers/importPartnersProgressSlice";
import {userIntroState} from "@redux/reducers/userIntroSlice";
import {partnerImportAfterClose} from "@v2components/PartnerImport/PartnerImportHelper";

const PartnerFileValidatingCTA = () => {

  const dispatch = useAppDispatch();
  const user = useAppSelector(userIntroState);

  const importPartners = useAppSelector(importPartnersState);
  const importPartnersProgress = useAppSelector(importPartnersProgressState);
  return (
    <>
      <Button variant="outline-dark"
              className={`fibre-landscape-padding fibre-border-radius fibre-btn-outline-dark ml-0`}
              onClick={() => {
                dispatch(importPartnersSlice.actions.hide())
                dispatch(importPartnersSlice.actions.setTitle({title: translateV2("LABEL.PARTNER.IMPORT.CHOOSE_FILE"),}))
                dispatch(importPartnersProgressSlice.actions.reset())
                partnerImportAfterClose()

              }}>
        {translateV2("LABEL.CANCEL")}
      </Button>

      <Button variant="success" className={"fibre-btn-success fibre-landscape-padding fibre-border-radius ml-3"}
              onClick={() => {
                dispatch(importPartnersSlice.actions.setTitle({title: translateV2("LABEL.PARTNER.IMPORT.CHOOSE_FILE"),}))
                dispatch(importPartnersProgressSlice.actions.setStep({step: EImportPartnersSteps.CHOOSE_FILE}))
              }}>
        {translateV2("LABEL.BACK")}
      </Button>
    </>
  )
}

export default PartnerFileValidatingCTA;