import React from 'react'
import {blockPage, translateV2} from '@src/helpers'
import {Button, Modal} from 'react-bootstrap'
import GlobalMessage from '@components/Input/Message'
import {useAppSelector} from '@redux/hooks/hooks'
import {confirmationMessageSlice, confirmationState} from "@redux/reducers/confirmationMessageSlice";
import {useDispatch} from "react-redux";
import _ from "lodash-es";

const GlobalModalConfirmation = () => {

    const confirmation = useAppSelector(confirmationState)
    const {
        isShow,
        title,
        style,
        message,
        type,
        messageData,
        size,
        confirmAction,
        cancelAction,
        confirmButton,
        cancelButton,
        children,
        modalProps = {},
        modalHeaderProps = {},
        modalTitleProps = {},
        modalBodyProps = {},
        modalFooterProps = {}
    } = confirmation
    const dispatch = useDispatch()

    const handleMessageModal = {
        submit: () => {
            blockPage()
            if (confirmAction && _.isString(confirmAction)) dispatch({type: confirmAction})
            if (confirmAction && _.isObject(confirmAction)) dispatch(confirmAction)

            dispatch(confirmationMessageSlice.actions.hideMessage())
        },
        close: () => {
            if (cancelAction && _.isString(cancelAction)) dispatch({type: cancelAction})
            if (cancelAction && _.isObject(cancelAction)) dispatch(cancelAction)
            dispatch(confirmationMessageSlice.actions.hideMessage())
        },
    }

    return (
        <>
            {confirmation.isShow && (
                <Modal style={style} show={isShow} onHide={handleMessageModal.close} size={size} {...modalProps}>
                    <Modal.Header {...modalHeaderProps}>
                        <Modal.Title {...modalTitleProps}>{translateV2(title)}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body {...modalBodyProps}>
                        {message && (
                            <GlobalMessage
                                errorClassName={type}
                                message={message}
                                messageData={messageData}
                            />
                        )}

                        {children}
                    </Modal.Body>
                    <Modal.Footer {...modalFooterProps}>
                        {
                            !confirmButton || confirmButton?.visible
                                ? (
                                    <Button variant={confirmButton?.variant || "success"}
                                            onClick={handleMessageModal.submit}
                                            className="text-capitalize">
                                        {_.upperFirst(translateV2(confirmButton?.label || 'LABEL.YES'))}
                                    </Button>)
                                : null
                        }

                        {
                            !cancelButton || cancelButton?.visible
                                ? (
                                    <Button variant={cancelButton?.variant || "outline-dark"}
                                            onClick={handleMessageModal.close}
                                            className="text-capitalize">
                                        {_.upperFirst(translateV2(cancelButton?.label || 'LABEL.NO'))}
                                    </Button>)
                                : null
                        }

                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}

export default GlobalModalConfirmation