import React, {useEffect, useState} from 'react';
import {isEmptyValues, translateV2} from '@src/helpers';
import _ from "lodash-es";
import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import GlobalMessage from "@components/Input/Message";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCompressAlt, faExpandAlt, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {DATE_FORMAT, DATETIME_FORMAT, MEDIA_TYPE, Message, QUESTION_TYPE, USER_ACTION} from "@src/types";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import {useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {MEDIA_API} from "@api/Media";
import Select from "react-select";

interface PRODUCT_TEMPLATE {
    content: string
    id: number
    is_public: number
    name: string
    status: number
    uid: string
}

const ProductTemplateAnswerViewV2 = ({
                                         action = "" as USER_ACTION,
                                         template: template = {} as PRODUCT_TEMPLATE,
                                         onChange = {} as any,
                                         onSubmit
                                     }: any) => {
    const langData = useAppSelector(languageState);

    const [formStatus, setFormStatus] = useState({
        processing: false,
        isValid: false,
        message: "",
    });

    let count = 0;
    const [item, setItem] = useState(template || {} as any)
    const [isTemplateExpand, setTemplateExpand] = useState(true)
    const [isValid, setValid] = useState(false);

    const [questions, setQuestions] = useState({
        selected: {},
        list: [],
    } as any);

    const [list, setList] = useState([] as any)

    const [selected, setSelected] = useState({
        templateId: "" as any,
        fields: "" as any
    } as any)

    const formHandle = {
        onUpdateQuestion: {
            validate: async () => {
                try {
                    let p = {...item};
                    const keysCheck = ["id", "name", "answer_type", "is_required", "is_public", "value"],
                        keys = [...keysCheck, "tooltip"];

                    const isValid = _.every(_.map(list, (cef) => {
                        let v = _.values(_.pick(cef, keysCheck));
                        return !_.some(v, isEmptyValues)
                    }));

                    if (isValid) {
                        let tmp = _.clone(list);
                        p.content = JSON.stringify(_.map(tmp, (v) => _.pick(v, keys)));
                    }

                    if (_.isFunction(onChange)) {
                        let tmp = {list, selected};
                        onChange(tmp)
                    }
                } catch (e) {
                    console.error(e)
                }
            },
            onChange: async (e: any, index: number, field: string, type = "" as QUESTION_TYPE) => {
                try {
                    let lt = [...list], {target} = e;
                    let value: any;

                    if (target) value = target?.type === 'checkbox' ? target.checked : target.value;
                    else value = e;

                    lt[index][field] = value;
                    setList([...lt])
                    setSelected({
                            ...selected,
                            templateId: selected.templateId,
                            fields: [...lt]
                        }
                    )
                    await formHandle.onUpdateQuestion.validate();
                } catch (e) {
                    console.error(e)
                }
            },
            onBlur: async (e: any, index: number, field: string, type = "" as QUESTION_TYPE) => {
                try {

                    let lt = [...list], {target} = e;
                    let value: any;

                    if (target) value = target?.type === 'checkbox' ? target.checked : target.value;
                    else value = e;

                    switch (type) {
                        case QUESTION_TYPE.DECIMAL:
                            value = parseFloat(value);
                            break;
                        case QUESTION_TYPE.INTEGER:
                            value = parseInt(value);
                            break;
                        case QUESTION_TYPE.DATE_PICKER:
                            value = e.format(DATE_FORMAT);
                            break;
                        case QUESTION_TYPE.DATETIME_PICKER:
                            value = e.format(DATETIME_FORMAT);
                            break;
                    }

                    lt[index][field] = value;
                    setList([...lt])
                    setSelected({
                            ...selected,
                            templateId: selected.templateId,
                            fields: [...lt]
                        }
                    )
                    await formHandle.onUpdateQuestion.validate();
                } catch (e) {
                    console.error(e)
                }
            },
            onChangeFile: async (e: any, index: number, field: string, type = "" as QUESTION_TYPE, ref: any = {}) => {
                try {

                    let lt = [...list], {target} = e, f = e.target.files[0];
                    let value: any, tmpRef = lt[index].ref || ref

                    if (target) value = target?.type === 'checkbox' ? target.checked : target.value;
                    else value = e;

                    const allowFileExtensions = ['jpeg', 'jpg', 'png', 'pdf'];

                    if (!_.some(allowFileExtensions, (v) => _.includes(f.type, v))) {
                        alert(`${translateV2("LABEL.FILE_MIMES_ONLY", '', {
                            '0': 'jpeg, jpg, png, pdf',
                            '1': ""
                        })} ${translateV2("LABEL.CHOOSE_AGAIN")}`);
                        tmpRef.current.value = "";
                    } else {
                        const fl = await MEDIA_API.uploadToS3({media: e.target.files[0], type: MEDIA_TYPE.TEMPLATE})
                        if (fl) value = fl;

                        lt[index][field] = value;
                        setList([...lt])
                        setSelected({
                                ...selected,
                                templateId: selected.templateId,
                                fields: [...lt]
                            }
                        )
                    }

                    await formHandle.onUpdateQuestion.validate();

                } catch (e) {
                    console.error(e)
                }
            },
            onExpand: async (index: number) => {
                try {
                    let cf = list
                    cf[index].is_expand = !cf[index].is_expand;
                    setQuestions({...questions, ...cf});

                } catch (e) {
                    console.error(e)
                }
            },
            onSelectChange: async (selectedOption: any, index: number, field: string, type = "" as QUESTION_TYPE) => {
                try {
                    let lt = [...list], {value} = selectedOption;
                    lt[index][field] = value;
                    setList([...lt])
                    setSelected({
                            ...selected,
                            templateId: selected.templateId,
                            fields: [...lt]
                        }
                    )
                    await formHandle.onUpdateQuestion.validate();
                } catch (e) {
                    console.error(e)
                }
            }
        },
    }

    const handleItem = (item) => {
        try {
            if (!_.isEmpty(item)) {
                let {id, content, pivot, uid} = item;

                let tmpList: any = [];
                let tmpSelected: any = {}

                if (id) tmpSelected.templateId = id;

                if (content) {
                    let t = JSON.parse(content);
                    if (_.isArray(t)) {
                        tmpList = [..._.map(t, (v: any) => {
                            v.answer_type = v.answer_type.toUpperCase();
                            v.disabled = (action === USER_ACTION.VIEW);

                            if (v.answer_type.toUpperCase() === QUESTION_TYPE.DROPDOWNLIST) {
                                if (!_.isEmpty(v.answer_type_content)) v.select = _.map(v.answer_type_content, (o: any, oIdx: number) => {
                                    let t: any = {value: "", label: ""};
                                    if (_.isObject(o)) {
                                        if (_.size(_.head(_.toPairs(o))) === 2) {
                                            let tpo: any = _.head(_.toPairs(o));
                                            t.value = tpo[0];
                                            t.label = tpo[1]
                                        }
                                    }
                                    return t;
                                })
                            }

                            return {...v, is_expand: true}
                        })];
                    }

                    if (pivot) {
                        let {content: pivotContent} = pivot

                        if (pivotContent) {
                            let p = JSON.parse(pivotContent);
                            if (p) {
                                if (_.isArray(p)) {
                                    _.each(p, (v: any) => {
                                        let i = _.find(tmpList, (o) => o.id === v.id)
                                        i.value = v.value;
                                    })
                                }
                            }
                        }

                    }
                    tmpSelected.fields = [...tmpList];
                    setList([...tmpList])
                }
                setSelected({...tmpSelected})
                if (_.isFunction(onChange)) onChange({list: tmpList, selected: tmpSelected})
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if (!_.isEqual(item, template)) {
                    let {id: templateId, content} = item;
                    setItem({...item, ...template});
                }
            } catch (e) {
                console.error(e)
            }
        })()
    }, [template])

    useEffect(() => {
        handleItem(item)
    }, [item])


    return (
        <>

            {formStatus.message && <GlobalMessage errorClassName={formStatus.isValid ? Message.SUCCESS : Message.ERROR}
                                                  message={translateV2(formStatus.message)}/>}

            {!_.isEmpty(item) && <div className={"product-template-container"}>

                <div className="template-wrapper">

                    <div className="template-header">

                        <Button type="button" variant="outline-success"
                                onClick={(e) => setTemplateExpand(!isTemplateExpand)}
                                className={"bs-height template-expander"}>
                            {!isTemplateExpand
                                ? <FontAwesomeIcon icon={faExpandAlt}/>
                                : <FontAwesomeIcon icon={faCompressAlt}/>
                            }
                        </Button>

                        <div><strong>{translateV2("LABEL.TEMPLATE")}: {item.name}</strong></div>

                    </div>

                    {!_.size(list) && <div className={"mt-2 mb-2 d-flex flex-column question-container "}
                    >
                        <div className={"question-fields"}>
                            <Form.Label className={"question-name disabled form-control mb-2"}
                            >
                                <strong>{translateV2("LABEL.FIELDS.IS_EMPTY")}</strong>
                            </Form.Label>
                        </div>
                    </div>}

                    {isTemplateExpand && _.size(list) > 0 &&
                        list.map((question: any, itemIndex: any) => {
                                let ref = question.ref || React.createRef();
                                return question.is_public
                                    ? <div key={`question-${itemIndex}`}
                                           className={"mt-2 mb-2 d-flex flex-column question-container "}
                                           id={`question-${itemIndex}`}>

                                        <div className={"question-fields"}>

                                            <Form.Label className={"question-name disabled form-control mb-2"}
                                            >
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip
                                                            bsPrefix={"fib-bs-tooltip tooltip"}
                                                            id={`qq-${itemIndex}`}>
                                                            {translateV2(question.tooltip || question.name)}</Tooltip>
                                                    }>
                                                    <FontAwesomeIcon icon={faInfoCircle} className={"mr-1"}/>
                                                </OverlayTrigger>

                                                {question.name} {question.is_required &&
                                                <strong className={"text-red"}>{translateV2("LABEL.ASTERISK")}</strong>}
                                            </Form.Label>

                                            {question.is_expand && <>
                                                {(question.answer_type === QUESTION_TYPE.DECIMAL || question.answer_type === QUESTION_TYPE.INTEGER)
                                                    && <Form.Control readOnly className={"form-control mb-2"}
                                                                     value={question.value || ""}
                                                                     type={"number"}
                                                                     onChange={(e) => formHandle.onUpdateQuestion.onChange(e, itemIndex, 'value', question.answer_type)}
                                                                     onBlur={(e) => formHandle.onUpdateQuestion.onBlur(e, itemIndex, 'value', question.answer_type)}
                                                                     placeholder={translateV2(`LABEL.ANSWER.${_.toUpper(question.answer_type)}`)}/>
                                                }
                                                {question.answer_type === QUESTION_TYPE.STRING
                                                    && <Form.Control readOnly as={"textarea"}
                                                                     className={"form-control mb-2"}
                                                                     value={question.value || ""}
                                                                     onChange={(e) => formHandle.onUpdateQuestion.onChange(e, itemIndex, 'value', question.answer_type)}
                                                                     onBlur={(e) => formHandle.onUpdateQuestion.onBlur(e, itemIndex, 'value', question.answer_type)}
                                                                     placeholder={translateV2(`LABEL.ANSWER.${_.toUpper(question.answer_type)}`)}/>
                                                }

                                                {(question.answer_type === QUESTION_TYPE.DATE_PICKER || question.answer_type === QUESTION_TYPE.DATETIME_PICKER)
                                                    && <Datetime
                                                        dateFormat={question.answer_type === QUESTION_TYPE.DATETIME_PICKER ? DATE_FORMAT : DATE_FORMAT}
                                                        timeFormat={question.answer_type === QUESTION_TYPE.DATETIME_PICKER ? "hh:mm a" : false}
                                                        closeOnSelect={true}
                                                        value={question.value || ""}
                                                        inputProps={{
                                                            autoComplete: "off",
                                                            readOnly: true,
                                                            className: `form-control bg-gray`,
                                                            disabled: true
                                                        }}
                                                        onChange={(e) => formHandle.onUpdateQuestion.onChange(e, itemIndex, 'value', question.answer_type)}
                                                    />}

                                                {question.answer_type === QUESTION_TYPE.FILE_UPLOAD
                                                    ?
                                                    <>
                                                        {
                                                            question.value ?
                                                                <Form.Control readOnly className={"form-control mb-2"}
                                                                              disabled={true}
                                                                              value={question.value || ""}
                                                                              placeholder={translateV2(`LABEL.ANSWER.${_.toUpper(question.answer_type)}`)}/>
                                                                : ''
                                                        }
                                                    </>
                                                    : ''
                                                }

                                                {question.answer_type === QUESTION_TYPE.PERCENTAGE
                                                    && <Form.Control readOnly className={"form-control mb-2"}
                                                                     value={question.value || ""}
                                                                     onChange={(e) => formHandle.onUpdateQuestion.onChange(e, itemIndex, 'value', question.answer_type)}
                                                                     onBlur={(e) => formHandle.onUpdateQuestion.onBlur(e, itemIndex, 'value', question.answer_type)}
                                                                     placeholder={translateV2(`LABEL.ANSWER.${_.toUpper(question.answer_type)}`)}/>
                                                }

                                                {question.answer_type === QUESTION_TYPE.DROPDOWNLIST
                                                    && <>
                                                        <Select
                                                            isDisabled={true}
                                                            menuPosition={"fixed"} menuShouldScrollIntoView={true}
                                                            className="fib-multi-select bg-gray-v2 view-only"
                                                            classNamePrefix="select"
                                                            isSearchable={true}
                                                            options={question.select}
                                                            value={_.findIndex(question.select, (q: any) => q.value === question.value) !== -1
                                                                ? question.select[_.findIndex(question.select, (q: any) => q.value === question.value)]
                                                                : ""}
                                                            onChange={(e) => formHandle.onUpdateQuestion.onSelectChange(e, itemIndex, 'value', question.answer_type)}
                                                        />
                                                    </>
                                                }

                                            </>}

                                        </div>

                                        <div key={"toggle"} className={"question-toggle"}>
                                            <Button type="button" variant="outline-success"
                                                    onClick={() => formHandle.onUpdateQuestion.onExpand(itemIndex)}
                                                    className={"bs-height"}>
                                                {!question?.is_expand
                                                    ? <FontAwesomeIcon icon={faExpandAlt}/>
                                                    : <FontAwesomeIcon icon={faCompressAlt}/>
                                                }
                                            </Button>
                                        </div>

                                    </div>
                                    : <div key={`question-${itemIndex}`}
                                           className={"mt-2 mb-2 d-flex flex-column question-container "}
                                           id={`question-${itemIndex}`}>
                                        <div className={"question-fields"}>
                                            <Form.Label className={"question-name disabled form-control mb-2"}
                                            >{question.name} {question.is_required &&
                                                <strong className={"text-red"}>{translateV2("LABEL.ASTERISK")}</strong>}
                                            </Form.Label>

                                            <Form.Control className={"form-control mb-2"}
                                                          value={translateV2("LABEL.FIELD.IS_NOT_PUBLIC")}
                                                          type={"text"} disabled={true}
                                            />

                                        </div>
                                    </div>
                            }
                        )}

                </div>
            </div>}

        </>
    );
}

export default ProductTemplateAnswerViewV2;
