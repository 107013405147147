import React from 'react';
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import ModalVideo from 'react-modal-video';
import {hideVideoModal, videoModalState} from "@redux/reducers/videoModalSlice";

const GlobalVideoModal = () => {
    const videoModal = useAppSelector(videoModalState);

    const dispatch = useAppDispatch();

    return (
        <>
            <ModalVideo channel={videoModal.channel}
                        isOpen={videoModal.isShow}
                        youtube={{
                            start: videoModal.start,
                            autoplay: 1,
                            modestbranding: 0,
                        }}
                        allowfullscreen={true}
                        classNames={{
                            modalVideo: 'modal-video ft-modal-video',
                            modalVideoClose: 'modal-video-close',
                            modalVideoBody: 'modal-video-body',
                            modalVideoInner: 'modal-video-inner',
                            modalVideoIframeWrap: 'modal-video-movie-wrap',
                            modalVideoCloseBtn: 'modal-video-close-btn',
                        }}
                        videoId={videoModal.videoID}
                        onClose={() => {
                            dispatch(hideVideoModal())
                        }}/>
        </>
    );
}

export default GlobalVideoModal;
