import React, {useState} from "react";
import {Alert, Image} from "react-bootstrap";
import {translateV2, translateV3} from "@src/helpers";
import {MEDIA_TYPE} from "@src/types";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {importPartnersState} from "@redux/reducers/importPartnersSlice";
import {importPartnersProgressSlice, importPartnersProgressState} from "@redux/reducers/importPartnersProgressSlice";
import _ from "lodash-es";
import {ASSETS} from "@assets/Assets";
import DropZoneSingleImportPartnerV2 from "@v2components/FormElement/DropZoneSingleImportPartnerV2";
import IconDownloadWhiteV2 from "@assets/images/icons/v2/icon_download_white.png";
import IconUploadLightRedSVGV2 from "@assets/images/icons/v2/icon_upload_lightred.svg";
import {APP_ROUTES_PATH} from "@src/routes";

interface I_PartnerFile {
    onChange?(any): void;
}

const PartnerFile = (
    {onChange}: I_PartnerFile) => {

    const dispatch = useAppDispatch();

    const [fileImport, setFileImport] = useState();

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);

    return (
        <>

            <Alert variant={'info'} className={""}>
                <div className={`d-flex flex-row justify-content-start align-items-center `}>

                    <Image src={ASSETS.IconInfoCircleInfo} loading={"lazy"} height={20} decoding={"async"}
                           className={""}
                           onClick={() => {
                           }}
                    />

                    <p className="mb-0 ml-3" style={{color:"#455487"}}>
                        {translateV3("LABEL.PARTNER.IMPORT.TERMS_OF_USE","",
                          {

                          },
                          {
                            allowHtml: true,
                            sanitize: false
                        })}
                    </p>
                </div>
            </Alert>

            <DropZoneSingleImportPartnerV2
                label={
                    `<span class="fib-border-radius text-nowrap">
                        <span class="v3drop-zone-file-btn-icon"></span>                        
                        ${translateV2("LABEL.DROP_ZONE.FILE_UPLOAD")}
                    </span>`
                }
                classNameZone={`m-auto text-center`}
                name={"import_partners_csv"} type={MEDIA_TYPE.PARTNERS_IMPORT}
                file_type={
                    ["text/csv", "application/vnd.ms-excel"]
                }
                reset={() => {
                }}
                change={(e) => {

                    if (_.isFunction(onChange)) {
                        onChange(e)
                    }

                    if (_.size(e?.current)) {

                    } else {

                    }

                    if (e?.list[0]) {
                        // setPartnersFile(e?.list[0])
                        dispatch(importPartnersProgressSlice.actions.chooseFile({
                            file: e?.list[0]
                        }))
                    }
                }}
            />


        </>
    )
}

export default PartnerFile;
