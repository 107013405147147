import {axiosRequest} from '@src/helpers';
import {AUTH} from "@api/Auth";
import {serialize} from 'object-to-formdata';

export const MEDIA_API = {

    uploadToS3: (data: any = {}) => {

        const token = AUTH.getToken();
        const form_data = serialize(data);

        return axiosRequest('/media', {
            method: 'POST',
            body: form_data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token,
            },
            timeout: 120000,
        }, true, false);
    },

}

