import React from 'react';
import {Image, Modal} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {ASSETS} from "@assets/Assets";
import {DEFAULT_APP_CONFIG} from "@src/variables";
import {wizardSlice, wizardState} from "@redux/reducers/wizardSlice";
import _ from "lodash-es";
import {userMetadataState, userState} from "@redux/reducers/userSlice";
import QuickStartWizardV2 from "@v2components/Wizard/QuickStartWizardV2";
import {triggerNextOrCloseModalAsync, userIntroManagerState} from "@redux/reducers/userIntroManagerSlice";

const GlobalWizardModal = () => {
    const user = useAppSelector(userState)
    const userMetadata = useAppSelector(userMetadataState);
    const wizardModal = useAppSelector(wizardState);
    const userIntroManager = useAppSelector(userIntroManagerState);
    const dispatch = useAppDispatch();

    const showModal = () => {
        const bodies = document.getElementsByTagName('body');
        _.map(bodies, (body: HTMLBodyElement) => {
            body.classList.add('modal-uxwizard-open');
        })

    }

    const hideModal = () => {
        dispatch(wizardSlice.actions.hideMessage())

        const bodies = document.getElementsByTagName('body');
        _.map(bodies, (body: HTMLBodyElement) => {
            body.classList.remove('modal-uxwizard-open');
        })
        dispatch(triggerNextOrCloseModalAsync({userMetadata, userIntroManager}))

    }

    const finishWizardModal = () => {

        dispatch(triggerNextOrCloseModalAsync({userMetadata, userIntroManager}))
        /**
         * @todo: I defined wizardChildSlice but I did not remember why
         * wizardChildSlice
         */
        // dispatch(wizardChildSlice.actions.showMessage({
        //     isShow: true,
        //     title: "",
        //     message: "",
        //     type: Message.NEUTRAL
        // }))
    }



    return (
        <>

            {user.is_company_admin && wizardModal.isShow && <Modal id={"Uxwizard"} show={wizardModal.isShow}
                                                                   onShow={showModal}
                                                                   onHide={hideModal} size={"lg"}>

                <Modal.Body>

                    <Image src={ASSETS.IconCloseV2} loading={"lazy"} height={20} decoding={"async"}
                           style={{marginBottom: "0.5em"}} className={"modal-fib-close"}
                           onClick={hideModal}
                    />

                    <div className="text-center pt-5">
                        <Image src={ASSETS.logo} fluid={true}
                               alt={DEFAULT_APP_CONFIG.app_name}
                               className={"mb-2"}
                        />
                    </div>

                    <QuickStartWizardV2
                        onFinish={finishWizardModal}
                        onContinue={finishWizardModal}
                    />

                </Modal.Body>
            </Modal>}


        </>
    );
}

export default GlobalWizardModal;
