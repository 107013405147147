import React, {useEffect, useState} from 'react';
import {Message} from '@src/types';
import _ from "lodash-es";
import {translateV2, validateEmail} from '@src/helpers';
import {ErrorTranslations, SuccessTranslations, Translations} from '@constants/translate';
import Loading from '@components/Loading/Loading';
import {PARTNER_API} from "@api/Partner";
import {Button} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {MAX_EMAIL_INVITE_LENGTH} from "@src/variables";
import {showMessage} from "@redux/reducers/messageSlice";
import GlobalMessage from "@components/Input/Message";

const InvitePartnerV2 = ({onSubmit = {} as any}: any) => {
    const langData = useAppSelector(languageState);
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccessState] = useState(false);
    const dispatch = useAppDispatch();

    const [globalMsg, setGlobalMsg] = useState({
        type: '',
        text: '',
        data: []
    });

    const [inviteData, setInviteData] = useState({
        emails: "",
        message: "",
    })

    const [errorMsg, setErrorMsg] = useState({
        emails: {isValid: true, error: "ERROR.VALIDATE.EMAILS", messageData: {}},
        message: {isValid: true, error: "ERROR.VALIDATE.MESSAGE", messageData: {}},
    })

    const [canSubmit, setSubmitState] = useState(false);
    const [emailError, showEmailError] = useState(false);

    const handleChange = (event: any) => {
        const data = inviteData;
        setGlobalMsg({
            type: '',
            text: '',
            data: []
        });

        if (event.target) {
            const value = event.target?.type === 'checkbox' ? event.target.checked : event.target.value;
            const name = event.target.name;

            if (_.size(value) > MAX_EMAIL_INVITE_LENGTH) {
                alert(translateV2("ERROR.EMAIL_INVITE_LENGTH", "", {length: MAX_EMAIL_INVITE_LENGTH}));
            }

            switch (name) {
                case "plain_emails" : {
                    showEmailError(false);
                    setInviteData({...inviteData, emails: value});
                    data.emails = value;
                    validateInput.emails(value);
                    break;
                }
                case "plain_message" : {
                    setInviteData({...inviteData, message: value});
                    data.message = value;
                    validateInput.message(value);
                    break;
                }
            }
            validateInput.submit(data);
        }
    };

    const validateEmails = (emails: string) => {
        let emailsList: Array<string>;
        emailsList = emails.split(",").map((v: string) => {
            return _.escape(_.trim(v))
        });
        const t = _.every(emailsList, validateEmail);
        return t;
    }

    const validateInput = {
        emails: (emails) => {
            const err = errorMsg;
            if (!emails) {
                err.emails.isValid = false;
                err.emails.error = "ERROR.VALIDATE.EMAILS";
                return !emails;
            }

            const stt = validateEmails(emails)
            err.emails.isValid = true;
            if (!stt) {
                err.emails.isValid = false;
                err.emails.error = "ERROR.EMAIL_WRONG_FORMAT";
                setSubmitState(false);
            }
            setErrorMsg(err);
            return stt;
        },
        message: (message) => {
            const err = errorMsg;
            err.message.isValid = true;
            if (!message) {
                err.message.isValid = false;
                setErrorMsg(err)
                setSubmitState(false);
            }
        },
        submit: ({emails, message}) => {
            const stt = validateEmails(emails);
            if (stt && message) {
                setSubmitState(true);
            }
        }
    };

    const handleSubmit = async (event: any) => {
        try {
            event.preventDefault();
            setLoading(true);
            if (canSubmit) {
                const req = await PARTNER_API.invitePartner({
                    emails: inviteData.emails,
                    message: inviteData.message,
                });
                const {error, data, message} = req;
                if (!error) {
                    setGlobalMsg({
                        type: Message.SUCCESS,
                        text: translateV2(SuccessTranslations["SUCCESS.INVITED"]),
                        data: []
                    })

                    dispatch(showMessage({
                        style: {zIndex: 999999999},
                        isShow: true,
                        type: Message.SUCCESS,
                        title: Message.SUCCESS,
                        message: translateV2("SUCCESS.INVITED"),
                    }))

                    setSuccessState(true);
                    if (_.isFunction(onSubmit)) {
                        await onSubmit(req);
                    }
                } else {
                    setGlobalMsg({
                        type: Message.ERROR,
                        text: (!_.isArray(error) ? error : error[0]) || ErrorTranslations["ERROR.API_ERROR"],
                        data
                    })

                    dispatch(showMessage({
                        style: {zIndex: 999999999},
                        isShow: true,
                        type: Message.ERROR,
                        title: Message.ERROR,
                        message: message
                            ? message
                            : (!_.isArray(error)
                                ? error
                                : _.flatten(_.map(error, (obj,) => _.keys(obj)))),
                        messageData: !_.isArray(error) ? "" : _.transform(_.flatten(_.map(error, (obj,) => {
                            return _.values(obj)
                        })), (result, value, key) => {
                            result[key] = value;
                        }, {})
                    }))

                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        } catch (err) {
            setGlobalMsg({
                type: Message.ERROR,
                text: err || ErrorTranslations["ERROR.API_ERROR"],
                data: []
            })
            setLoading(false)
            dispatch(showMessage({
                style: {zIndex: 999999999},
                isShow: true,
                type: Message.ERROR,
                title: Message.ERROR,
                message: err.message
            }))
        }
    };

    useEffect(() => {
        setInviteData({...inviteData, message: translateV2("LABEL.INVITE_MESSAGE.JOIN_FIBRETRACE_PARTNERS")})
    }, [langData.localize]);

    return (
        <>
            <form id="inviteForm" onSubmit={handleSubmit}>

                {!isSuccess &&
                    <>

                        <label htmlFor="inviteEmail">
                            {translateV2("LABEL.INVITE.PARTNER_EMAIL")} ({MAX_EMAIL_INVITE_LENGTH - (inviteData.emails.length)} {translateV2("LABEL.CHARACTERS_COUNTER")})
                        </label>
                        <textarea id="inviteEmail" className="form-control" maxLength={MAX_EMAIL_INVITE_LENGTH}
                                  onChange={handleChange}
                                  onBlur={() => {
                                      showEmailError(true)
                                  }}
                                  name="plain_emails"
                                  placeholder={translateV2("LABEL.INVITE.PARTNER_EMAIL.PLACEHOLDER")}/>

                        <p className={"mt-3"}>
                            <b className={``}>&#40;{translateV2("LABEL.ASTERISK")}&#41;</b>&nbsp;
                            <i>{translateV2("LABEL.COMMON.CHARACTERS_NOT_ALLOWED", "",
                                {characters: translateV2("LABEL.COMMON.ALLOWED_CHARACTERS")}
                            )}</i>
                        </p>

                        {!errorMsg.emails.isValid && errorMsg.emails.error && emailError &&
                            <GlobalMessage errorClassName={Message.ERROR}
                                           message={translateV2(errorMsg.emails.error)}
                                           messageData={errorMsg.emails.messageData}
                            />}

                        <br/>

                        <label htmlFor="inviteMessage">
                            {translateV2("LABEL.INVITE.PARTNER_TEXTBOX")}
                        </label>

                        <textarea id="inviteMessage" className="form-control" rows={5}
                                  onChange={handleChange} name="plain_message"
                                  value={translateV2(inviteData.message)}
                                  placeholder={translateV2("LABEL.INVITE.PARTNER_TEXTBOX")}/>

                        {!errorMsg.message.isValid && errorMsg.message.error && <div className="global-msg">
                                    <span className="error">
                                        {translateV2(errorMsg.message.error)}</span>
                        </div>}


                    </>
                }

                <div className="mt-3">
                    <Button type="submit" variant={"success"} className="w-100" id="submitInviteBtn"
                            disabled={isLoading || !canSubmit}>
                        {isLoading && <Loading/>}
                        {translateV2(Translations["LABEL.INVITE"])}
                    </Button>
                </div>
            </form>
        </>
    );
}

export default InvitePartnerV2;
