import React, {useEffect, useState} from 'react';
import GlobalModalMessage from "@components/ModalMessage/GlobalMessage";
import GlobalVideoModal from "@components/ModalMessage/GlobalVideoModal";
import GlobalModalConfirmation from "@components/ModalMessage/GlobalConfirmationModal";
import {GlobalCompanySetupModal} from "@components/ModalMessage/GlobalCompanySetupModal";
import GlobalWizardModal from "@components/ModalMessage/GlobalWizardModal";
import GlobalAuditConfirmation from "@components/ModalMessage/GlobalAuditConfirmation";
import GlobalCompanyClaimModal from "@components/ModalMessage/GlobalCompanyClaimModal";
import PartnerImportModal from "@v2components/PartnerImport/PartnerImportModal";
import {E_INTRO_QUEUE, triggerModalAsync, userIntroManagerState} from "@redux/reducers/userIntroManagerSlice";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {updateUserMetaAsync, userMetadataState, userState} from "@redux/reducers/userSlice";
import _ from "lodash-es";
import {createSearchQuery, navigateUrlStr, redirectByQuery} from "@src/helpers";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import AuditClaimDisputeModal from "@v2components/AuditClaimDispute/AuditClaimDisputeModal";
import GlobalPanel from "@components/ModalMessage/GlobalPanel";
import {languageState} from "@redux/reducers/languageSlice";
import {companyMetaState} from "@redux/reducers/companyMetaSlice";
import {APP_ROUTES_PATH} from "@src/routes";
import {AuditTabsV2} from "@src/types";
import {E_COMPANY_METADATA} from "@src/types/metadata";
import GlobalSessionExpiredModal from "@components/ModalMessage/GlobalSessionExpiredModal";
import {companyState} from "@redux/reducers/companySlice";
import GlobalSendAuditConfirmation from "@components/ModalMessage/GlobalSendAuditConfirmation";
import GlobalOnlineServiceBlockModal from "@v2components/Modal/CompanyInfoPaymentModal";


const GlobalModalManager = () => {

    const dispatch = useAppDispatch();

    const lang = useAppSelector(languageState);
    const user = useAppSelector(userState);
    const userMetadata = useAppSelector(userMetadataState);
    const userIntroManager = useAppSelector(userIntroManagerState);
    const userMetadataIntegers = _.mapValues(userMetadata, _.toInteger);
    const company = useAppSelector(companyState);
    const companyMetaStore = useAppSelector(companyMetaState)

    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [itemsQueue, setItemsQueue] = useState<Array<E_INTRO_QUEUE>>([]);

    useEffect(() => {
        if (userMetadata.initialized) {

            /**
             * Redirect to My Audits > Claim Dispute first, then it will show intro when user access dashboard
             */
            if (companyMetaStore.initialized && _.toInteger(companyMetaStore.is_claim_dispute_required)) {
                navigate(navigateUrlStr({
                    pathname: APP_ROUTES_PATH.MY_AUDITS,
                    search: createSearchQuery({t: AuditTabsV2.CLAIM_DISPUTE})
                }))

                dispatch(updateUserMetaAsync({
                    object: "COMPANY",
                    key: E_COMPANY_METADATA.IS_CLAIM_DISPUTE_REQUIRED,
                    value: "0"
                }))

            } else {

                /**
                 * Global audit confirmation. Eg:
                 * {{URL}}/register?review_audit={{uuid}}
                 * Will be added to modalManager later
                 */
                if (!userIntroManager.showing && searchParams.get('review_audit')) {
                    dispatch(triggerModalAsync({item: E_INTRO_QUEUE.AUDIT_CONFIRMATION}))
                }

                /**
                 * @todo:
                 * Do not need to wait until usermeta done fetching, try to check another store for sure
                 */
                if (userMetadata.initialized && !userIntroManager.showing) {

                    if ((searchParams.get('r') || searchParams.get('t'))) {
                        const {redirect, search, path} = redirectByQuery();
                        if (redirect) navigate(navigateUrlStr({pathname: path, search}))
                    }

                }

            }

        }

    }, [userMetadata, companyMetaStore]);

    return (
        <>

            <GlobalCompanyClaimModal/>

            <GlobalCompanySetupModal/>

            {
                userMetadata.initialized && company.company_package_payment.import_sites
                    ? <PartnerImportModal/>
                    : null
            }

            <GlobalWizardModal/>

            <GlobalAuditConfirmation/>

            <GlobalSendAuditConfirmation/>

            <GlobalModalConfirmation/>

            <AuditClaimDisputeModal/>

            <GlobalPanel/>

            <GlobalModalMessage/>

            <GlobalVideoModal/>

            <GlobalSessionExpiredModal/>

            <GlobalOnlineServiceBlockModal/>

        </>
    );
}

export default GlobalModalManager;
