import {translateV2} from "@src/helpers";
import React, {useEffect} from "react";
import {Modal} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {companyState} from "@redux/reducers/companySlice";
import CompanyInfoPayment from "@components/CompanyInfo/CompanyInfoPayment";
import {companyPaymentInfoModalSlice, companyPaymentInfoModalState} from "@redux/reducers/companyPaymentInfoModalSlice";

const GlobalOnlineServiceBlockModal = ({type, data, onSubmit}: any) => {

  const companyStore = useAppSelector(companyState);
  const companyInfoModal = useAppSelector(companyPaymentInfoModalState)
  const dispatch = useAppDispatch();

  useEffect(() => {

  }, [companyStore]);

  return (
    <>
      <Modal
        backdrop="static"
        centered={true}
        style={{zIndex: 9999990002}}
        size={"lg"}
        show={companyInfoModal.isShow}
        onHide={() => {
          dispatch(companyPaymentInfoModalSlice.actions.hide())
        }}>
        <Modal.Header closeButton>
          <Modal.Title>{translateV2("LABEL.COMPANY_INFORMATION")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CompanyInfoPayment
            onLeave={({block}) => {
            }}
            onChange={(com) => {
            }}
            onSubmit={(com) => {
              dispatch(companyPaymentInfoModalSlice.actions.hide())
            }}
          />
        </Modal.Body>
      </Modal>

    </>
  )
}

export default GlobalOnlineServiceBlockModal