import React from 'react';
import {translateV2} from '@src/helpers';
import {Button, Modal} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {hideMessage, messageState} from "@redux/reducers/messageSlice";
import GlobalMessage from "@components/Input/Message";

const GlobalModalMessage = () => {
    const message = useAppSelector(messageState);
    const {style = {}} = message;
    const dispatch = useAppDispatch();

    const handleMessageModal = {
        close: () => {
            try {
                dispatch(hideMessage());
            } catch (e) {
                console.error(e)
            }
        }
    }


    return (
        <>
            {message.isShow &&
                <Modal centered={message?.centered} style={style} show={message.isShow}
                       onHide={handleMessageModal.close} size={message.size}>
                    <Modal.Header closeButton>
                        <Modal.Title>{translateV2(message.title)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {message.message &&
                            <GlobalMessage errorClassName={message.type}
                                           message={message.message}
                                           messageData={message.messageData}/>}
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            !message?.cancelButton
                                ? null
                                : <Button variant={message?.cancelButton.variant} onClick={handleMessageModal.close}>
                                    {translateV2(message?.cancelButton.label)}
                                </Button>

                        }

                    </Modal.Footer>
                </Modal>}
        </>
    );
}

export default GlobalModalMessage;
