import React, {useEffect, useState} from 'react';
import {translateV2} from '@src/helpers';
import {Button, Modal} from "react-bootstrap";
import GlobalMessage from "@components/Input/Message";
import {COMMON_POPUP, Message} from "@src/types";
import _ from "lodash-es";
import FibreModalCloseButton from "@v2components/Modal/FibreModalCloseButton";

const ModalConfirmation = ({
                               children,
                               isShow = false,
                               title = "",
                               message = "",
                               messageData = {},
                               type = Message.NEUTRAL as Message,
                               size = "lg",
                               onSubmit = {} as any,
                               onClose = {} as any,
                               style = {} as any,
                               modalProps = {},
                               confirmButton = {
                                   visible: true,
                                   label: "LABEL.YES",
                                   variant: "success"
                               } as any,
                               cancelButton = {
                                   visible: true,
                                   label: "LABEL.NO",
                                   variant: "outline-dark"
                               } as any,
                               closeIconVisible = false,

                           }: any) => {

    const [modal, setModal] = useState({
        isShow,
        title,
        message,
        type,
    } as COMMON_POPUP)


    const handleMessageModal = {
        submit: () => {
            if (_.isFunction(onSubmit)) onSubmit()
        },
        close: () => {
            if (_.isFunction(onClose)) onClose()
        }
    }

    useEffect(() => {
        setModal({...modal, isShow, title, message, type})
    }, [isShow, title, message, type])

    return (
        <>
            {isShow && <Modal style={style} show={isShow} onHide={handleMessageModal.close} size={size} {...modalProps}>

                <Modal.Header>

                    <Modal.Title>{translateV2(title)}</Modal.Title>

                    {closeIconVisible && <FibreModalCloseButton onClick={handleMessageModal.close}/>}

                </Modal.Header>

                <Modal.Body>
                    {message &&
                        <GlobalMessage errorClassName={type} message={message} messageData={messageData}/>}

                    {children}
                </Modal.Body>
                <Modal.Footer>
                    {
                        !confirmButton || confirmButton?.visible
                            ?
                            <Button variant={confirmButton?.variant || "success"}
                                    onClick={handleMessageModal.submit} className={"text-capitalize"}>
                                {_.upperFirst(translateV2(confirmButton?.label || "LABEL.YES"))}
                            </Button>
                            : null
                    }

                    {
                        !cancelButton || cancelButton?.visible
                            ?
                            <Button variant={cancelButton?.variant || "outline-dark"}
                                    onClick={handleMessageModal.close} className={"text-capitalize"}>
                                {_.upperFirst(translateV2(cancelButton?.label || "LABEL.NO"))}
                            </Button>
                            : null
                    }


                </Modal.Footer>
            </Modal>}
        </>
    );
}

export default ModalConfirmation;
