import React, {useEffect, useState} from 'react';
import {isEmptyValues, navigateUrlStr, translateV2, validate} from '@src/helpers';

import {COMPONENT_STATUS, InputRules, Message, USER_ACTION} from '@src/types';
import erroricon from '@assets/images/icons/error-msg.png';
import {SITE_API} from "@api/Site";
import GoogleMapReact from 'google-map-react';
import _ from "lodash-es";
import {GG_KEY} from "@constants/variables";
import {useAppDispatch} from "@redux/hooks/hooks";

import {DEFAULT_COMPONENT_SETTINGS} from "@src/variables";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faMap, faMapPin} from "@fortawesome/free-solid-svg-icons";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {RouterInterface} from "@src/types/router";

const SiteCreateWizardV2 = ({id, data, siteMapVisible, onToggleMap, onHideMap, onShowMap, onChange, onSubmit}: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const siteFormDefault = {
        name: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: true,
            disabled: false,
            error: ''
        },
        manual: {
            value: false,
            rules: [],
            isValid: true,
            error: ''
        },
        address: {
            value: '',
            rules: [InputRules.REQUIRED, InputRules.ADDRESS],
            isValid: true,
            disabled: false,
            error: ''
        },
        country: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: ''
        },
        lat: {
            value: '' as any,
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: ''
        },
        long: {
            value: '' as any,
            rules: [InputRules.REQUIRED],
            isValid: true,
            error: ''
        }
    };
    const [siteForm, setSiteForm] = useState(JSON.parse(JSON.stringify({...siteFormDefault})));

    const [globalMsg, setGlobalMsg] = useState({
        type: '',
        text: ''
    });

    const [confirmPos, setConfirmPos] = useState({
        lat: "", lng: "",
        address: "",
        country: ""
    } as any);
    const [site, setSite] = useState({} as any);
    const [mapInstance, setMap] = useState({} as any);
    const [mapInstances, setMaps] = useState({} as any);
    const [marker, setMarker] = useState({} as any);
    const [geocoder, setGeoCoder] = useState({} as any)
    // const [infowindow, setInfoWindow] = useState({} as any)
    const [placeService, setPlaceService] = useState({} as any)
    const [markers, setMarkers] = useState([] as any);
    const [compSettings, setCompSettings] = useState({...DEFAULT_COMPONENT_SETTINGS});
    // set FiberTrace as default
    const center = {
        lat: 1.285631,
        lng: 103.8517833
    }

    const inputHandle = (event: any) => {
        try {
            const f = siteForm;
            const name: any = event.target.name;
            const type = event.type;
            const value = event.target?.type === 'checkbox' ? event.target.checked : event.target.value;
            const {error, value: formattedValue} = validate(value, f[name].rules,);

            if (type === 'change') {
                if (_.has(f, name) && value !== f[name].value) {

                    if (event.target?.type === 'checkbox') {

                    }

                    f[name].value = formattedValue || value || "";
                    f[name].isDirty = true;
                    f[name].isValid = !error && f[name].isDirty;
                    f[name].error = "";

                }
            }

            if (type === 'blur') {
                f[name].error = f[name].isDirty ? (error || "") : "";
            }

            if (name === 'address' && value) {
                mapHandle.onPlacesChanged(value, false)
            }

            formHandle.validate({...siteForm, ...f});

        } catch (e) {
            console.error(e)
        }
    }

    const formHandle = {
        validate: (data: any = {}) => {
            let obj = {...siteForm, ...data};
            const tmpComp = {
                ...compSettings,
                isValid: _.every(obj, (v) => v.isValid),
                object: {
                    name: obj.name.value,
                    address: obj.address.value,
                    country: obj.country.value || null,
                    lat: obj.lat.value || null,
                    long: obj.long.value || null,
                },
                form: {...obj}
            }
            setSiteForm({...obj});
            setCompSettings({...tmpComp})
            if (_.isFunction(onChange)) onChange({...tmpComp})
        },
        reset: () => {
            setSiteForm({...siteFormDefault});
        },
        onLoaded: () => {
            setSiteForm({
                ...siteForm,
                name: {...siteForm.name, disabled: false},
                address: {...siteForm.address, disabled: false},
            })
            setCompSettings({...compSettings, status: COMPONENT_STATUS.READY})
        },
        onChange: inputHandle,
        onBlur: inputHandle,
        onSubmit: async (event: any, red?: RouterInterface) => {
            setGlobalMsg({type: '', text: ''});
            event.preventDefault();
            let canSubmit = true;
            const curForm = {...siteForm};

            const keys = Object.keys(curForm) as any[];

            for (const key of keys) {
                const {error, isValid} = validate(curForm[key].value, curForm[key].rules,);
                curForm[key].error = error;
                if (!isValid) {
                    canSubmit = false;
                }
            }

            setSiteForm({
                ...siteForm,
                ...curForm
            });

            if (canSubmit) {
                try {
                    let data = _.omitBy({
                        name: siteForm.name.value,
                        address: siteForm.address.value,
                        country: siteForm.country.value,
                        lat: siteForm.lat.value,
                        long: siteForm.long.value
                    }, isEmptyValues);

                    let req;
                    if (id) {
                        req = await SITE_API.updateSite(id, data);
                    } else {
                        req = await SITE_API.createSite(data);
                    }

                    if (req.error) {
                        setGlobalMsg({
                            type: Message.ERROR,
                            text: req.error
                        });
                    } else {
                        if (!red && _.isFunction(onSubmit)) {
                            onSubmit(req);
                            modalHandle.close();
                        }
                        setSiteForm({...siteFormDefault})
                        if (red) navigate(navigateUrlStr({pathname: red.path, search: red.search}))
                    }
                } catch (error) {
                    setGlobalMsg({
                        type: Message.ERROR,
                        text: error
                    });
                }
            }
        }
    }

    const modalHandle = {
        close: () => {
            formHandle.reset();
            setMarkers([])
        }
    }

    const mapHandle = {
        googleMapApiLoaded: (map, maps) => {
            try {
                setMap(map);
                setMaps(maps);

                setGeoCoder(new maps.Geocoder());
                setPlaceService(new maps.places.PlacesService(map));
                // setInfoWindow(new maps.InfoWindow());
                formHandle.onLoaded();
            } catch (e) {
                console.error(e)
            }
        },
        createMarker: ({lat, lng, label = ""}) => {
            try {
                let opts = {
                    position: new mapInstances.LatLng(lat, lng),
                    map: mapInstance,
                } as any;

                if (label) opts.label = {
                    text: label,
                    color: "#000000",
                    fontWeight: "bold",
                    fontSize: "14px",
                }
                let m = new mapInstances.Marker(opts);
                markers.push(m);

                setMarker(m)
                setMarkers([...markers, m])

                return m;
            } catch (e) {
                console.error(e)
            }
        },
        setAddressLngLat: async ({lat, lng, setAddress = true, setCountry = true}) => {
            try {
                let f = {...siteForm}, {address, country} = await mapHandle.getGeoData({lat, lng});
                f.long.value = lng;
                f.lat.value = lat;
                if (setAddress && address) f.address.value = address;
                if (setCountry && country) f.country.value = country;
                setSiteForm({...siteForm, ...f})
            } catch (e) {
                console.error(e)
            }
        },
        getGeoData: async ({lat, lng}) => {
            let address = "", country = "";
            try {
                const {results} = await geocoder.geocode({location: {lat, lng}})
                if (results && results[0]) {
                    const {formatted_address, address_components} = results[0]
                    const ct = _.find(address_components, (v: any, k: number) => {
                        return _.includes(v.types, "country");
                    })
                    if (formatted_address) address = formatted_address;
                    if (ct) country = ct.long_name;
                }
            } catch (e) {
                console.error(e)
            }
            return {address, country}
        },
        setMarkerCenter: ({lat, lng, label = ""}) => {
            try {
                mapInstance.setCenter({lat, lng})
                marker.setPosition({lat, lng});
                marker.setLabel(label);
            } catch (e) {
                console.error(e)
            }
        },
        onClick: async ({x, y, lat, lng, event}) => {
            try {
                if (_.size(markers)) {
                    if (mapInstance && marker) {
                        mapHandle.setMarkerCenter({lat, lng});
                    }
                } else {
                    mapHandle.createMarker({lat, lng, label: ""})
                    mapInstance.setCenter({lat, lng})
                }
                await mapHandle.setAddressLngLat({lat, lng});
            } catch (e) {
                console.trace(e)
            }
        },
        onDragEnd: async (map) => {
            try {
                const pos = map.getCenter().toJSON();
                await mapHandle.setAddressLngLat(pos);
                mapHandle.setMarkerCenter(pos)
            } catch (e) {
                console.error(e)
            }
        },
        onPlacesChanged: (address, setAddress = false) => {
            try {
                let request = {
                    query: _.escape(address),
                    fields: ['name', 'geometry'],
                };
                placeService.findPlaceFromQuery(request, async (results, status) => {
                    if (status === mapInstances.places.PlacesServiceStatus.OK) {
                        const r = results[0].geometry.location;
                        let pos = {
                            lng: r.lng(), lat: r.lat(), label: "",
                            setAddress: setAddress
                        }
                        if (_.size(markers)) {
                            mapHandle.setMarkerCenter(pos)
                        } else {
                            mapHandle.createMarker(pos)
                            mapInstance.setCenter(pos)
                        }

                        await mapHandle.setAddressLngLat(pos);
                    }
                });

            } catch (e) {
                console.error(e)
            }
        },
    }

    const siteHandle = {
        getSiteByID: async (id, cancelToken = {}) => {
            try {
                if (id) {
                    const {data} = await SITE_API.getSiteByID(id, cancelToken);
                    if (data) {
                        setSite(data)
                        const {name = "", address = "", country = "", latitude = "", longitude = ""} = data;
                        setSiteForm({
                            ...siteForm,
                            name: {...siteForm.name, ...{value: name, disabled: false}},
                            address: {...siteForm.address, ...{value: address, disabled: false}},
                            country: {...siteForm.country, value: country},
                            lat: {...siteForm.lat, value: latitude},
                            long: {...siteForm.long, value: longitude},
                            manual: {...siteForm.manual, value: true},
                        });
                    }
                } else {
                    // formHandle.reset();
                }
            } catch (error) {
                console.trace(error)
            }
        },
    }

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        (async () => {
            const {id} = data;
            if (id) await siteHandle.getSiteByID(id, source.token);
            else {
                let f = {...siteForm};
                _.forIn(data, (v: any, k: any) => {
                    if (_.has(f, k)) {
                        f[k].value = v;
                    }
                })
                setSiteForm({...f})
            }
        })();
        return () => {
            source.cancel("stop getting site detail")
        }
    }, [data]);

    useEffect(() => {
        (async () => {
            if (!_.isEmpty(site)) {
                const pos = {
                    lat: site.latitude,
                    lng: site.longitude,
                    label: site.name
                };
                mapHandle.createMarker(pos)
                mapInstance.setCenter(new mapInstances.LatLng(pos.lat, pos.lng))
                formHandle.onLoaded();
            }
        })();
    }, [site, mapInstance, mapInstances]);

    return (
        <>
            <div className={"site-wizard-container position-relative"}>

                {!isMobile &&
                    <OverlayTrigger overlay={

                        <Tooltip id={`show-map`}>
                            {translateV2("LABEL.SHOW_MAP")}/{translateV2("LABEL.HIDE_MAP")}
                        </Tooltip>} defaultShow={false}>

                        <FontAwesomeIcon className={"tooltip-helper cursor-pointer"}
                                         icon={faMap}
                                         onClick={() => {
                                             if (_.isFunction(onToggleMap)) onToggleMap()
                                         }}/>

                    </OverlayTrigger>}

                <dl>
                    <dd>
                        <div className="input-group">
                            <input type="text" className="form-control"
                                   value={siteForm.name.value} placeholder={translateV2("LABEL.NAME.TOOLTIP_HELPER.EG")}
                                   onChange={formHandle.onChange}
                                   onBlur={formHandle.onBlur}
                                   name="name"/>
                        </div>
                        {siteForm.name.error && <div className="global-msg">
                            <span className="error">{translateV2(siteForm.name.error)}</span>
                        </div>}
                    </dd>
                    <dd>
                        <div className="input-group">
                            <input type="text" style={{marginRight: isMobile ? "25px" : "inherit"}}
                                   className="form-control" value={siteForm.address.value}
                                   placeholder={translateV2("LABEL.ADDRESS.TOOLTIP_HELPER.EG")}
                                   onFocus={() => {
                                       if (_.isFunction(onShowMap)) onShowMap()
                                   }}
                                   onChange={formHandle.onChange}
                                   onBlur={formHandle.onBlur}
                                   name="address"/>

                            {isMobile && <OverlayTrigger overlay={

                                <Tooltip id={`show-map`}>
                                    {translateV2("LABEL.SHOW_MAP")}/{translateV2("LABEL.HIDE_MAP")}
                                </Tooltip>} defaultShow={false}>

                                <FontAwesomeIcon className={"tooltip-helper cursor-pointer"}
                                                 style={{
                                                     top: '50%',
                                                     right: 0
                                                 }}
                                                 icon={faMap}
                                                 onClick={() => {
                                                     if (_.isFunction(onToggleMap)) onToggleMap()
                                                 }}/>

                            </OverlayTrigger>}

                        </div>
                        {siteForm.address.error && <div className="global-msg">
                            <span className="error">{translateV2(siteForm.address.error)}</span>
                        </div>}
                    </dd>

                    {(!_.isEqual(siteForm.address.value, confirmPos.address)) && confirmPos.lat &&
                        <dt className={""}>
                            <p className="action-texts font-italic fw-500 d-flex align-items-center">{confirmPos.address}&#46; {translateV2("LABEL.CONFIRM_SITE_ADDRESS")}&#46;&nbsp;&nbsp;
                                <span className={"action-texts-cta text-uppercase fw-700 cursor-pointer btn-green"}
                                      onClick={async () => {
                                          let f = {...siteForm};
                                          const addressValidator = validate(confirmPos.address, [InputRules.REQUIRED, InputRules.ADDRESS]);

                                          f.long.value = confirmPos.lng;
                                          f.lat.value = confirmPos.lat;
                                          f.country.value = confirmPos.country;

                                          f.address.isValid = !addressValidator.error
                                          f.address.value = addressValidator.value;
                                          f.address.error = addressValidator.error;

                                          setConfirmPos({lat: "", lng: "", address: "", country: ""})

                                          formHandle.validate({...siteForm, ...f});
                                      }}>{translateV2("LABEL.YES")}</span>
                                {/*&#45; <span*/}
                                {/*    className={"action-texts-cta text-green text-uppercase fw-700 cursor-pointer"}*/}
                                {/*    onClick={() => {*/}

                                {/*    }}>{translateV2("LABEL.NO")}</span>*/}
                            </p>
                        </dt>}

                    <dd className={!siteMapVisible ? "d-none" : ""}>
                        <div style={{height: '30vh', width: '100%'}}>
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: GG_KEY,
                                    libraries: ['places', 'geometry', 'drawing', 'visualization']
                                }}
                                options={{
                                    panControl: false,
                                    mapTypeControl: false,
                                    scrollwheel: false,
                                    styles: [{stylers: [{'visibility': 'on'}]}]
                                }}
                                defaultCenter={center}
                                center={center}
                                defaultZoom={18}
                                yesIWantToUseGoogleMapApiInternals={true}
                                onGoogleApiLoaded={({map, maps}) => mapHandle.googleMapApiLoaded(map, maps)}
                                onClick={async ({x, y, lat, lng, event}) => {
                                    try {
                                        let f = {...siteForm};
                                        if (_.size(markers)) {
                                            if (mapInstance && marker) {
                                                mapHandle.setMarkerCenter({lat, lng});
                                            }
                                        } else {
                                            mapHandle.createMarker({lat, lng, label: ""})
                                            mapInstance.setCenter({lat, lng})
                                        }

                                        const {address = "", country = ""} = await mapHandle.getGeoData({lat, lng});
                                        f.lat.value = lat;
                                        f.long.value = lng;
                                        f.country.value = country;

                                        setSiteForm({...f});
                                        setConfirmPos({...confirmPos, lat, lng, address, country})
                                        formHandle.validate({...f});
                                    } catch (e) {
                                        console.error(e)
                                    }
                                }}
                                onDragEnd={async (map) => {
                                    try {
                                        let f = {...siteForm};
                                        const pos = map.getCenter().toJSON();
                                        pos.setAddress = false;

                                        if (_.size(markers)) {
                                            if (mapInstance && marker) {
                                                mapHandle.setMarkerCenter({...pos});
                                            }
                                        } else {
                                            mapHandle.createMarker({...pos, label: ""})
                                            mapInstance.setCenter({...pos})
                                        }

                                        const {address = "", country = ""} = await mapHandle.getGeoData({...pos});
                                        f.lat.value = pos.lat;
                                        f.long.value = pos.lng;
                                        f.country.value = country;

                                        setSiteForm({...f});
                                        setConfirmPos({...confirmPos, ...pos, address, country})
                                        formHandle.validate({...f});
                                    } catch (e) {
                                        console.error(e)
                                    }
                                }
                                }
                            >
                            </GoogleMapReact>
                        </div>
                    </dd>


                </dl>
            </div>
        </>
    );
}

export default SiteCreateWizardV2;
