import React, {useEffect, useState} from 'react';
import {Image} from "react-bootstrap";
import {ASSETS} from "@assets/Assets";
import {DEFAULT_PANEL_SETTINGS} from "@src/variables";
import _ from "lodash-es";
import {ProductPageTabs} from "@src/types";
import {translateV2} from "@src/helpers";
import LoadingV2 from "@v2components/Loading/LoadingV2";


const PanelV2 = ({
                     show = false, loading = true, meta = {} as any,
                     header = "" as any,
                     children,
                     onClose = {} as any
                 }: any) => {


    const [panelState, setPanelState] = useState({
        loading: true,
        show: false,
        meta: {
            component: ProductPageTabs.PRODUCTS
        } as any
    })
    const [object, setCurrentObject] = useState({} as any)
    const [processingMetadata, setProcessingMetadata] = useState({...DEFAULT_PANEL_SETTINGS});

    const handleState = {
        reset: () => {
            try {
                setProcessingMetadata({...processingMetadata, ...DEFAULT_PANEL_SETTINGS})
                setCurrentObject({})
                if (_.isFunction(onClose)) onClose();
            } catch (e) {
                console.error(e)
            }
        },
        open: async (data: any = {}) => {
            document.getElementsByTagName("body")[0].classList.add('panel-open');
        },
        close: () => {
            document.getElementsByTagName("body")[0].classList.remove('panel-open');
            handleState.reset();
        },
        change: async (data: any) => {
            try {

            } catch (e) {
                console.error(e)
            }
        },
        submit: async (e: any = {}) => {
            try {

            } catch (e) {
                console.error(e)
            }
        },
    }

    useEffect(() => {
        (async () => {
            setPanelState({...panelState, show})
            if (show) {
                await handleState.open();
            } else {
                await handleState.close();
            }
        })();
    }, [show])

    return (
        <>

            <div className={`fib-panel-container ${panelState.show ? " show " : ""}`}>


                <Image src={ASSETS.IconCloseV2} loading={"lazy"} height={20} decoding={"async"}
                       style={{marginBottom: "0.5em"}} className={"fib-panel-close"}
                       onClick={handleState.close}
                />

                <div className="fib-panel-inner">

                    {(!_.isEmpty(header) && _.isString(header)) &&
                        <div className="fib-panel-header"><h3>{translateV2(header)}</h3></div>}

                    <div className="fib-panel-body">
                        {(!_.isEmpty(header) && _.isString(header)) && <hr/>}
                        {children}
                    </div>

                </div>

            </div>

        </>
    );
}

export default PanelV2;
