import React, {useEffect, useState} from 'react';
import _ from "lodash-es";
import {DEFAULT_FILE} from "@src/variables";
import {MEDIA_TYPE} from "@src/types";
import {Button, Image} from "react-bootstrap";
import {fibLogger, translateV2} from "@src/helpers";
import {ASSETS} from "@assets/Assets";
import {PARTNER_API} from "@api/Partner";

const DropZoneSingleImportPartnerV2 = ({
                                           label,
                                           options,
                                           icon,
                                           disabled,
                                           isloading = false,
                                           val,
                                           className,
                                           classNameZone = "",
                                           change,
                                           name, type = "" as MEDIA_TYPE,
                                           file_type = ["image/"],
                                           customClass,
                                           controlClass = ""

                                       }: any) => {

    let [file, setFile] = useState({...DEFAULT_FILE({ref: React.useRef({} as any), type: type})})
    let [fileType, setFileType] = useState("");

    const fileHandle = {
        onFileReset: () => {
            try {
                setFile({...DEFAULT_FILE({ref: React.createRef(), type: type})});
                if (_.isFunction(change)) change({...DEFAULT_FILE({ref: React.createRef(), type: type})})
            } catch (e) {
                console.error(e)
            }
        },
        onFileChange: (e: any) => {
            try {
                console.log("file", e.target.files);

                let m = {...file}, f = e.target.files[0]
                _.map(e.target.files, (f, k) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(f);
                    if (f) {
                        setFileType(f.type)
                        if (file_type.indexOf(f.type) === -1) {
                            m.ref.current.value = "";
                            setFile({...DEFAULT_FILE({ref: React.createRef(), type: type})})
                        } else {
                            m.hasFile = true;
                            m.list[k] = f;
                            setFile({...file, ...m});

                            reader.onloadend = function (e) {
                                m.current[k] = [reader.result]
                                setFile({...file, ...m});
                                if (_.isFunction(change)) change({...file})
                            }

                        }
                    }
                });
            } catch (e) {
                console.error(e)
            }
        }
    }

    const onClickDownloadCSV = async (e) => {
        try {
            e.preventDefault();

            const {data} = await PARTNER_API.getPartnersImportTemplate();

            if (data) {
                const {file: FilePartnersImportUrl} = data;

                const csvFileUrl = FilePartnersImportUrl;
                const downloadLink = document.createElement('a');

                // downloadLink.download = FilePartnersImportName;
                downloadLink.href = csvFileUrl;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        fibLogger({file})
    }, [file])

    return (
        <div className={`v3drop-zone-container ${_.isEmpty(file.current) ? "" : " has-file "}`}>

            <div className="v3drop-zone-inner">

                <div className={`v3drop-zone ${classNameZone}`}>

                    {_.isEmpty(file.current)
                        ? <>
                            <div className={`v3drop-zone-file-container`}>
                                <div className="v3drop-zone-file-inner-top">
                                    <label htmlFor={name}>
                                        <input ref={file.ref} type="file" name={name} id={name}
                                               onChange={fileHandle.onFileChange}
                                               className={"v3-input-file"}/>
                                        <p className={`fibre-border-radius v3drop-zone-file-btn ${fileType && file_type.indexOf(fileType) === -1 ? " has-error " : ""}`}
                                           dangerouslySetInnerHTML={{
                                               __html: `${label} 
                                    ${fileType && file_type.indexOf(fileType) === -1
                                                   ? `<br/><span class="">${translateV2("LABEL.FILE_MIMES_ONLY", '', {
                                                       '0': file_type,
                                                       '1': ""
                                                   })}${translateV2("LABEL.CHOOSE_AGAIN")}</span>`
                                                   : ""
                                               }`
                                           }}/>
                                    </label>
                                </div>

                                <div className="v3drop-zone-file-inner-middle text-center">
                                    <span>or</span>
                                </div>

                                <div className="v3drop-zone-file-inner-bottom ">
                                    <Button
                                            className={"btn-white fw-400 pl-4 pr-4 fibre-border-radius btn-fib-transparent-black"}
                                            onClick={async (e) => {
                                                await onClickDownloadCSV(e)
                                            }}>
                                        <Image width={16} height={16}
                                               src={ASSETS.IconDownloadBlackV2}
                                               alt={"LABEL.PARTNER.IMPORT.DOWNLOAD_SAMPLE_CSV"}
                                               className={"mr-2"}/>
                                        {translateV2("LABEL.PARTNER.IMPORT.DOWNLOAD_SAMPLE_CSV")}
                                    </Button>
                                </div>

                            </div>
                        </>
                        : <>
                            <Button variant={"outline-dark"} className={"v3drop-zone-reset"}
                                    style={{borderColor: "#B7B7B7"}}
                                    onClick={fileHandle.onFileReset}
                            >
                                {translateV2("LABEL.PARTNER.IMPORT.REPLACE_FILE")}
                            </Button>

                            <div className="v3drop-zone-preview">
                                {
                                    file.current.map((imgData: any, itemIndex: any) =>
                                            <span key={itemIndex}>
                                                {file.list[itemIndex].type.indexOf('image/') !== -1
                                                    ? <img key={itemIndex} src={imgData} alt=""
                                                           className={"img-responsive"}
                                                           style={{maxHeight: "100px", width: "auto"}}/>
                                                    : <>
                                                        <span>{file.list[itemIndex].name}</span>
                                                    </>
                                                }
                            </span>
                                    )
                                }
                            </div>
                        </>
                    }

                </div>

            </div>

        </div>
    );
}

export default DropZoneSingleImportPartnerV2;
