export const ENV = {
  MODE: "development",
  SKIP_PREFLIGHT_CHECK: true,
  DISABLE_ESLINT_PLUGIN: true,
  FAST_REFRESH: false,
  REACT_APP_API_URL: "https://admin-webapp-dev.fibretrace.io/api/v1",
  PAYPAL_CLIENT_ID: "AY5w0NYPbXzc0iDccVTWSdedFrZhhphyn9eNDdFetkAJw_RMYeNnetzMA0Xd4M5v4yaHLqlWXKScSga2",
  GENERATE_SOURCEMAP: false,
  ZENDESK_KEY: "3743c967-e14b-49cc-a77e-cbb36b3bf99d",
  LOG_ROCKET_ID: "h4kpfe/fibretrace",
  TINYMCE_API_KEY: "j6byy6jr6wunrvecjk16fhip7ejctvc5lutjb490b7jqhjuh",
  GOOGLE_RECAPTCHA_V2_SITE_KEY: "6LexFaIgAAAAAJjIGsI3_zkSngN3-TDUAUCVEMas",
  REPORT_MODULE: false,
  REPORT_URL: "https://report-webapp-dev.fibretrace.io",
  API_URL: "https://admin-webapp-dev.fibretrace.io/api/",
  API_URL_ALT: "https://admin-webapp-dev.fibretrace.io/api/",
  API_VERSION: "v1",
  API_VERSION_ALT: "v2",
  CONTACT_URL: "https://www.fibretrace.io/pages/contact-us",
  FIBRETRACE_V2_SHOPIFY_URL: "https://shopify-webapp-dev.fibretrace.io",
  SOCKET_URL: "https://utilities-dev.fibretrace.io",
}