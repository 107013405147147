import {fibLogger} from "@src/helpers";
import {AuditTabsV2, PROCESS_AUDIT} from "@src/types";
import _ from "lodash-es";

export const onStartNotifications = async (bluetoothCharacteristic: any) => {
    try {
        fibLogger('Starting Notifications...');
        await bluetoothCharacteristic.startNotifications();
        fibLogger('> Notifications started');
    } catch (error) {
        fibLogger('Argh! ' + error, "error");
        // $(element).parent().parent().addClass("error")
        //         .attr("title", "Notification error ! Please check it and try again later");
        // hideSpinner($("#fibretraceAuditForm"));
    }
}

export const connectDeviceAndCacheCharacteristics = async ({
                                                               bluetoothDevice,
                                                               bluetoothCharacteristic,
                                                               serviceUuid,
                                                               characteristicUuid,
                                                               handleDataChanged = () => {
                                                               }
                                                           }: any) => {
    if (bluetoothDevice.gatt.connected && bluetoothCharacteristic) {
        return;
    }

    fibLogger('Connecting to GATT Server...');
    const server = await bluetoothDevice.gatt.connect();

    fibLogger('Getting Service...');
    const service = await server.getPrimaryService(serviceUuid);

    fibLogger('Getting Characteristic...');
    bluetoothCharacteristic = await service.getCharacteristic(characteristicUuid);

    await onStartNotifications(bluetoothCharacteristic);
    bluetoothCharacteristic.addEventListener('characteristicvaluechanged', handleDataChanged);

    return bluetoothCharacteristic;

    // bluetoothCharacteristic.addEventListener('characteristicvaluechanged', handleDataChanged);
    // $(element).parent().parent().removeClass("error").css("background-color", "#28d8a1")
    //         .attr("title", "Connected! Ready to scan.");
    // hideSpinner($("#fibretraceAuditForm"));
}

export const requestDevice = async ({
                                        serviceUuid,
                                        characteristicUuid,
                                        deviceName
                                    }: any) => {
    if (!serviceUuid || !characteristicUuid) {
        return false;
    }

    let options = {} as any;
    let filters = [] as any[];

    // Process optional connection
    if (deviceName !== null && deviceName !== '') {
        filters.push({namePrefix: deviceName});
    }

    if (filters.length > 0) {
        options.filters = filters;
    } else {
        options.acceptAllDevices = true;
    }

    let optionalServices = [serviceUuid];
    options.optionalServices = optionalServices;

    fibLogger('Requesting any Bluetooth Device...');
    return await (navigator as any)?.bluetooth.requestDevice(options);

//                bluetoothDevice.addEventListener('gattserverdisconnected', onDisconnected);
}

export const connectAndReadBLEData = async ({
                                                serviceUuid,
                                                characteristicUuid,
                                                deviceName,
                                                bluetoothDevice,
                                                bluetoothCharacteristic,
                                                handleDataChanged
                                            }: any) => {

    try {
        if (!bluetoothDevice) {
            bluetoothDevice = await (navigator as any)?.bluetooth.requestDevice({filters: [{services: [serviceUuid]}]});

            if (!bluetoothDevice) {
                return bluetoothDevice;
            }
        }
        bluetoothCharacteristic = await connectDeviceAndCacheCharacteristics({
            bluetoothDevice,
            bluetoothCharacteristic,
            serviceUuid,
            characteristicUuid,
            handleDataChanged
        });

        return {
            bluetoothDevice,
            bluetoothCharacteristic
        }
    } catch (error) {
        fibLogger('Argh! ' + error, "error");
        // $(element).parent().parent().addClass("error")
        //         .attr("title", error);
        // hideSpinner($("#fibretraceAuditForm"));
    }
}

export const parseFibreReader = (value: any[]) => {
    return {
        block_length: value[1],
        block_checksum: value[2],
        reader_op_status: value[3],
        reder_hw_status: value[4],
        battery_charge: value[5],
        signal_estimate: value[6],
        hardware_version_bcd: value[7],
        result_age: value[8],
        last_tau: `0x${value[10].slice(-2)}${value[9].slice(-2)}`,
        last_int: `0x${value[12].slice(-2)}${value[11].slice(-2)}`,
        parameter_hash: `0x${value[14].slice(-2)}${value[13].slice(-2)}`,
        reader_serno: `0x${value[16].slice(-2)}${value[15].slice(-2)}`,
        firmware_vesion_bcd: `0x${value[18].slice(-2)}${value[17].slice(-2)}`,
    };
}

/**
 * Todo: Define Interface and use object `audit` returned by BE for safety
 * @param audit
 * @param status
 */
export const buildAuditProcessInfo = ({audit = {} as PROCESS_AUDIT, status = null as any}) => {
    try {
        console.log("buildAuditProcessInfo", audit)
        let t = {
            ad_process_position: audit.process_position,
            ad_order_id: audit.order_id,
            ad_order_reference_id: audit.order_reference_id,
            ad_process_name: audit.name,
            ad_audit_client: audit.audit_client,
            ad_status: status,
            ad_manufacturer_lat: audit.manufacturer_lat,
            ad_manufacturer_long: audit.manufacturer_long,
            ad_requires_fibretrace_id_when_audit: audit.requires_fibretrace_id_when_audit,
            ad_manufacturer_address: audit.manufacturer_address,
            ad_manufacturer_name: audit.manufacturer_name,
            ad_product_image: audit.product_image,
            ad_carbon: audit.process_carbon || '',
            ad_energy: audit.process_energy || '',
            ad_water: audit.process_water || '',
            ad_scanned_times_and_scan_count: audit.scanned_times_and_scan_count || '',
            ad_fibretrace_id: audit.fibretrace_id || null,
            ad_process_uid: audit.process_uid || null,
            scanned_count: 0,
            scan_count: 0,
            product_name: audit.product_name,
            product_description: audit.product_description,
            quantity: audit.quantity,
            audit_template_content: audit.audit_template_content || [],
            fibretrace_id_label: audit?.fibretrace_id_label||"",
            product_uid: audit.product_uid || null,
            site: audit.site,
            site_previous: audit.site_previous,
            process_name_previous: audit.process_name_previous,
            order_owner_email: audit.order_owner_email,
            process_position: audit.process_position,
            requires_st_scanner_when_audit: audit.requires_st_scanner_when_audit
        };

        let scans = _.map(_.split(audit.scanned_times_and_scan_count, '/'), (n) => Number(n));
        if (_.size(scans) > 1) {
            t.scanned_count = scans[0]
            t.scan_count = scans[1]
        }

        return _.omitBy(t, _.isUndefined) as any;
    } catch (e) {
        console.error(e.to)
    }
}

export const getAuditStatusByTab = (selected: any) => {
    let status: number;
    switch (selected) {
        case AuditTabsV2.UPCOMING_AUDITS:
            status = 1;
            break;
        case AuditTabsV2.PENDING_AUDITS:
            status = 3;
            break;
        case AuditTabsV2.PREVIOUS_AUDITS:
            status = 2;
            break;
        default:
            status = 1;
            break;
    }

    return status;
}
