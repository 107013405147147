import React, {useEffect, useState} from 'react';
import {COMPONENT_STATUS, Message, USER_ACTION} from '@src/types';
import * as _ from "lodash-es";
import {translateV2, validateEmail} from '@src/helpers';
import erroricon from "@assets/images/icons/error-msg.png";
import {PARTNER_API} from "@api/Partner";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {DEFAULT_COMPONENT_SETTINGS} from "@src/variables";
import {isMobile} from "react-device-detect";
import GlobalMessage from "@components/Input/Message";
import {colourCreatableInlineStyles} from "@components/Input/MultiSelect";
import {COMPANY_API} from "@api/Company";
import AsyncSelect from 'react-select/async';

const InvitePartnerWizardV2 = ({onChange = {} as any, onSubmit = {} as any}: any) => {
    const langData = useAppSelector(languageState);
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccessState] = useState(false);

    const [globalMsg, setGlobalMsg] = useState({
        type: '',
        text: '',
        data: []
    });

    const [inviteData, setInviteData] = useState({
        emails: "",
        message: "",
    })

    const [errorMsg, setErrorMsg] = useState({
        emails: {isValid: true, error: "ERROR.VALIDATE.EMAILS", messageData: {}},
        message: {isValid: true, error: "ERROR.VALIDATE.MESSAGE", messageData: {}},
    })

    const [canSubmit, setSubmitState] = useState(false);
    const [compSettings, setCompSettings] = useState({...DEFAULT_COMPONENT_SETTINGS});
    const [emailError, showEmailError] = useState(false);
    const [companies, setCompanies] = useState([] as any);

    const handleChange = (event: any) => {
        const data = inviteData;
        setGlobalMsg({
            type: '',
            text: '',
            data: []
        });

        if (event.target) {
            const value = event.target?.type === 'checkbox' ? event.target.checked : event.target.value;
            const name = event.target.name;
            switch (name) {
                case "plain_emails" : {
                    showEmailError(false);
                    setInviteData({...inviteData, emails: value});
                    data.emails = value;
                    validateInput.emails(value);
                    break;
                }
                case "plain_message" : {
                    setInviteData({...inviteData, message: value});
                    data.message = value;
                    validateInput.message(value);
                    break;
                }
            }
            validateInput.submit(data);
        }
    };

    const validateEmails = (emails: string) => {
        let emailsList: Array<string>;
        emailsList = emails.split(",").map((v: string) => {
            return _.escape(_.trim(v))
        });
        const t = _.every(emailsList, validateEmail);
        return t;
    }

    const validateInput = {
        emails: (emails) => {
            const err = errorMsg;
            if (!emails) {
                err.emails.isValid = false;
                err.emails.error = "ERROR.VALIDATE.EMAILS";
                return !emails;
            }

            const stt = validateEmails(emails)
            err.emails.isValid = true;
            if (!stt) {
                err.emails.isValid = false;
                err.emails.error = "ERROR.EMAIL_WRONG_FORMAT";
                setSubmitState(false);
            }
            setErrorMsg(err);
            return stt;
        },
        message: (message) => {
            const err = errorMsg;
            err.message.isValid = true;
            if (!message) {
                err.message.isValid = false;
                setErrorMsg(err)
                setSubmitState(false);
            }
        },
        submit: ({emails, message}) => {
            const stt = validateEmails(emails);
            if (stt && message) {
                setSubmitState(true);
                const tmp = {
                    ...compSettings,
                    isValid: true,
                    action: USER_ACTION.INVITE_USERS,
                    status: COMPONENT_STATUS.READY,
                    object: {...inviteData, emails, message}
                };
                setCompSettings({...tmp})
                if (_.isFunction(onChange)) {
                    onChange({...tmp})
                }
            }
        }
    };

    const handleSubmit = async (event: any) => {
        try {
            event.preventDefault();
            setLoading(true);
            if (canSubmit) {
                const req = await PARTNER_API.invitePartner({
                    emails: inviteData.emails,
                    message: inviteData.message,
                    companies: _.map(companies, 'uid')
                });
                const {error, data, message} = req;
                if (!error) {
                    setGlobalMsg({
                        type: Message.SUCCESS,
                        text: translateV2("SUCCESS.INVITED"),
                        data: []
                    })
                    setSuccessState(true);
                    if (_.isFunction(onSubmit)) {
                        await onSubmit(req);
                    }
                } else {
                    setGlobalMsg({
                        type: Message.ERROR,
                        text: message
                            ? message
                            : (
                                !_.isArray(error) || _.every(error, _.isString)
                                    ? error
                                    : _.every(error, _.isObject) ? _.flatten(_.map(error, (obj,) => _.keys(obj))) : error
                            ) || "ERROR.API_ERROR",
                        data
                    })
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        } catch (err) {
            setGlobalMsg({
                type: Message.ERROR,
                text: err || "ERROR.API_ERROR",
                data: []
            })
            setLoading(false)
        }
    };

    useEffect(() => {
        setInviteData({...inviteData, message: translateV2("LABEL.INVITE_MESSAGE.JOIN_FIBRETRACE_PARTNERS")})
    }, [langData.localize]);

    return (
        <>
            <form id="inviteForm" className={"form position-relative fib-invitepartners-form"} onSubmit={handleSubmit}>

                <OverlayTrigger overlay={

                    <Tooltip id={`tooltip-invite-partners`}>
                        {translateV2("LABEL.TOOLTIP.PARTNERS.SELECT_WITH_EG")}
                    </Tooltip>} defaultShow={false}>

                    <FontAwesomeIcon
                        style={{right: isMobile ? "16px" : "-24px"}}
                        className={"tooltip-helper cursor-pointer"} icon={faInfoCircle}/>

                </OverlayTrigger>

                {globalMsg.type &&
                    <div className="global-msg-wrap text-left">
                        <div className="global-msg">
                            {globalMsg.type === Message.ERROR && <img src={erroricon} alt=""/>}
                            <p className={`${globalMsg.type === Message.ERROR ? 'error' : 'success'}`}>
                                {globalMsg.text === 'ERROR.INVITE_EXISTED_USER' && `${globalMsg.data} `}
                                {translateV2(globalMsg.text)}</p>
                        </div>
                    </div>
                }

                {!isSuccess &&
                    <>
                        <textarea id="inviteEmail" className="form-control"
                                  style={{width: isMobile ? "calc(100% - 40px)" : "100%"}}
                                  onChange={handleChange}
                                  onBlur={() => {
                                      showEmailError(true)
                                  }}
                                  name="plain_emails"
                                  placeholder={translateV2("LABEL.INVITE_EMAIL")}/>

                        <p className={"mt-3"}>
                            <b className={``}>&#40;{translateV2("LABEL.ASTERISK")}&#41;</b>&nbsp;
                            <i>{translateV2("LABEL.COMMON.CHARACTERS_NOT_ALLOWED", "",
                                {characters: translateV2("LABEL.COMMON.ALLOWED_CHARACTERS")}
                            )}</i>
                        </p>

                        {!errorMsg.emails.isValid && errorMsg.emails.error && emailError &&
                            <GlobalMessage errorClassName={Message.ERROR}
                                           message={translateV2(errorMsg.emails.error)}
                                           messageData={errorMsg.emails.messageData}
                            />}

                        <hr className={"fpw-50 ml-0"}/>

                        <label htmlFor={"companies"}>
                            {translateV2("LABEL.INVITE.PARTNER_SEARCH")}
                        </label>

                        <AsyncSelect
                            id={`companies`}
                            styles={colourCreatableInlineStyles}
                            className="basic-multi-select mt-2 mb-2 w-100"
                            classNamePrefix="select"
                            isMulti
                            cacheOptions
                            defaultOptions
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isSearchable={false}
                            placeholder={translateV2("LABEL.INVITE.PARTNER_SEARCH.SELECT_COMPANIES")}
                            name="companies"
                            getOptionLabel={(option: {
                                name: any;
                                id: any
                            }) => option.name}
                            getOptionValue={(option: {
                                name: any;
                                id: any
                            }) => option.id}
                            loadOptions={() => new Promise((resolve, reject) => {
                                COMPANY_API.getCompaniesV2({})
                                    .then(({data = []}) => {
                                        resolve(data);
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            }).then(r => {
                                return r;
                            })}
                            onChange={(e) => {
                                setCompanies(e)

                                setSubmitState(_.size(e) > 0);

                                const tmp = {
                                    ...compSettings,
                                    isValid: _.size(e) > 0,
                                    action: USER_ACTION.INVITE_USERS,
                                    status: COMPONENT_STATUS.READY,
                                    object: {...inviteData, companies:_.map(e,'uid')}
                                };

                                setCompSettings({...tmp})

                                if (_.isFunction(onChange)) {
                                    onChange({...tmp})
                                }

                            }}
                        />
                        <br/>

                        <label htmlFor="inviteMessage" className={"d-none"}>
                            {translateV2("LABEL.INVITE_MESSAGE")}
                        </label>
                        <textarea id="inviteMessage" className="form-control d-none" rows={5}
                                  onChange={handleChange} name="plain_message"
                                  value={translateV2(inviteData.message)}
                                  placeholder={translateV2("LABEL.INVITE_MESSAGE")}/>

                        {!errorMsg.message.isValid && errorMsg.message.error && <div className="global-msg">
                                    <span className="error">
                                        {translateV2(errorMsg.message.error)}</span>
                        </div>}

                    </>
                }

            </form>
        </>
    );
}

export default InvitePartnerWizardV2;
