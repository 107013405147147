import {Alert, Modal, ProgressBar} from "react-bootstrap";
import React, {useState} from "react";
import {EImportPartnersSteps, importPartnersSlice, importPartnersState} from "@redux/reducers/importPartnersSlice";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import LoadingV2 from "@v2components/Loading/LoadingV2";
import FibreModalHeader from "@v2components/Modal/FibreModalHeader";
import {
    importPartnersProgressSlice,
    importPartnersProgressState,
    ImportStatusEnum
} from "@redux/reducers/importPartnersProgressSlice";

import {faInfoCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {translateV2} from "@src/helpers";
import PartnerFile from "@v2components/PartnerImport/FileChoose/PartnerFile";
import PartnerPreview from "@v2components/PartnerImport/PartnerPreview/PartnerPreview";
import PartnerImporting from "@v2components/PartnerImport/PartnerImporting/PartnerImporting";
import PartnerImportDone from "@v2components/PartnerImport/PartnerImportDone/PartnerImportDone";
import PartnerFileCTA from "@v2components/PartnerImport/FileChoose/PartnerFileCTA";
import PartnerFileValidatingCTA from "@v2components/PartnerImport/FileValidating/PartnerFileValidatingCTA";
import PartnerPreviewCTA from "@v2components/PartnerImport/PartnerPreview/PartnerPreviewCTA";
import PartnerImportDoneCTA from "@v2components/PartnerImport/PartnerImportDone/PartnerImportDoneCTA";
import _ from "lodash-es";
import PartnerInvitationDone from "@v2components/PartnerImport/PartnerInvitationDone/PartnerInvitationDone";
import PartnerInviting from "@v2components/PartnerImport/PartnerInviting/PartnerInviting";
import PartnerInvitation from "@v2components/PartnerImport/PartnerInvitation/PartnerInvitation";
import PartnerInvitationCTA from "@v2components/PartnerImport/PartnerInvitation/PartnerInvitationCTA";
import PartnerInvitationDoneCTA from "@v2components/PartnerImport/PartnerInvitationDone/PartnerInvitationDoneCTA";
import {userState} from "@redux/reducers/userSlice";
import {partnerImportAfterClose} from "@v2components/PartnerImport/PartnerImportHelper";
import PartnerFileValidate from "@v2components/PartnerImport/FileValidating/PartnerFileValidate";

/**
 * @todo:
 * config FilePartnersImportUrl
 */
const FilePartnersImportName = "partners_import_template11.csv"
const FilePartnersImportUrl = "http://localhost:8088/templates/partners_import_template.csv"
const PartnerImportModal = () => {

    const dispatch = useAppDispatch();

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);

    const [loading, setLoading] = useState(false);
    const [partnersFile, setPartnersFile] = useState<HTMLInputElement | null>(null)
    const [partnersList, setPartnersList] = useState<Array<unknown>>([])

    /**
     * step choose file
     */
    const [fileImport, setFileImport] = useState();

    /**
     * Step importing
     */
    const [progress, setProgress] = useState(0);
    const [importComplete, setImportComplete] = useState(false);
    const [importFailed, setImportFailed] = useState(false);

    /**
     * Related to: first_login_and_first_user of User
     */
    const user = useAppSelector(userState);


    return (
        <>
            <Modal style={{

            }}
                   className={"fibre-modal-import-partner"}
                   backdrop={'static'}
                   keyboard={false} scrollable={true}
                   show={importPartners.isShow}
                   onHide={() => {
                       dispatch(importPartnersSlice.actions.hide())
                       partnerImportAfterClose();
                   }} size={"lg"} centered={true}>

                {importPartnersProgress.loading && <LoadingV2 className={`fibre-modal-radius`}/>}

                <FibreModalHeader
                    title={translateV2(importPartners.title || "LABEL.PARTNER.IMPORT." + importPartnersProgress.step)}
                    onClickClose={() => {
                        dispatch(importPartnersSlice.actions.hide())
                        dispatch(importPartnersProgressSlice.actions.reset())

                        partnerImportAfterClose();

                    }}
                />

                <Modal.Body className={"modal-fib-body"}>

                    {
                        importPartnersProgress.step === EImportPartnersSteps.CHOOSE_FILE &&
                        <PartnerFile onChange={(e: any) => {
                            if (_.size(e?.current)) {
                                setFileImport(e?.list[0])
                            } else {

                            }
                        }}/>
                    }

                    {
                        (importPartnersProgress.step === EImportPartnersSteps.VALIDATE_FILE) && <PartnerFileValidate/>
                    }

                    {
                        importPartnersProgress.step === EImportPartnersSteps.PREVIEW && <PartnerPreview/>
                    }

                    {
                        importPartnersProgress.step === EImportPartnersSteps.IMPORTING && <PartnerImporting/>
                    }

                    {
                        importPartnersProgress.step === EImportPartnersSteps.IMPORT_DONE && <PartnerImportDone/>
                    }

                    {
                        /** Invitation **/
                        importPartnersProgress.step === EImportPartnersSteps.INVITATION && <PartnerInvitation/>
                    }

                    {
                        /** Invitation Done **/
                        importPartnersProgress.step === EImportPartnersSteps.INVITING && <PartnerInviting/>
                    }

                    {
                        /** Invitation Done **/
                        importPartnersProgress.step === EImportPartnersSteps.INVITE_DONE && <PartnerInvitationDone/>
                    }

                </Modal.Body>

                {
                    importPartnersProgress.step === EImportPartnersSteps.IMPORTING || importPartnersProgress.step === EImportPartnersSteps.INVITING
                        ? null
                        : <>
                            <Modal.Footer className={`modal-fib-footer`}>

                                {
                                    importPartnersProgress.step === EImportPartnersSteps.CHOOSE_FILE && <PartnerFileCTA/>
                                }

                                {
                                    importPartnersProgress.step === EImportPartnersSteps.VALIDATE_FILE &&
                                    <PartnerFileValidatingCTA/>
                                }

                                {
                                    importPartnersProgress.step === EImportPartnersSteps.PREVIEW && <PartnerPreviewCTA/>
                                }

                                {
                                    importPartnersProgress.step === EImportPartnersSteps.IMPORT_DONE &&
                                    <PartnerImportDoneCTA/>
                                }

                                {
                                    importPartnersProgress.step === EImportPartnersSteps.INVITATION &&
                                    <PartnerInvitationCTA/>
                                }


                                {
                                    importPartnersProgress.step === EImportPartnersSteps.INVITE_DONE &&
                                    <PartnerInvitationDoneCTA/>
                                }

                            </Modal.Footer>
                        </>
                }


            </Modal>

        </>
    )

}

export default PartnerImportModal