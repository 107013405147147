import React from "react";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {importPartnersState} from "@redux/reducers/importPartnersSlice";
import {importPartnersProgressState} from "@redux/reducers/importPartnersProgressSlice";
import _ from "lodash-es";
import {translateV2} from "@src/helpers";

const PartnerInvitation = () => {

    const dispatch = useAppDispatch();

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);

    return (
        <>
            <p dangerouslySetInnerHTML={{
                __html:
                    translateV2("LABEL.PARTNER.IMPORT.INVITATION.TOTAL_INVITE", "", {
                        total: _.size(importPartnersProgress.stepData.invitation.partner_list_invitation)
                    })
            }}/>
        </>
    )
}

export default PartnerInvitation;