import React from 'react';
import {Icon} from '@src/types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import parse from "html-react-parser";
import {translateV2} from "@src/helpers";

const CustomTextArea = ({
                            type,
                            rows,
                            label,
                            icon,
                            placeholder,
                            disabled,
                            val,
                            className,
                            change,
                            blur,
                            name,
                            inputClassName = "",
                            labelClassName = "",
                            autoComplete = "",
                            required = false
                        }: any) => {

    return (
        <div className={`input-group fibre-textarea ${className || ''}`}>

            {
                !label && !icon
                    ? null
                    : <div className="input-group-prepend">
                        {label && <span className={`input-group-text fibre-textarea_label ${labelClassName}`}>{label}
                            {required && <span className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span>}
                        </span>}

                        <span className="input-group-text fibre-textarea_icon">
                            {icon && icon.type === Icon.IMG && <img src={icon.icon} alt=""/>}
                                    {icon && icon.type === Icon.FA && <FontAwesomeIcon icon={icon.icon}/>}
                        </span>
                    </div>
            }


            {disabled && <div className={"form-control fibre-textarea_control disabled"}>{parse(val)}</div>}

            {!disabled && <textarea className={`form-control fibre-textarea_control ${inputClassName}`} rows={rows}
                                    placeholder={placeholder}
                                    name={name} value={val}
                                    disabled={disabled}
                                    onChange={change} onBlur={blur}/>}
        </div>
    );
}

export default CustomTextArea;
