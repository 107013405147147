import React from 'react';
import {Icon} from '@src/types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Loading from '@components/Loading/Loading';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {translateV2} from "@src/helpers";
import _ from "lodash-es";

const SwitchIcon = ({tooltipLabel = '', icon}: any) => {

    const IconMarkup = !icon
        ? <></>
        : <span className="input-group-text fibre-input_icon">
            {icon.type === Icon.IMG && <img src={icon.icon} alt=""/>}
            {icon.type === Icon.FA && <FontAwesomeIcon icon={icon.icon}/>}
        </span>

    return <>
        {!tooltipLabel
            ? IconMarkup
            : <OverlayTrigger
                overlay={<Tooltip
                    bsPrefix={"fib-bs-tooltip tooltip"}
                    id={_.uniqueId('switcher_')}>{translateV2(tooltipLabel)}</Tooltip>}>
                {IconMarkup}
            </OverlayTrigger>
        }
    </>
}

const SwitchInput = ({tooltipLabel, name, controlClass, val, change, disabled, options, isloading}: any) => {
     const id = _.uniqueId(name);
    const InputMarkup = <div className={`toggle-switch fibre-input_control ${controlClass}`}>
        <input
            type="checkbox"
            className="toggle-switch-checkbox"
            name={name}
            id={id}
            checked={val}
            onChange={change}
            disabled={disabled}
        />
        <label className="toggle-switch-label" htmlFor={id}>
            <span className={`toggle-switch-inner${disabled ? ' toggle-switch-disabled' : ''}`}
                  data-yes={options[0]} data-no={options[1]}/>
            <span className={`toggle-switch-switch${disabled ? ' toggle-switch-disabled' : ''}`}/>
        </label>

        {isloading && <Loading/>}

    </div>

    return <>
        {!tooltipLabel 
            ? InputMarkup
            : <OverlayTrigger
                overlay={<Tooltip
                    bsPrefix={"fib-bs-tooltip tooltip"}
                    id={_.uniqueId('switcher_')}>{translateV2(tooltipLabel)}</Tooltip>}>
                {InputMarkup}
            </OverlayTrigger>
        }
    </>
}

const Switch = ({
                    label,
                    labelHelper = '',
                    tooltipLabel = '',
                    options,
                    icon,
                    disabled,
                    isloading = false,
                    val,
                    className,
                    change,
                    name,
                    customClass,
                    controlClass = ""
                }: any) => {
    return (
        <div className={`input-group fibre-input ${className || ''}`}>

            <div className="input-group-prepend">
                {label && <span className={`input-group-text fibre-input_label ${customClass}`}>{label}{labelHelper}</span>}
                <SwitchIcon tooltipLabel={tooltipLabel} icon={icon}/>
            </div>

            <SwitchInput
                name={name}
                tooltipLabel={tooltipLabel}
                controlClass={controlClass}
                val={val}
                change={change}
                disabled={disabled}
                options={options}
                isloading={isloading}
            />

        </div>
    );
}

export default Switch;
