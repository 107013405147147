import React, {useEffect, useState} from "react";
import {Alert, Image, ProgressBar} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {EImportPartnersSteps, importPartnersState} from "@redux/reducers/importPartnersSlice";
import {importPartnersProgressState} from "@redux/reducers/importPartnersProgressSlice";
import {translateV2} from "@src/helpers";
import {ASSETS} from "@assets/Assets";

const PartnerFileValidate = () => {

    const dispatch = useAppDispatch();

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);

    const [progress, setProgress] = useState(0)

    useEffect(() => {

        const t = setInterval(() => {
            if (progress <= 97) {
                setProgress(prev => {
                    return ++prev;
                })
            }
        }, 50)

        return () => {
            clearInterval(t)
        }

    }, [progress, importPartnersProgress.stepData.importing.progress])

    return (
        <>
            {
                (importPartnersProgress.step === EImportPartnersSteps.VALIDATE_FILE)
                    ? importPartnersProgress.message
                        ? <Alert variant={'danger'} className={"fibre-alert-danger pt-4 pb-4"}>
                            <div className={`d-flex flex-row justify-content-start align-items-center `}>

                                <Image src={ASSETS.IconInfoCircleError} loading={"lazy"} height={20} decoding={"async"}
                                       className={""}
                                       onClick={() => {
                                       }}
                                />

                                <p className="mb-0 ml-3"
                                   dangerouslySetInnerHTML={{__html: translateV2(importPartnersProgress.message||"LABEL.PARTNER.IMPORT.VALIDATE_FILE.GENERAL_ERROR")}}/>

                            </div>
                        </Alert>
                        : <>
                            <p>{translateV2("LABEL.PARTNER.IMPORT.VALIDATE_FILE.WAITING")}</p>
                            <div className="ml-4 mr-4">
                                <ProgressBar
                                    className={`fib-progress-bar mt-4 mb-3 `}
                                    variant={"success"}
                                    now={progress}
                                    animated={true}
                                />
                            </div>
                        </>

                    : null
            }
        </>
    )
}

export default PartnerFileValidate;