import React, {useEffect, useState} from 'react';
import {blockPage, translateV2, validate} from '@src/helpers';
import _ from "lodash-es";
import {Col, Form, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import GlobalMessage from "@components/Input/Message";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle,} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import {InputRules, Message, USER_ACTION} from "@src/types";
import {DEFAULT_COMPONENT_SETTINGS, DEFAULT_SELECT} from "@src/variables";
import {isMobile} from "react-device-detect";
import {RouterInterface} from "@src/types/router";

const ProcessDetailWizardV2 = ({
                                   action = USER_ACTION.CREATE as USER_ACTION,
                                   processes = {...DEFAULT_SELECT},
                                   data = {} as any,
                                   onFocus = {} as any,
                                   onChange = {} as any,
                                   onSubmit = {} as any
                               }: any) => {


    const formDefault = {
        fibretrace_manufacturer_site_id: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: false,
            isDirty: false,
            error: ''
        },
        fibretrace_manufacturer_type_id: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: false,
            isDirty: false,
            error: ''
        },
        fibretrace_process_id: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: false,
            isDirty: false,
            error: ''
        },
        name: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: false,
            isDirty: false,
            error: ''
        },
        description: {
            value: '',
            rules: [InputRules.REQUIRED],
            isValid: false,
            isDirty: false,
            error: ''
        },
        uploaded_images: {
            value: [] as any,
            rules: [],
            isValid: true,
            isDirty: false,
            error: ''
        },
        uploaded_certificates: {
            value: "" as any,
            rules: [],
            isValid: true,
            isDirty: false,
            error: ''
        },
        uploaded_video: {
            value: '',
            rules: [],
            isValid: true,
            isDirty: false,
            error: ''
        },

    }

    const [form, setForm] = useState({...formDefault})
    const [process, setProcess] = useState(data || {} as any)
    const [compSettings, setCompSettings] = useState({...DEFAULT_COMPONENT_SETTINGS});

    const handleInput = async ({target, type}: any = {}) => {
        try {
            const f = form, p = process;
            const name = target.name;
            const value = target?.type === 'checkbox' ? target.checked : target.value;
            let error: string, formattedValue: string;

            ({error, value: formattedValue} = validate(value, f[name].rules));

            if (type === 'change' && _.has(f, name) && value !== f[name].value) {
                f[name].value = formattedValue || (value || "");
                f[name].isDirty = true;
                f[name].isValid = !error && f[name].isDirty;
                f[name].error = "";
                p[name] = formattedValue || (value || "");
            }

            if (type === 'blur') {
                f[name].error = f[name].isDirty ? (error || "") : "";
            }

            setForm({...form, ...f});

            await formHandle.validate();
        } catch (e) {
            console.error(e)
        }
    }

    const formHandle = {
        validate: async (trigger: boolean = true) => {
            const keys = ["id", "src", "name", "field", "validity", "path", "isValid", "file", "fibretrace_manufacturer_id", "fibretrace_manufacturer_process_id"]
            const f = {...form};
            let tmp = {} as any;

            if (trigger) {
                tmp = {
                    ...compSettings,
                    processing: false,
                    isValid: _.every(f, (v: any) => v.isValid),
                    message: "",
                    form,
                    process,
                };
                if (_.isArray(tmp.process.certificates)) {
                    tmp.process.certificates = tmp.process.certificates.map((v) => _.pick(v, keys));
                }
                tmp.object = {...tmp.process};
                if (_.isFunction(onChange)) await onChange(tmp);

            }
        },
        onChange: handleInput,
        onBlur: handleInput,
        onSelectChange: async (selected: any, field: any) => {
            try {
                const f = form, p = process, {error} = validate(selected.value, f[field].rules);

                f[field] = {
                    ...f[field], ...{
                        error: (error || ""), isValid: !error
                    }
                }

                if (_.has(form, field)) {
                    if (selected.value) {
                        f[field].value = selected.value;
                        p[field] = selected.value;
                    }
                }

                setForm({...form, ...f})
                setProcess({...process, ...p})

                await formHandle.validate();
            } catch (e) {
                console.error(e)
            }
        },
        submit: async (e: any, red?: RouterInterface) => {
            try {

            } catch (e) {

            }
        }
    }

    useEffect(() => {
        (async () => {
            const {name} = data;
            if (name) {
                setForm({
                    ...form, name: {
                        ...form.name,
                        value: name,
                        isValid: true,
                        isDirty: true,
                        error: ""
                    }
                })
            }
        })()

        return () => {
        }
    }, [data])

    useEffect(() => {
        (async () => {
            const f = {...form}, p = {...process};

            _.map(p, (v, k) => {
                if (_.has(f, k)) {
                    const {error} = validate(v, f[k].rules);
                    f[k].isValid = !error;
                    f[k].value = v;
                }
            })

            setForm({...form, ...f});

            await formHandle.validate(false);
            blockPage(false)
        })()

        return () => {
            setForm({...formDefault});
            setProcess({});
            setCompSettings({
                ...compSettings, processing: false,
                isValid: false,
                message: ""
            })
        }

    }, [])


    return (
        <>

            {compSettings.message &&
                <GlobalMessage errorClassName={compSettings.isValid ? Message.SUCCESS : Message.ERROR}
                               message={translateV2(compSettings.message)}/>}

            <Form noValidate onSubmit={formHandle.submit}>

                <Form.Group as={Row} className="mb-2" controlId="fibretrace_process_id">
                    <Col>
                        <Select
                            className={`basic-single ux-processes-select`}
                            classNamePrefix="select"
                            isSearchable={processes.isSearchable}
                            name="fibretrace_process_id"
                            options={processes.list}
                            value={
                                _.findIndex(processes.list, (v: any) => v.id === form.fibretrace_process_id.value) !== -1
                                    ? processes.list[_.findIndex(processes.list, (v: any) => v.id === form.fibretrace_process_id.value)]
                                    : ""}
                            onChange={(e) => formHandle.onSelectChange(e, 'fibretrace_process_id')}
                            onFocus={(data: any) => {
                                if (_.isFunction(onFocus)) onFocus();
                            }}
                        />

                        {
                            !_.isEmpty(processes.list) && <OverlayTrigger overlay={

                                <Tooltip id={`tooltip-process-cat`}>
                                    {translateV2("LABEL.TOOLTIP.PROCESS.SELECT_WITH_EG", "", {
                                        '0': _.join(_.map(processes.list, (v) => v.name), ", ")
                                    })}
                                </Tooltip>} defaultShow={false}>

                                <FontAwesomeIcon
                                    style={{right: isMobile ? "15px" : "-10px"}}
                                    className={"tooltip-helper cursor-pointer"} icon={faInfoCircle}/>

                            </OverlayTrigger>
                        }

                        {/*<GlobalMessage className={"pt-1 mb-1 font-italic"} errorClassName={"success"}*/}
                        {/*               message={translateV2(`LABEL.TOOLTIP.PROCESS.SELECT`)}*/}
                        {/*/>*/}

                        {form?.fibretrace_process_id?.error &&
                            <GlobalMessage message={translateV2(form?.fibretrace_process_id?.error)}/>}
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="name">
                    <Col>
                        <Form.Control type="text" name={"name"}
                                      onFocus={(data: any) => {
                                          if (_.isFunction(onFocus)) onFocus();
                                      }}
                                      onChange={formHandle.onChange} value={form.name.value}
                                      placeholder={translateV2("LABEL.TOOLTIP.PROCESS.NAME")}/>

                    </Col>

                </Form.Group>

                {/*Certificates*/}

            </Form>

        </>
    );
}

export default ProcessDetailWizardV2;
