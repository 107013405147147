import React from "react";
import {Button} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {EImportPartnersSteps, importPartnersSlice, importPartnersState} from "@redux/reducers/importPartnersSlice";
import {
    beginImportPartnersFileAsync,
    importPartnersProgressSlice,
    importPartnersProgressState
} from "@redux/reducers/importPartnersProgressSlice";
import _ from "lodash-es";
import {translateV2} from "@src/helpers";
import {unwrapResult} from "@reduxjs/toolkit";

const PartnerPreviewCTA = () => {

    const dispatch = useAppDispatch();

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);


    const isDisabled = () => {

        const optionsChecked = _.filter(importPartnersProgress.stepData.preview.partner_list_formatted, (fp: any) => fp.checked);

        return (_.size(optionsChecked) <= 0)
            || !_.every(optionsChecked, (p: any) => {
                return p.allow_import || p.send_invitation
            })
    }

    return (
        <>
            <div className={`d-flex flex-column flex-sm-row justify-content-sm-end w-100`}>

                <div
                    className={`d-inline-flex flex-row justify-content-between justify-content-sm-end align-items-baseline`}>
                    <Button
                        className={`fibre-landscape-padding fibre-border-radius fibre-btn-outline-dark`}
                        variant="outline-dark"
                        onClick={() => {
                            // dispatch(importPartnersSlice.actions.hide())
                            dispatch(importPartnersProgressSlice.actions.reset())
                            dispatch(importPartnersProgressSlice.actions.setStep({
                                step: EImportPartnersSteps.CHOOSE_FILE
                            }))
                        }}>
                        {translateV2("LABEL.BACK")}
                    </Button>

                    <Button variant="success"
                            className={"fibre-btn-success fibre-border-radius ml-3"}
                            disabled={isDisabled()}
                            onClick={async () => {

                                /**
                                 * Begin importing
                                 */
                                dispatch(beginImportPartnersFileAsync({
                                    partner_list: _.filter(importPartnersProgress.stepData.preview.partner_list_formatted, (p: any) => p.checked),
                                    import_partner_data: importPartnersProgress.stepData.validate_file.import_partner_data
                                })).then(unwrapResult)
                                    .then((data, error) => {
                                        console.log("beginImportPartnersFileAsync", data, error)

                                        if (!_.isEmpty(data) && !error) {

                                            /**
                                             * Set modalTitle based on checkFileStatus
                                             */
                                            let modalTitle = !error ? "Import Successful" : "Import Failed"
                                            dispatch(importPartnersSlice.actions.setTitle({title: modalTitle}))

                                            /**
                                             * To make life easier, dispatch several times with immerjs
                                             */

                                            // dispatch(importPartnersProgressSlice.actions.setPartnersList({
                                            //     partner_list_formatted: [...data]
                                            // }))


                                        } else {
                                            dispatch(importPartnersSlice.actions.setTitle({title: translateV2("LABEL.PARTNER.IMPORT.IMPORT_FAILED"),}))
                                        }
                                    })
                                    .catch((error: any) => {
                                        console.log(error);

                                    })

                            }}>
                        {translateV2("LABEL.IMPORT_PARTNERS")}
                    </Button>
                </div>

            </div>
        </>
    )
}

export default PartnerPreviewCTA;
