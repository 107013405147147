import {axiosRequest, axiosRequestAlt} from '@src/helpers';
import {AUTH} from "@api/Auth";
import _ from "lodash-es";
import {serialize} from "object-to-formdata";
import {E_AuditClaimDispute} from "@redux/reducers/auditSelectedSlice";

export const AUDIT_API = {
    getOrderProcess: (id?: string | null) => {
        return axiosRequest(`/get-auditor-next-order-process${id ? `?process_id=${id}` : ''}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    getOrderProcessList: ({params = {}, cancelToken = {}}) => {
        let options = {
            method: 'GET',
            params,
            headers: {...AUTH.getHeader()},
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken;
        return axiosRequest(`/user/audits`, options, true, false);
    },

    getScanners: (page: number = 1, numPerPage: number = 100, keyword: string = '') => {
        const fipt = AUTH.getToken();
        return axiosRequest(`/get-auditor-list-scanner?page=${page}&num_per_page=${numPerPage}&keyword=${keyword}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + fipt
            },
        });
    },

    getFibretraceIDs: () => {
        const fipt = AUTH.getToken();
        return axiosRequest('/get-list-fibretraceid', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + fipt
            },
        });
    },

    getEneryType: () => {
        const fipt = AUTH.getToken();
        return axiosRequest('/get-list-energy-type', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + fipt
            },
        });
    },

    sendMisMatchLocation: (data: any) => {
        const fipt = AUTH.getToken();

        return axiosRequest('/alert-missmatch-location', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + fipt
            },
            body: JSON.stringify(data),
        });
    },

    updateAuditFromBC: ({code, method}: any) => {

        const fipt = AUTH.getToken();
        return axiosRequest('/process-bc-audit', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + fipt
            },
            body: JSON.stringify({
                code,
                method
            }),
        });
    },

    getPreviousProcess: ({order_code, process_position}: any) => {
        const fipt = AUTH.getToken();
        return axiosRequest(`/get-auditor-previous-process?order_code=${order_code}&process_position=${process_position}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + fipt
            },
        }, true, false);
    },

    getAuditBCStatus: async ({code, bc_status}: any) => {
        return axiosRequest(`/check-audit-blockchain-status?code=${code}${bc_status ? `&bc_status=${bc_status}` : ''}`, {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        }, true, false);
    },

    checkFID: (tau: number, order: string) => {
        const fipt = AUTH.getToken();
        return axiosRequest(`/check-fibretrace-id?tau=${tau}&fibretrace_order_code=${order}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + fipt
            },
        });
    },

    postAudit: async (data: any) => {
        try {
            /**
             * @description: this function was copied from old source code
             * @todo: refactor - work with backend to re define this API to use json instead of formData. I guess old dev use formData because this api accept files
             */
            let formData = new FormData();

            formData.append('fibretrace_order_processes_position', data.fibretrace_order_processes_position);
            formData.append('fibretrace_scanner_id', data.fibretrace_scanner_id);
            formData.append('scan_result', data.scan_result);
            formData.append('scan_result_tau', data.scan_result.split(", ")[0]);
            formData.append('scan_result_int', data.scan_result.split(", ")[1]);
            formData.append('product_barcode', data.product_barcode);
            formData.append('lat', data.lat);
            formData.append('long', data.long);
            formData.append('comment', data.comment);
            formData.append('carbon', data.carbon);
            formData.append('water', data.water);
            formData.append('energy', data.energy);
            formData.append('energy_type', data.energy_type);
            formData.append('water_recycle', data.water_recycle);
            formData.append('fibretrace_order_code', data.fibretrace_order_code);
            formData.append('serial_number', data.serial_number);
            formData.append('show_attached_files', data.show_attached_files);
            formData.append('attributeTemplateValue', data.attributeTemplateValue);
            formData.append('nfc', data.nfc || "");
            formData.append('st_scan_result', data.st_scan_result || "");

            if (data.intensity_change_reason && data.intensity_auditor_comment) {
                formData.append('intensity_change_reason', data.intensity_change_reason);
                formData.append('intensity_auditor_comment', data.intensity_auditor_comment);
                formData.append('intensity_applied_for_multiple_scan', data.intensity_applied_for_multiple_scan);
            }

            if (data.images && data.images.length > 0) {
                data.images.forEach((image: any) => {
                    formData.append('images[]', image);
                });
            }
            if (data.attached_files && data.attached_files.length > 0) {
                data.attached_files.forEach((image: any) => {
                    formData.append('attached_files[]', image);
                });
            }
            const response = await axiosRequest(`/post-auditor-audit`, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': AUTH.getBearer()
                },
                body: formData
            }, true, false);

            return response;
        } catch (e) {
            console.error(e)
            return false;
        }
    },

    getCompanyAuditors: async () => {
        try {
            const fipt = AUTH.getToken();
            return axiosRequest('/company/auditors', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + fipt
                },
            });
        } catch (e) {
            console.error(e);
            return false;
        }
    },

    transferAudit: async (data: any) => {
        try {
            const {
                order_code,
                process_id,
                user_id
            } = data
            const fipt = AUTH.getToken();
            return axiosRequest(`/orders/${order_code}/processes/${process_id}/assign/${user_id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + fipt
                },
            }, true, false);
        } catch (e) {
            console.error(e);
            return false;
        }
    },

    getOrderProcessAuditTemplate: async (data: any = {}) => {
        const {uid, signal, ...params} = data;
        if (uid) {
            let options = {
                method: 'GET', signal,
                headers: {...AUTH.getHeader()},
                params
            } as any;
            return axiosRequest(`/user/audits/${uid}/attribute-template`, options, true, false);
        }
        return false;
    },

    getAuditCompanies: async (params: any = {}, cancelToken: any = {}) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params,
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/audits/companies`, options, true, false);
    },

    getAuditCompaniesV2: async (params: any = {}, cancelToken: any = {}) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params,
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequestAlt(`/audits/companies`, options, true, false);
    },

    getAuditReceivers: async (params: any = {}, cancelToken: any = {}) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params,
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/audits/receivers`, options, true, false);
    },

    sendAudit: async (params: any = {}, cancelToken: any) => {
        const {uid} = params;
        const form_data = serialize(params);
        let options = {
            method: 'POST',
            body: form_data,
            headers: {...AUTH.getFormHeader()},
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/user/audits/${uid}/send`, options, true, false);

    },

    getAuditReceived: async (params: any = {}, cancelToken: any) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params,
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/audits/received`, options, true, false);
    },

    getAuditAccepted: async (params: any = {}, cancelToken: any) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params,
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/audits/accepted`, options, true, false);
    },

    getAuditRejected: async (params: any = {}, cancelToken: any) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
            params,
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
        return axiosRequest(`/audits/rejected`, options, true, false);
    },

    acceptAudit: async (params: any = {}, cancelToken: any) => {
        const {code} = params;
        if (code) {
            let options = {
                method: 'POST',
                headers: {...AUTH.getHeader()},
                body: params,
            } as any;
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return axiosRequest(`/audits/received/${code}/accept`, options, true, false);
        }
        return false
    },

    rejectAudit: async (params: any = {}, cancelToken: any) => {
        const {code} = params;
        if (code) {
            let options = {
                method: 'POST',
                headers: {...AUTH.getHeader()},
                body: params,
            } as any;
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return axiosRequest(`/audits/received/${code}/reject`, options, true, false);
        }
        return false;
    },

    getAuditQrCode: async (params: any = {}, cancelToken: any = {}) => {
        // v1/user/audits/{uid}/qr
        let {uid} = params;
        if (uid) {
            let options = {
                method: 'GET',
                headers: {...AUTH.getHeader()},
            } as any;
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return axiosRequest(`/user/audits/${uid}/qr`, options, true, false);
        }
        return false;
    },

    getLastStageAudit: async (params: any = {}, cancelToken: any = {}) => {
        let {uid} = params;
        if (uid) {
            let options = {
                method: 'GET',
                headers: {...AUTH.getHeader()},
            } as any;
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return axiosRequest(`/last-stage-audit/${uid}`, options, true, false);
        }
        return false;
    },

    acceptLastStageAudit: async (params: any = {}, cancelToken: any = {}) => {
        let {uid} = params;
        if (uid) {
            let options = {
                method: 'POST',
                headers: {...AUTH.getHeader()},
                body: params,
            } as any;
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return axiosRequest(`/accept-last-stage-audit/${uid}`, options, true, false);
        }
        return false;
    },

    rejectLastStageAudit: async (params: any = {}, cancelToken: any = {}) => {
        let {uid} = params;
        if (uid) {
            let options = {
                method: 'POST',
                headers: {...AUTH.getHeader()},
                body: params,
            } as any;
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return axiosRequest(`/reject-last-stage-audit/${uid}`, options, true, false);
        }
        return false;
    },

    getOrderProcessAuditDetail: async (params: any = {}, cancelToken?: any) => {
        const {id} = params;
        if (id) {
            let options = {
                method: 'GET',
                headers: {...AUTH.getHeader()},
            } as any;
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return axiosRequest(`/user/audits/${id}`, options, true, false);
        }
        return false;
    },

    getOrderProcessAuditDetailByProcessUid: async (params: any = {}, cancelToken?: any) => {
        const {uid} = params;
        if (uid) {
            let options = {
                method: 'GET',
                headers: {...AUTH.getHeader()},
            } as any;
            if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken
            return axiosRequest(`/order-process/${uid}`, options, true, false);
        }
        return false;
    },

    getIntensityChangeReasons: async (params: any = {}, cancelToken?: any) => {
        let options = {
            method: 'GET',
            headers: {...AUTH.getHeader()},
        } as any;
        if (!_.isEmpty(cancelToken)) options.cancelToken = cancelToken

        return axiosRequest(`/intensity-change-reasons`, options, true, false);
    },

    updateAuditClaimedStatus: async ({processes, status, signal}: {
        processes: number[],
        status: E_AuditClaimDispute,
        signal?: AbortSignal | undefined
    }) => {

        let options = {
            method: 'POST',
            signal,
            data: {processes, status},
            headers: {...AUTH.getHeader()},
        } as any;

        return axiosRequest(`/update-audit-claimed-status`, options, true, false);
    },

    // get-scan-result/S_AND_T_001
    // regenSTcode: async (params: {
    //     signal?: AbortSignal | undefined
    // } = {}) => {
    //     let {signal} = params;
    //     let options = {
    //         method: 'GET',
    //         signal,
    //         headers: {...AUTH.getHeader()},
    //         body: {},
    //     } as any;
    //
    //     return axiosRequest(`/get-scan-result/S_AND_T_001`, options, true, false);
    //
    // },


    receiverLastAcceptAudit: async (data: { signal?: AbortSignal } = {}) => {
        const {signal} = data;
        let options = _.omitBy({
            method: 'GET', signal,
            headers: {...AUTH.getHeader()},
        }, _.isNil) as any;

        return axiosRequest(`/audits/receiver-last-accept-audit`, options, true, false);
    },

    validateSpinTurnScannerResult: async (params: {
        st_scan_result?: string | unknown,
        signal?: AbortSignal | undefined
    } = {}) => {
        let {st_scan_result, signal} = params;
        if (st_scan_result) {
            let options = {
                method: 'POST',
                signal,
                headers: {...AUTH.getHeader()},
                body: {st_scan_result},
            } as any;
            return axiosRequest(`/validate-scan-result`, options, true, false);
        }
        return false;
    },
}

