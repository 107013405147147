import React, {useEffect, useState} from 'react';
import erroricon from '@assets/images/icons/error-msg.png';
import {DATE_FORMAT, FIBRETRACE_IDS, InputRules, Message, USER_ACTION} from '@src/types';
import Datetime from 'react-datetime';
import {blockPage, fibLogger, getParams, translateV2, validate} from '@src/helpers';
import {PRODUCTS_API} from '@api/Product';
import {COMMON_API} from '@api/Common';
import {COMPANY_API} from '@api/Company';
import Select from "react-select";
import Switch from '@components/Input/Switch';
import {ORDER_API} from '@api/Order';
import {Accordion, Button, Card, Image, OverlayTrigger, Tooltip} from 'react-bootstrap';
import axios from "axios";
import _ from "lodash-es";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faSpinner, faTags, faTrash} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {Link, useNavigate} from 'react-router-dom';
import {APP_ROUTES_PATH} from "@src/routes";
import {colourSingleStyles, colourStyles} from "@components/Input/MultiSelect";
import {DEFAULT_MODAL, DEFAULT_PANEL_SETTINGS, DEFAULT_SELECT} from "@src/variables";
import GlobalMessage from "@components/Input/Message";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {languageState} from "@redux/reducers/languageSlice";
import Loading from "@components/Loading/Loading";
import {companyState} from "@redux/reducers/companySlice";
import {isMobile} from "react-device-detect";
import {configState} from "@redux/reducers/configSlice";
import {ASSETS} from "@assets/Assets";
import {showMessage} from "@redux/reducers/messageSlice";
import ModalConfirmation from "@v2components/Modal/Confirmation";
import {panelSlice} from "@redux/reducers/panelSlice";
import LoadingV2 from "@v2components/Loading/LoadingV2";
import SelectMultipleIdPercentage from "@v2components/Order/SelectMultipleIdPercentage";
import SelectMultipleIdFID from "@v2components/Order/SelectMultipleIdFID";
import {TAGS_API} from "@api/Tag";
import CreatableSelect from 'react-select/creatable';

const maxAuditDeadline = moment().add(5, "year");
const ORDER_DATE_FORMAT = 'YYYY-MM-DD';

interface ISelect {
  id: number,
  value?: number,
  label?: string
}

const formatDataSelect = (data: ISelect, list: ISelect[]) => {
  if (data.value && data.label) {
    return data;
  } else {
    return list.find(item => item.id === data.id)
  }
}

const buildOrderData = ({orderForm, useMultipleIdDetection, multiIdValid}: any) => {
  const res: any = {
    fibretrace_product_id: orderForm.fibretrace_product_id.value,
    quantity: orderForm.quantity.value,
    uom_id: orderForm.uom_id.value,
    order_date: orderForm.order_date.value,
    retail_date: orderForm.retail_date.value,
    countries: orderForm.countries.value.map((country: any) => country.id),
    eco_visible: orderForm.eco_visible.value,
    condition_brand: orderForm.condition_brand.value,
    condition_manufacturer: orderForm.condition_manufacturer.value,
    condition_whitelist: orderForm.condition_whitelist.value,
    condition_ip: orderForm.condition_ip.value,
    condition_fos: orderForm.condition_fos.value,
    fibretrace_id: parseInt(orderForm.fibretrace_id.value) === -1 || parseInt(orderForm.fibretrace_id.value) === 0 ? null : Number(orderForm.fibretrace_id.value),
    frequency_of_scan: orderForm.frequency_of_scan.value,
    frequency_of_scan_period: orderForm.frequency_of_scan_period.value,
    reference_id: orderForm.reference_id.value,
    mark_as_completed: orderForm.mark_as_completed.value,
    intensity_check: orderForm.intensity_check.value,
  }

  if (useMultipleIdDetection && multiIdValid) {
    res.pseudo_id_ingredients = orderForm?.ids?.value || orderForm?.fibretrace_id?.ingredients;
  }

  fibLogger({res,useMultipleIdDetection, multiIdValid})

  return res;
}

const OrderFormV2 = ({orderData, isShow, onSubmit, onClose, orderCode, step}: any) => {

  const langData = useAppSelector(languageState);
  const company = useAppSelector(companyState);
  const appConfig = useAppSelector(configState);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  /**
   * @todo
   * change $scannerFunction back to normal
   */
  const scannerFunction = company.scanner_function_available;
  // const scannerFunction = true;

  const [scannerFunctionTooltip, setScannerFunctionTooltip] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState({...DEFAULT_MODAL});

  const [confirmCompleteModal, setConfirmCompleteModal] = useState({...DEFAULT_MODAL});
  const [publishTransCert, setPublishTransCert] = useState({...DEFAULT_MODAL});

  const [item, setItem] = useState({} as any);
  const [originalForm, setOriginalForm] = useState({} as any);
  const [originalOrderData, setOriginalOrderData] = useState({order_status: 2} as any);

  const [hasPendingAudits, setHasPendingAudits] = useState(false);
  const [intensityModule, setIntensityModule] = useState(false);
  const [tags, setTags] = useState({...DEFAULT_SELECT});

  const orderFormDefault = {
    fibretrace_product_id: {
      value: '',
      rules: [InputRules.REQUIRED],
      isValid: false,
      error: '',
      editable: true,
    },
    status: {
      value: 1,
      rules: [InputRules.REQUIRED],
      isValid: true,
      error: '',
      editable: true,
    },
    quantity: {
      value: 1 as any,
      rules: [InputRules.REQUIRED],
      isValid: true,
      error: '',
      editable: true,
    },
    uom_id: {
      value: 0,
      rules: [InputRules.REQUIRED],
      isValid: false,
      error: '',
      editable: true,
    },
    order_date: {
      value: moment().format(DATE_FORMAT),
      rules: [InputRules.REQUIRED],
      isValid: true,
      error: '',
      editable: true,
    },
    retail_date: {
      value: "",
      rules: [InputRules.REQUIRED],
      isValid: true,
      error: '',
      editable: true,
    },
    eco_visible: {
      value: false,
      rules: [] as InputRules[],
      isValid: true,
      error: '',
      editable: true,
    },
    condition_brand: {
      value: false,
      rules: [] as InputRules[],
      isValid: true,
      error: '',
      editable: true,
    },
    condition_manufacturer: {
      value: false,
      rules: [] as InputRules[],
      isValid: true,
      error: '',
      editable: true,
    },
    condition_whitelist: {
      value: false,
      rules: [] as InputRules[],
      isValid: true,
      error: '',
      editable: true,
    },
    condition_ip: {
      value: false,
      rules: [] as InputRules[],
      isValid: true,
      error: '',
      editable: true,
    },
    condition_fos: {
      value: false,
      rules: [] as InputRules[],
      isValid: true,
      error: '',
      editable: true,
    },
    fibretrace_id: {
      value: -1 as any,
      rules: [InputRules.REQUIRED],
      isValid: true,
      error: '',
      editable: true,
    },
    frequency_of_scan: {
      value: Number(appConfig.frequency_of_scan),
      rules: [InputRules.REQUIRED],
      isValid: true,
      error: '',
      editable: true,
    },
    frequency_of_scan_period: {
      value: Number(appConfig.frequency_of_scan_period),
      rules: [InputRules.REQUIRED],
      isValid: true,
      error: '',
      editable: true,
    },
    reference_id: {
      value: "",
      rules: [],
      isValid: true,
      error: '',
      editable: true,
    },
    countries: {
      value: [],
      rules: [] as InputRules[],
      isValid: true,
      error: '',
      editable: true,
    },
    mark_as_completed: {
      value: "" as "" | boolean,
      rules: [],
      isValid: true,
      error: '',
      step: 1,
      editable: false
    },
    intensity_check: {
      value: false,
      rules: [],
      isValid: true,
      error: '',
      step: 1,
      editable: false
    },
    tags: {
      value: false,
      rules: [],
      isValid: true,
      error: '',
      step: 1,
      editable: true
    },
    ids: {
      value: [] as any,
      rules: [] as InputRules[],
      isValid: true,
      error: '',
      editable: true,
    },
  }
  const [metaLoaded, setMetaLoaded] = useState(false)
  const [orderForm, setOrderForm] = useState({...orderFormDefault});
  const [listCountry, setListCountry] = useState([]);
  const [listFID, setListFID] = useState([] as Array<FIBRETRACE_IDS>);
  const [products, setProducts] = useState({...DEFAULT_SELECT})
  const [uoms, setUoms] = useState([])
  const [globalMsg, setGlobalMsg] = useState({
    type: '',
    text: ''
  });
  const [componentData, setComponentData] = useState({...DEFAULT_PANEL_SETTINGS})
  const [tmpOrderCode, setTmpOrderCode] = useState("");
  const [orderDetail, setOrderDetail] = useState({} as any)


  /**
   * FIP-2843
   */
  const [showMultipleIdDetection, setShowMultipleIdDetection] = useState(false);
  /**
   * hide switcher but set true by default
   * plan to remove $useMultipleIdDetection later
   */
  const [useMultipleIdDetection, setUseMultipleIdDetection] = useState(true);
  const [multipleID, setMultipleID] = useState<Map<number, FIBRETRACE_IDS>>(new Map())
  const [percentMultipleID, setPercentMultipleID] = useState<Map<number, number | string>>(new Map())
  const [multiIdValid, setMultiIdValid] = useState(false);
  const [multipleFIDS, setMultipleFIDS] = useState([] as Array<FIBRETRACE_IDS>);

  const inputHandler = async (e: any = {}) => {
    try {
      const target = e.target;
      const value = target?.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      const f = {...orderForm};
      let error: string;
      ({error} = validate(value, f[name].rules));
      f[name].error = error || "";
      f[name].isValid = !error;

      if (name && !_.isNil(value)) {
        f[name].value = value;
      }

      await formHandle.validate({...orderForm, ...f});

    } catch (e) {
      alert(e.toString())
    }
  }

  const handleChangeCountry = async (event: any) => {
    await inputHandler({
      target: {
        name: 'countries',
        value: event
      }
    })
  };

  const handleChangeDate = async (event: any, name: string) => {
    await inputHandler({
      target: {
        name,
        value: event.format(ORDER_DATE_FORMAT)
      }
    })
  };

  const assignOrderFormData = async (data: any = {}, orderDetail: any = {}) => {
    try {
      if (data) {
        if (_.findIndex(products.list, (v: any) => v.id === data.fibretrace_product_id.value) !== -1) {
          let idx = products.list[_.findIndex(products.list, (v: any) => v.id === data.fibretrace_product_id.value)];
          if (idx) products.selected = idx;

          await formHandle.onSelectChange({value: idx}, 'fibretrace_product_id');
          setProducts({...products, selected: idx})

        } else {
          await formHandle.onSelectChange({value: data.fibretrace_product_id.value}, 'fibretrace_product_id');
          setProducts({
            ...products, selected: {
              value: data.fibretrace_product_id.value,
              label: orderDetail?.order?.product_name || ""
            }
          })
        }

        if (data.fibretrace_product_id) data.fibretrace_product_id.isValid = true;
        let tmp = {
          fibretrace_product_id: {
            ...orderForm.fibretrace_product_id,
            ...data.fibretrace_product_id
          },
          status: {
            ...orderForm.status,
            ...data.status
          },
          quantity: {
            ...orderForm.quantity,
            ...data.quantity
          },
          uom_id: {
            ...orderForm.uom_id,
            ...data.uom_id
          },
          order_date: {
            ...orderForm.order_date,
            ...data.order_date
          },
          retail_date: {
            ...orderForm.retail_date,
            ...(
              !moment(data.retail_date.value).isAfter(maxAuditDeadline)
                ? data.retail_date
                : {
                  editable: data.retail_date.editable,
                  isValid: false,
                  value: ""
                }
            )
          },
          eco_visible: {
            ...orderForm.eco_visible,
            ...data.eco_visible
          },
          condition_brand: {
            ...orderForm.condition_brand,
            ...data.condition_brand
          },
          condition_manufacturer: {
            ...orderForm.condition_manufacturer,
            ...data.condition_manufacturer
          },
          condition_whitelist: {
            ...orderForm.condition_whitelist,
            ...data.condition_whitelist
          },
          condition_ip: {
            ...orderForm.condition_ip,
            ...data.condition_ip
          },
          condition_fos: {
            ...orderForm.condition_fos,
            ...data.condition_fos
          },
          fibretrace_id: {
            ...orderForm.fibretrace_id,
            ...{
              editable: data.fibretrace_id.editable,
              value: scannerFunction && data.fibretrace_id.value ? data.fibretrace_id.value : -1
            }
          },
          frequency_of_scan: {
            ...orderForm.frequency_of_scan,
            ...data.frequency_of_scan
          },
          frequency_of_scan_period: {
            ...orderForm.frequency_of_scan_period,
            ...data.frequency_of_scan_period
          },
          reference_id: {
            ...orderForm.reference_id,
            ...data.reference_id
          },
          countries: {
            ...orderForm.countries,
            ...data.countries,
            value: data.countries.value.map(countryID => ({id: countryID}))
          },
          mark_as_completed: {
            ...orderForm.mark_as_completed,
            ...data.mark_as_completed,
          },
          intensity_check: {
            ...orderForm.intensity_check,
            ...data.intensity_check,
          },
          tags: {
            ...orderForm.tags,
            ...data.tags,
          },
        };
        const tagsResponse: any = await TAGS_API.getTags({orderCode, type: 'order'});
        const {data: tagsData = []} = tagsResponse
        const tagsFormat = tagsData.map((i: any) => {
          const {id: value, name: label} = i;
          return {...i, value, label}
        })
        if (!_.isEmpty(data.tags.value) && step !== USER_ACTION.CREATE) {
          setTags({
            ...tags,
            selected: _.intersectionWith(tagsFormat, data.tags.value, (o: any, i: any) => o.id === i.id),
            list: [...tagsFormat]
          })
        } else {
          setTags({
            ...tags,
            list: [...tagsFormat]
          })
        }
        setOriginalForm(tmp)

        await formHandle.validate(tmp);

        fibLogger({data})

        setItem(data)

        setOriginalOrderData(data);

        if (data.fibretrace_id && _.size(data.fibretrace_id?.ingredients) > 0) {

          if (!_.find(listFID, (fid) => fid.id === data.fibretrace_id.id)) {
            setListFID(prevState => {
              prevState.push(data.fibretrace_id)
              return prevState
            })
          }

          setShowMultipleIdDetection(true)
          setUseMultipleIdDetection(true)
          /**
           * Case Edit old order which has multiple_id_detection loaded
           */

          let mID: Map<number, FIBRETRACE_IDS> = new Map();
          let pmID: Map<number, number | string> = new Map();

          _.map(data.fibretrace_id?.ingredients, (fid: any, index: number) => {
            const {id, percentage} = fid;
            let sel = _.find(listFID, (lf) => lf.id === id)
            // if (sel) mID.set(index, sel)
            mID.set(index, fid)
            pmID.set(index, percentage)
          })

          setMultipleID(mID)
          setPercentMultipleID(pmID)
        }


        return data
      }
    } catch (e) {
      console.error(e)
    }
  }

  const formHandle = {
    validate: async (data = {}, trigger: boolean = true) => {
      if (!_.isEmpty(data)) {
        setOrderForm({...orderForm, ...data})
      }
      setComponentData({
        ...componentData,
        form: {...orderForm, ...data},
        isValid: _.every({...orderForm, ...data}, (v: any) => v.isValid)
      })
      if (trigger) {

      }
    },
    onBlur: inputHandler,
    onChange: inputHandler,
    onSelectChange: async (selected: any, field: any) => {
      try {
        const f = {...orderForm}, {error} = validate(selected.value, f[field].rules);

        if (_.has(f, field)) {

          f[field].error = error || "";
          f[field].isValid = !error;

          if (selected.value) {
            f[field].value = selected.value;
            setProducts({...products, selected})
          }
        }

        await formHandle.validate({...orderForm, ...f});
      } catch (e) {
        console.error(e)
      }
    },
    onSubmit: async (event: any = {}, red = {redirect: false, path: ""}, extra = {step: step}) => {
      try {
        setComponentData(prevState => ({
          ...prevState,
          processing: true
        }));

        const attachTags = _.differenceWith(tags.selected, _.has(originalOrderData, 'tags') ? originalOrderData.tags.value : [], (curr: any, prev: any) => curr.id === prev.id)
        const detachTags = _.differenceBy(_.has(originalOrderData, 'tags') ? originalOrderData.tags.value : [], tags.selected, 'id')

        if (!_.isEmpty(event)) {
          const {preventDefault} = event
          if (typeof preventDefault === 'function') {
            preventDefault()
          }
        }

        setGlobalMsg({
          type: '',
          text: ''
        });

        if (componentData.isValid) {
          const formData = buildOrderData({orderForm, useMultipleIdDetection, multiIdValid});

          let resultReq;
          if (orderCode) {
            resultReq = await ORDER_API.updateOrder(orderCode, formData);
          } else {
            resultReq = await ORDER_API.createOrder(formData);
          }
          const {error, data} = resultReq;
          if (error) {
            setGlobalMsg({
              type: Message.ERROR,
              text: error
            });
          } else {
            if (data) {
              let {code} = data;
              /**
               * attach tags to order
               */
              if (!_.isEmpty(attachTags)) await TAGS_API.attachTags({
                model_id: code,
                tags: _.compact(_.map(attachTags, (t: any) => t.id)),
                type: 'order'
              })
              /**
               * detach tags from product
               */
              if (!_.isEmpty(detachTags)) {
                await TAGS_API.detachTags({
                  model_id: code,
                  tags: _.compact(_.map(detachTags, (t: any) => t.id)),
                  type: 'order'
                })
              }
              if (code) setTmpOrderCode(code);
              if (red.redirect) navigate(red.path + `/${code}`)
            }
            if (!red.redirect && _.isFunction(onSubmit)) onSubmit(extra);
            dispatch(panelSlice.actions.hide())
          }
        }
      } catch (e) {
        setGlobalMsg({
          type: Message.ERROR,
          text: e.toString()
        });
      } finally {
        setComponentData(prevState => ({
          ...prevState,
          processing: false
        }));
      }

    },

  };

  const resetComponent = async () => {
    try {
      setProducts({...DEFAULT_SELECT})
      setListCountry([])
      setListFID([])
      setMultipleFIDS([])
      setOrderForm({...orderFormDefault})
      setMetaLoaded(false)

    } catch (e) {
      console.error(e)
    }
  }

  const orderHandle = {
    open: (item) => {
    },
    close: () => {
    },
    delete: async (item) => {
      try {
        const {order_code} = item;
        if (order_code) {
          blockPage();
          const {error, message} = await ORDER_API.deleteOrderByCode(order_code)

          dispatch(showMessage({
            style: {zIndex: 999999999},
            isShow: true,
            message: message || error,
            title: "",
            type: error ? Message.ERROR : Message.SUCCESS
          }))

          if (_.isFunction(onClose)) onClose();
        }
      } catch (e) {
        console.error(e)
        dispatch(showMessage({
          isShow: true,
          style: {zIndex: 999999999},
          type: Message.ERROR,
          title: "LABEL.ERROR",
          message: e.toString()
        }))
        if (_.isFunction(onClose)) onClose();
      }

    },
    submit: () => {
    }
  }

  const onSelectMultipleID = () => {
    try {
      let ids: any = []
      _.forEach(Array.from(multipleID), ([key, value]) => {
        ids.push(value.id)
      });

      setListFID(prevState => {
        prevState.map(fid => {
          fid.isDisabled = _.indexOf(ids, fid.id) !== -1;
          return fid;
        })
        return prevState;
      })
      validateMultipleId();
    } catch (err) {

    } finally {

    }
  }

  const onSelectFibretraceID = (value, key) => {
    try {
      if (!value?.selected?.id || value.selected.id === -1) {
        return;
      }
      setMultipleID(prev => {
        const newMap = new Map(prev)
        newMap.set(key, value.selected)
        return newMap;
      })

    } catch (err) {

    } finally {

    }
  }

  const onSelectPercentage = (opt, key) => {
    try {
      setPercentMultipleID(prev => {
        const newMap = new Map(prev)
        newMap.set(key, opt.selected.value)
        return newMap;
      })
    } catch (err) {

    } finally {

    }
  }

  const validateMultipleId = async () => {
    try {

      let ids: any = new Map();
      let perc: any = new Map();
      let tmpForm: typeof orderForm = {...orderForm};

      _.forEach(Array.from(multipleID), ([key, value]) => {
        ids.set(key, value.id)
      });

      _.forEach(Array.from(percentMultipleID), ([key, value]) => {
        perc.set(key, value)
      });

      if (ids.size === 2 && perc.size === 2) {
        setMultiIdValid(true)

        tmpForm.ids.isValid = true;
        tmpForm.ids.error = "";
        tmpForm.ids.value = [
          {
            id: ids.get(0),
            percentage: perc.get(0)
          },
          {
            id: ids.get(1),
            percentage: perc.get(1)
          }
        ]

      } else {
        setMultiIdValid(false)
        if (showMultipleIdDetection && useMultipleIdDetection) {
          tmpForm.ids.isValid = false;
        }
      }
      await formHandle.validate(tmpForm);
    } catch (err) {

    }
  }

  const onChangeMultipleIdSwitcher = (e) => {
    setUseMultipleIdDetection(e.target.checked)
    validateMultipleId();
  }

  const getFibreTraceIds = () => {
    try {
      let fids = _.filter(listFID, (fid) => fid.id !== -1 && fid.id !== "")
      /**
       * @todo
       * finalize with business and backend whether we allow user to change FIDs in the future
       * for now only return fids
       */
      return fids;
    } catch (e) {
      console.error(e)
      return []
    }
  }

  const onChangeSingleFibretraceID = async (e: any) => {
    try {

      const target = e.target;
      const {name, value} = target;

      const f = {...orderForm};
      let error: string;
      ({error} = validate(value, f[name].rules));
      f[name].error = error || "";
      f[name].isValid = !error;

      if (name && !_.isNil(value)) {
        f[name].value = value;
      }

      const t = _.find(listFID, (fid => Number(fid.id) === Number(value)))

      console.log(t)

      if (t && _.size(t.ingredients) > 0) {
        const f = _.filter(listFID, (fid) => (_.map(t.ingredients, (i) => i.id)).includes(fid.id))

        _.forEach(t.ingredients, (fid, index: number) => {
          const tid = _.find(listFID, (fid) => Number(fid.id) === Number(t.id));

          if (tid) {
            setMultipleID(prev => {
              const newMap = new Map(prev)
              newMap.set(index, fid)
              return newMap;
            })

            setPercentMultipleID(prev => {
              const newMap = new Map(prev)
              newMap.set(index, fid.percentage)
              return newMap;
            })
          }

        })

      }else{
        setMultiIdValid(false)
      }

      setShowMultipleIdDetection(t && _.size(t.ingredients) > 0)

      await formHandle.validate({...orderForm, ...f});

    } catch (e) {
      alert(e.toString())
    }
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    (async () => {
      try {
        setGlobalMsg({
          type: '',
          text: ''
        });
        const allInfoPromise = Promise.all([
          COMMON_API.getCountry(),
          COMPANY_API.getCompanyFIDs({cancelToken: source.token}),
          PRODUCTS_API.getProducts({is_paginate: false}),
          COMMON_API.getUnitOfMeasurements(),
          COMMON_API.getDefaultUOM(),

        ])

        const tagsResponse: any = await TAGS_API.getTags({type: 'order'});
        const {data: tagsData = []} = tagsResponse
        const tagsFormat = tagsData.map((i: any) => {
          const {id: value, name: label} = i;
          return {...i, value, label}
        })
        setTags({
          ...tags,
          list: [...tagsFormat]
        })

        const result = await allInfoPromise;
        const fidList = result[1].data || []
        const uomList = result[3].data || []
        const {data: p} = result[2]
        const defaultUOM = result[4].data || 'PC'

        setListCountry(result[0].data ? result[0].data.map((val: any) => ({
          ...val,
          value: val.id,
          label: val.name
        })) : []);

        setListFID([
          {
            code: translateV2("LABEL.CHOOSE_ONE"),
            id: ""
          },
          {
            code: translateV2("LABEL.FIBRETRACE_ID.NONE"),
            id: -1
          }, ...fidList]);

        setMultipleFIDS([
          ...fidList
        ])

        setUoms(uomList);

        const {pid} = getParams();

        let tmpList = [
          ...products.list,
          ...p.map((i: any) => {
            const {id: value, name: label} = i;
            return {...i, value, label}
          })
        ]
        let tmpPlist = {
          ...products,
          isLoading: false,
          isDisabled: !(p && _.size(p) > 0),
          list: [...tmpList],
        }
        let pidx = _.findIndex(tmpList, (p: any) => p.id === Number(pid))
        if (pidx !== -1) {
          tmpPlist.selected = tmpList[pidx]
        }

        let uom = uomList.length > 0 ? uomList.filter(uom => uom.code == defaultUOM) : [];

        let tmpForm = {
          ...orderForm,
          fibretrace_id: {
            ...orderForm.fibretrace_id,
            isValid: true,
            value: !orderCode ? (!_.isEmpty(fidList) && scannerFunction ? fidList[0].id : -1) : orderForm.fibretrace_id.value
          },
          fibretrace_product_id: {
            ...orderForm.fibretrace_product_id,
            isValid: pidx !== -1,
            value: pidx !== -1 ? tmpList[pidx].id : 0,
          },
          uom_id: {
            ...orderForm.uom_id,
            value: uom.length > 0 ? uom[0].id : 0,
            isValid: uom.length > 0,
          }
        }
        setProducts({...products, ...tmpPlist})
        setOrderForm({...tmpForm});
        setComponentData({
          ...componentData,
          isValid: _.every(tmpForm, (v: any) => v.isValid)
        })

        setMetaLoaded(true)

        /**
         * Intensity Check
         */
        const {data} = await COMPANY_API.getCompanyMeta({fields: ["scanning_intensity_module_available"]})
        if (data) {
          const {scanning_intensity_module_available} = data;
          setIntensityModule(scanning_intensity_module_available)
        }

      } catch (error) {
        setGlobalMsg({
          type: Message.ERROR,
          text: "ERROR.API_ERROR"
        });
      }
    })();

    return () => {
      resetComponent()
    }

  }, []);

  useEffect(() => {
    onSelectMultipleID()
  }, [multipleID])

  useEffect(() => {
    validateMultipleId();
  }, [percentMultipleID])

  useEffect(() => {

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    (async () => {
      try {
        if (orderCode && _.isBoolean(metaLoaded) && metaLoaded) {

          let tmpOrderDetail: any = {};

          const {data, error} = await ORDER_API.getOrderSummary(orderCode);
          if (data && !error) {

            setOrderDetail({...data});
            tmpOrderDetail = {...data}

            if (data?.order_process_summary?.order_process_percentage !== 100) {
              setHasPendingAudits(true)
            }

          }

          if (_.isBoolean(metaLoaded) && metaLoaded) {
            const {data} = await ORDER_API.getOrderByCode(orderCode, source.token);
            await assignOrderFormData(data, tmpOrderDetail);
          }

        }

      } catch (error) {
        setGlobalMsg({
          type: Message.ERROR,
          text: "ERROR.API_ERROR"
        });
      }

    })();

  }, [metaLoaded, orderCode]);

  // useEffect(() => {
  //
  //     if (orderCode) {
  //         (async () => {
  //             const {data, error} = await ORDER_API.getOrderSummary(orderCode);
  //             if (data && !error) {
  //                 setOrderDetail({...data});
  //
  //                 if (data?.order_process_summary?.order_process_percentage !== 100) {
  //                     setHasPendingAudits(true)
  //                 }
  //
  //             }
  //         })();
  //     }
  //
  // }, [orderCode]);

  useEffect(() => {
    // console.log({orderForm})
  }, [orderForm]);

  return (
    <>

      {globalMsg.type &&
          <div className="global-msg-wrap text-center">
              <div className="global-msg">
                {globalMsg.type === Message.ERROR && <img src={erroricon} alt=""/>}

                  <p className={`${globalMsg.type === Message.ERROR ? 'error' : ''}`}

                     dangerouslySetInnerHTML={{
                       __html:
                         translateV2(globalMsg.text, "", ({code, text}) => {
                           if (code === "ERROR.VALIDATE.CREDIT_BALANCE_NOT_ENOUGH") {
                             return `<a class="text-red" href="${APP_ROUTES_PATH.PROFILE + "?t=SERVICES"}"}>
                                               ${translateV2(code)}
                                           </a><br/>`
                           }
                           return text;
                         })
                     }}
                  />

              </div>
          </div>
      }

      {componentData.processing && <LoadingV2/>}

      <dl>
        <dt>
          <label>{translateV2("LABEL.PRODUCT")} <span
            className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
        </dt>
        <dd>
          <div className="input-group">

            <Select
              styles={colourSingleStyles}
              className="basic-single mb-2 w-100"
              classNamePrefix="select"
              isDisabled={products.isDisabled || !orderForm.fibretrace_product_id.editable}
              isLoading={products.isLoading}
              isClearable={products.isClearable}
              isSearchable={products.isSearchable}
              name="fibretrace_product_id"
              options={products.list}
              value={products.selected}
              onChange={(e) => formHandle.onSelectChange(e, 'fibretrace_product_id')}
            />

          </div>

          {orderForm.fibretrace_product_id.error &&
              <GlobalMessage errorClassName={Message.ERROR} message={orderForm.fibretrace_product_id.error}/>}

          {metaLoaded && products.list.length <= 0 && <p className="mt-2 text-red">
            {translateV2("LABEL.NO_PRODUCTS")}. <Link replace={true} to={APP_ROUTES_PATH.PRODUCTS}
                                                      className="link">{translateV2("LABEL.CREATE_PRODUCT")}</Link>
          </p>}

        </dd>

        <dt>
          <label>{translateV2("LABEL.QUANTITY")} <span
            className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
          <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                         message={translateV2('LABEL.ASTERISK') + translateV2(`LABEL.TOOLTIP.ORDER.QUANTITY`)}
          />
        </dt>
        <dd>
          <div className="input-group">
            <input type="text"
                   className="form-control" value={orderForm.quantity.value}
                   id="quantity" name="quantity"
                   disabled={!orderForm.quantity.editable}
                   min={1}
                   onChange={formHandle.onChange}
                   onBlur={async (e: any) => {
                     if (!(/^(?:\d+(?:\.\d*)?|\.\d+)?$/g.test(e.target.value)) || parseInt(e.target.value) <= 0) {
                       alert(translateV2("LABEL.ENTER_VALID_QUANTITY"));
                       await formHandle.validate({
                         ...orderForm,
                         quantity: {
                           ...orderForm.quantity,
                           value: parseInt(e.target.value)
                         }
                       })
                     } else {
                     }
                   }}
            />
          </div>
          {orderForm.quantity.error && <div className="global-msg">
              <span className="error">{translateV2(orderForm.quantity.error)}</span>
          </div>}
        </dd>

        <dt>
          <label>{translateV2("LABEL.UOM")} <span
            className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
          <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                         message={translateV2('LABEL.ASTERISK') + translateV2(`LABEL.TOOLTIP.ORDER.UOM`)}
          />
        </dt>
        <dd>
          <div className="input-group">
            <select className="form-control" id="uom_id" name="uom_id"
                    disabled={!orderForm.uom_id.editable}
                    value={orderForm.uom_id.value || 0}
                    onChange={formHandle.onChange}>
              <option value="">{translateV2('LABEL.CHOOSE_ONE')}</option>
              {
                uoms.length > 0 && uoms.map((uom: any) => <option key={`uom-${uom.id}`}
                                                                  value={uom.id}>{uom.name}</option>)
              }
            </select>
          </div>

          {orderForm.uom_id.error &&
              <GlobalMessage errorClassName={Message.ERROR} message={orderForm.uom_id.error}/>}
        </dd>

        <dt>
          <label>{translateV2("LABEL.RETAIL_OR_DELIVERY_DATE")} <span
            className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
        </dt>
        <dd>
          <div className="d-flex flex-column flex-sm-row justify-content-between">
            <div className="d-inline-flex">
              <div className="input-group">

                <div className="d-flex flex-row align-items-center">
                  <Datetime timeFormat={false} dateFormat={ORDER_DATE_FORMAT}
                            className={originalOrderData?.order_status.value === 2 || !orderForm.retail_date.editable ? "disabled" : `bg-transparent`}
                            closeOnSelect={true}
                            inputProps={{
                              autoComplete: "off",
                              readOnly: true,
                              disabled: originalOrderData?.order_status.value === 2 || !orderForm.retail_date.editable,
                              className: "form-control " + (originalOrderData?.order_status.value === 2 || !orderForm.retail_date.editable ? "bg-gray" : ""),
                            }}
                            value={
                              orderForm.retail_date.value
                                ? moment(orderForm.retail_date.value).format(DATE_FORMAT)
                                : ""
                            }
                            onChange={(val) => handleChangeDate(val, 'retail_date')}/>

                </div>

              </div>
            </div>

            <div className={`d-inline-flex`}>
              <Switch
                customClass={`text-nowrap pl-1 ${!orderForm.mark_as_completed.editable ? "text-muted" : ""}`}
                label={`OR ${translateV2("LABEL.MARK_ORDER_AS_COMPLETED")}`}
                tooltipLabel={
                  translateV2(
                    hasPendingAudits ?
                      "LABEL.MARK_ORDER_AS_COMPLETED.HAS_PENDING_AUDITS" :
                      "LABEL.MARK_ORDER_AS_COMPLETED")
                }
                val={orderForm.mark_as_completed.value} name="mark_as_completed"
                className="flex-row-reverse flex-sm-row justify-content-end "
                change={formHandle.onChange}
                options={[translateV2("LABEL.YES"), translateV2("LABEL.NO")]}
                disabled={!orderForm.mark_as_completed.editable}
              />
            </div>
          </div>

          {orderForm.retail_date.error && <div className="global-msg">
              <span className="error">{translateV2(orderForm.retail_date.error)}</span>
          </div>}
        </dd>


        <dt className={scannerFunction ? "" : " order-fib-title is-locked "}>
          <OverlayTrigger
            show={scannerFunctionTooltip}
            onToggle={() => {
              if (!scannerFunction) setScannerFunctionTooltip(!scannerFunctionTooltip)
            }}
            overlay={<Tooltip
              bsPrefix={"fib-bs-tooltip tooltip"}
              id={`orderformtit-scanner-fn`}>
              {translateV2("LABEL.UNLOCK_VERIFIED")}</Tooltip>}>
            <label
              onClick={async (event) => {
                if (!scannerFunction) navigate('/profile?t=PACKAGE');
              }}
            >
              {translateV2("LABEL.FIBRETRACE_ID")} <span
              className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span>
            </label>

          </OverlayTrigger>

          {!scannerFunction && <OverlayTrigger
              overlay={<Tooltip
                bsPrefix={"fib-bs-tooltip tooltip"}
                id={`orderformtitico-scanner-fn`}>
                {translateV2("LABEL.UNLOCK_VERIFIED")}</Tooltip>}><Image
              src={ASSETS.IconLockGray}
              loading={"lazy"} height={24} decoding={"async"}
              style={{}} className={""}
              onClick={async (event) => {
                navigate('/profile?t=PACKAGE');
              }}
          /></OverlayTrigger>}
        </dt>

        <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                       message={translateV2('LABEL.ASTERISK') + translateV2(`LABEL.TOOLTIP.ORDER.FID`)}
        />

        <dd className={scannerFunction ? "" : " order-fib-field is-locked "}>
          <div className="input-group">
            <select className="form-control" id="fibretrace_id" name="fibretrace_id"
                    disabled={!orderForm.fibretrace_id.editable}
                    value={orderForm.fibretrace_id.value}
                    onChange={onChangeSingleFibretraceID}>
              {
                listFID.length > 0 && listFID.map((fid: any) => <option
                  key={`fid-${fid.id}`}
                  value={fid.id}>{fid.code}</option>)
              }
            </select>
          </div>
          {orderForm.fibretrace_id.error && <div className="global-msg">
              <span className="error">{translateV2(orderForm.fibretrace_id.error)}</span>
          </div>}
        </dd>

        {
          !showMultipleIdDetection
            ? null
            : <>
              <dd className={`d-none`}>
                <div className="input-group">
                  <Switch label={translateV2(`LABEL.ORDER.USE_MULTIPLE_FIBRETRACE_ID`)}
                          name="multiple_id_detection"
                          className="flex-row-reverse w-auto"
                          tooltipLabel={translateV2("LABEL.ORDER.USE_MULTIPLE_FIBRETRACE_ID.TOOLTIP")}
                          customClass={"text-left"}
                          disabled={true}
                          val={useMultipleIdDetection}
                          change={onChangeMultipleIdSwitcher}
                          options={[translateV2("LABEL.YES"), translateV2("LABEL.NO")]}/>
                </div>
                {orderForm.fibretrace_id.error && <div className="global-msg">
                    <span className="error">{translateV2(orderForm.fibretrace_id.error)}</span>
                </div>}
              </dd>

              <dt>
                <p className="text-muted mb-1">{translateV2("LABEL.INGREDIENTS")}</p>
              </dt>

              {
                !useMultipleIdDetection
                  ? <dd className={scannerFunction ? "" : " order-fib-field is-locked "}>
                    <div className="input-group">
                      <select className="form-control" id="fibretrace_id" name="fibretrace_id"
                              disabled={!orderForm.fibretrace_id.editable}
                              value={orderForm.fibretrace_id.value}
                              onChange={formHandle.onChange}>
                        {
                          listFID.length > 0 && listFID.map((fid: any) => <option
                            key={`fid-${fid.id}`}
                            value={fid.id}>{fid.code}</option>)
                        }
                      </select>
                    </div>
                    {orderForm.fibretrace_id.error && <div className="global-msg">
                        <span className="error">{translateV2(orderForm.fibretrace_id.error)}</span>
                    </div>}
                  </dd>
                  : <dd>

                    <div className="fibre-multipleid-container d-flex flex-column ">

                      <div className="fibre-multipleid-row d-flex flex-row mb-2 justify-content-between">
                        <div className="fibre-multipleid-ids d-flex">
                          <SelectMultipleIdFID
                            isDisabled={true}
                            value={multipleID.get(0)}
                            optionsDirect={getFibreTraceIds()}
                            onChange={(e) => onSelectFibretraceID(e, 0)}/>
                        </div>
                        <div className="d-flex fibre-multipleid-percent ">
                          <SelectMultipleIdPercentage
                            min={parseInt(appConfig.multiple_id_detection_minimum_blending_percentage)}
                            max={100 - parseInt(appConfig.multiple_id_detection_minimum_blending_percentage)}
                            maxSelectable={100 - _.toInteger(percentMultipleID.get(1))}
                            value={percentMultipleID.get(0)}
                            onChange={(e) => onSelectPercentage(e, 0)}/>
                        </div>
                      </div>
                      <div className="fibre-multipleid-row d-flex flex-row justify-content-between">
                        <div className="fibre-multipleid-ids d-flex">
                          <SelectMultipleIdFID
                            isDisabled={true}
                            value={multipleID.get(1)}
                            optionsDirect={getFibreTraceIds()}
                            onChange={(e) => onSelectFibretraceID(e, 1)}/>
                        </div>
                        <div className="d-flex fibre-multipleid-percent">
                          <SelectMultipleIdPercentage
                            min={parseInt(appConfig.multiple_id_detection_minimum_blending_percentage)}
                            max={100 - parseInt(appConfig.multiple_id_detection_minimum_blending_percentage)}
                            maxSelectable={100 - _.toInteger(percentMultipleID.get(0))}
                            value={percentMultipleID.get(1)}
                            onChange={(e) => onSelectPercentage(e, 1)}/>
                        </div>
                      </div>


                    </div>

                  </dd>
              }

            </>
        }

        {
          (!intensityModule || Number(orderForm.fibretrace_id.value) <= 0 || _.isNil(orderForm.fibretrace_id.value))
            ? null
            : <>
              <dt>
                <label
                  htmlFor="intensity_check"
                  className={"mb-0"}>{translateV2("LABEL.SCAN_STRENGTH_NOTIFICATION")}</label>
                <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                               message={translateV2('LABEL.ASTERISK') + translateV2(`LABEL.SCAN_STRENGTH_NOTIFICATION.NOTE`)}
                />
              </dt>

              <dd className={``}>
                <div className="input-group">
                  <Switch label={``}
                          className="flex-row-reverse flex-sm-row justify-content-start "
                          val={orderForm.intensity_check.value} name="intensity_check"
                          disabled={
                            (orderCode && !orderForm.intensity_check.editable) ||
                            (
                              !(_.includes(_.toUpper(company.current_subscription.name), "VERIFIED")
                                && !_.isNil(orderForm.fibretrace_id.value))
                              && Number(orderForm.fibretrace_id.value) > 0
                            )
                          }
                          change={formHandle.onChange}
                          options={[translateV2("LABEL.YES"), translateV2("LABEL.NO")]}/>
                </div>
                {orderForm.fibretrace_id.error && <div className="global-msg">
                    <span className="error">{translateV2(orderForm.fibretrace_id.error)}</span>
                </div>}
              </dd>
            </>
        }


        <dt>
          <label
            htmlFor="reference_id"
            className={"mb-0"}>{translateV2("LABEL.REFERENCE_ID")}</label>
          <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                         message={translateV2('LABEL.ASTERISK') + translateV2(`LABEL.TOOLTIP.ORDER.REFERENCE`)}
          />
        </dt>

        <dd>
          <div className="input-group">
            <input type="text" className="form-control"
                   value={orderForm.reference_id.value || ""} onChange={formHandle.onChange}
                   id="reference_id" name="reference_id"
                   disabled={!orderForm.reference_id.editable}/>
          </div>

          {orderForm.reference_id.error && <div className="global-msg">
                            <span
                                className="error">{translateV2(orderForm.reference_id.error)}</span>
          </div>}
        </dd>

        <dt>
          <label
            htmlFor="order_tags"
            className={"mb-0"}>{translateV2("LABEL.ORDER_TAGS")} &#40;{translateV2("LABEL.MAX_FILES", "", {
            "0": 5,
            "1": translateV2("LABEL.TAGS")
          })}&#41;
          </label>
        </dt>

        <dd>
          <CreatableSelect
            styles={colourStyles}
            className="basic-multi-select mt-2 mb-2 w-100"
            classNamePrefix="select"
            cacheOptions
            defaultOptions
            isMulti
            isDisabled={step === USER_ACTION.READ || !orderForm.tags.editable}

            name="tags"
            options={tags.list}
            value={tags.selected}
            onChange={async (selected, attrs) => {
              // console.log({selected, attrs}, tags.selected)
              let tmpTags = {...tags}
              const {action, name, option, removedValue} = attrs;

              if (selected.length > 5) return alert(translateV2("LABEL.MAX_FILES", "", {
                "0": 5,
                "1": translateV2("LABEL.TAGS")
              }));

              if (action === 'create-option') {
                const {data, error} = await TAGS_API.createTag({name: option.value, type: 'order'})
                // console.log({data, error})
                if (!error) {
                  _.forIn(selected, (item: any) => {
                    console.log("item", item.__isNew__ && item.label === data.name, item, data)

                    if (item.__isNew__ && item.label === data.name) {
                      item.value = data.id
                      item.id = data.id
                    }
                    return item
                  })
                  // console.log("selected", tmpTags.selected)
                } else {
                  alert(translateV2(error))
                }
              }

              if (action === 'remove-value' && step === USER_ACTION.UPDATE) {

              }
              // console.log({selected, attrs}, tags.selected)
              // console.log(
              //     "dataTags", data.tags,
              //     _.differenceBy(data.tags, selected, 'id'),
              //     _.differenceWith(selected, data.tags, (prev: any, cur: any) => prev.id === cur.id)
              // );
              tmpTags.selected = [...selected]

              setTags({...tmpTags})
              // console.log({...tmpTags})
              await formHandle.validate();
            }}
          />
        </dd>

        {
          company.company_package_payment.eco_score &&
            <Accordion className={"mb-2"}
                       defaultActiveKey={(orderForm.eco_visible.error) ? "0" : ""}
            >
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <FontAwesomeIcon icon={faPlusCircle} className={"mr-1"}/>
                        <b>
                          {_.capitalize(translateV2("B2C.TITLE.ECO_SCORE"))}
                        </b>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <dt>
                                <label>{translateV2("LABEL.ECO_VISIBLE")}</label>
                            </dt>
                            <dd>
                                <div className="order-condition">
                                    <div className="input-group">
                                        <Switch val={orderForm.eco_visible.value} name="eco_visible"
                                                className="flex-row-reverse justify-content-end"
                                                change={formHandle.onChange}
                                                options={[translateV2("LABEL.YES"), translateV2("LABEL.NO")]}
                                                disabled={!orderForm.eco_visible.editable}/>
                                    </div>
                                </div>
                            </dd>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        }

        {
          Number(orderForm.fibretrace_id.value) !== -1 && orderForm.fibretrace_id.value &&
            <Accordion className={"mb-2"}
                       defaultActiveKey={(orderForm.frequency_of_scan.error || orderForm.frequency_of_scan_period.error) ? "0" : ""}
            >
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <FontAwesomeIcon icon={faPlusCircle} className={"mr-1"}/>
                        <b>
                          {translateV2("LABEL.ORDER.FORM.FOS_CONFIG")}
                        </b>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>

                            <dd>
                                <div className="order-condition">
                                    <div className="input-group">
                                        <Switch label={translateV2("LABEL.VERIFICATION_FOS")}
                                                val={orderForm.condition_fos.value} name="condition_fos"
                                                className="flex-row-reverse justify-content-end borderless"
                                                change={formHandle.onChange}
                                                options={[translateV2("LABEL.YES"), translateV2("LABEL.NO")]}
                                                disabled={!orderForm.condition_fos.editable}/>
                                        <br/>
                                        <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                                                       message={translateV2('LABEL.ASTERISK') + translateV2(`LABEL.ORDER.FORM.FOS_DESCRIPTION`)}
                                        />
                                    </div>
                                </div>
                            </dd>

                            <dt>
                                <label>{translateV2("LABEL.ORDER.FORM.FOS_TOTAL")} <span
                                    className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
                            </dt>
                            <dd>
                                <div className="input-group">
                                    <input type="number" className="form-control"
                                           value={orderForm.frequency_of_scan.value}
                                           onChange={formHandle.onChange} id="frequency_of_scan"
                                           name="frequency_of_scan"
                                           disabled={!orderForm.frequency_of_scan.editable}/>
                                </div>
                              {orderForm.frequency_of_scan.error && <div className="global-msg">
                            <span
                                className="error">{translateV2(orderForm.frequency_of_scan.error)}</span>
                              </div>}
                            </dd>

                            <dt>
                                <label
                                    htmlFor="ad_comment">{translateV2("LABEL.ORDER.FORM.FOS_PERIOD")} <span
                                    className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
                            </dt>
                            <dd>
                                <div className="input-group">
                                    <input type="number" className="form-control"
                                           value={orderForm.frequency_of_scan_period.value}
                                           onChange={formHandle.onChange}
                                           id="frequency_of_scan_period" name="frequency_of_scan_period"
                                           disabled={!orderForm.frequency_of_scan_period.editable}/>
                                </div>
                              {orderForm.frequency_of_scan_period.error && <div className="global-msg">
                            <span
                                className="error">{translateV2(orderForm.frequency_of_scan_period.error)}</span>
                              </div>}
                            </dd>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        }

        <dt>
          <hr/>
        </dt>
        <dd style={{color: "red", fontStyle: "italic"}}><span
          className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span> : {translateV2('LABEL.MANDATORY')}
        </dd>

      </dl>

      <div className={"mt-3 d-flex justify-content-between flex-column flex-sm-row"}>
        <Button
          type={"button"}
          variant={"success"}
          disabled={!componentData.isValid}
          onClick={async (e) => {
            // buildOrderData({orderForm, useMultipleIdDetection, multiIdValid})
            if (!orderForm.mark_as_completed.value) {
              if (orderCode && item.order_status.value === 2 && !_.isEqual(buildOrderData({
                orderForm: item,
                useMultipleIdDetection,
                multiIdValid
              }), buildOrderData({
                orderForm, useMultipleIdDetection,
                multiIdValid
              }))) {
                setPublishTransCert({
                  ...publishTransCert,
                  isShow: true,
                  title: "LABEL.PUBLISH_TRANSACTION_CERTIFICATE",
                  type: Message.ERROR,
                  message: "LABEL.PUBLISH_TRANSACTION_CERTIFICATE.NOTE",
                })
              } else {
                blockPage(true, {style: {zIndex: 9999999999},})
                await formHandle.onSubmit();
              }

            } else {
              setConfirmCompleteModal({
                ...confirmCompleteModal,
                isShow: true,
                title: "LABEL.MARK_ORDER_AS_COMPLETED",
                type: Message.NEUTRAL,
                message: `<p class="text-red mb-1">${translateV2("LABEL.CONFIRMATION_DELETE_GENERAL.ORDER", "", {"0": ""})}</p><i class="fz-13">${translateV2("LABEL.CONFIRMATION_DELETE_GENERAL.ORDER.NOTE")}</i>`,
                messageData: {}
              })
            }
          }} className={!orderCode && !isMobile ? "fpw-40" : "w-100"}
        >
          {componentData.processing && <FontAwesomeIcon icon={faSpinner} className="mr-1" spin/>}
          {translateV2("LABEL.SUBMIT")}
        </Button>

        {!orderCode && <Button type="submit"
                               variant={"success"}
                               disabled={!componentData.isValid}
                               onClick={(e) => formHandle.onSubmit({}, {
                                 redirect: true,
                                 path: APP_ROUTES_PATH.ORDERS
                               })}
                               className={isMobile ? "fpw-100 mt-2" : "fpw-58"}>
          {componentData.processing && <Loading/>}
          {translateV2("LABEL.SAVE_AND_CREATE_ORDER_PROCESS")}
        </Button>}

        {
          orderCode && !_.isNil(orderData) && <OverlayTrigger overlay={
            <Tooltip id={`edit-orderm-${orderCode}`}>
              {translateV2(!orderData?.can_be_deleted
                ? "LABEL.TOOLTIP.CANNOT_DELETE_ORDER"
                : "LABEL.DELETE_ORDER")}
            </Tooltip>}>

                <Button variant={"outline-success"}
                        className={`ml-1 ${!orderData?.can_be_deleted ? "disabled" : ""}`}
                        onClick={async () => {
                          if (orderData?.can_be_deleted) {
                            setItem({...item})
                            setConfirmationModal({
                              ...confirmationModal,
                              isShow: true,
                              title: orderCode,
                              type: Message.ERROR,
                              message: 'LABEL.CONFIRMATION_DELETE_GENERAL',
                              messageData: {
                                '0': `${translateV2("LABEL.ORDER").toLowerCase()} <b><u>${orderCode}</u></b>`,
                                '1': ''
                              }
                            })

                          }
                        }}>
                    <FontAwesomeIcon icon={faTrash}/>
                </Button>
            </OverlayTrigger>
        }


      </div>

      <ModalConfirmation
        style={{zIndex: 999999999}}
        size={'md'}
        isShow={confirmationModal.isShow}
        title={confirmationModal.title}
        type={confirmationModal.type}
        message={confirmationModal.message}
        messageData={confirmationModal.messageData}
        onSubmit={async () => {
          setConfirmationModal({...DEFAULT_MODAL})
          await orderHandle.delete({order_code: orderCode})
        }}
        onClose={() => setConfirmationModal({...DEFAULT_MODAL})}
      >

      </ModalConfirmation>

      <ModalConfirmation
        style={{zIndex: 999999999}}
        size={'lg'}
        isShow={confirmCompleteModal.isShow}
        title={confirmCompleteModal.title}
        type={confirmCompleteModal.type}
        message={confirmCompleteModal.message}
        messageData={confirmCompleteModal.messageData}
        onSubmit={async (e) => {
          blockPage(true, {style: {zIndex: 9999999999},})


          await formHandle.onSubmit({},
            {
              redirect: !orderCode,
              path: orderCode ? "" : APP_ROUTES_PATH.ORDERS
            }, {
              step: ""
            }
          )
          setConfirmCompleteModal({...DEFAULT_MODAL})
        }}
        onClose={() => {
          setOrderForm({
            ...orderForm,
            mark_as_completed: {
              ...orderForm.mark_as_completed,
              value: false
            }
          })
          setConfirmCompleteModal({...DEFAULT_MODAL})
        }}
      >

      </ModalConfirmation>

      <ModalConfirmation
        style={{zIndex: 999999999}}
        size={'md'}
        isShow={publishTransCert.isShow}
        title={publishTransCert.title}
        type={publishTransCert.type}
        message={publishTransCert.message}
        messageData={publishTransCert.messageData}
        confirmButton={{
          visible: true,
          label: "LABEL.UNDERSTAND",
          variant: "success"
        }}
        cancelButton={{
          visible: false
        }}
        onSubmit={async () => {
          try {
            blockPage(true, {style: {zIndex: 9999999999},})
            formHandle.onSubmit({}).then(() => {
              setPublishTransCert({...DEFAULT_MODAL})
            })
          } catch (e) {
            console.error(e.toString())
            dispatch(showMessage({
              style: {zIndex: 999999999},
              isShow: true,
              message: e.toString(),
              title: "LABEL.ERROR",
              type: Message.ERROR
            }))
          }
        }}
        onClose={() => {
          blockPage(true, {style: {zIndex: 9999999999},})
          formHandle.onSubmit({}).then(() => {
            setPublishTransCert({...DEFAULT_MODAL})
          })
        }}
      >

      </ModalConfirmation>

    </>
  );
}

export default OrderFormV2;
