import React, {Fragment, useEffect, useRef, useState} from 'react';
import erroricon from '@assets/images/icons/error-msg.png';
import {DATE_FORMAT, InputRules, Message, USER_ACTION} from '@src/types';
import Datetime from 'react-datetime';
import {
  blockPage, fibLogger,
  isEmptyValues,
  LocaleStringToNumber,
  NumberToLocaleString,
  translateV2,
  validate
} from '@src/helpers';
import {PRODUCTS_API} from '@api/Product';
import {COMPANY_API} from '@api/Company';
import Switch from '@components/Input/Switch';
import {ORDER_API} from '@api/Order';
import {Button, Image, OverlayTrigger, Tooltip} from 'react-bootstrap';
import _ from "lodash-es";
import moment from "moment";
import {colourSingleInlineStyles, colourSingleStyles, colourStyles} from "@components/Input/MultiSelect";
import Select from "react-select";
import {AUDIT_TEMPLATE_API} from '@api/AuditTemplate';
import {AUDIT_API} from "@api/Audit";
import {faPlusCircle, faQuestionCircle, faSpinner, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GlobalMessage from "@components/Input/Message";
import {DEFAULT_MODAL, DEFAULT_ORDER_SUMMARY, DEFAULT_SELECT} from "@src/variables";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {companyState} from "@redux/reducers/companySlice";
import {ASSETS} from "@assets/Assets";
import {useNavigate} from "react-router-dom";
import {showVideoModal, videoModalState} from "@redux/reducers/videoModalSlice";
import ModalConfirmation from "@v2components/Modal/Confirmation";
import {showMessage} from "@redux/reducers/messageSlice";
import {userMetadataState, userState} from "@redux/reducers/userSlice";
import {APP_ROUTES_PATH} from "@src/routes";
import {produce} from "immer";
import {panelSlice} from "@redux/reducers/panelSlice";

const maxAuditDeadline = moment().add(5, "year");
const inputOrdersFormat = (a: any) => {
  try {
    let r: Array<any> = [];
    _.map(a, (b: any) => {
      _.map(b.orders, (o) => {
        let d: any = {};
        d.order_code = o.order_code;
        d.quantity = o.quantity;
        d.b2c_map_view_visible = o.b2c_map_view_visible;
        r.push(d);
        return d;
      })
      return b;
    })
    return r;
  } catch (e) {
    console.error(e.toString());
    return []
  }
}

enum PROCESS_SELECT_FROM_KEY {
  MANUFACTURES = 'fibretrace_manufacturer_id',
  PROCESSES = 'fibretrace_manufacturer_process_id',
  COMPANIES = 'fibretrace_company_id',
  AUDITORS = 'fibretrace_auditor_id',
}

enum PROCESS_SELECT {
  MANUFACTURES = 'manufactures',
  PROCESSES = 'processes',
  COMPANIES = 'companies',
  AUDITORS = 'auditors',
}

type AuditorOpponent = {
  is_has_st_scanner: boolean,
  email?: string,
  fibretrace_auditor_id?: number
  label?: string,
  name?: string,
  uid?: string,
  value?: number
}

const tmpSelectMapper = {
  [PROCESS_SELECT_FROM_KEY.MANUFACTURES]: PROCESS_SELECT.MANUFACTURES,
  [PROCESS_SELECT_FROM_KEY.PROCESSES]: PROCESS_SELECT.PROCESSES,
  [PROCESS_SELECT_FROM_KEY.COMPANIES]: PROCESS_SELECT.COMPANIES,
  [PROCESS_SELECT_FROM_KEY.AUDITORS]: PROCESS_SELECT.AUDITORS,
}

const orderProcessFormDefault = {
  fibretrace_manufacturer_id: {
    value: '' as (number | string),
    rules: [],
    isValid: true,
    error: '',
    editable: true
  },
  fibretrace_manufacturer_process_id: {
    value: '' as any,
    rules: [InputRules.REQUIRED],
    isValid: false,
    error: '',
    editable: true
  },
  fibretrace_company_id: {
    value: '' as (number | string),
    rules: [],
    isValid: true,
    error: '',
    editable: true
  },
  fibretrace_auditor_id: {
    value: '' as (number | string),
    rules: [InputRules.REQUIRED],
    isValid: false,
    error: '',
    editable: true
  },
  due_date: {
    value: '' as any,
    rules: [InputRules.REQUIRED],
    isValid: false,
    error: '',
    editable: true
  },
  visible: {
    value: true,
    rules: [] as InputRules[],
    isValid: true,
    error: '',
    editable: true
  },
  notify_auditor: {
    value: true,
    rules: [] as InputRules[],
    isValid: true,
    error: '',
    editable: true
  },
  requires_fibretrace_id_when_audit: {
    value: false,
    rules: [] as InputRules[],
    isValid: true,
    error: '',
    editable: true
  },
  scan_count: {
    value: 1,
    rules: [InputRules.REQUIRED, InputRules.NUMBER_OF_AUDITS],
    isValid: true,
    error: '',
    editable: true
  },
  attributeTemplateValue: {
    value: '' as any,
    rules: [],
    isValid: true,
    error: '',
    editable: true
  },
  input_orders: {
    value: [] as any,
    rules: [],
    isValid: true,
    error: '',
    editable: true
  },
  requires_st_scanner_when_audit: {
    value: false,
    rules: [] as InputRules[],
    isValid: true,
    error: '',
    editable: true
  },
};

const OrderProcessFormV2 = ({action, isShow, handleSubmit, handleClose, options}: any) => {
  const userStore = useAppSelector(userState);
  const userMetaStoreRaw = useAppSelector(userMetadataState);
  const companyInfo = useAppSelector(companyState);
  const videoModalGlobalState = useAppSelector(videoModalState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userMetaStore = _.mapValues(userMetaStoreRaw, _.toInteger);
  const scannerFunction = companyInfo.scanner_function_available;
  const [scannerFunctionTooltip, setScannerFunctionTooltip] = useState(false);

  const [isUnclaimed, setIsUnclaimed] = useState<null | boolean>(null)

  const refDueDate = useRef(null);

  const [formStatus, setFormStatus] = useState({
    processing: false,
    isValid: false,
    message: "",
  });
  const [orderProcessForm, setOrderProcessForm] = useState({...orderProcessFormDefault});

  const [globalMsg, setGlobalMsg] = useState({
    type: '',
    text: ''
  });
  const [fibretraceId, setFibretraceId] = useState(null);

  const [selects, setSelects] = useState({
    [PROCESS_SELECT.MANUFACTURES]: {...DEFAULT_SELECT},
    [PROCESS_SELECT.PROCESSES]: {...DEFAULT_SELECT},
    [PROCESS_SELECT.COMPANIES]: {...DEFAULT_SELECT},
    [PROCESS_SELECT.AUDITORS]: {...DEFAULT_SELECT},
  })
  const [templates, setTemplates] = useState({...DEFAULT_SELECT})

  const [questions, setQuestions] = useState({
    selected: [],
    list: [],
  } as any);
  const [addProduct, setAddProduct] = useState(true);

  const [inventoryOrders, setInventoryOrders] = useState({
    full: [],
    list: [],
    current: [],
    selected: [],
  } as any)
  const [inputProducts, setInputProducts] = useState([] as any)

  const [templateChangeValue, setTemplateChangeValue] = useState({} as any);
  const [confirmationModal, setConfirmationModal] = useState({...DEFAULT_MODAL});

  // for publish transcert
  const [publishTransCert, setPublishTransCert] = useState({...DEFAULT_MODAL});
  const [originalOrders, setOriginalOrders] = useState([]);

  /**
   * FIP-2482
   *
   */
  const [unclaimedConfirmModal, setUnclaimedConfirmModal] = useState({...DEFAULT_MODAL});

  const [orderDetail, setOrderDetail] = useState<any>({...DEFAULT_ORDER_SUMMARY});
  const [orderSummary, setOrderSummary] = useState<any>();
  const manuAbortController = useRef<any>(null)
  const companyAbortController = useRef<any>(null)
  const manuProcessAbortController = useRef<any>(null)
  const companyAuditorAbortController = useRef<any>(null)
  const processAbortController = useRef<any>(null)
  const inputOrderAbortController = useRef<any>(null)
  const auditTemplateAbortController = useRef<any>(null)
  const processAuditTemplateAbortController = useRef<any>(null)

  /**
   * FIP-2903
   */
  const [auditor, setAuditor] = useState<AuditorOpponent>({
    is_has_st_scanner: false,
  });
  const [spinTurnVisible, setSpinTurnVisible] = useState(false);


  const handleFetch = {
    getManufacturers: async (selected: (unknown) = undefined) => {
      try {
        if (manuAbortController.current) {
          manuAbortController.current.abort();
        }
        manuAbortController.current = new AbortController();
        const tmpOptions = {
          include_id: undefined,
          process_id: undefined,
          signal: manuAbortController.current.signal
        };
        const tmpSelected = action === USER_ACTION.CREATE ? companyInfo.company_id : selected;
        const {process} = options;
        if (process) {
          const {manufacturer_company_id, process_id} = process;

          if (manufacturer_company_id) {
            tmpOptions.include_id = manufacturer_company_id;
          }

          if (process_id) {
            tmpOptions.process_id = process_id;
          }
        }

        const {data: list} = await PRODUCTS_API.getManufacturersV2(_.omitBy(tmpOptions, _.isNil));
        const listFormatted = list.map((i: any) => {
          const {id: value, name: label} = i;
          return {...i, value, label}
        })

        setSelects(produce(draft => {
          draft.manufactures.isLoading = false
          draft.manufactures.isDisabled = !(list && _.size(list) > 0);
          draft.manufactures.list = listFormatted
          if (tmpSelected) {
            draft.manufactures.selected = draft.manufactures.list.filter((v: any) => v.id === tmpSelected)
          } else {
            if (action === USER_ACTION.CREATE) {
              draft.manufactures.selected = draft.manufactures.list.filter((v: any) => v.id === tmpSelected)
            }
          }
        }))

        if (tmpSelected) {
          const m = _.find(list, (v: any) => v.id === tmpSelected)
          if (m) {
            fibLogger({message: "unclaimed company"})
            setIsUnclaimed(m?.is_unclaimed)

            let t: any = {
              requires_fibretrace_id_when_audit: {},
              scan_count: {}
            }

            if (m?.is_unclaimed) {
              t.requires_fibretrace_id_when_audit.value = false;
              t.scan_count.value = 1;
            }

            setOrderProcessForm(produce(draft => {
              if (m?.is_unclaimed) {
                draft.requires_fibretrace_id_when_audit.value = false
                draft.scan_count.value = 1
              }
              if (action === USER_ACTION.CREATE) {
                draft.fibretrace_manufacturer_id.value = tmpSelected as number;
                draft.fibretrace_manufacturer_id.isValid = true;
              }
            }))

            await formHandle.validate(t)
          }
        }

      } catch (e) {
        console.error(e)
      }
    },
    getCompanies: async () => {
      try {
        if (companyAbortController.current) {
          companyAbortController.current.abort();
        }
        companyAbortController.current = new AbortController();

        const {orderCode: order_code, processID: process_id} = options;
        const tmpOptions = {
          include_id: undefined, order_code, process_id,
          signal: companyAbortController.current.signal
        };
        const {process} = options;

        if (process) {
          const {audit_company_id} = process;
          if (audit_company_id) {
            tmpOptions.include_id = audit_company_id;
          }
        }

        const {data: list} = await COMPANY_API.getCompaniesV2(_.omitBy(tmpOptions, _.isNil));
        const listFormatted = list.map((i: any) => {
          const {id: value, name: label} = i;
          return {...i, value, label}
        })
        setSelects(produce(draft => {
          draft.companies.isLoading = false
          draft.companies.isDisabled = !(list && _.size(list) > 0);
          draft.companies.list = listFormatted;

          if (action === USER_ACTION.CREATE) {
            draft.companies.selected = draft.companies.list.filter((v: any) => v.id === companyInfo.company_id)
          }

          if (action === USER_ACTION.EDIT) {
            draft.companies.selected = draft.companies.list.filter((v: any) => v.id === options?.process?.audit_company_id)
          }
        }))

        if (action === USER_ACTION.CREATE) {
          setOrderProcessForm(produce(draft => {
            draft.fibretrace_company_id.value = companyInfo.company_id as number;
            draft.fibretrace_company_id.isValid = true;
          }))
        }


      } catch (e) {
        console.error(e)
      }
    },
    getManufacturerProcesses: async (manufacturer_id: (number | string)) => {
      try {
        if (manufacturer_id) {

          if (manuProcessAbortController.current) {
            manuProcessAbortController.current.abort();
          }
          manuProcessAbortController.current = new AbortController();

          const tmpOptions = {process_id: undefined, signal: manuProcessAbortController.current.signal};
          const {process} = options;
          if (process) {
            const {manufacturer_company_id, process_id} = process;

            if (process_id) {
              tmpOptions.process_id = process_id;
            }
          }

          const {data: list} = await PRODUCTS_API.getProcessByManufacturer(manufacturer_id, _.omitBy(tmpOptions, _.isNil));

          setSelects(produce(draft => {
            draft.processes.isLoading = false
            draft.processes.isDisabled = !(list && _.size(list) > 0);
            draft.processes.list = list.map((i: any) => {
              const {id: value, name: label} = i;
              return {...i, value, label}
            })

          }))
        }
      } catch (error) {
        console.error("MSG", error)
      }
    },
    getCompanyAuditors: async (company_id: (number | string), selected: (unknown) = undefined) => {
      try {
        if (company_id) {
          if (companyAuditorAbortController.current) {
            companyAuditorAbortController.current.abort();
          }
          companyAuditorAbortController.current = new AbortController();

          const {orderCode: order_code, processID: process_id} = options;
          const {data: list} = await COMPANY_API.getAuditorByCompany(_.omitBy({
            id: company_id,
            signal: companyAuditorAbortController.current.signal,
            order_code,
            process_id,
          }, _.isNil));

          let formattedList = list.map((i: any) => {
            let {fibretrace_auditor_id: value, email, name: label} = i;
            label = `${label} (${email})`;
            return {...i, value, label}
          })

          setSelects(produce(draft => {
            draft.auditors.isLoading = false
            draft.auditors.isDisabled = !(list && _.size(list) > 0);
            draft.auditors.list = formattedList
            if (selected) {
              draft.auditors.selected = draft.auditors.list.filter((v: any) => v.fibretrace_auditor_id === selected)
            }
          }))

          let t = _.find(formattedList, (v: any) => v.fibretrace_auditor_id === selected)
          if (t) {
            setOrderProcessForm(produce(draft => {
              draft.fibretrace_auditor_id.value = t.fibretrace_auditor_id
              draft.fibretrace_auditor_id.isValid = true
            }))
          }
        }
      } catch (error) {
        setGlobalMsg({
          type: Message.ERROR,
          text: "ERROR.API_ERROR"
        });
      }
    },
    setDefaultManufacturer: async () => {
      try {
        const {process} = options;
        const {company_id} = companyInfo;
        if (_.isEmpty(process) && company_id) {
          const curr = _.find(selects.manufactures.list, (v: any) => v.id === company_id)

          if (curr) {
            const {value: manufacturer_company_id, is_unclaimed = false} = curr;
            if (manufacturer_company_id) {
              /**
               * Checking selected manufacturer is unclaimed or not
               */
              setIsUnclaimed(is_unclaimed)

              setOrderProcessForm(produce(draft => {
                draft.fibretrace_manufacturer_id.value = manufacturer_company_id
                draft.fibretrace_company_id.value = manufacturer_company_id
                if (is_unclaimed) {
                  draft.requires_fibretrace_id_when_audit.value = false
                }
              }))
              if (action === USER_ACTION.CREATE) {
                setSelects(produce(draft => {
                  draft.manufactures.selected = draft.manufactures.list.filter((v: any) => v.id === manufacturer_company_id)
                  draft.companies.selected = draft.companies.list.filter((v: any) => v.id === manufacturer_company_id)
                }))
              }

            }
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const getProcessData = async () => {
    try {
      if (processAbortController.current) {
        processAbortController.current.abort();
      }
      processAbortController.current = new AbortController();

      const {data} = await ORDER_API.getProcessByIDV2({
        orderCode: options.orderCode,
        id: options.processID,
        signal: processAbortController.current.signal
      });
      if (data) {
        setOrderProcessForm(produce(draft => {
          _.merge(draft.fibretrace_manufacturer_id, data.fibretrace_manufacturer_id)
          _.merge(draft.fibretrace_manufacturer_process_id, data.fibretrace_manufacturer_process_id)
          _.merge(draft.fibretrace_company_id, data.fibretrace_company_id)
          _.merge(draft.fibretrace_auditor_id, data.fibretrace_auditor_id)
          _.merge(draft.due_date, data.due_date,)
          _.merge(draft.visible, data.visible)
          _.merge(draft.notify_auditor, data.notify_auditor)
          _.merge(draft.requires_fibretrace_id_when_audit, data.requires_fibretrace_id_when_audit)
          _.merge(draft.requires_st_scanner_when_audit, data.requires_st_scanner_when_audit)
          _.merge(draft.scan_count, data.scan_count)
          _.merge(draft.attributeTemplateValue, data.attributeTemplateValue)
          draft.fibretrace_manufacturer_process_id.isValid = true;
          draft.fibretrace_auditor_id.isValid = true;
          draft.due_date.isValid = true;
        }));

        await inventoryHandle.getInputProducts();

        setOriginalOrders(data.input_products.value)

        setInputProducts(data.input_products.value)

        await handleAuditorCompany({fibretrace_company_id: data.fibretrace_company_id.value});
      }
    } catch (error) {
      setGlobalMsg({
        type: Message.ERROR,
        text: "ERROR.API_ERROR"
      });
    }
  }

  const getCommonInformation = async () => {
    try {
      setGlobalMsg({type: '', text: ''});
      await handleFetch.getManufacturers(options?.process?.manufacturer_company_id);
      await handleFetch.getCompanies();
      await handleFetch.setDefaultManufacturer()
    } catch (error) {
      setGlobalMsg({
        type: Message.ERROR,
        text: "ERROR.API_ERROR"
      });
    }
  }

  const productTemplateHandle = {
    getBatch: async (extra = {}) => {
      try {

        if(!companyInfo.company_package_payment.audit_template) return false;

        if (auditTemplateAbortController.current) {
          auditTemplateAbortController.current.abort()
        }
        auditTemplateAbortController.current = new AbortController();

        setTemplates({...templates, isLoading: true})
        let tp = {...templates};
        const {data, error} = await AUDIT_TEMPLATE_API.getTemplatesV2({
          is_paginate: false,
          signal: auditTemplateAbortController.current.signal
        });

        if (data && !error) {
          tp.isDisabled = _.isEmpty(data);
          tp.isLoading = false;
          tp.list = _.map(data, (v: any) => {
            let {uid, name} = v;
            return {...v, value: uid, label: name}
          })
        }
        setTemplates({...templates, ...tp})
      } catch (e) {
        console.log("productTemplateHandle ERR", e)

        console.error(e)
      }
    },
    getCurrentTemplates: async (extra = {} as any) => {

      try {

        if (processAuditTemplateAbortController.current) {
          processAuditTemplateAbortController.current.abort()
        }

        processAuditTemplateAbortController.current = new AbortController();

        setTemplates(produce(draft => {
          draft.isLoading = true
        }))
        let tp = {...templates}, list: any = [];
        const attrTemplates = await AUDIT_API.getOrderProcessAuditTemplate({
          uid: options.process.uid,
          signal: processAuditTemplateAbortController.current.signal
        })
        if (!_.isEmpty(attrTemplates)) {
          list = _.map(attrTemplates, (v: any) => {
            let {uid, name} = v;
            return {...v, value: uid, label: name}
          })
        }
        setTemplates(produce(draft => {
          draft.isDisabled = true;
          draft.isLoading = false;
          draft.selected = list;
        }))

        await formHandle.onTemplateChange(list, {action: "select-option", removedValue: []})

      } catch (e) {
        console.error(e)
        setTemplates(produce(draft => {
          draft.isLoading = false
        }))
      }
    },
    onChange: async (data: any) => {
      try {
        let ques = {...questions};
        let {selected} = data;

        let i = _.findIndex(ques.selected, (o: any) => {
          return o.templateId === selected.templateId;
        });

        if (i === -1) ques.selected.push(selected);
        else ques.selected[i] = {...ques.selected[i], ...selected};

      } catch (e) {
        console.error(e)
      }
    }
  }

  const inventoryHandle = {
    getInputProducts: async () => {
      try {

        if (inputOrderAbortController.current) {
          inputOrderAbortController.current.abort();
        }

        inputOrderAbortController.current = new AbortController();

        let opt = {orderCode: options.orderCode} as any;
        opt.signal = inputOrderAbortController.current.signal;
        if (options.processID) opt.order_process_id = options.processID;

        const {data, error} = await ORDER_API.getOrderProductsInventory(_.omitBy(opt, _.isNil))

        if (!error) {
          let inv = {...inventoryOrders};
          if (_.isArray(data)) {
            let tmp: any = [];

            _.map(data, (v: any) => {
              v.label = v.name;
              v.value = v.id;
              let l = _.map(v.orders, (o: any) => {
                let t: any = {...o, value: o.order_code, label: o.order_code};
                t.quantity = parseFloat(t.quantity).toFixed(4);
                t.ref = React.createRef()
                t.ref_b2c_visible = React.createRef()
                t.b2c_map_view_visible = false;

                tmp.push(t);
                return t;
              })
              v.list = l
              v.orders = l
              v.current = [];
              return v;
            });

            inv.list = [...data];
            inv.full = [...data];

            setInventoryOrders({...inventoryOrders, ...inv});
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    setSelected: async () => {
      try {
        let keys = ["order_code", "quantity", "b2c_map_view_visible"];
        let i = {...inventoryOrders}, s: any = [];
        _.map(i.current, (ob: any) => {
          _.map(ob.current, (p: any) => {
            let o = _.pick(p, keys);
            let v = _.values(o);

            if (!_.some(v, isEmptyValues)) {
              s.push(o);
            }
            return p
          })
          return ob;
        })
        return s;
      } catch (e) {
        console.error(e)
      }
    },
    onChange: async (data: any = {}) => {
      try {
        let i = {...inventoryOrders, ...data}
        i.selected = [...await inventoryHandle.setSelected()]
        setInventoryOrders({...i})
        await formHandle.validate()
      } catch (e) {
        console.error(e)
      }
    },
    onAddProduct: async () => {
      try {
        let i = inventoryOrders, t = {
          id: '',
          name: '',
          orders: [],
          current: [],
          selected: [],
        };
        if (_.size(i.current) < _.size(i.full)) {
          i.current.push(t);
          await inventoryHandle.onChange({...inventoryOrders, ...i});
        }
      } catch (e) {
        console.error(e)
      }
    },
    onRemoveProduct: async (e: any, itemIndex: number) => {
      try {
        let i = inventoryOrders;
        let item = i.current[itemIndex]

        let spi = _.find(inventoryOrders.list, (o: any) => o.id === item.id);
        if (spi) spi.isDisabled = false;

        // console.log({e, spi}, item,);

        i.current.splice(itemIndex, 1);
        await inventoryHandle.onChange({...inventoryOrders, ...i});
      } catch (e) {
        console.error(e)
      }
    },
    onSelectProduct: async (e: any, f: any, itemIndex: number) => {
      try {
        let i = inventoryOrders;
        let spi = _.find(inventoryOrders.list, (o: any) => o.id === e.id);
        if (spi) spi.isDisabled = true
        i.current[itemIndex] = {...e};
        // console.log({e, spi}, inventoryOrders.list)

        await inventoryHandle.onChange({...inventoryOrders, ...i});
      } catch (e) {
        console.error(e)
      }
    },
    onAdd: async (productIndex: number) => {
      try {
        let i = inventoryOrders, t = {
          order_code: "",
          quantity: "" as any,
          min: 0 as any,
          max: 0 as any
        };

        i.current[productIndex].current.push(t);
        await inventoryHandle.onChange({...inventoryOrders, ...i});
      } catch (e) {
        console.error(e)
      }
    },
    onRemove: async (e: any, itemIndex: number, productIndex: number) => {
      try {
        let i = inventoryOrders;
        let item = i.current[productIndex].current[itemIndex];
        let spi = _.find(i.current[productIndex].list, (o: any) => o.order_code === item.order_code);
        if (spi) spi.isDisabled = false;
        i.current[productIndex].current.splice(itemIndex, 1);
        await inventoryHandle.onChange({...inventoryOrders, ...i});
      } catch (e) {
        console.error(e)
      }
    },
    onSelectOrder: async (e: any, f: any, itemIndex: number, productIndex: number) => {
      try {

        let i = inventoryOrders;
        let item = i.current[productIndex].current[itemIndex];
        let ref = item.ref

        let list = _.differenceBy(i.current[productIndex].orders, i.current[productIndex].current, "order_code")
        let spi = _.find(i.current[productIndex].list, (o: any) => o.order_code === e.order_code);
        if (spi) spi.isDisabled = true;

        i.current[productIndex].current[itemIndex] = {
          ...e,
          max: e.inventory,
          quantity: 0,

        }

        if (!itemIndex) {
          i.current[productIndex].current[itemIndex].b2c_map_view_visible = true
        }

        if (ref?.current?.value > e.inventory) {
          ref.current.value = ""
        }
        await inventoryHandle.onChange(i);
      } catch (e) {
        console.error(e)
      }
    },
    onInputQuantity: async (e: any, itemIndex: number, productIndex: number) => {
      try {
        let i = inventoryOrders;
        let ref = i.current[productIndex].current[itemIndex].ref

        if (!(/^(?:\d+(?:\.\d*)?|\.\d+)?$/g.test(e.target.value))) {
          return false
        }

        let value = parseFloat(e.target.value) || 0;

        const {max} = i.current[productIndex].current[itemIndex];
        i.current[productIndex].current[itemIndex].quantity = value > max ? max : value;

        if (value > max) ref.current.value = max;

        await inventoryHandle.onChange({...inventoryOrders, ...i});
        // console.log("onInputQuantity")

      } catch (e) {
        console.error(e)
      }
    },
    onBlurInputQuantity: async (e: any, itemIndex: number, productIndex: number) => {
      try {
        let i = inventoryOrders;
        let ref = i.current[productIndex].current[itemIndex].ref

        if (!(/^(?:\d+(?:\.\d*)?|\.\d+)?$/g.test(e.target.value))) {
          alert(translateV2("LABEL.ENTER_VALID_QUANTITY"));
          ref.current.value = "";
          return false
        }

        let value = parseFloat(e.target.value) || 0;

        const {max} = i.current[productIndex].current[itemIndex];
        i.current[productIndex].current[itemIndex].quantity = parseFloat(value > max ? max : value)

        if (value > max) ref.current.value = max;
        ref.current.value = NumberToLocaleString(i.current[productIndex].current[itemIndex].quantity)

        await inventoryHandle.onChange({...inventoryOrders, ...i});
        // console.log("onBlurInputQuantity")
      } catch (e) {
        console.error(e)
      }
    },
    onFocusInputQuantity: async (e: any, itemIndex: number, productIndex: number) => {
      try {
        let i = inventoryOrders, value;
        let ref = i.current[productIndex].current[itemIndex].ref || React.createRef()

        if (e.target.value.length > 0 && isNaN(e.target.value)) {
          value = LocaleStringToNumber(e.target.value)
          ref.current.value = value;
        }

        value = parseFloat(value) || 0;

        const {max} = i.current[productIndex].current[itemIndex];
        i.current[productIndex].current[itemIndex].quantity = value > max ? max : value;

        if (value > max) ref.current.value = max;

        await inventoryHandle.onChange({...inventoryOrders, ...i});
        // console.log("onInputQuantity")

      } catch (e) {
        console.error(e)
      }
    },
    onCheckB2CVisible: async (e: any, itemIndex: number, productIndex: number) => {
      try {
        // console.log(e)
        let i = inventoryOrders, value;
        let ref = i.current[productIndex].current[itemIndex].ref_b2c_visible || React.createRef()

        i.current[productIndex].current[itemIndex].b2c_map_view_visible = e.target.checked;
        ref.current.checked = e.target.checked;

        await inventoryHandle.onChange({...inventoryOrders, ...i});
      } catch (e) {
        console.error(e)
      }
    },
  }

  const inputHandler = async (e: any = {}) => {
    try {
      const target = e.target;
      const value = target?.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      let f: any = {}
      f[name] = f[name] || {};

      let {error} = validate(value, orderProcessForm[name].rules);
      f[name].error = error || "";
      f[name].isValid = !error;

      if (name && !_.isNil(value)) {
        f[name].value = value;
      }

      await formHandle.validate({...f});
    } catch (err) {
      console.error(err, e)
    }
  }

  const onChangeRequireFID = async (e: any = {}) => {
    try {
      const target = e.target;
      const value = target?.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      let f: any = {}
      f[name] = f[name] || {...orderProcessForm[name]};
      f.requires_st_scanner_when_audit = {...orderProcessForm.requires_st_scanner_when_audit};

      let {error} = validate(value, orderProcessForm[name].rules);
      f[name].error = error || "";
      f[name].isValid = !error;

      if (name && !_.isNil(value)) {
        f[name].value = value;
      }

      if (value) {
        f.requires_st_scanner_when_audit.value = false;
        f.requires_st_scanner_when_audit.isValid = true;

        // await onChangeRequireSpinTurn({
        //     target: {name: "requires_st_scanner_when_audit", value: false}
        // })
      }

      await formHandle.validate({...f});
    } catch (err) {
      console.error(err, e)
    }
  }

  const onChangeRequireSpinTurn = async (e: any = {}) => {
    try {
      const target = e.target;
      const value = target?.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      let f: any = {}
      f[name] = f[name] || {...orderProcessForm[name]};
      f.requires_fibretrace_id_when_audit = {...orderProcessForm.requires_fibretrace_id_when_audit};

      let {error} = validate(value, orderProcessForm[name].rules);
      f[name].error = error || "";
      f[name].isValid = !error;

      if (name && !_.isNil(value)) {
        f[name].value = value;
      }

      if (value) {
        f.requires_fibretrace_id_when_audit.value = false;
        f.requires_fibretrace_id_when_audit.isValid = true;
        // await onChangeRequireFID({target: {name: "requires_fibretrace_id_when_audit", value: false}})
      }

      await formHandle.validate({...f});
    } catch (err) {
      console.error(err, e)
    }
  }

  const isShowSpinTurn = () => {
    try {
      let visible: boolean = false;

      const aud = _.find(selects.auditors.list, (v: any) => v.value === orderProcessForm.fibretrace_auditor_id.value)
      /**
       * Default check conditions to display s&t
       * @todo: recheck the logic again with backend and JIRA tickets
       */
      if (
        options?.orderDetail?.fibretrace_id
        && aud?.is_has_st_scanner
      ) {
        visible = true
      }

      return visible;

    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeAuditor = async (aud: {
    email: string,
    fibretrace_auditor_id: number,
    is_has_st_scanner: boolean
    label: string,
    name: string,
    uid: string,
    value: number
  }) => {
    try {
      const field = "fibretrace_auditor_id";
      const {error} = validate(aud, orderProcessForm.fibretrace_auditor_id.rules);

      let tmpForm = {
        requires_fibretrace_id_when_audit: {
          error: "",
          isValid: true
        },
        requires_st_scanner_when_audit:
          aud.is_has_st_scanner
            ? {
              error: "",
              isValid: true,
            } : {
              error: "",
              isValid: true,
              value: false,
            },
        fibretrace_auditor_id: {
          error: (error || ""),
          isValid: !error,
          value: aud.value
        }
      }

      setAuditor(aud)

      // tmpForm.requires_fibretrace_id_when_audit.value = false
      // tmpForm.requires_fibretrace_id_when_audit.error = ""
      // tmpForm.requires_fibretrace_id_when_audit.isValid = true
      // tmpForm.requires_st_scanner_when_audit.error = ""
      // tmpForm.requires_st_scanner_when_audit.isValid = true
      // if (!aud.is_has_st_scanner) {
      //     tmpForm.requires_st_scanner_when_audit.value = false;
      // }
      // tmpForm[field] = {
      //     ...tmpForm[field], ...{
      //         error: (error || ""),
      //         isValid: !error
      //     }
      // }
      // if (_.has(tmpForm, field)) {
      //     if (aud.value) {
      //         tmpForm[field].value = aud.value;
      //     }
      // }
      setSelects(produce(draft => {
        draft[PROCESS_SELECT.AUDITORS].selected = aud
      }))

      await formHandle.validate(tmpForm);

    } catch (err) {
      console.error(err)
    }
  }

  const formHandle = {
    validate: async (data = {}, trigger: boolean = true) => {
      let fs = formStatus;

      let tmpData = {...orderProcessForm, ...data}

      let count = 0;

      _.forEach(inventoryOrders.current, (v: any) => {
        count += v.current.length
      })

      // if (
      //     (!_.isEmpty(inventoryOrders.current) && !_.every(inventoryOrders.current, (v: any) => v.id))
      //     || (!_.isEmpty(inventoryOrders.selected) && !_.every(inventoryOrders.selected, (v: any) => LocaleStringToNumber(v.quantity) > 0))
      //     || (!_.isEmpty(inventoryOrders.selected) && count !== inventoryOrders.selected.length)
      // ) {
      //     // tmpData.input_orders.isValid = false
      // } else {
      //     // tmpData.input_orders.isValid = true
      // }

      // fs.isValid = _.every(tmpData, (v: any) => v.isValid);
      // fs.message = "";

      // setFormStatus({...formStatus, ...fs})

      setFormStatus(produce(draft => {
        draft.isValid = _.every({...orderProcessForm, ...data}, (v: any) => v.isValid);
        draft.message = ""
      }))

      setOrderProcessForm(produce(draft => {
        // draft.input_orders = tmpData.input_orders
        _.merge(draft, data)

        if (
          (!_.isEmpty(inventoryOrders.current) && !_.every(inventoryOrders.current, (v: any) => v.id))
          || (!_.isEmpty(inventoryOrders.selected) && !_.every(inventoryOrders.selected, (v: any) => LocaleStringToNumber(v.quantity) > 0))
          || (!_.isEmpty(inventoryOrders.selected) && count !== inventoryOrders.selected.length)
        ) {
          // tmpData.input_orders.isValid = false
          draft.input_orders.isValid = false
        } else {
          // tmpData.input_orders.isValid = true
          draft.input_orders.isValid = true
        }

      }))

      if (trigger) {
        // fibLogger({...fs, ...orderProcessForm})
      }

      // fibLogger({info: "OrderProcessesFormV2", ...formStatus, ...fs, form: tmpData, inventoryOrders},)
    },
    onBlur: inputHandler,
    onChange: inputHandler,
    onSelectChange: async (selected: any, field: any) => {
      try {

        let f = {...orderProcessForm},
          {error} = validate(selected.value, f[field].rules);

        f[field] = {
          ...f[field], ...{
            error: (error || ""), isValid: !error
          }
        }

        if (_.has(f, field)) {
          if (selected.value) {
            f[field].value = selected.value;
          }
        }

        setSelects(produce(draft => {
          draft[tmpSelectMapper[field]].selected = selected
        }))

        await formHandle.validate({...f});
      } catch (e) {
        console.error(e)
      }
    },
    onTemplateChange: async (value, {action, removedValue}) => {
      try {
        let tmp: any = [], f = orderProcessForm;
        let ques = {...questions};
        setTemplates({...templates, selected: value});

        ques.list = value;

        if (action === "remove-value") {
          ques.selected = _.filter(ques.selected, (o: any) => {
            return o.templateId !== removedValue.id;
          })
        }

        if (_.isArray(value) && !_.isEmpty(value)) {
          tmp = _.map(value, (v, k: number) => {
            return {
              templateId: v.id,
              fields: JSON.parse(v.content || "[]")
            }
          })
          ques.selected = tmp
        }

        // await inputHandler({
        //     target: {
        //         name: 'attributeTemplateValue',
        //         value: JSON.stringify(ques.selected)
        //     }
        // })

        setOrderProcessForm(produce(draft => {
          draft.attributeTemplateValue.value = JSON.stringify(ques.selected)
        }))

        setQuestions({...ques});

      } catch (e) {
        console.error(e)
      }
    },
    onChangeDate: async (event: any, name: string) => {
      await formHandle.validate({
        due_date: {
          value: event.format(DATE_FORMAT),
          isValid: !!event.format(DATE_FORMAT),
        }
      })
      // await formHandle.onChange({
      //     target: {
      //         name,
      //         value: event.format(DATE_FORMAT)
      //     }
      // })
    },
    onSubmit: async (event: any) => {

      try {

        setGlobalMsg({type: '', text: ''});

        setFormStatus(produce(draft => {
          draft.processing = true
        }))

        if (!orderProcessForm.due_date.value) {
          setGlobalMsg({type: Message.ERROR, text: "ERROR.VALIDATE.DUE_DATE_IS_REQUIRED"});
          setFormStatus(produce(draft => {
            draft.processing = false
          }))
          return false;
        }
        // ERROR.VALIDATE.DUE_DATE_MAX_IS_REACHED
        //Audit Deadline (Due Date) is very far in the future. Please choose a new date for the Audit Deadline.

        const formData = {
          fibretrace_manufacturer_process_id: orderProcessForm.fibretrace_manufacturer_process_id.value,
          fibretrace_auditor_id: orderProcessForm.fibretrace_auditor_id.value,
          fibretrace_company_id: orderProcessForm.fibretrace_company_id.value,
          fibretrace_manufacturer_id: orderProcessForm.fibretrace_manufacturer_id.value,
          due_date: orderProcessForm.due_date.value,
          visible: orderProcessForm.visible.value,
          notify_auditor: orderProcessForm.notify_auditor.value,
          requires_fibretrace_id_when_audit: orderProcessForm.requires_fibretrace_id_when_audit.value,
          requires_st_scanner_when_audit: orderProcessForm.requires_st_scanner_when_audit.value,
          scan_count: orderProcessForm.scan_count.value,
          attributeTemplateValue: orderProcessForm.attributeTemplateValue.value,
          input_orders: orderProcessForm.input_orders.value,
        }
        let resultReq: any;
        if (options.processID) {
          resultReq = await ORDER_API.updateOrderProcess(options.orderCode, options.processID, {...formData});
        } else {
          resultReq = await ORDER_API.createOrderProcess(options.orderCode, {...formData,});
        }
        const {error} = resultReq;
        if (error) setGlobalMsg({type: Message.ERROR, text: error});
        else if (_.isFunction(handleSubmit)) {
          handleSubmit();
          dispatch(panelSlice.actions.hide())
        }

        if (!error) {
          dispatch(panelSlice.actions.hide())
        }

        setFormStatus(produce(draft => {
          draft.processing = false
        }))


      } catch (error) {
        setGlobalMsg({type: Message.ERROR, text: error});
        setFormStatus(produce(draft => {
          draft.processing = false
        }))
        console.error(error.toString())
      }
    },

  };

  /**
   * FIP-2482
   */
  const handleManufacturer = async ({fibretrace_manufacturer_id = undefined}: {
    fibretrace_manufacturer_id: unknown
  }) => {
    try {
      let defaultManufacturer: string | number | unknown, selectedManufacturer: unknown;
      defaultManufacturer = fibretrace_manufacturer_id ?? orderProcessForm.fibretrace_manufacturer_id.value;

      setSelects(produce(draft => {
        draft.manufactures.selected = draft.manufactures.list.filter((v: any) => v.id === defaultManufacturer)
      }))

      return selectedManufacturer

    } catch (e) {
      console.error(e)
    }
  }

  const handleAuditorCompany = async ({fibretrace_company_id = undefined}: { fibretrace_company_id: unknown }) => {
    try {
      let defaultCompany: string | number | unknown, selectedCompany: unknown;

      defaultCompany = fibretrace_company_id ?? orderProcessForm.fibretrace_company_id.value;

      if (isUnclaimed) {
        defaultCompany = companyInfo?.company_id || ""
      }

      setSelects(produce(draft => {
        draft.companies.selected = draft.companies.list.filter((v: any) => v.id === defaultCompany)
      }))

      let t = _.find(selects.companies.list, (v: any) => v.id === defaultCompany)
      if (t) {
        setOrderProcessForm(produce(draft => {
          draft.fibretrace_company_id.value = t.id
        }))
      }

      return selectedCompany
    } catch (e) {
      console.error(e)
    }
  }

  const fiberTraceScanner = {
    canUseFiberTraceScanner: () => {
      try {
        return scannerFunction && options?.orderDetail?.fibretrace_id
      } catch (e) {
        console.error(e)
      }
    },
    isDisabledSwitcher: () => {
      return !fiberTraceScanner.canUseFiberTraceScanner() || !orderProcessForm.requires_fibretrace_id_when_audit.editable || orderProcessForm.requires_st_scanner_when_audit.value
    }
  }

  const canUseFiberTraceScanner = () => {
    try {
      return scannerFunction && options?.orderDetail?.fibretrace_id
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    (async () => {

      if (!_.isEmpty(inventoryOrders.selected)) {
        // f.input_orders.value = inventoryOrders.selected
        setOrderProcessForm(produce(draft => {
          draft.input_orders.value = inventoryOrders.selected
        }))
        // await inputHandler({
        //     target: {
        //         name: 'input_orders',
        //         value: inventoryOrders.selected
        //     }
        // })
      } else {
        if (_.isEmpty(inventoryOrders.current)) {
          setOrderProcessForm(produce(draft => {
            draft.input_orders.value = []
          }))
        }
      }

      if (_.size(inventoryOrders.current) >= _.size(inventoryOrders.full)) {
        setAddProduct(false)
      } else {
        setAddProduct(true)
      }

    })()
  }, [inventoryOrders]);

  useEffect(() => {
    (async () => {
      if (_.size(inputProducts) > 0) {
        let inv = {...inventoryOrders}, ip = [...inputProducts];

        let tmpList = _.differenceBy(inputProducts, inventoryOrders.full, "id")

        _.map(ip, (v: any) => {
          let tl = [..._.map(v.orders, (o: any, i: number) => {

            let oId = _.find(inv.full, (to: any) => to.id === v.id);
            if (oId) {
              let tmpO = _.find(oId.orders, (tmpO: any) => tmpO.order_code === o.order_code)
              o.original_quantity = tmpO.original_quantity;
            }
            o.min = 0;
            o.max = o.inventory;
            o.quantity = parseFloat(o.quantity).toFixed(4);
            o.value = o.order_code;
            o.label = o.order_code;
            o.ref = React.createRef();
            o.ref_b2c_visible = React.createRef();
            /**
             * @todo: work with backend to finalize
             * for now turn on for testing
             */
            // o.b2c_map_view_visible = true

            return o;
          })]

          v.label = v.name;
          v.value = v.id;

          v.list = tl;
          v.current = tl;

        });

        inv.current = [...ip];
        inv.full = [...inv.full, ...tmpList]
        inv.list = [...inv.list, ...tmpList]

        let s: any = [];
        _.map(inv.current, (ob: any) => {
          _.map(ob.current, (p: any) => {
            let o = _.pick(p, ["order_code", "quantity"]);
            let v = _.values(o);

            if (!_.some(v, isEmptyValues)) {
              s.push(o);
            }
            return p
          })
          return ob;
        })

        setInventoryOrders({...inventoryOrders, ...inv});

        setOrderProcessForm(produce(draft => {
          draft.input_orders.value = s
        }))

      }
    })()
  }, [inputProducts]);

  useEffect(() => {
    (async () => {
      await getCommonInformation();
      await productTemplateHandle.getBatch();
    })()

    return () => {

      if (manuAbortController.current) {
        manuAbortController.current.abort()
      }
      if (companyAbortController.current) {
        companyAbortController.current.abort()
      }
      if (auditTemplateAbortController.current) {
        auditTemplateAbortController.current.abort()
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!_.isEmpty(options.process) && !_.isEmpty(templates.list)) {
        await productTemplateHandle.getCurrentTemplates();
      }
    })()
    return () => {
      if (processAuditTemplateAbortController.current) {
        processAuditTemplateAbortController.current.abort()
      }
    }
  }, [templates.list])


  useEffect(() => {
    (async () => {
      await handleFetch.getManufacturerProcesses(orderProcessForm.fibretrace_manufacturer_id.value);
    })();
    return () => {
      if (manuProcessAbortController.current) {
        manuProcessAbortController.current.abort()
      }
    }
  }, [orderProcessForm.fibretrace_manufacturer_id.value])

  useEffect(() => {
    (async () => {
      await handleFetch.getCompanyAuditors(orderProcessForm.fibretrace_company_id.value, orderProcessForm.fibretrace_auditor_id.value);
    })();
    return () => {
      if (companyAuditorAbortController.current) {
        companyAuditorAbortController.current.abort()
      }
    }
  }, [orderProcessForm.fibretrace_company_id.value])

  useEffect(() => {
    (async () => {

      let tmpOrderDetail: any = {};

      if (options.orderCode) {

        if (options.processID) {
          await getProcessData();
        } else {
          await inventoryHandle.getInputProducts();
        }

        const [orderSummaryRequest, orderDetailRequest] = await Promise.all([
          ORDER_API.getOrderSummary(options.orderCode),
          ORDER_API.getOrderDetail(options.orderCode),
        ])

        if (orderSummaryRequest) {
          const orderSummary = orderSummaryRequest.data;
          setOrderSummary(orderSummary)

          _.merge(
            tmpOrderDetail,
            orderSummary.order,
            orderSummary.order_process_latest,
            orderSummary.order_process_summary
          )
        }

        /**
         * Update orderDetail
         */
        if (orderDetailRequest) {
          const orderDetail = orderDetailRequest.data;
          if (orderDetail) {
            const {fibretrace_id} = orderDetail;
            if (fibretrace_id && fibretrace_id?.value) {
              setFibretraceId(fibretrace_id.value);
            }
            tmpOrderDetail.reference_id = orderDetail.reference_id.value;
            tmpOrderDetail.retail_date = orderDetail.retail_date.value
            tmpOrderDetail.fibretrace_id = orderDetail.fibretrace_id.value
          }
        }

        setOrderDetail(tmpOrderDetail)

        setOrderProcessForm(produce(draft => {

          if (orderDetailRequest) {
            const orderDetail = orderDetailRequest.data;
            if (orderDetail) {
              const {fibretrace_id} = orderDetail;

              if (!options.processID && fibretrace_id && fibretrace_id?.value) {
                draft.requires_fibretrace_id_when_audit.value = false;
              }
            }
          }

          if (!options.processID) {
            if (options.orderDetail.fibretrace_manufacturer_process_id) {
              draft.fibretrace_manufacturer_process_id.value = options.orderDetail.fibretrace_manufacturer_process_id
              draft.fibretrace_manufacturer_process_id.isValid = true
            }
          }
        }));
      }


    })()

    return () => {

    }

  }, [options.orderCode, options.processID]);

  return (

    <>
      <dl>
        <dt>
          <label className={"mb-0"}>{translateV2("LABEL.MANUFACTURER")} <span
            className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>

          <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                         message={translateV2(`LABEL.TOOLTIP.ORDER_PROCESS.MANUFACTURER`)}
          />
        </dt>
        <dd>
          <div className="input-group">
            <Select
              styles={colourSingleStyles}
              className="basic-single mb-2 w-100"
              classNamePrefix="select"
              isDisabled={selects.manufactures.isDisabled || !orderProcessForm.fibretrace_manufacturer_id.editable}
              isLoading={selects.manufactures.isLoading}
              isClearable={selects.manufactures.isClearable}
              isSearchable={selects.manufactures.isSearchable}
              name="fibretrace_manufacturer_id"
              options={selects.manufactures.list}
              value={selects.manufactures.selected}
              onChange={(e) => {
                formHandle.onSelectChange(e, 'fibretrace_manufacturer_id').then(r => {
                  const {is_unclaimed = false} = e;

                  /**
                   * reset processes
                   */
                  setSelects(produce(draft => {
                    draft.processes.selected = ""
                  }))
                  /**
                   * Checking selected manufacturer is unclaimed or not
                   */
                  setIsUnclaimed(is_unclaimed)

                  let t: any = {
                    fibretrace_manufacturer_process_id: {
                      value: '',
                      isValid: false
                    },
                    requires_fibretrace_id_when_audit: {},
                    scan_count: {}
                  }

                  if (is_unclaimed) {

                    t.requires_fibretrace_id_when_audit.value = false;
                    t.scan_count.value = 1;

                    handleAuditorCompany({fibretrace_company_id: companyInfo.company_id}).then(async () => {
                      await handleFetch.getCompanyAuditors(
                        companyInfo.company_id as number,
                        userStore.id
                      );
                    })

                  }

                  setOrderProcessForm(produce(draft => {
                    draft.fibretrace_manufacturer_process_id.value = ''
                    draft.fibretrace_manufacturer_process_id.isValid = false
                    draft.due_date.value = !is_unclaimed ? "" : moment().format(DATE_FORMAT)
                    draft.due_date.isValid = is_unclaimed
                    draft.due_date.editable = !is_unclaimed

                    if (is_unclaimed) {
                      draft.requires_fibretrace_id_when_audit.value = false
                      draft.requires_fibretrace_id_when_audit.isValid = true

                      draft.requires_st_scanner_when_audit.value = false
                      draft.requires_st_scanner_when_audit.isValid = true
                      draft.scan_count.value = 1
                    }
                  }))

                  formHandle.validate(t)
                })
              }}
            />
          </div>
        </dd>

        <dt>
          <label>{translateV2("LABEL.PROCESS_NAME")} <span
            className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
        </dt>
        <dd>
          <div className="input-group">
            <Select
              styles={colourSingleStyles}
              className="basic-single mb-2 w-100"
              classNamePrefix="select"
              isDisabled={selects.processes.isDisabled || !orderProcessForm.fibretrace_manufacturer_process_id.editable}
              isLoading={selects.processes.isLoading}
              isClearable={selects.processes.isClearable}
              isSearchable={selects.processes.isSearchable}
              name="fibretrace_manufacturer_process_id"
              options={selects.processes.list}
              value={
                _.findIndex(selects.processes.list, (v: any) => v.id === orderProcessForm.fibretrace_manufacturer_process_id.value) !== -1
                  ? selects.processes.list[_.findIndex(selects.processes.list, (v: any) => v.id === orderProcessForm.fibretrace_manufacturer_process_id.value)]
                  : ""
              }
              onChange={(e) => formHandle.onSelectChange(e, 'fibretrace_manufacturer_process_id')}
            />
          </div>
          {orderProcessForm.fibretrace_manufacturer_process_id.error && <div className="global-msg">
                            <span
                                className="error">{translateV2(orderProcessForm.fibretrace_manufacturer_process_id.error)}</span>
          </div>}
        </dd>

        <dt>
          <label className={"mb-0"}>{translateV2("LABEL.ORDER_PROCESS.AUDIT_COMPANY")} <span
            className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
          <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                         message={translateV2(`LABEL.TOOLTIP.ORDER_PROCESS.AUDIT_COMPANY`)}
          />
        </dt>
        <dd>
          <div className="input-group">

            <Select
              styles={colourSingleStyles}
              className="basic-single mb-2 w-100"
              classNamePrefix="select"
              isDisabled={isUnclaimed || selects.companies.isDisabled || !orderProcessForm.fibretrace_company_id.editable}
              isLoading={selects.companies.isLoading}
              isClearable={selects.companies.isClearable}
              isSearchable={selects.companies.isSearchable}
              name="fibretrace_company_id"
              options={selects.companies.list}
              value={selects.companies.selected}
              onChange={(e) => formHandle.onSelectChange(e, 'fibretrace_company_id')}
            />

          </div>
        </dd>

        <dt>
          <label>{translateV2("LABEL.AUDITOR")} <span
            className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
        </dt>
        <dd>
          <div className="input-group">
            <Select
              styles={colourSingleStyles}
              className="basic-single mb-2 w-100"
              classNamePrefix="select"
              isDisabled={isUnclaimed || selects.auditors.isDisabled || !orderProcessForm.fibretrace_auditor_id.editable}
              isLoading={selects.auditors.isLoading}
              isClearable={selects.auditors.isClearable}
              isSearchable={selects.auditors.isSearchable}
              name="fibretrace_auditor_id"
              options={selects.auditors.list}
              value={selects.auditors.selected}
              onChange={async (e) => {
                await handleChangeAuditor(e)
              }}
            />

          </div>
          {orderProcessForm.fibretrace_auditor_id.error && <div className="global-msg">
                            <span
                                className="error">{translateV2(orderProcessForm.fibretrace_auditor_id.error)}</span>
          </div>}
        </dd>

        <dt>
          <label>{translateV2("LABEL.AUDIT_DEADLINE_DUE_DATE")} <span
            className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
        </dt>
        <dd>
          <div className="input-group">
            <Datetime
              ref={refDueDate}
              timeFormat={false}
              dateFormat={DATE_FORMAT} closeOnSelect={true}
              className={`${orderProcessForm.due_date.editable ? "bg-transparent" : "disabled"}`}
              inputProps={{
                autoComplete: "off",
                readOnly: true,
                disabled: !orderProcessForm.due_date.editable
              }}
              value={orderProcessForm.due_date.value}
              onChange={(val) => formHandle.onChangeDate(val, 'due_date')}
            />
          </div>
          {orderProcessForm.due_date.error && <div className="global-msg">
                            <span
                                className="error">{translateV2(orderProcessForm.due_date.error)}</span>
          </div>}
        </dd>

        <dt>
          <label>{translateV2("LABEL.VISIBLE_ON_B2C")}</label>
        </dt>
        <dd>
          <div className="order-condition">
            <div className="input-group">
              <Switch val={orderProcessForm.visible.value} name="visible"
                      disabled={!orderProcessForm.visible.editable}
                      change={formHandle.onChange}
                      options={[translateV2("LABEL.YES"), translateV2("LABEL.NO")]}/>
            </div>
          </div>
          <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                         message={translateV2(`LABEL.TOOLTIP.ORDER_PROCESS.VISIBLE_ON_B2C`)}
          />
        </dd>

        <dt>
          <label>{translateV2("LABEL.NOTIFY_AUDITOR")}</label>
        </dt>
        <dd>
          <div className="order-condition">
            <div className="input-group">
              <Switch val={orderProcessForm.notify_auditor.value} name="notify_auditor"
                      disabled={!orderProcessForm.notify_auditor.editable}
                      change={formHandle.onChange}
                      options={[translateV2("LABEL.YES"), translateV2("LABEL.NO")]}/>
            </div>
          </div>
        </dd>

        <dt
          style={fiberTraceScanner.canUseFiberTraceScanner() && orderProcessForm.requires_st_scanner_when_audit.value ? {opacity: 0.35} : {}}
          className={!fiberTraceScanner.canUseFiberTraceScanner() ? " order-fib-title is-locked " : ""}>
          <OverlayTrigger
            overlay={<Tooltip
              bsPrefix={"fib-bs-tooltip tooltip"}
              id={`procformtit-scanner-fn`}>
              {translateV2(!scannerFunction ? "LABEL.UNLOCK_VERIFIED" : "LABEL.REQUIRE_FIBRETRACE_SCANNER.TOOLTIP")}</Tooltip>}>

            <label
              onClick={async (event) => {
                if (!scannerFunction) navigate('/profile?t=PACKAGE');
              }}
            >{translateV2("LABEL.REQUIRE_FID")}</label>
          </OverlayTrigger>

          {!scannerFunction && <OverlayTrigger
              overlay={<Tooltip
                bsPrefix={"fib-bs-tooltip tooltip"}
                id={`procformtitico-scanner-fn`}>
                {translateV2("LABEL.UNLOCK_VERIFIED")}</Tooltip>}>
              <Image
                  src={ASSETS.IconLockGray}
                  loading={"lazy"} height={24} decoding={"async"}
                  style={{}} className={""}
                  onClick={async (event) => {
                    navigate('/profile?t=PACKAGE');
                  }}
              />
          </OverlayTrigger>}
        </dt>

        <dd className={scannerFunction && orderDetail.fibretrace_id ? "" : " order-fib-field is-locked "}>
          <div className="order-condition">
            <div className="input-group">
              <Switch val={orderProcessForm.requires_fibretrace_id_when_audit.value}
                      disabled={fiberTraceScanner.isDisabledSwitcher()}
                      name="requires_fibretrace_id_when_audit" change={onChangeRequireFID}
                      options={[translateV2("LABEL.YES"), translateV2("LABEL.NO")]}/>
            </div>
          </div>
        </dd>

        {
          !isShowSpinTurn()
            ? null
            : <>
              <dt
                style={orderProcessForm.requires_fibretrace_id_when_audit.value ? {opacity: 0.35} : {}}
                className={isUnclaimed ? " order-fib-field is-locked " : ""}>
                <OverlayTrigger
                  overlay={<Tooltip
                    bsPrefix={"fib-bs-tooltip tooltip"}
                    id={`spinturn-scanner-fn`}>
                    {translateV2("LABEL.REQUIRE_SPIN_AND_TURN_SCANNER.TOOLTIP")}</Tooltip>}>

                  <label>{translateV2("LABEL.REQUIRE_SPIN_AND_TURN_SCANNER")}</label>
                </OverlayTrigger>
              </dt>
              <dd
                className={isUnclaimed || orderProcessForm.requires_fibretrace_id_when_audit.value ? " order-fib-field is-locked " : "  "}>
                <div className="order-condition">
                  <div className="input-group">
                    <Switch val={orderProcessForm.requires_st_scanner_when_audit.value}
                            disabled={isUnclaimed || !orderProcessForm.requires_st_scanner_when_audit.editable || orderProcessForm.requires_fibretrace_id_when_audit.value}
                            name="requires_st_scanner_when_audit" change={onChangeRequireSpinTurn}
                            options={[translateV2("LABEL.YES"), translateV2("LABEL.NO")]}/>
                  </div>
                </div>
              </dd>
            </>
        }

        <dt>
          <OverlayTrigger
            overlay={<Tooltip
              bsPrefix={"fib-bs-tooltip tooltip"}
              id={_.uniqueId(`scan_count_`)}>
              {translateV2("LABEL.TOOLTIP.ORDER_PROCESS.NO_OF_AUDITS")}</Tooltip>}>

            <label>{translateV2("LABEL.NO_OF_AUDITS")} <span
              className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span></label>
          </OverlayTrigger>
        </dt>
        <dd>
          <div className="input-group">
            <input type="text"
                   pattern={"^[1-9]\\d*$"}
                   className="form-control"
                   disabled={isUnclaimed || !orderProcessForm.scan_count.editable}
                   value={orderProcessForm.scan_count.value}
                   onChange={formHandle.onChange} name="scan_count"/>
          </div>
          {orderProcessForm.scan_count.error && <div className="global-msg">
                            <span
                                className="error">{translateV2(orderProcessForm.scan_count.error)}</span>
          </div>}
          <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                         message={translateV2(`LABEL.TOOLTIP.ORDER_PROCESS.NO_OF_AUDITS`)}
          />
        </dd>


        {
          Boolean(!companyInfo.company_package_payment.audit_template && !JSON.parse(localStorage.getItem("audit_template_enable") + ""))
            ? <></>
            : <>
              <dt>
                {translateV2("LABEL.AUDIT_TEMPLATE")}&nbsp;{translateV2("LABEL.OPTIONAL")}

                <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                               message={translateV2(`LABEL.TOOLTIP.ORDER_PROCESS.TEMPLATE`)}
                />
                <p>
                  <a className={`text-underline text-italic`}
                     href={"https://www.youtube.com/watch?v=Y1Q_uD1Zuuk"}
                     rel={"noopener noreferrer"}
                     target={"_blank"} onClick={(e) => {
                    e.preventDefault();
                    dispatch(showVideoModal({
                      ...videoModalGlobalState,
                      isShow: true,
                      videoID: "Y1Q_uD1Zuuk",
                    }))
                  }}>{translateV2("LABEL.SHOW_ME_THE_WAY")}</a>
                </p>
              </dt>
              <dd>
                <Select
                  isDisabled={!orderProcessForm.attributeTemplateValue.editable}
                  styles={colourSingleInlineStyles}
                  className="basic-multi-select mt-2 mb-2 w-100"
                  classNamePrefix="select"
                  name="attributeTemplateValue"
                  isMulti
                  options={templates.list}
                  value={templates.selected}
                  onChange={async (value, {action, removedValue}) => {
                    setTemplateChangeValue({value, action, removedValue});

                    if (action === "remove-value") {
                      setConfirmationModal({
                        ...confirmationModal,
                        isShow: true,
                        title: Message.WARNING,
                        type: Message.INFO,
                        message: 'LABEL.CONFIRMATION_DELETE_GENERAL.AUDIT_TEMPLATE',
                      })
                    } else {
                      await formHandle.onTemplateChange(value, {action, removedValue})
                    }

                    await formHandle.validate()
                  }}
                />
              </dd>
            </>
        }


        <dt>
          <label className={"mb-0"}>{translateV2("LABEL.INPUT_ORDERS")}</label>
          <GlobalMessage className={"font-italic mb-1"} errorClassName={"success"}
                         message={translateV2(`LABEL.TOOLTIP.ORDER_PROCESS.INPUT_ORDERS_FIRST_STEP`)}
          />
          <p>
            <a className={`text-underline text-italic`} href={"https://www.youtube.com/watch?v=sSdjMFuY0XY"}
               rel={"noopener noreferrer"}
               target={"_blank"} onClick={(e) => {
              e.preventDefault();
              dispatch(showVideoModal({
                ...videoModalGlobalState,
                isShow: true,
                videoID: "sSdjMFuY0XY",
              }))
            }}>{translateV2("LABEL.SHOW_ME_THE_WAY")}</a>
          </p>
        </dt>
        <dd>

          <Button
            variant={"outline-success"}
            disabled={!addProduct}
            onClick={inventoryHandle.onAddProduct}
          >
            <FontAwesomeIcon
              icon={faPlusCircle}/>&nbsp;{translateV2("LABEL.ADD_PRODUCT")}
          </Button>

          {
            _.map(inventoryOrders.current, (product: any, productIndex: number) =>

              <div key={`product-${productIndex}`} className={"input-orders-container"}>

                <div className="input-orders-col">
                  <div className="input-orders-product">

                    <Button
                      className={"input-orders-product-remove"}
                      variant={"outline-success"}
                      onClick={(e) => inventoryHandle.onRemoveProduct(e, productIndex)}
                    >
                      <FontAwesomeIcon icon={faTrash}/>
                    </Button>

                    <Select
                      // isDisabled={templates.isDisabled}
                      // isLoading={templates.isLoading}
                      // isClearable={templates.isClearable}
                      // isSearchable={templates.isSearchable}
                      placeholder={translateV2("LABEL.SELECT_PRODUCT")}
                      value={
                        _.findIndex(inventoryOrders.list, (v: any) => v.value === product.value) !== -1
                          ? inventoryOrders.list[_.findIndex(inventoryOrders.list, (v: any) => v.value === product.value)]
                          : ""
                      }
                      styles={colourSingleStyles}
                      className="basic-multi-select input-orders-select-product"
                      classNamePrefix="select"
                      name="input_orders"
                      options={inventoryOrders.list}
                      onChange={(e, f) => {
                        inventoryHandle.onSelectProduct(e, f, productIndex);
                        inventoryHandle.onAdd(productIndex);

                        if (_.size(inventoryOrders.current[productIndex].list[0]) === 1 && inventoryOrders.current[productIndex].list[0]) {
                          inventoryHandle.onSelectOrder(inventoryOrders.current[productIndex].list[0], {
                            "action": "select-option",
                            "name": "input_orders"
                          }, 0, productIndex)
                        }
                      }}
                    />

                    <Button
                      variant={"outline-success"} className={"ml-2 text-nowrap"}
                      disabled={_.isEmpty(inventoryOrders.current[productIndex]) || (_.size(inventoryOrders.current[productIndex].current) >= _.size(inventoryOrders.current[productIndex].orders))}
                      onClick={() => inventoryHandle.onAdd(productIndex)}
                    >
                      <FontAwesomeIcon
                        icon={faPlusCircle}/>&nbsp;{translateV2("LABEL.ADD_ORDER")}
                    </Button>
                  </div>
                </div>

                <div className="input-orders-col">
                  <div className="input-orders-list">
                    {
                      _.map(product.current, (inventory: any, itemIndex: number) => {

                          const checkboxId = _.uniqueId(`order-check-`);

                          return <div key={`inventory-${itemIndex}`}
                                      className={"input-orders-list-container"}>

                            <div className={"input-orders-item "}>

                              <label
                                htmlFor={checkboxId}
                                className={"input-orders-checked"}
                              >
                                <input
                                  id={checkboxId}
                                  ref={inventory.ref_b2c_visible}
                                  type={"checkbox"}
                                  className={""}
                                  defaultChecked={inventory.b2c_map_view_visible}
                                  onChange={async (e) => {
                                    await inventoryHandle.onCheckB2CVisible(e, itemIndex, productIndex)
                                  }}
                                />
                                <span/>
                              </label>

                              <div className={"d-flex flex-wrap justify-around align-items-center"}>

                                <Select
                                  // isDisabled={templates.isDisabled}
                                  // isLoading={templates.isLoading}
                                  // isClearable={templates.isClearable}
                                  // isSearchable={templates.isSearchable}
                                  placeholder={translateV2("LABEL.SELECT_ORDER")}
                                  value={
                                    _.findIndex(product.list, (it: any) => it.order_code === inventory.order_code) !== -1
                                      ? product.list[_.findIndex(product.list, (it: any) => it.order_code === inventory.order_code)]
                                      : _.size(product.list) === 1 ? product.list[0] : ""
                                  }
                                  styles={colourSingleStyles}
                                  className="basic-multi-select input-orders-select"
                                  classNamePrefix="select"
                                  name="input_orders"
                                  options={product.list}
                                  onChange={(e, f) => inventoryHandle.onSelectOrder(e, f, itemIndex, productIndex)}
                                />

                                <span
                                  className={"pl-2 fz-12 text-muted"}>{translateV2("LABEL.ADD")}</span>

                                <input
                                  ref={inventory.ref}
                                  type={"text"}
                                  disabled={!inventory.order_code}
                                  className={"form-control input-orders-quantity"}
                                  min={inventory.min} max={inventory.max}
                                  defaultValue={NumberToLocaleString(inventory.quantity || "")}
                                  onFocus={(e) => inventoryHandle.onFocusInputQuantity(e, itemIndex, productIndex)}
                                  onChange={(e) => inventoryHandle.onInputQuantity(e, itemIndex, productIndex)}
                                  onBlur={(e) => inventoryHandle.onBlurInputQuantity(e, itemIndex, productIndex)}
                                />

                                <div className={"d-flex align-items-center mt-2 w-100"}>
                                                            <span
                                                              className={"pr-2 fz-12 text-muted"}>{translateV2("LABEL.REMAINING")}:</span>

                                  {(inventory.max > 0) && <span
                                      style={{
                                        whiteSpace: "nowrap",
                                        paddingRight: "8px"
                                      }}>{NumberToLocaleString(inventory.inventory)}{translateV2("LABEL.SLASH")}{NumberToLocaleString(inventory.original_quantity)}</span>}
                                  {/*{translateV2( "LABEL.NUMBER_OF", "", {1: inventory.max})}*/}

                                  {inventory.uom && <span
                                      className={"fz-12"}
                                      style={{
                                        whiteSpace: "nowrap",
                                        paddingRight: "8px"
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: inventory.uom + `(s)`
                                      }}
                                  />}

                                  <Button
                                    className={"input-orders-remove"}
                                    variant={"outline-success"}
                                    onClick={(e) => inventoryHandle.onRemove(e, itemIndex, productIndex)}
                                  >
                                    <FontAwesomeIcon icon={faTrash}/>
                                  </Button>
                                </div>

                              </div>

                            </div>

                          </div>
                        }
                      )
                    }

                  </div>
                </div>

              </div>
            )
          }


        </dd>


        <dt>
          <hr/>
        </dt>
        <dd style={{color: "red", fontStyle: "italic"}}><span
          className={"text-red"}>{translateV2('LABEL.ASTERISK')}</span> : {translateV2('LABEL.MANDATORY')}
        </dd>


      </dl>

      {globalMsg.type &&
          <div className="global-msg-wrap text-center">
            {globalMsg.type === Message.ERROR && <img src={erroricon} alt=""/>}

              <p className={`${globalMsg.type === Message.ERROR ? 'text-red error' : ''}`}

                 dangerouslySetInnerHTML={{
                   __html:
                     translateV2(globalMsg.text, "", ({code, text}) => {
                       if (code === "ERROR.VALIDATE.CREDIT_BALANCE_NOT_ENOUGH") {
                         return `<a class="text-red" href="${APP_ROUTES_PATH.PROFILE + "?t=SERVICES"}"}>
                                               ${translateV2(code)}
                                           </a><br/>`
                       }
                       if (code === "ERROR.VALIDATE.PARTNER_CREDIT_BALANCE_NOT_ENOUGH") {
                         return translateV2(code, "", {
                           '0': orderDetail?.owner_company_name || ""
                         })
                       }
                       return text + "<br/>";
                     })
                 }}
              />
          </div>
      }

      <Button type="button"
              variant={"success"}
              style={{width: "100%"}}
              disabled={!formStatus.isValid || formStatus.processing}
              onClick={async (e) => {
                //console.log(orderProcessForm.input_orders.value, inputOrdersFormat(originalOrders), {options})

                if (options.orderCode && orderDetail?.is_completed
                  && !_.isEqual(inputOrdersFormat(originalOrders), orderProcessForm.input_orders.value)) {
                  setPublishTransCert({
                    ...publishTransCert,
                    isShow: true,
                    title: "LABEL.PUBLISH_TRANSACTION_CERTIFICATE",
                    type: Message.ERROR,
                    message: "LABEL.PUBLISH_TRANSACTION_CERTIFICATE.NOTE",
                  })
                } else {
                  if (isUnclaimed) {
                    setUnclaimedConfirmModal({
                      ...unclaimedConfirmModal,
                      isShow: true,
                      title: "LABEL.CONFIRMATION_SUBMIT_AUDITS_FOR_UNCLAIMED_COMPANY",
                      type: Message.ERROR,
                      message: "LABEL.CONFIRMATION_SUBMIT_AUDITS_FOR_UNCLAIMED_COMPANY.NOTE",
                    })
                  } else {
                    await formHandle.onSubmit(e)

                  }
                }
              }}>
        {formStatus.processing && <FontAwesomeIcon icon={faSpinner} className="mr-1" spin/>}
        {translateV2("LABEL.SUBMIT")}
      </Button>


      <ModalConfirmation
        style={{zIndex: 999999999}}
        size={'md'}
        isShow={confirmationModal.isShow}
        title={confirmationModal.title}
        type={confirmationModal.type}
        message={confirmationModal.message}
        messageData={confirmationModal.messageData}
        onSubmit={async () => {
          await formHandle.onTemplateChange(
            templateChangeValue.value, {
              action: templateChangeValue.action,
              removedValue: templateChangeValue.removedValue
            })
          setConfirmationModal({...DEFAULT_MODAL})
        }}
        onClose={() => setConfirmationModal({...DEFAULT_MODAL})}
      >

      </ModalConfirmation>

      <ModalConfirmation
        style={{zIndex: 999999999}}
        size={'md'}
        isShow={publishTransCert.isShow}
        title={publishTransCert.title}
        type={publishTransCert.type}
        message={publishTransCert.message}
        messageData={publishTransCert.messageData}
        confirmButton={{
          visible: true,
          label: "LABEL.UNDERSTAND",
          variant: "success"
        }}
        cancelButton={{
          visible: false
        }}
        onSubmit={async () => {
          try {
            blockPage(true, {style: {zIndex: 9999999999},})
            formHandle.onSubmit({}).then(() => {
              setPublishTransCert({...DEFAULT_MODAL})
            })
          } catch (e) {
            console.error(e.toString())
            dispatch(showMessage({
              style: {zIndex: 999999999},
              isShow: true,
              message: e.toString(),
              title: "LABEL.ERROR",
              type: Message.ERROR
            }))
          }
        }}
        onClose={() => {
          blockPage(true, {style: {zIndex: 9999999999},})
          formHandle.onSubmit({}).then(() => {
            setPublishTransCert({...DEFAULT_MODAL})
          })
        }}
      >

      </ModalConfirmation>

      <ModalConfirmation
        style={{zIndex: 999999999}}
        size={'md'}
        isShow={unclaimedConfirmModal.isShow}
        title={unclaimedConfirmModal.title}
        type={unclaimedConfirmModal.type}
        message={unclaimedConfirmModal.message}
        messageData={unclaimedConfirmModal.messageData}
        closeIconVisible={true}
        confirmButton={{
          visible: true,
          label: "LABEL.UNDERSTAND",
          variant: "success"
        }}
        cancelButton={{
          visible: false
        }}
        onSubmit={async () => {
          try {
            blockPage(true, {style: {zIndex: 9999999999},})
            formHandle.onSubmit({}).then(() => {
              setUnclaimedConfirmModal({...DEFAULT_MODAL})
            })
          } catch (e) {
            console.error(e.toString())
            dispatch(showMessage({
              style: {zIndex: 999999999},
              isShow: true,
              message: e.toString(),
              title: "LABEL.ERROR",
              type: Message.ERROR
            }))
          }
        }}
        onClose={() => {
          setUnclaimedConfirmModal({...DEFAULT_MODAL})
        }}
      >

      </ModalConfirmation>

    </>
  );
}

export default OrderProcessFormV2;
