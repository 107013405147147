import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {translateV2} from "@src/helpers";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {importPartnersSlice, importPartnersState} from "@redux/reducers/importPartnersSlice";
import {
    importPartnersProgressSlice,
    importPartnersProgressState,
    validateImportPartnersFileAsync
} from "@redux/reducers/importPartnersProgressSlice";
import _ from "lodash-es";
import {unwrapResult} from "@reduxjs/toolkit";
import {userIntroState} from "@redux/reducers/userIntroSlice";
import {partnerImportAfterClose} from "@v2components/PartnerImport/PartnerImportHelper";
import {PARTNER_API} from "@api/Partner";
import {showVideoModal, videoModalState} from "@redux/reducers/videoModalSlice";
import {isMobile} from "react-device-detect";

/**
 * @todo:
 * config FilePartnersImportUrl
 */
const PartnerFileCTA = () => {

    const dispatch = useAppDispatch();

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);
    const user = useAppSelector(userIntroState);
    const videoModalGlobalState = useAppSelector(videoModalState)

    const onClickDownloadCSV = async (e) => {
        try {
            e.preventDefault();

            const {data} = await PARTNER_API.getPartnersImportTemplate();

            if (data) {
                const {file: FilePartnersImportUrl} = data;

                const csvFileUrl = FilePartnersImportUrl;
                const downloadLink = document.createElement('a');

                // downloadLink.download = FilePartnersImportName;
                downloadLink.href = csvFileUrl;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <>
            <div className={`d-flex flex-column flex-sm-row justify-content-sm-between align-items-center w-100`}>

                <div className={`d-sm-inline-flex mb-3 mb-sm-0`}
                  style={
                    isMobile ? { width:"100%" }:{}
                  }
                >
                    <a className={`text-green text-underline`}
                       href={`#`}
                       onClick={(e) => {
                           /**
                            * US change - show youtube "how to do"
                            */
                           // onClickDownloadCSV(e);
                           dispatch(showVideoModal({
                               ...videoModalGlobalState,
                               isShow: true,
                               videoID: translateV2("LABEL.PARTNER.IMPORT.SHOW_ME_THE_WAY.YOUTUBE_ID"),
                           }))
                       }}
                    >
                        {translateV2("LABEL.PARTNER.IMPORT.SHOW_ME_THE_WAY")}
                    </a>
                </div>

                <div
                    className={`d-flex d-sm-inline-flex flex-row justify-content-between justify-content-sm-end align-items-baseline`}
                    style={
                      isMobile ? { width:"100%" }:{}
                    }>
                    <Button
                        className={`fibre-landscape-padding fibre-border-radius fibre-btn-outline-dark`}
                        variant="outline-dark"
                        onClick={() => {
                            dispatch(importPartnersSlice.actions.hide())
                            dispatch(importPartnersProgressSlice.actions.reset())

                            partnerImportAfterClose()
                        }}>
                        {translateV2("LABEL.CANCEL")}
                    </Button>


                    <Button variant="success" className={"fibre-btn-success fibre-border-radius text-nowrap ml-3"}
                            disabled={!(importPartnersProgress.stepData.choose_file.file instanceof File)}
                            onClick={async () => {

                                dispatch(validateImportPartnersFileAsync({
                                    partner_list: importPartnersProgress.stepData.choose_file.file
                                })).then(unwrapResult)
                                    .then((data, error) => {
                                        if (!_.isEmpty(data) && !error) {

                                            /**
                                             * Set modalTitle based on checkFileStatus
                                             */
                                            let modalTitle = !error ? "LABEL.PARTNER.IMPORT.PREVIEW" : "LABEL.PARTNER.IMPORT.VALIDATE_FILE.HEADER"
                                            dispatch(importPartnersSlice.actions.setTitle({title: modalTitle}))

                                            /**
                                             * To make life easier, dispatch several times with immerjs
                                             */

                                            // dispatch(importPartnersProgressSlice.actions.setPartnersList({
                                            //     partner_list_formatted: [...data]
                                            // }))


                                        } else {
                                            dispatch(importPartnersSlice.actions.setTitle({title: translateV2("LABEL.PARTNER.IMPORT.VALIDATE_FILE.HEADER"),}))

                                            dispatch(importPartnersProgressSlice.actions.setErrorMessage({
                                                message: error
                                            }))
                                        }
                                    })
                                    .catch((error: any) => {
                                      console.error(error);
                                      dispatch(importPartnersSlice.actions.setTitle({title: translateV2("LABEL.PARTNER.IMPORT.IMPORT_FAILED"),}))
                                    })


                            }}>
                        {translateV2("LABEL.PARTNER.IMPORT.UPLOAD_PREVIEW")}
                    </Button>
                </div>

            </div>
        </>
    )
}

export default PartnerFileCTA;
