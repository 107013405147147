import React, {useEffect, useState} from "react";
import {Alert, ProgressBar} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@redux/hooks/hooks";
import {importPartnersState} from "@redux/reducers/importPartnersSlice";
import {
    importPartnersProgressState,
    ImportStatusEnum,
    InvitationStatusEnum
} from "@redux/reducers/importPartnersProgressSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash-es";
import {translateV2} from "@src/helpers";

const PartnerInviting = () => {

    const dispatch = useAppDispatch();

    const importPartners = useAppSelector(importPartnersState);
    const importPartnersProgress = useAppSelector(importPartnersProgressState);

    const [progress,setProgress] = useState(0);

    useEffect(() => {

        const t = setInterval(() => {
            if (progress <= 97) {
                setProgress(prev => {
                    return ++prev;
                })
            }
        }, 50)

        return () => {
            clearInterval(t)
        }

    }, [progress, importPartnersProgress.stepData.importing.progress])

    return (
        <>
            <p>{translateV2("LABEL.PARTNER.IMPORT.INVITATION.WAIT")}</p>

            <div className="ml-4 mr-4">
                <ProgressBar
                    className={`fib-progress-bar mt-4 mb-3`}
                    variant={"success"}
                    now={progress}
                    animated={true}
                />
            </div>

        </>
    )
}

export default PartnerInviting;